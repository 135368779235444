// OrderHistory.js
import {Flex, Text } from "@chakra-ui/react";
import { CircleCheck } from "lucide-react";


const OrderHistory = ({ order }) => {

  return (
    <>
      
                  <Flex
                    className="history-log"
                    direction={"column"}
                    p={8}
                    flex={1}
                    gap={"16px"}>
                    {order?.historyLog?.length > 0 ? (
                      order.historyLog
                        .slice()
                        .reverse()
                        .map((logEntry, index) => (
                          <Flex
                            gap={8}
                            w="100%"
                            alignItems={"stretch"}
                            className="log"
                            key={index}>
                            <Flex className="logIcon" alignItems={"center"}>
                              <CircleCheck size={16} color="var(--success)" />
                            </Flex>
                            <Flex
                              w={"100%"}
                              key={index}
                              direction="column"
                              bg="var(--grey100)"
                              p={4}
                              borderRadius={8}>
                              <Text fontWeight="500">{logEntry.action}</Text>
                              <Text fontSize="12px" color="var(--grey500)">
                                {new Date(logEntry.timestamp).toLocaleString()}{" "}
                                by {logEntry.user}
                              </Text>
                            </Flex>
                          </Flex>
                        ))
                    ) : (
                      <Text>No history available for this order.</Text>
                    )}
                  </Flex>
    

     
    </>
  );
};

export default OrderHistory;
