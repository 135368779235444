import React, { useState, useEffect } from "react";
import {
  Flex,
  Input,
  Table,
  Tr,
  Td,
  Thead,
  Th,
  Tbody,
  Tooltip,
  Button,
  Box,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { useAppContext } from "../../contexts/AppContext";
import { useStockContext } from "../../contexts/StockContext";
import { updateDoc, doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import MenuSwitcherWithoutOrdering from "../menu/MenuSwitcherWithoutOrdering";
import { CircleHelp, Info } from "lucide-react";

const StockList = () => {
  const { mainLanguage, selectedRestaurantId, mainCategoryName, settings, menuAdminBarZIndex } = useAppContext();
  
  const {
    filteredStockItems,
    searchStockItem,
    setSearchStockItem,
    cleanUndefinedFields,
    updateItemPriceInItemsCollection,
  } = useStockContext();

  const [localStockItems, setLocalStockItems] = useState(filteredStockItems);
  const [desiredMarkup, setDesiredMarkup] = useState(4);

  useEffect(() => {
    setLocalStockItems(filteredStockItems);
  }, [filteredStockItems]);

  // Mettre à jour le Markup à partir des settings une fois chargés
  useEffect(() => {
    if (settings?.salesSettings?.desiredMarkup) {
      setDesiredMarkup(settings.salesSettings.desiredMarkup);
    }
  }, [settings]);



  // Fonction pour gérer le changement d'input et la mise à jour Firestore
  const handleInputChange = (e, itemId, field) => {
    const newValue = e.target.value;

    // Mettre à jour l'état local sans convertir immédiatement en nombre pour permettre la saisie de décimales
    const updatedItems = localStockItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, [field]: newValue };
      }
      return item;
    });

    setLocalStockItems(updatedItems);
  };



  const handleBlurAndFormat = async (e, itemId, field) => {
    let newValue = e.target.value;
  
    // Si c'est un champ qui doit être formaté en nombre
    if (["AM", "content", "cost", "price"].includes(field)) {
      // Remplacer la virgule par un point pour les décimales et s'assurer que c'est un nombre
      newValue = parseFloat(newValue.replace(",", ".")) || 0;
  
      // Formatage avec 2 décimales et re-remplacement du point par une virgule
      newValue = newValue.toFixed(2).replace(".", ",");
    }
  
    // Mettre à jour l'état local (affichage dans l'interface)
    const updatedItems = localStockItems.map((item) => {
      if (item.id === itemId) {
        return { ...item, [field]: newValue };
      }
      return item;
    });
  
    setLocalStockItems(updatedItems);
  
    // Ensuite, sauvegarde dans Firestore comme tu le fais dans handleBlur
    const itemRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`,
      itemId
    );
  
    try {
      const itemDoc = await getDoc(itemRef);
  
      if (itemDoc.exists()) {
        await updateDoc(itemRef, { [field]: newValue });
      } else {
        const stockData = {
          id: itemId,
          [field]: newValue,
          createdAt: new Date(),
        };
        const cleanedStockData = cleanUndefinedFields(stockData);
        await setDoc(itemRef, cleanedStockData);
      }
  
    } catch (error) {
      console.error("Erreur lors de la mise à jour du stock:", error);
    }
  };
  
  

  // Fonction pour formater les nombres en chaîne avec 2 décimales et une virgule
  const formatNumber = (num) => {
    if (isNaN(num)) return "";
    return num.toFixed(2).replace(".", ",");
  };
  const roundUpToNextTenth = (num) => {
    return Math.ceil(num * 10) / 10; // Ne pas utiliser toFixed ici pour conserver un nombre
  };
  const formatNumberWithComma = (num) => {
    return num.toFixed(2).replace(".", ",");
  };
  
  
  const ensureTwoDecimalsWithComma = (num) => {
    // Convertir en nombre, forcer 2 décimales, puis remplacer le point par une virgule
    return parseFloat(num).toFixed(2).replace(".", ",");
  };

  const handleUpdatePrice = async (itemId, suggestedPrice) => {
    try {
      // Forcer le prix à toujours être une chaîne avec 2 décimales et une virgule
      const formattedPrice = ensureTwoDecimalsWithComma(suggestedPrice);  // On force la virgule et 2 décimales
  
      // Mettre à jour le prix dans la collection /items
      await updateItemPriceInItemsCollection(itemId, formattedPrice);
  
      // Mettre à jour l'état local pour refléter la modification
      const updatedItems = localStockItems.map((item) => {
        if (item.id === itemId) {
          return { ...item, price: formattedPrice };  // Utiliser la version avec virgule pour l'affichage
        }
        return item;
      });
      setLocalStockItems(updatedItems);  // Actualiser l'état local avec le nouveau prix
  
      // Mettre à jour également le champ 'price' dans la collection /stock
      const stockRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`, itemId);
  
      // Sauvegarder dans Firebase avec la virgule
      await updateDoc(stockRef, {
        price: formattedPrice  // Sauvegarder directement avec une virgule dans Firebase
      });
  
      console.log(`Price updated successfully for item: ${itemId} in both /items and /stock`);
  
    } catch (error) {
      console.error("Erreur lors de la mise à jour du prix dans items et stock:", error);
    }
  };

  // Sauvegarder le changement de desiredMarkup dans Firestore
  const handleMarkupChange = async (e) => {
    const newMarkup = parseFloat(e.target.value);
    setDesiredMarkup(newMarkup);

    try {
      const settingsRef = doc(db, `restaurants/${selectedRestaurantId}/settings/config`);
      await updateDoc(settingsRef, {
        "salesSettings.desiredMarkup": newMarkup,  // Sauvegarder dans Firestore
      });
      console.log("desiredMarkup mis à jour dans Firestore:", newMarkup);
    } catch (error) {
      console.error("Erreur lors de la mise à jour de desiredMarkup dans Firestore:", error);
    }
  };
  

  return (
    <Flex direction="column" gap={4} flex={1}>
      <Flex alignItems="center" gap={4}>
        <h3>Product sales assistant</h3>
      </Flex>
      

      <Flex
        justifyContent="space-between"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        position={"sticky"}
        top={0}
        bg={"white"}
        py={4}
        zIndex={menuAdminBarZIndex}
        borderBottom="1px solid var(--borders)"
        mx={{ desktop: "0", mobile: -8 }}
        px={{ desktop: 0, mobile: 8 }}
        w={{ desktop: "100%", mobile: "100vw" }}
      >
      
      <MenuSwitcherWithoutOrdering />      
      <Flex>
      
            <InputGroup>
            <InputLeftAddon h="42px !important" bg="var(--grey50)" border="none" boxShadow="0 0 0 1.5px var(--borders)">Markup</InputLeftAddon>
            <Input
              maxW={"50px"}
              w={"50px"}
              h="42px !important"
              className="markup"
              textAlign="center"
              borderRadius={"0 8px 8px 0 !important"}
              min="1"
              type="number"
              value={desiredMarkup || 4}
              onChange={handleMarkupChange}  // Gérer le changement de desiredMarkup
              placeholder="1"
            />
          </InputGroup>
          
          
      </Flex>
      </Flex>
      <Flex>
        <Input
          size="lg"
          type="text"
          placeholder="Search menu item by name"
          value={searchStockItem}
          onChange={(e) => setSearchStockItem(e.target.value)}
          className="search-input"
          bg="white !important"
        />
      </Flex>
      <Flex direction="column">
        <Box overflowX="auto">
        <Table
          size={"xs"}
          w={"100%"}
          fontSize="13px"
          sx={{
            input: { border: "none", px: "8px" },
            td: { borderRight: "1px solid var(--borders)" },
            th: { p: "8px" },
            "th:first-of-type": { pl: "0" },
            "td:first-of-type": { pl: "0" },
            "th:last-of-type": { borderRight: "none", pr: "0" },
            "td:last-of-type": { borderRight: "none", pr: "0" },
          }}>
          <Thead>
            <Tr>
              <Th>Menu item</Th>
              <Tooltip label="Quantity of the product that you sell at once" fontSize="12px">
                <Th isNumeric>
                  <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Qty <CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>
                  </Th>
              </Tooltip>
              <Tooltip label="Cost of the product" fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Order cost <CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>
                </Th>
              </Tooltip>
              <Tooltip
                label="Order Quantity is the number of units of the product you buy at once"
                fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Order qty <CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>
                </Th>
              </Tooltip>
              <Tooltip label="Cost per unit of the product" fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Cost per unit <CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>
                </Th>
              </Tooltip>
              <Tooltip
                label="Current selling price of the product"
                fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Current price<CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>
                </Th>
              </Tooltip>
              <Tooltip label="Profit per unit based on current selling price" fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Profit per unit<CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>

                </Th>
              </Tooltip>
              <Tooltip label="Price markup based on cost" fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Markup<CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>

                </Th>
              </Tooltip>
              <Tooltip
                label={`Suggested price for a markup of ${desiredMarkup}x rounded up to the nearest decimal`}
                fontSize="12px">
                <Th isNumeric>
                <Flex gap={1} alignItems={"center"} justifyContent={"flex-end"}>Suggested price<CircleHelp size={10} strokeWidth={1} className="sm" /></Flex>

                </Th>
              </Tooltip>
            </Tr>
          </Thead>
          <Tbody>
            {localStockItems.map((item) => {
              // Vérification que les champs ne sont pas undefined avant d'appeler toString()
              const cost = parseFloat(item?.cost?.toString().replace(".", ",") || 0);
              const AM = parseFloat(item?.AM?.toString().replace(",", ".") || 1);
              const content = parseFloat(item?.content?.toString().replace(",", ".") || 1);
              const price = parseFloat(item?.price?.toString().replace(",", ".") || 0);
            
              const costPrice = cost > 0 && AM > 0 ? cost / (AM / content) : 0;
              const profit = price - costPrice;
              const markup = costPrice > 0 ? price / costPrice : 0;
            
              // Calcul correct de suggestedPrice avec précision de deux décimales
              const suggestedPrice = roundUpToNextTenth(costPrice * desiredMarkup);
            
              // Convertir price en nombre pour la comparaison
              const currentPrice = parseFloat(price.toFixed(2)); 
            
              // Déterminer la couleur du prix suggéré
              let suggestedPriceColor = "var(--success)"; // Couleur par défaut (vert)
            
              if (suggestedPrice > currentPrice) {
                suggestedPriceColor = "var(--error)"; // Rouge si le prix suggéré est supérieur
              } else if (suggestedPrice === currentPrice) {
                suggestedPriceColor = "var(--grey700)"; // Gris si les prix sont égaux
              }

              return (
                <Tr key={item.id}>
                  <Td fontWeight={"500"} w={"20%"} minW={"200px"}>
                    <Flex gap={1} alignItems={"center"}>
                      <Box maxW={"90%x"} overflow={"hidden"} textOverflow={"ellipsis"} whiteSpace={"nowrap"}> {item[`name_${mainLanguage}`]}</Box> 
                      
                      {item[`description_${mainLanguage}`] && (
                      <Tooltip label={`${item[`description_${mainLanguage}`]}`} fontSize="12px"><Info size={10} className="sm" /></Tooltip>
                       )}
                    </Flex>
                  </Td>
                  <Td w="5%" >
                    <Input
                    textAlign={"right"}
                      size="sm"
                      value={item.content || ""}
                      onChange={(e) => handleInputChange(e, item.id, "content")}
                      onBlur={(e) => handleBlurAndFormat(e, item.id, "content")}  // Formatage après saisie
                      />
                  </Td>
                  <Td w="8%" isNumeric>
                    <Input
                      textAlign={"right"}
                      size="sm"
                      value={item.cost || ""}
                      onChange={(e) => handleInputChange(e, item.id, "cost")}
                      onBlur={(e) => handleBlurAndFormat(e, item.id, "cost")}  // Formatage après saisie
                      />
                  </Td>
                  <Td w="5%" >
                    <Input
                    textAlign={"right"}
                      size="sm"
                      value={item.AM || ""}
                      onChange={(e) => handleInputChange(e, item.id, "AM")}
                      onBlur={(e) => handleBlurAndFormat(e, item.id, "AM")}
                      />
                  </Td>
                  <Td w="9%" px={"8px"} isNumeric cursor={"not-allowed"}>
                    {costPrice > 0 ? <>{formatNumber(costPrice)}€</> : ""}
                  </Td>
                  <Td w="9%" px={"8px"} isNumeric>
                    {formatNumber(price)}€
                  </Td>
                  <Td w="9%" px={"8px"} isNumeric cursor={"not-allowed"} color={profit > 0 ? "var(--success)" : "var(--error)"}>
                    {profit > 0 && costPrice > 0 && "+"}{costPrice > 0 ? <>{formatNumber(profit)}€</> : ""}
                  </Td>
                  <Td isNumeric
                   cursor={"not-allowed"}
                    w="9%"
                    px={"8px"}
                    fontWeight={"500"}
                    color={markup > desiredMarkup ? "var(--success)" : "var(--error)"}>
                    {costPrice > 0 ? <>{formatNumber(markup)}x</> : ""}
                  </Td>
                  <Td
                    cursor={"not-allowed"}
                    w="12%"
                    px={"8px"}
                    fontWeight={"500"}
                    color={suggestedPriceColor}>
                    
                      {costPrice > 0 ? 
                        <Flex gap={3} alignItems={"center"} justifyContent={"flex-end"}>
                    
                    {suggestedPrice !== currentPrice ? (
                      <Button
                      size="xs"
                      variant="outline"
                      onClick={() => handleUpdatePrice(item.id, suggestedPrice)}>
                      Apply
                    </Button>

                    ): ""}
                    
                    
                    {costPrice > 0 ? <>{formatNumberWithComma(suggestedPrice)}€</> : ""}
                      
                    
                    </Flex>
                         : ""}
                     
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        </Box>
      </Flex>
    </Flex>
  );
};

export default StockList;
