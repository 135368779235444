import { useState, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';
import { Flex,Box } from '@chakra-ui/react';
import Table from './Table';
import { db } from '../../firebaseConfig';
import { doc, updateDoc, query, collection, onSnapshot } from "firebase/firestore";
import { useAppContext } from '../../contexts/AppContext';


function TablePlan({ tables, setTables,  selectedTables, setSelectedTables, updateTable, updateTableCombinations }) {
    
    const { selectedRestaurantId } = useAppContext();

    const ref = useRef(null);
   const [selecting, setSelecting] = useState(false);
   const [shiftPressed, setShiftPressed] = useState(false);



    const moveTable = async (id, newX, newY) => {
        const canvasWidth = document.getElementById('tablePlanCanvas').clientWidth;
        const canvasHeight = document.getElementById('tablePlanCanvas').clientHeight;
        // Limiter newX et newY pour s'assurer qu'ils restent dans les limites du canevas
        const adjustedX = Math.min(Math.max(newX, 0), canvasWidth - 50); // -50 pour la largeur estimée de la table
        const adjustedY = Math.min(Math.max(newY, 0), canvasHeight - 50); // -50 pour la hauteur estimée de la table
      
        setTables(prevTables => {
          const updatedTables = prevTables.map(table => {
            if (table.id === id) {
              return { ...table, x: adjustedX, y: adjustedY };
            }
            return table;
          });
          console.log('Updated tables:', updatedTables);
          return updatedTables;
        });
              const tableDocRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, id);
        try {
          await updateDoc(tableDocRef, {
            x: adjustedX,
            y: adjustedY
          });
          console.log('Firestore update successful');
        } catch (error) {
          console.error('Error updating table in Firestore:', error);
        }
      };

const [, drop] = useDrop(() => ({
    accept: 'table',
    drop: (item, monitor) => {
      const delta = monitor.getDifferenceFromInitialOffset();
      if (delta) {
        const newX = Math.round(item.x + delta.x);
        const newY = Math.round(item.y + delta.y);
        moveTable(item.id, newX, newY);
      }
    }       
  }));
  

  const toggleSelect = (id) => {
    setSelectedTables((prev) => {
      if (shiftPressed) {
        // Logique pour la sélection multiple
        return prev.includes(id)
          ? prev.filter((tableId) => tableId !== id)
          : [...prev, id];
      } else {
        // Logique pour la sélection unique
        return prev.includes(id) ? [] : [id];
      }
    });
  };
  

  

  return (
  <Flex className='scrollContainer' maxWidth="100vw" overflow='auto'>
    <Box ref={drop} id="tablePlanCanvas" bg="white" position="relative" minWidth='1080px' w='100%' aspectRatio='16/9' className='widget' >
    <Box position="absolute" width="100%" height="100%" left={0} top={0}>
        <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <pattern id="smallGrid" width="10" height="10" patternUnits="userSpaceOnUse">
      <path d="M 10 0 L 0 0 0 10" fill="none" stroke="lightblue" stroke-width="0.5"/>
    </pattern>
    <pattern id="grid" width="100" height="100" patternUnits="userSpaceOnUse">
      <rect width="100" height="100" fill="url(#smallGrid)"/>
      <path d="M 100 0 L 0 0 0 100" fill="none" stroke="lightblue" stroke-width="1"/>
    </pattern>
  </defs>

  <rect width="100%" height="100%" fill="url(#grid)" />
</svg></Box>
      {tables.map((table) => (
        <Table 
          tables={tables} key={table.id} id={table.id} 
          name={table.name} x={table.x} y={table.y} capacity={table.capacity} 
          shape={table.shape} combination={table.combination} moveTable={moveTable} 
          updateTable={updateTable} updateTableCombinations={updateTableCombinations}
          isSelected={selectedTables.includes(table.id)}
          toggleSelect={toggleSelect}
        />
      ))}
    </Box>
    </Flex>
  );
}

export default TablePlan;
