const PrintButton = ({ order, mainLanguage, deliveryTime }) => {
    const handlePrint = () => {
      const printWindow = window.open('', '', 'width=600,height=400');
  
      if (!printWindow) {
        alert('Please allow pop-ups for this website');
        return;
      }
      
      // Calculate the scheduled time, subtracting 15 minutes if deliveryMode is "delivery"
      let scheduledTime = order.scheduledTime.toDate();
      if (order.deliveryMode === 'delivery') {
        scheduledTime = new Date(scheduledTime.getTime() - deliveryTime * 60000);
      }
    
      const itemsList = order.cartItems.map(item => {
        const itemName = item[`name_${mainLanguage}`];
        const extras = item.extras ? `<br/><i style="font-size:8px;">${item.extras.map(extra => extra.name).join(', ')}</i>` : '';
        const note = item.note ? `<br/>${item.note}` : '';
        return `<li style="padding:10px 0;border-bottom:.5px dotted #CCC;"><b>${itemName}</b><span style="float:right;">${item.quantity}</span>${extras}${note}</li>`;
      }).join('');
      
      const receiptContent = `
        <div style="max-width: 80mm; font-family: monospace; font-size: 10px;letter-spacing: -.05em">
          <div style="text-align:center"><img src=${order.restaurantDetails.logo} width="80px" /></div>
          <p style="font-size:13px;font-weight:bold">#${order.id}</p>
          <p style="line-height:1.2;">${order.customerDetails.firstName} ${order.customerDetails.lastName} <br/> ${order.customerDetails.email} <br/> ${order.customerDetails.phone}</p>
          <p style="font-weight:bold">Pickup time: ${scheduledTime.toLocaleDateString("en-GB", {
            hour: '2-digit',
            minute: '2-digit',
          })}</p>
          <hr style="border: .5px dashed #000;" />
          <ul style="list-style-type:none;padding-left:0;">
            ${itemsList}
          </ul>
        </div>
      `;
  
      printWindow.document.write('<html><head><title>Print order</title></head><body>');
      printWindow.document.write(receiptContent);
      printWindow.document.write('</body></html>');
      printWindow.document.close();
      printWindow.focus();
      setTimeout(() => {
        printWindow.print();
        printWindow.close();
      }, 500);
    };
  
    return <li onClick={handlePrint}>Print order</li>;
  };
  
  export default PrintButton;
  