import React, { useState, useEffect } from "react";
import { Flex, Box, Text, FormControl, FormLabel, Input, InputRightElement, Select, InputGroup, Tabs, TabList, TabPanels, Tab, TabPanel, TabIndicator } from "@chakra-ui/react";
import { collection, addDoc, doc, updateDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalTopBar from "../modal/ModalTopBar";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import { toast } from "react-toastify";
import isEqual from "lodash.isequal"; // Import lodash's isequal
import CouponUsageLog from "./CouponUsageLog";

Modal.setAppElement("#root");

const CouponModal = ({
  isOpen,
  onClose,
  selectedRestaurantId,
  onCouponAdded,
  selectedCoupon,
  preFilledCustomerEmail
}) => {
  const [name, setName] = useState("");
  const [application, setApplication] = useState("all");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [couponValue, setCouponValue] = useState("");
  const [couponType, setCouponType] = useState("fixed");
  const [maxUses, setMaxUses] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [expirationDate, setExpirationDate] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [initialCouponData, setInitialCouponData] = useState(null); // For comparing changes
  const [hasChanges, setHasChanges] = useState(false); // Track if any changes have been made
  const { customStyles } = useModal();
  const [usageLogs, setUsageLogs] = useState([]); // Store coupon usage logs

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersRef = collection(
        db,
        `restaurants/${selectedRestaurantId}/customers`
      );
      const customersSnapshot = await getDocs(customersRef);
      const customerOptions = customersSnapshot.docs.map((doc) => ({
        value: doc.data().email,
        label: `${doc.data().firstName} ${doc.data().lastName} (${
          doc.data().email
        })`,
      }));
      setCustomers(customerOptions);
    };

    fetchCustomers();
  }, [selectedRestaurantId]);

  useEffect(() => {
    if (selectedCoupon) {
      const initialData = {
        name: selectedCoupon.name,
        application:
          selectedCoupon.application === "All customers" ? "all" : "specific",
        couponValue: selectedCoupon.couponValue.split(" ")[0],
        couponType: selectedCoupon.couponValue.includes("%")
          ? "percentage"
          : "fixed",
        maxUses:
          selectedCoupon.maxUses !== undefined ? selectedCoupon.maxUses : 0,
        expirationDate: selectedCoupon.expirationDate
          ? new Date(selectedCoupon.expirationDate)
          : null,
        selectedCustomers:
          selectedCoupon.application !== "All customers"
            ? selectedCoupon.application.map((email) => ({
                value: email,
                label: email,
              }))
            : [],
      };

      setName(initialData.name);
      setApplication(initialData.application);
      setCouponValue(initialData.couponValue);
      setCouponType(initialData.couponType);
      setMaxUses(initialData.maxUses);
      setExpirationDate(initialData.expirationDate);
      setSelectedCustomers(initialData.selectedCustomers);
      setInitialCouponData(initialData);

      // Fetch usage logs for the selected coupon
      fetchCouponUsageLogs(selectedCoupon.id); 
    } else {
      resetForm();
    }
  }, [selectedCoupon]);

  useEffect(() => {
    // Pre-fill the application field with "specific" and selectedCustomers with the passed email
    if (preFilledCustomerEmail) {
      setApplication("specific");
      setSelectedCustomers([{ value: preFilledCustomerEmail, label: preFilledCustomerEmail }]);
    }
  }, [preFilledCustomerEmail]);

  const fetchCouponUsageLogs = async (couponId) => {
    try {
      const usageRef = collection(db, `restaurants/${selectedRestaurantId}/couponsUsageLogs`);
      const q = query(usageRef, where('couponId', '==', couponId));
      const usageSnapshot = await getDocs(q);

      const logs = usageSnapshot.docs.map((doc) => doc.data());
      setUsageLogs(logs); // Set usage logs in the state
    } catch (error) {
      console.error("Error fetching coupon usage logs:", error);
    }
  };

  useEffect(() => {
    const currentData = {
      name,
      application,
      couponValue,
      couponType,
      maxUses,
      expirationDate,
      selectedCustomers,
    };

    const isValid = name && couponValue && application;
    const isChanged = !isEqual(currentData, initialCouponData); // Check if the form has changed
    setIsFormValid(isValid && isChanged); // Only valid if form is valid and has changed
  }, [name, couponValue, application, couponType, maxUses, expirationDate, selectedCustomers]);

  const getCurrentFormData = () => ({
    name,
    application,
    couponValue,
    couponType,
    maxUses,
    expirationDate,
    selectedCustomers,
  });
  
  const resetForm = () => {
    setName("");
    setApplication("all");
    setCouponValue("");
    setCouponType("fixed");
    setMaxUses(0);
    setExpirationDate(null);
    setSelectedCustomers([]);
  };

  // Track changes when any input changes
  const handleChange = (setter) => (e) => {
    setter(e.target.value);
    setHasChanges(true); // Set changes to true when the user modifies any input
  };

  const handleAddOrUpdateCoupon = async () => {
    if (!isFormValid) return;

    try {
      let status = "active";
      if (expirationDate && new Date(expirationDate) < new Date()) {
        status = "expired";
      }

      const newCoupon = {
        name,
        application:
          application === "all"
            ? "All customers"
            : selectedCustomers.map((cust) => cust.value),
        couponValue: `${couponValue} ${couponType === "fixed" ? "€" : "%"}`,
        maxUses: parseInt(maxUses, 10) || 0,
        expirationDate: expirationDate ? expirationDate.toISOString() : null,
        status,
        createdAt: new Date(),
      };

      const couponsRef = collection(
        db,
        `restaurants/${selectedRestaurantId}/coupons`
      );

      if (selectedCoupon) {
        const couponDocRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/coupons`,
          selectedCoupon.id
        );
        await updateDoc(couponDocRef, newCoupon);
        toast.success("Coupon updated successfully");
      } else {
        await addDoc(couponsRef, newCoupon);
        toast.success("Coupon added successfully");
      }

      if (onCouponAdded) {
        onCouponAdded();
      }
      onClose();
    } catch (error) {
      console.error("Error adding/updating coupon: ", error);
    }
  };

  const handleModalClose = () => {
    if (hasChanges && !isEqual(getCurrentFormData(), initialCouponData)) {
      // Show browser's native confirmation dialog only if there are changes
      const confirmLeave = window.confirm(
        "You have unsaved changes. Are you sure you want to leave without saving?"
      );
      if (!confirmLeave) return; // Prevent closing if user cancels
    }
    onClose(); // Close modal if confirmed or no changes
  };

  const handleCouponValueChange = (e) => {
    let value = e.target.value;

    if (couponType === "percentage" && value > 100) {
      value = 100;
    }

    setCouponValue(value);
    setHasChanges(true); // Mark as changed when the value is modified
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleModalClose} 
      className={"modal"}
      style={customStyles}
    >
      <Flex className="modal-content" w="640px" overflow="hidden">
        <ModalTopBar
          title={selectedCoupon ? "Edit coupon" : "Add coupon"}
          onSave={handleAddOrUpdateCoupon}
          onClose={handleModalClose} 
          disabled={!isFormValid}
        />

        <Tabs
            variant="unstyled"
            w="100%"
            display={"flex"}
            flex={1}
            flexDirection={"column"}
            p={0}
            overflow="hidden">
            <Box zIndex={1}>
              <TabList>
                <Tab>Settings</Tab>
                <Tab>Logs</Tab>
              </TabList>

              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="var(--brand)"
                borderRadius="1px"
              />
            </Box>
      
      
      <Flex className="modalInner" p={0} flex={1} overflow="hidden">
      <TabPanels display={"flex"} flex={1} overflow="hidden">
      <TabPanel
                  flex={1}
                  display={"flex"}
                  flexDirection={"column"}
                  overflow="hidden">
        <Flex
                    direction="column"
                    p={8}
                    gap={0}
                    flex={1}
                    overflowY={"auto"}
                    style={{ scrollbarWidth: "none" }}>

               
          <FormControl isRequired>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}
            >
              <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
                <FormLabel fontWeight={600} color={"var(--heading)"} mb={0}>
                  Coupon name
                </FormLabel>
                <Text maxW={"85%"} fontSize={14}>
                  Enter a coupon name.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Input
                  type="text"
                  value={name}
                  onChange={handleChange(setName)} // Track changes
                  placeholder="Enter coupon name"
                  required
                />
              </Flex>
            </Flex>
          </FormControl>

          <FormControl isRequired>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pt={8}
              pb={8}
            >
              <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
                <FormLabel fontWeight={600} color={"var(--heading)"} mb={0}>
                  Application
                </FormLabel>
                <Text maxW={"85%"} fontSize={14}>
                  Specify who can use this coupon.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Select
                  value={application}
                  onChange={handleChange(setApplication)} // Track changes
                  required
                >
                  <option value="all">All customers</option>
                  <option value="specific">Specific customers</option>
                </Select>

                {application === "specific" && (
                  <ReactSelect
                    isMulti
                    options={customers}
                    value={selectedCustomers}
                    onChange={(e) => {
                      setSelectedCustomers(e);
                      setHasChanges(true); // Track changes
                    }}
                    placeholder="Select customers"
                    id="emailSelect"
                    className="customSelect-container"
                    classNamePrefix="customSelect"
                  />
                )}
              </Flex>
            </Flex>
          </FormControl>

          <FormControl isRequired>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pt={8}
              pb={8}
            >
              <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
                <FormLabel fontWeight={600} color={"var(--heading)"} mb={0}>
                  Coupon value
                </FormLabel>
                <Text maxW={"85%"} fontSize={14}>
                  Set the discount amount. Enter a number and choose if it’s a
                  percentage (%) or a fixed amount (€).
                </Text>
              </Flex>
              <Flex direction="column" gap={2}>
                <InputGroup>
                  <Input
                    type="number"
                    min={0}
                    value={couponValue}
                    onChange={handleCouponValueChange} 
                    placeholder="Enter value"
                    required
                  />
                  <InputRightElement width="4.5rem" h={"100%"}>
                    <Select
                      value={couponType}
                      onChange={(e) => {
                        setCouponType(e.target.value);
                        setHasChanges(true); // Track changes
                      }}
                      size="sm"
                      borderRadius={"0 8px 8px 0 !important"}
                    >
                      <option value="fixed">€</option>
                      <option value="percentage">%</option>
                    </Select>
                  </InputRightElement>
                </InputGroup>
              </Flex>
            </Flex>
          </FormControl>

          <FormControl>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pt={8}
              pb={8}
              alignItems={"flex-start"}
            >
              <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
                <FormLabel fontWeight={600} color={"var(--heading)"} mb={0}>
                  Maximum uses
                </FormLabel>
                <Text maxW={"85%"} fontSize={14}>
                  Limit the number of times this coupon can be redeemed. Leave
                  to 0 for unlimited uses.
                </Text>
              </Flex>
              <Flex direction="column" gap={2}>
                <Input
                  type="number"
                  value={maxUses === "" ? 0 : maxUses} 
                  min={0}
                  onChange={handleChange(setMaxUses)} // Track changes
                />
              </Flex>
            </Flex>
          </FormControl>

          <FormControl>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              pt={8}
              pb={4}
            >
              <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
                <FormLabel fontWeight={600} color={"var(--heading)"} mb={0}>
                  Expiration date
                </FormLabel>
                <Text maxW={"85%"} fontSize={14}>
                  Choose when the coupon will no longer be valid. Leave blank
                  for no expiration date.
                </Text>
              </Flex>
              <Flex direction="column" gap={2}>
                <DatePicker
                  selected={expirationDate}
                  onChange={(date) => {
                    setExpirationDate(date);
                    setHasChanges(true); // Track changes
                  }}
                  dateFormat="dd/MM/yyyy"
                  isClearable
                  placeholderText="Select expiration date"
                />
              </Flex>
            </Flex>
          </FormControl>
          </Flex>
          </TabPanel>
          
          <TabPanel>
            <CouponUsageLog usageLogs={usageLogs}/>
          </TabPanel>
        </TabPanels>
        </Flex>
        
        </Tabs>
        
      </Flex>
    </Modal>
  );
};

export default CouponModal;
