const ItemActions = ({
  item,
  toggleAvailability,
  closeSubMenu,
  onDuplicate,
  confirmAndDelete,
  onArchive,
  uncategorizedItem = false,
}) => {
  return (
    <ul>
      {!uncategorizedItem && 
      <li
        onClick={(e) => {
          e.stopPropagation();
          toggleAvailability(item);
          closeSubMenu();
        }}
      >
        {item.availability ? "Mark as unavailable" : "Mark as available"}
      </li>
      }
      <li
        onClick={(e) => {
          e.stopPropagation();
          onDuplicate(item);
          closeSubMenu();
        }}
      >
        Duplicate item
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation();
          onArchive(item.id);
          closeSubMenu();
        }}
      >
        Send to archives
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation(); // Empêche la propagation vers l'extérieur
          confirmAndDelete(item.id); // Action spécifique
          closeSubMenu(); // Ferme le sous-menu
        }}
        style={{ color: "var(--error)" }}
      >
        Delete item
      </li>
    </ul>
  );
};

export default ItemActions;
