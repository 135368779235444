import { Flex } from "@chakra-ui/react";
import { MenuProvider } from "../contexts/MenuContext";
import { StockProvider } from "../contexts/StockContext";
import { useAppContext } from "../contexts/AppContext";

function RecipesLayout({ children }) {
  const { settings } = useAppContext();

  return (
    <MenuProvider>
      <StockProvider>
        {settings.modules?.ratio === true ? (
        <>

      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: 8, mobile: '172px' }} 
        flex={1}
        gap={8}
        maxW="1320px"
      >
        
        {children}
        </Flex>
        </>
        ) : ( 
          null
        )}
      </StockProvider>
    </MenuProvider>
  );
}
export default RecipesLayout;
