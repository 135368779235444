import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button, Text, Box, InputGroup, Input, InputRightElement, InputLeftElement, IconButton, AlertDialog, AlertDialogFooter, AlertDialogContent, AlertDialogOverlay  } from "@chakra-ui/react";
import { EllipsisVertical, Search, X } from "lucide-react";
import MenuSwitcher from "./MenuSwitcher";
import ArchiveItemActions from "./ArchiveItemActions";
import useMenuData from "../../hooks/useMenuData";
import { Tooltip } from 'react-tooltip'
import { toast } from "react-toastify";
import SkeletonLayout from "./SkeletonLayout";
import EmptyList from "./EmptyList";

const ArchivesList = () => {
  const { selectedRestaurantId, mainCategoryName, mainLanguage, userRole } = useAppContext();

  const { restoreItemFromArchive } = useMenuData();

  const [searchTerm, setSearchTerm] = useState("");
  const [archivedItems, setArchivedItems] = useState([]);  // État pour stocker les items archivés
  const [isLoading, setIsLoading] = useState(true); 
  
   // Alert dialog state
   const [isAlertOpen, setIsAlertOpen] = useState(false);
   const [itemToDelete, setItemToDelete] = useState(null); // Store the item that we want to delete
   const cancelRef = useRef();

  // Fonction pour récupérer les items de la collection /archives
  const fetchArchivedItems = async () => {
    try {
      if (!selectedRestaurantId || !mainCategoryName) return;

      setIsLoading(true);
      const archiveSnapshot = await getDocs(
        collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/archives`)
      );
      const fetchedItems = archiveSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setArchivedItems(fetchedItems);
    } catch (error) {
      console.error("Error fetching archived items: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteArchiveItem = async (itemId) => {
    setItemToDelete(itemId);
    setIsAlertOpen(true);
  };

  // Fonction pour confirmer et effectuer la suppression après validation
  const confirmDeleteItem = async () => {
    try {
      if (!selectedRestaurantId || !mainCategoryName || !itemToDelete) {
        throw new Error("SelectedRestaurantId, mainCategoryName, or itemToDelete is undefined.");
      }

      // Référence à l'élément dans la collection /archives
      const archiveRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/archives`, itemToDelete);

      // Supprimer l'élément de Firestore
      await deleteDoc(archiveRef);

      // Mettre à jour localement la liste des items archivés
      setArchivedItems((prevItems) => prevItems.filter((item) => item.id !== itemToDelete));

      // Fermeture de la boîte de dialogue de confirmation
      closeAlertDialog();

      toast.success("Archived item successfully deleted.");
    } catch (error) {
      console.error("Error deleting archived item:", error);
      toast.error("Error deleting archived item.");
    }
  };

  // Fonction pour fermer l'AlertDialog
  const closeAlertDialog = () => {
    setIsAlertOpen(false);
    setItemToDelete(null); // Reset the item ID
  };
  
  useEffect(() => {
    fetchArchivedItems();  // Appel pour récupérer les items archivés
  }, [selectedRestaurantId, mainCategoryName]);

  // Filtrage des items en fonction du terme de recherche
  const filteredItems = archivedItems.filter(item =>
    item[`name_${mainLanguage}`]?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  
  const [showOverlay, setShowOverlay] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const toggleSubMenu = (itemId) => (e) => {
    e.stopPropagation();
    if (openSubMenu === itemId) {
      setOpenSubMenu(null);
      setShowOverlay(false);
    } else {
      setOpenSubMenu(itemId);
      setShowOverlay(true);
      setScrollPosition(window.scrollY);
    }
  };

  const closeSubMenu = () => {
    setOpenSubMenu(null); // Ferme le sous-menu
    setShowOverlay(false); // Masque l'overlay
  };

  return (
    <Flex direction="column" flexGrow="1" minW="0" gap={8}>
      <Flex w="100%" justifyContent="space-between">
        <MenuSwitcher />
      </Flex>

      <Flex gap={2}>
        <Flex flex={1}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search color="var(--grey500)" width="16px" />
            </InputLeftElement>
            <Input
              type="text"
              pl="36px !important"
              placeholder="Search by name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              flex={1}
              isDisabled={filteredItems.length === 0}
            />
            {searchTerm && (
              <InputRightElement onClick={() => setSearchTerm("")}>
                <Button size={"xs"} variant={"outline"} width="22.5px" data-tooltip-id="clearSearch" data-tooltip-content="Clear search" data-tooltip-place="bottom">
                <X size={16} />
                  </Button>
                  <Tooltip id="clearSearch" />
              </InputRightElement>
            )}
          </InputGroup>
        </Flex>
      </Flex>

      <Flex direction="column" mt={4} flex={1}>
        {isLoading ? (
          <SkeletonLayout />
        ) : filteredItems.length === 0 ? (
          <EmptyList
              title="No archives found"

              primaryButton={false}
              primaryButtonText="Add category"
              secondaryButton={false}
              secondaryButtonText="Import CSV"
            />
        ) : (
          filteredItems.map((item) => (
            <Flex
                                    direction="row"
                                    justifyContent="space-between"
                                    className="dish"
                                     data-tooltip-id={item.id}
                                     data-tooltip-content={item[`description_${mainLanguage}`]}
                                    >
                                    <Tooltip id={item.id} />

                                    <Flex className="dish-name">
                                      <Box>{item[`name_${mainLanguage}`]}</Box>
                                    </Flex>

                                    {userRole === "superAdmin" && (
                                      <Text
                                        fontSize="10px"
                                        maxWidth="20px"
                                        whiteSpace="nowrap"
                                        overflow="hidden">
                                        {item.id}
                                      </Text>
                                    )}

                                    <Flex
                                      direction="row"
                                      justifyContent="flex-end"
                                      className="dish-info"
                                      alignItems={"center"}
                                      gap={4}>
                                      
                                      

                                      <Flex className="dish-price">
                                        {item.type === "variable" ? (
                                          Array.isArray(item.variations) ? (
                                            <div className="tag sm">
                                              {item.variations.length}{" "}
                                              variations
                                            </div>
                                          ) : null
                                        ) : (
                                          <>{item.price}€</>
                                        )}
                                      </Flex>
                                      <IconButton
                                        size="sm"
                                        bg={"transparent !important"}
                                        color="var(--grey500)"
                                        cursor="pointer"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleSubMenu(item.id)(e);
                                        }}>
                                        <EllipsisVertical
                                          size={20}
                                          
                                        />
                                        </IconButton>

                                        {openSubMenu === item.id && (
                                          <Flex className="submenu" m={0} p={"0 !important"}>
                                          
                                          <ArchiveItemActions
                                            item={item}
                                            closeSubMenu={closeSubMenu}
                                            onRestore={() => restoreItemFromArchive(item.id, setArchivedItems)}
                                            onDelete={() => deleteArchiveItem(item.id)}
                                          />
                                          
                                         
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation(); // Empêche la propagation vers l'extérieur
                                              closeSubMenu(); // Ferme le sous-menu
                                            }}
                                            className="closeSubmenu">
                                            Cancel
                                          </button>
                                        </Flex>
                                        
                                        )}
                                    </Flex>
                                  </Flex>
          ))
        )}
      </Flex>
      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={cancelRef}
        onClose={closeAlertDialog}
        p={8}
      >
        <AlertDialogOverlay>
          <AlertDialogContent p={8}>
         

              <Text fontSize={"lg"} color={'var(--grey700)'} fontWeight={"600"}>Confirm deletion?</Text>
              <Text>Are you sure you want to delete this archived item? This action cannot be undone.</Text>

            <AlertDialogFooter p={0} mt={4}>
              <Button ref={cancelRef} onClick={closeAlertDialog} variant={"outline"}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={confirmDeleteItem} ml={3}>
                Confirm
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};

export default ArchivesList;
