import { Flex, Text} from "@chakra-ui/react";
import { useAppContext } from "../contexts/AppContext";
import { NavLink } from "react-router-dom";
import { CircleGauge, Megaphone } from "lucide-react";
import OrdersAnalytics from "../components/dashboard/OrdersAnalytics";
import ReservationsAnalytics from "../components/dashboard/ReservationsAnalytics";
import BestSellers from "../components/dashboard/BestSellers";
import TopCustomers from "../components/dashboard/TopCustomers";
import KPIs from "../components/dashboard/KPIs";

const Dashboard = () => {
  const { selectedRestaurantId, menuAdminBarZIndex, settings } = useAppContext();


  return (
    <>
      <Flex
        justifyContent="space-between"
        direction="row"
        w="100%"
        alignItems="center"
        position="sticky"
        top="0"
        bg={{ desktop: "white", mobile: "var(--grey100)" }}
        px={{ desktop: 8, mobile: 4 }}
        borderBottom="1px solid var(--borders)"
        style={{ zIndex: menuAdminBarZIndex }}>
        <Flex direction="row" className="tabLinks">
          <NavLink
            to={`/${selectedRestaurantId}/dashboard`}
            className="tabLink"
            style={({ isActive }) => ({
              color: isActive ? "var(--brand)" : "var(--grey700)",
            })}>
            {({ isActive }) => (
              <Flex alignItems={"center"} gap={1}>
                <CircleGauge
                  size={16}
                  color={isActive ? "var(--brand)" : "var(--grey500)"}
                />
                <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                  Dashboard
                </Text>
              </Flex>
            )}
          </NavLink>
          <NavLink
            to={`/${selectedRestaurantId}/news`}
            className="tabLink"
         
            style={({ isActive }) => ({
              color: isActive ? "var(--brand)" : "var(--grey500)",
              pointerEvents: "none",
              opacity: 0.4,
            })}
            >
            {({ isActive }) => (
              <Flex alignItems={"center"} gap={1}>
                <Megaphone
                  size={16}
                  color={isActive ? "var(--brand)" : "var(--grey500)"}
                />
                <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                  News
                </Text>
              </Flex>
            )}
          </NavLink>
        </Flex>
      </Flex>

      <Flex direction="column" flex={1}>
        
        <Flex
        direction="column"
          p={{ desktop: 12, mobile: 8 }}
          pb={{ desktop: 8, mobile: 36 }}
          gap={{ desktop: 16, mobile: 8 }}
          w={"100%"}
          maxW="1320px">
        

        <KPIs />
        
        {settings?.modules?.shop && (
        <Flex gap={{ desktop: 16, mobile: 8 }} w={"100%"} flex={1} direction={{ desktop: "row", mobile: "column"}}>
          <OrdersAnalytics />
          <BestSellers />
        </Flex>
        )}

        {settings?.modules?.reservations && (
          <Flex gap={{ desktop: 16, mobile: 8 }} w={"100%"} flex={1} direction={{ desktop: "row", mobile: "column"}}>
          <ReservationsAnalytics />
          <TopCustomers />
        </Flex>
        )}
        


        </Flex>
        </Flex>
       
    </>
  );
};

export default Dashboard;
