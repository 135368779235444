import { useState } from 'react';
import { useDrag } from 'react-dnd';
import { Flex, Box, Text, useDisclosure, Button } from '@chakra-ui/react';
import { Users, Edit, Trash } from "lucide-react";
import EditTable from './EditTable';

function Table({ tables, id, name, x, y, moveTable, capacity, shape, combination, updateTable, updateTableCombinations,  toggleSelect, isSelected }) {
    


    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'table',
        item: { id, x, y, type: 'table' },
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging(),
        }),
        end: (item, monitor) => {
            const sourceOffset = monitor.getSourceClientOffset();
            if (sourceOffset) {
                const newX = Math.round(sourceOffset.x);
                const newY = Math.round(sourceOffset.y);
                moveTable(item.id, newX, newY);
            }
        }           
    }), [id, x, y, moveTable]);

    const { isOpen, onOpen, onClose } = useDisclosure();
    
    const [updatedName, setUpdatedName] = useState(name);
    const [updatedCapacity, setUpdatedCapacity] = useState(capacity);
    const [updatedShape, setUpdatedShape] = useState(shape);
    const [selectedCombinations, setSelectedCombinations] = useState(combination);
    
    const getMinHeight = (capacity) => {
        if (capacity >= 1 && capacity <= 2) return "60px";
        if (capacity >= 3 && capacity <= 4) return "100px";
        if (capacity >= 5 && capacity <= 6) return "140px";
        if (capacity >= 7 && capacity <= 8) return "180px";
        if (capacity >= 9 && capacity >= 10) return "220px";
        return "auto"; // Default value if none of the conditions are met
    };

    const getMinWidth = (shape, capacity) => {
        const defaultWidth = getMinHeight(capacity);
        if (shape === "round") return defaultWidth;
        return "72px";
    };


    const minHeight = getMinHeight(capacity);
    const minWidth = getMinWidth(shape, capacity);

    const handleClick = (e) => {
        toggleSelect(id);
        e.stopPropagation();
    };

  return (
    <>
    <Flex ref={drag} 
        onClick={handleClick}
    style={{
      position: 'absolute',
      left: x,
      top: y,
      cursor: 'move',
      opacity: isDragging ? 0.5 : 1,
      boxShadow: isSelected ? '0 0 0 2px var(--brand)' : '0 0 0 1px var(--borders)',

    }}
    bg= 'var(--grey100)'
    pl={3} pr={3} pt={2} pb={2}
    justifyContent="center"
    alignItems="center"
    border="1px solid var(--borders)"
    borderRadius={ shape === "round" ? "100%" : "8px"}
    gap={4}
    minWidth={minWidth}
    minHeight={minHeight}  
    className='table'
    _hover={{
        boxShadow: '0 0 0 2px var(--brand) !important',
    }}
    >
       <Flex position="absolute" justifyContent="center"  alignItems="center" top="50%" left="-10px" height="24px" w="24px" fontSize="10px" fontWeight="600" bg="black" color="white" borderRadius="24px" border="2px solid white" transform="translateY(-50%)">{name}</Flex>

         {/* <Flex gap={1} alignItems="center">
           <GripVertical size={16} color='var(--grey600)' />
        </Flex> */}
        <Flex gap={1} alignItems="center" className='tag' bg="white">
        <Users size={13} color='var(--grey600)' />
            <Text color="var(--grey600)" fontSize={"13px"} fontWeight="600">{capacity}</Text>
        </Flex>

        {isSelected && (
                    <Flex position="absolute" 
                    border="1px solid var(--borders)"
                    borderRadius="8px"
                    p={1}
                    top="-36px" left="50%" transform="translateX(-50%)" zIndex="tooltip" bg="white">
                        
                        <Button onClick={onOpen} size="xs" bg="transparent" >
                            <Edit size={16} color='var(--grey600)' />
                        </Button>
                        
                    
                        <Button size="xs" bg="transparent">
                            <Trash size={16}  color='var(--grey600)' />
                        </Button>
                    </Flex>
                )}

       
    </Flex>
    <EditTable
        id={id}
        name={name}
        updatedName={updatedName}
        setUpdatedName={setUpdatedName}
        updatedCapacity={updatedCapacity}
        setUpdatedCapacity={setUpdatedCapacity}
        updatedShape={updatedShape}
        setUpdatedShape={setUpdatedShape}
        selectedCombinations={selectedCombinations}
        setSelectedCombinations={setSelectedCombinations}
        isOpen={isOpen}
        onClose={onClose}   
        updateTable={updateTable}
        updateTableCombinations={updateTableCombinations}
        tables={tables}

    />
    </>
  );
}

export default Table;
