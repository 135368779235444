import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TablePlan from "./TablePlan";
import { Flex, Button, Tooltip } from "@chakra-ui/react";
import { useAppContext } from "../../contexts/AppContext";
import { db } from "../../firebaseConfig";
import {
  doc,
  writeBatch,
  updateDoc,
  collection,
  getDocs,
  setDoc,
  query, arrayUnion, arrayRemove, getDoc
} from "firebase/firestore";
import { toast } from "react-toastify";
import {
  AlignCenterVertical,
  AlignCenterHorizontal,
  Group,
  Ungroup,
} from "lucide-react";

function TablePlanProvider() {
  const { selectedRestaurantId } = useAppContext();

  const [tables, setTables] = useState([]);
  const [selectedTables, setSelectedTables] = useState([]);
  const [combinations, setCombinations] = useState([]);
  const [selectedCombinationId, setSelectedCombinationId] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState({});


  const fetchTables = async () => {
    const q = query(
      collection(db, `restaurants/${selectedRestaurantId}/tables`)
    );
    const snapshot = await getDocs(q);
    const tablesArray = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setTables(tablesArray);
    return tablesArray; // Retourner les tables pour utilisation immédiate
  };

  useEffect(() => {
    fetchTables();
  }, [selectedRestaurantId]);
  
  

  

  const addNewTable = async () => {
    const tablesRef = collection(
      db,
      `restaurants/${selectedRestaurantId}/tables`
    );

    const snapshot = await getDocs(tablesRef);
    const tableCount = snapshot.size;
    const newTableId = (tableCount + 1).toString().padStart(2, "0");

    const canvasWidth = document.getElementById("tablePlanCanvas").clientWidth;
    const canvasHeight =
      document.getElementById("tablePlanCanvas").clientHeight;
    const newX = Math.random() * (canvasWidth - 100);
    const newY = Math.random() * (canvasHeight - 100);

    const newTable = {
      id: newTableId,
      name: newTableId,
      x: newX,
      y: newY,
      capacity: 2,
      shape: "rectangle",
      combination: [],
      room: "",
    };

    // Utiliser setDoc pour ajouter la nouvelle table dans Firestore
    await setDoc(doc(tablesRef, newTableId), newTable);

    // Mise à jour de l'état local si nécessaire
    setTables((prevTables) => [...prevTables, newTable]);
  };

  const updateTable = async (tableId, newName, newCapacity, newShape) => {
    const tableDocRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/tables`,
      tableId
    );

    try {
      await updateDoc(tableDocRef, {
        name: newName,
        capacity: Number(newCapacity),
        shape: newShape,
      });

      console.log(
        `Updating table with ID: ${tableId} and new name: ${newName}`
      );

      setTables((prevTables) =>
        prevTables.map((table) => {
          console.log(`Checking table with ID: ${table.id}`);
          if (table.id === tableId) {
            console.log(`Updating table with ID: ${table.id}`);
            return {
              ...table,
              name: newName,
              capacity: newCapacity,
              shape: newShape,
            };
          }
          return table;
        })
      );

      console.log("Table details updated successfully");
      toast.success("Table updated successfully");
    } catch (error) {
      console.error("Error updating table details:", error);
    }
  };

  


/*


  const fetchCombinations = async () => {
    const comboCollection = collection(db, `restaurants/${selectedRestaurantId}/table-combinations`);
    const snapshot = await getDocs(comboCollection);
    const combos = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCombinations(combos);
    return combos; // Retourner les combinaisons pour une utilisation immédiate après l'appel
  };
  
  useEffect(() => {
    fetchCombinations().then(combos => {
      const newBoundingBoxes = updateBoundingBoxes(combos, tables);
      setBoundingBoxes(newBoundingBoxes);
    });
  }, [selectedRestaurantId, tables]);


  const saveTableCombination = async (selectedRestaurantId, tableIds) => {
    const combinationCollection = collection(db, `restaurants/${selectedRestaurantId}/table-combinations`);
  
    try {
      const querySnapshot = await getDocs(query(combinationCollection, where('tables', 'array-contains-any', tableIds)));
      const existingCombinations = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      if (existingCombinations.length > 0) {
        const allCombinedTables = new Set([...tableIds]);
        existingCombinations.forEach(comb => {
          comb.tables.forEach(t => allCombinedTables.add(t));
        });
  
        const [firstCombination] = existingCombinations;
        await updateDoc(doc(db, `restaurants/${selectedRestaurantId}/table-combinations`, firstCombination.id), {
          tables: Array.from(allCombinedTables)
        });
  
        existingCombinations.slice(1).forEach(async comb => {
          await deleteDoc(doc(db, `restaurants/${selectedRestaurantId}/table-combinations`, comb.id));
        });
  
      } else {
        await addDoc(combinationCollection, { tables: tableIds });
      }
  
      // Fetch et mise à jour après les modifications pour s'assurer que les états sont synchronisés
      const updatedTables = await fetchTables();
      const updatedCombinations = await fetchCombinations();
      const newBoundingBoxes = updateBoundingBoxes(updatedCombinations, updatedTables);
      setBoundingBoxes(newBoundingBoxes);
      toast.success("Combination updated successfully");
  
    } catch (e) {
      console.error("Error managing table combination: ", e);
      toast.error("Failed to update combination");
    }
  };
  

  const updateBoundingBoxes = (combinations, tables) => {
    const newBoundingBoxes = {};
  
    combinations.forEach(combination => {
      let minX = Infinity, maxX = 0, minY = Infinity, maxY = 0;
  
      combination.tables.forEach(tableId => {
        const table = tables.find(t => t.id === tableId);
        if (table) {
          minX = Math.min(minX, table.x);
          maxX = Math.max(maxX, table.x + (table.width || 100));
          minY = Math.min(minY, table.y);
          maxY = Math.max(maxY, table.y + (table.height || 50));
        }
      });
  
      if (minX < Infinity) {
        newBoundingBoxes[combination.id] = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
      }
    });
  
    return newBoundingBoxes;
  };
  
  


  const handleSelectCombination = (combinationId) => {
    const combination = combinations.find(c => c.id === combinationId)
    if (combination) {
      setSelectedTables(combination.tables);
      // Ou ouvrir un menu pour des actions supplémentaires, comme supprimer la combinaison
    }
    setSelectedCombinationId(combinationId);
  };

  
  const deleteSelectedCombination = async () => {
  if (selectedCombinationId) {
    try {
      await deleteDoc(doc(db, `restaurants/${selectedRestaurantId}/table-combinations`, selectedCombinationId));
      setCombinations(combinations.filter(comb => comb.id !== selectedCombinationId));
      setBoundingBoxes(prev => {
        const updated = {...prev};
        delete updated[selectedCombinationId];
        return updated;
      });
      setSelectedCombinationId(null);
      toast.success("Combination deleted successfully");
    } catch (error) {
      console.error("Erreur lors de la suppression de la combinaison :", error);
      toast.error("Error deleting combination");
    }
  }
};

useEffect(() => {
  // Recalcule les bounding boxes chaque fois que les tables ou les combinaisons changent
  const newBoundingBoxes = updateBoundingBoxes(combinations, tables);
  setBoundingBoxes(newBoundingBoxes);
}, [combinations, tables]); // Surveillance des tables et des combinaisons


*/
  const updateTableCombinations = async (tableId, selectedCombinations) => {
    const batch = writeBatch(db);
    const mainTableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, tableId);

    // Fetch current combinations
    const mainTableDoc = await getDoc(mainTableRef);
    const currentCombinations = mainTableDoc.exists() ? mainTableDoc.data().combination || [] : [];

    // Create sets for comparison
    const currentSet = new Set(currentCombinations);
    const newSet = new Set(selectedCombinations);

    // Remove unwanted combinations
    currentSet.forEach(value => {
        if (!newSet.has(value)) {
            const comboTableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, value);
            batch.update(comboTableRef, {
                combination: arrayRemove(tableId)
            });
        }
    });

    // Add new combinations
    newSet.forEach(value => {
        if (!currentSet.has(value)) {
            const comboTableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, value);
            batch.update(comboTableRef, {
                combination: arrayUnion(tableId)
            });

            // Check if the newly added combination is associated with other tables
            const associatedCombinations = currentCombinations.filter(combo => selectedCombinations.includes(combo));
            associatedCombinations.forEach(associatedCombo => {
                const associatedTableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, associatedCombo);
                batch.update(associatedTableRef, {
                    combination: arrayUnion(tableId)
                });
            });
        }
    });

    // Update the main table
    batch.update(mainTableRef, { combination: Array.from(newSet) });

    await batch.commit();
    console.log("Combinations updated successfully");
};




const alignTablesHorizontally = async () => {
  if (selectedTables.length < 2) {
    toast.info("Select at least two tables to align them horizontally.");
    return;
  }

  // Calcul de la moyenne des positions 'y' centrées, en prenant en compte la hauteur de chaque table
  let validCount = 0;
  const totalY = selectedTables.reduce((acc, tableId) => {
    const table = tables.find(t => t.id === tableId);
    if (table && typeof table.y === 'number' && typeof table.height === 'number') {
      validCount++;
      return acc + (table.y + table.height / 2); // Utilisation du centre vertical de chaque table
    }
    return acc;
  }, 0);

  if (validCount === 0) {
    toast.error("No valid positions found for selected tables. Please check if 'y' and 'height' properties are correctly set.");
    return;
  }

  const averageY = totalY / validCount;

  const batch = writeBatch(db);

  selectedTables.forEach(tableId => {
    const table = tables.find(t => t.id === tableId);
    if (table && typeof table.height === 'number') {
      const newY = averageY - table.height / 2; // Ajustement pour aligner les centres
      const tableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, tableId);
      batch.update(tableRef, { y: newY });
      // Mise à jour de l'état local également
      setTables(prev => prev.map(t => t.id === tableId ? { ...t, y: newY } : t));
    }
  });

  try {
    await batch.commit();
    toast.success("Tables aligned horizontally by center.");
  } catch (error) {
    console.error("Failed to align tables horizontally by center: ", error);
    toast.error("Failed to align tables horizontally.");
  }
};




const alignTablesVertically = async () => {
  if (selectedTables.length < 2) {
    toast.info("Select at least two tables to align them vertically.");
    return;
  }

  const averageX = selectedTables.reduce((acc, tableId) => {
    const table = tables.find(t => t.id === tableId);
    return acc + table.x;
  }, 0) / selectedTables.length;

  const batch = writeBatch(db);

  selectedTables.forEach(tableId => {
    const tableRef = doc(db, `restaurants/${selectedRestaurantId}/tables`, tableId);
    batch.update(tableRef, { x: averageX });
    // Update the local state as well
    setTables(prev => prev.map(table => table.id === tableId ? { ...table, x: averageX } : table));
  });

  try {
    await batch.commit();
  } catch (error) {
    console.error("Failed to align tables vertically: ", error);
  }
};

  
  return (
    <DndProvider backend={HTML5Backend}>
      <Flex justifyContent={"space-between"}>
        <Button onClick={addNewTable} className="btn primary" >
          Add New Table
        </Button>

        <Flex gap={2}>
          {/*
          
          <Tooltip label="Associate tables" placement="top">
            <Button bg="white" border="1px solid var(--borders)" 
              onClick={() =>
                saveTableCombination(selectedRestaurantId, selectedTables)
              }
              disabled={selectedTables.length < 2}>
              <Group color="var(--grey600)" />
            </Button>
          </Tooltip>
          <Tooltip label="Dissociate tables" placement="top">
            <Button
              bg="white"
              border="1px solid var(--borders)"
              onClick={deleteSelectedCombination}>
              <Ungroup color="var(--grey600)" />
            </Button>
          </Tooltip>
            */}
          <Tooltip label="Align horizontally" placement="top">
          <Button bg="white" border="1px solid var(--borders)" onClick={alignTablesHorizontally}>
            <AlignCenterHorizontal  />
          </Button>
          </Tooltip>
          <Tooltip label="Align vertically" placement="top">
          <Button bg="white" border="1px solid var(--borders)" onClick={alignTablesVertically}>
            <AlignCenterVertical  />
          </Button>
          </Tooltip>
        </Flex>
      </Flex>

      <TablePlan
        tables={tables}
        setTables={setTables}
        selectedTables={selectedTables}
        setSelectedTables={setSelectedTables}
        updateTable={updateTable}
        boundingBoxes={boundingBoxes}
        setBoundingBoxes={setBoundingBoxes}
        combinations={combinations}
        setCombinations={setCombinations}
        updateTableCombinations={updateTableCombinations}
      />
    </DndProvider>
  );
}

export default TablePlanProvider;
