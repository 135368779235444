import React, { useState } from "react";
import { useAppContext } from "../contexts/AppContext";
import {
  Flex,
  Box,
  Table,
  Tbody,
  Tr,
  Td,
  Th,
  Thead,
  Text,
  Checkbox,
  Button,
} from "@chakra-ui/react";
import { Download, Trash } from "lucide-react";
import CustomerModal from "../components/customers/CustomerModal";
import useCustomers from "../hooks/useCustomers";

const Customers = () => {
  const { openSubMenu, showOverlay, toggleSubMenu, closeSubMenu, settings } = useAppContext();
  const {
    customers,
    selectedCustomer,
    setSelectedCustomer,
    selectedCustomers,
    setSelectedCustomers,
    deleteCustomer,
    deleteSelectedCustomers,
    exportAllCustomersToCSV,
  } = useCustomers();

  const [isModalOpen, setIsModalOpen] = useState(false);

  // Ouvrir la modal avec les informations du client sélectionné
  const openCustomerModal = (customer) => {
    setSelectedCustomer(customer);
    setIsModalOpen(true);
  };

  // Fermer la modal
  const closeCustomerModal = () => {
    setSelectedCustomer(null);
    setIsModalOpen(false);
  };

  // Sélectionner/Désélectionner un client
  const handleSelectCustomer = (customerId, event) => {
    setSelectedCustomers(
      (prevSelected) =>
        prevSelected.includes(customerId)
          ? prevSelected.filter((id) => id !== customerId) // Deselect
          : [...prevSelected, customerId] // Select
    );
  };


  return (
    <Flex flex={1} bg="white">
      <Flex
        direction="column"
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: "124px", mobile: "172px" }}
        gap={8}
        w={"100%"}
        maxW="1320px"
        flex={1}>
        {settings?.modules?.shop || settings?.modules?.reservations ? (
          <Flex w="100%" direction="column" gap={8}>
            <h3>Customers</h3>

            <Box w={"fit-content"}>
              <Button
                variant={"outline"}
                size={"md"}
                w={"fit-content"}
                onClick={toggleSubMenu("customersActions")}>
                Actions
              </Button>

              {openSubMenu === "customersActions" && (
                <Flex className="submenu" left={0}>
                  <ul>
                    <li
                      style={
                        selectedCustomers.length === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                      onClick={deleteSelectedCustomers}>
                      <Flex
                        alignItems="center"
                        gap={2}
                        opacity={selectedCustomers.length === 0 ? 0.5 : 1}>
                        <Trash size={16} color="var(--grey700)" /> Delete
                      </Flex>
                    </li>
                    <div className="separator"></div>
                    <li onClick={exportAllCustomersToCSV}>
                      <Flex alignItems="center" gap={2}>
                        <Download size={16} color="var(--grey700)" /> Export CSV
                      </Flex>
                    </li>
                  </ul>
                  <button onClick={closeSubMenu} className="closeSubmenu">
                    Cancel
                  </button>
                </Flex>
              )}
            </Box>

            {showOverlay && openSubMenu === "customersActions" && (
              <div className="overlay" onClick={closeSubMenu}></div>
            )}

            {customers && customers.length > 0 ? (
             <Box overflowX="scroll" style={{ scrollbarWidth: "none"}}> <Table w="100%">
                <Thead>
                  <Tr>
                    <Th>
                      <Checkbox
                        isChecked={selectedCustomers.length === customers.length}
                        onChange={(e) => setSelectedCustomers(e.target.checked ? customers.map((c) => c.id) : [] )}
                      />
                    </Th>
                    <Th> Name</Th>
                    {settings?.modules?.shop && <Th>Orders</Th>}
                    {settings?.modules?.reservations && <Th>Reservations</Th>}
                    <Th isNumeric>Total spent</Th>
                    <Th isNumeric>Average</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {customers.map((customer, index) => {
                    const totalReservations =
                      (customer?.numberOfReservations?.finished || 0) +
                      (customer?.numberOfReservations?.cancelled || 0) +
                      (customer?.numberOfReservations?.noshow || 0);

                    return (
                      <Tr
                        key={index}
                        cursor={"pointer"}
                        _hover={{ bg: "var(--grey25)" }}>
                        <Td w={0} pr={0}>
                          <Checkbox
                            isChecked={selectedCustomers.includes(customer.id)}
                            onChange={() => handleSelectCustomer(customer.id)}
                          />
                        </Td>
                        <Td onClick={() => openCustomerModal(customer)}>
                          <Flex
                            gap={1}
                            fontWeight={500}
                            color={"var(--grey700)"}>
                            {customer.firstName} {customer.lastName}
                          </Flex>
                          <Text fontSize={"14px"}>{customer.email}</Text>
                        </Td>
                        {settings?.modules?.shop && (
                          <Td onClick={() => openCustomerModal(customer)}>
                            <Box className="tag">
                              {customer.numberOfOrders > 0 ? (
                                <>{customer.numberOfOrders}</>
                              ) : (
                                0
                              )}
                            </Box>
                          </Td>
                        )}

                        {settings?.modules?.reservations && (
                          <Td onClick={() => openCustomerModal(customer)}>
                            <Box className="tag">
                              {totalReservations > 0 ? (
                                <>{totalReservations}</>
                              ) : (
                                0
                              )}
                            </Box>
                          </Td>
                        )}

                        <Td onClick={() => openCustomerModal(customer)} isNumeric>
                          {customer.totalSpent ? customer.totalSpent : "0,00"}€
                        </Td>
                        <Td onClick={() => openCustomerModal(customer)} isNumeric>
                          {customer.averageSpent ? customer.averageSpent : "0,00"}€
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
              </Box>
            ) : (
              <p>No customers found.</p>
            )}
          </Flex>
        ) : (
          <div>
            Customers are available only if you activate shop or reservations
            modules.
          </div>
        )}

        <CustomerModal
          selectedCustomer={selectedCustomer}
          isOpen={isModalOpen}
          onClose={closeCustomerModal}
          onDelete={deleteCustomer}
        />
      </Flex>
    </Flex>
  );
};

export default Customers;
