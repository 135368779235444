// DelivererModal.js
import Modal from "react-modal";
import { Flex, Box, Button} from "@chakra-ui/react";
import Select from 'react-select';

const DelivererModal = ({ isOpen, onClose, deliverers, selectedDeliverer, setSelectedDeliverer, handleCancelDelivererSelection, handleConfirmDeliverer, preselectedDeliverer }) => {

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(2px)",
      display: "flex",
      paddingTop: "2%",
      paddingBottom: window.innerWidth <= 960 ? "0" : "2%",
      justifyContent: "center",
      alignItems: window.innerWidth <= 960 ? "flex-end" : "flex-start",
      zIndex: "1700",
    },
    content: {
      padding: "0",
    },
  };

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={handleCancelDelivererSelection} 
      className={"modal"}
      style={customStyles}>


<div className="modal-content ">
        <Flex className="modalTopBar" justifyContent={"flex-start"} gap={4}>
          <div className="closeModal">
            <span className="close" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          <Box className="modalTitle">Assign deliverer</Box> 
        </Flex>

        <Flex className="modalInner">

                        <Select
                            options={deliverers.map(deliverer => ({
                                value: deliverer.id,
                                label: `${deliverer.name} ${deliverer.lastName}`,
                            }))}
                            onChange={(option) => setSelectedDeliverer(option)} // Set the entire object
                            value={selectedDeliverer || preselectedDeliverer || null} // Pré-sélectionner si un deliverer est déjà assigné
                            placeholder="Select a deliverer"
                            className="customSelect-container"
                            classNamePrefix="customSelect"
                            isSearchable
                        />
                        <Flex gap={2} justifyContent={"flex-end"}>
                            <Button onClick={handleCancelDelivererSelection}>Cancel</Button>
                            <Button
                                bg='var(--brand)'
                                color={'white'}
                                onClick={handleConfirmDeliverer}
                                isDisabled={!selectedDeliverer} // Désactiver le bouton si aucun livreur n'est sélectionné
                            >
                                Confirm
                            </Button>
                        </Flex>
          
            
         
        </Flex>
      </div>



                
                
                
                
                
            </Modal>
  );
};

export default DelivererModal;
