// useCategoryData.js

import { useState, useEffect, useCallback } from "react";
import { db } from "../firebaseConfig";
import { doc, collection, getDocs, setDoc, writeBatch, getDoc } from "firebase/firestore";
import { toast } from 'react-toastify';
import { useAppContext } from "../contexts/AppContext";
import { useMenuContext } from "../contexts/MenuContext";
import useMenuData from "./useMenuData";


const useMainCategories = ( selectedRestaurantId ) => {
	
  const { mainCategoryName, setMainCategoryName, mainLanguage  } = useAppContext();
  const { mainCategories, setMainCategories, currentMainCategory, setCurrentMainCategory  } = useMenuContext();
  const { fetchData } = useMenuData();

	const [editMainCategory, setEditMainCategory] = useState(null);
	const [isMainCategoryModalOpen, setIsMainCategoryModalOpen] = useState(false);
  const [mainCategoryHasChanges, setMainCategoryHasChanges] = useState(false);

  
  useEffect(() => {
		const mainCategoryExists = mainCategories.some(category => category.id === mainCategoryName);
	  
		if (!mainCategoryExists) {
		  setMainCategoryName('');
		  setCurrentMainCategory(null);
		}
	}, [mainCategories, mainCategoryName, setMainCategoryName, setCurrentMainCategory]);
	  
	 

  const fetchMainCategories = useCallback(async () => {
    if (selectedRestaurantId) {
      const querySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu`));
      const fetchedCategories = querySnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(category => !category.id.endsWith('-categories'))
        .sort((a, b) => a.order - b.order);
      setMainCategories(fetchedCategories);
      const isMainCategoryValid = fetchedCategories.some(category => category.id === mainCategoryName);
      if (!isMainCategoryValid && fetchedCategories.length > 0) {
        setMainCategoryName(fetchedCategories[0].id);
      }
    }
  }, [selectedRestaurantId, setMainCategories, mainCategoryName, setMainCategoryName]);
  

  useEffect(() => {
    fetchMainCategories();
  }, [fetchMainCategories]);



  const handleSaveNewMainCategory = async (category) => {
    if (!selectedRestaurantId) {
      console.error("No selected restaurant ID.");
      return;
    }
  
    const isNewCategory = !editMainCategory || !editMainCategory.id;
    const order = isNewCategory 
    ? mainCategories.length + 1 
    : (editMainCategory?.order !== undefined ? editMainCategory.order : mainCategories.length + 1);
  
    let categoryData = {
      order: order,
      published: category.published || false,
      imageDisplay: category.imageDisplay || false,
      menuIcon: category.menuIcon,
      activateExtras: category.activateExtras || false,
      showPrices: category.showPrices || false,
    };
  
    if (typeof category.name === 'object' && !Array.isArray(category.name)) {
      Object.keys(category.name).forEach(lang => {
        if (typeof category.name[lang] === 'string') {
          categoryData[`name_${lang}`] = category.name[lang].trim();
        }
      });
    }
  
    try {
      let categoryId;
      if (isNewCategory) {
        const nameInMainLanguage = categoryData[`name_${mainLanguage}`];
        if (!nameInMainLanguage) {
          toast.error('The category name in the main language is required.');
          return;
        }
        categoryId = nameInMainLanguage.toLowerCase().replace(/[^a-z0-9]+/g, '-');
  
        const existingDocRef = doc(db, `restaurants/${selectedRestaurantId}/menu`, categoryId);
        const docSnap = await getDoc(existingDocRef);
        if (docSnap.exists()) {
          toast.error('A category with this name already exists.');
          return;
        }
      } else {
        categoryId = editMainCategory.id;
      }
  
      const mainCategoryDocRef = doc(db, `restaurants/${selectedRestaurantId}/menu`, categoryId);
      await setDoc(mainCategoryDocRef, categoryData);
  
      // Passer l'ID de la nouvelle catégorie à fetchMainCategories
      await fetchMainCategories(categoryId);
  
      toast.success('Menu saved successfully');
    } catch (error) {
      console.error("Error saving category:", error);
      toast.error('Error saving menu');
    }
  };


  const handleDuplicateMainCategory = async (categoryToDuplicate) => {
    if (!selectedRestaurantId || !categoryToDuplicate) {
      console.error("No selected restaurant ID or category to duplicate.");
      return;
    }

    // Generate a new unique ID based on the category's name and ensure uniqueness
    let newCategoryId = `${categoryToDuplicate.name}_${Date.now()}`.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    
    const existingDocRef = doc(db, `restaurants/${selectedRestaurantId}/menu`, newCategoryId);
    const docSnap = await getDoc(existingDocRef);
    if (docSnap.exists()) {
      toast.error('A category with this name already exists.');
      return;
    }

    const newCategoryData = {
      ...categoryToDuplicate,
      id: newCategoryId, // Ensure the ID is different
      order: mainCategories.length + 1, // Assign it a new order at the end
      name: {
        ...categoryToDuplicate.name,
        [`name_${mainLanguage}`]: `${categoryToDuplicate.name[`name_${mainLanguage}`]} (Copy)`, // Optionally add "(Copy)" to name
      }
    };

    try {
      const newCategoryRef = doc(db, `restaurants/${selectedRestaurantId}/menu`, newCategoryId);
      await setDoc(newCategoryRef, newCategoryData);

      await fetchMainCategories();
      setMainCategoryName(newCategoryId); // Optionally set the duplicated category as the current category

      toast.success('Category duplicated successfully');
    } catch (error) {
      console.error("Error duplicating category:", error);
      toast.error('Error duplicating category');
    }
  };
  
  
  
  
	
  const handleDeleteMainCategory = async (category) => {
    try {
        const batch = writeBatch(db);

        const menuItemsSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${category.id}/items`));
        menuItemsSnapshot.docs.forEach(docSnap => {
            const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${category.id}/items`, docSnap.id);
            batch.delete(itemRef);
        });

        const subCategoriesSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${category.id}-categories/categories`));
        subCategoriesSnapshot.docs.forEach(docSnap => {
            const subCategoryRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${category.id}-categories/categories`, docSnap.id);
            batch.delete(subCategoryRef);
        });

        const tagsSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${category.id}/tags`));
        tagsSnapshot.docs.forEach(docSnap => {
            const tagRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${category.id}/tags`, docSnap.id);
            batch.delete(tagRef);
        });

        const categoryDocRef = doc(db, `restaurants/${selectedRestaurantId}/menu`, category.id);
        batch.delete(categoryDocRef);

        await batch.commit();
        await fetchMainCategories();
        fetchData();
        toast.success('Menu and its subcategories deleted successfully');
    } catch (error) {
        console.error("Error deleting category and its subcategories:", error);
        toast.error('Error deleting menu');
    }
  };





	  const handleOnDragEnd = (result) => {
		if (!result.destination) return;
	
		const items = Array.from(mainCategories);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
	
		setMainCategories(items);
		updateFirestoreOrder(items);
	  };

	  const updateFirestoreOrder = async (categories) => {
		const batch = writeBatch(db);
	  
		categories.forEach((category, index) => {
		  const catRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${category.id}`);
		  batch.update(catRef, { order: index });
		});
	  
		await batch.commit();
	  };

    useEffect(() => {
      const fetchAndSetActiveMainCategory = async () => {
        if (!selectedRestaurantId) return;
    
        try {
          const querySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu`));
          const fetchedCategories = querySnapshot.docs
            .map(doc => ({ id: doc.id, ...doc.data() }))
            .filter(category => !category.id.endsWith('-categories'))
            .sort((a, b) => a.order - b.order);
    
          setMainCategories(fetchedCategories);
    
          const isActiveCategoryValid = fetchedCategories.some(category => category.id === mainCategoryName);
    
          if (!isActiveCategoryValid || !mainCategoryName) {
            if (fetchedCategories.length > 0) {
              const firstCategoryName = fetchedCategories[0].id;
              setMainCategoryName(firstCategoryName);
              setCurrentMainCategory(fetchedCategories[0]);
            } else {
              setMainCategoryName('');
              setCurrentMainCategory(null);
            }
          } else if (isActiveCategoryValid) {
            const activeCategory = fetchedCategories.find(category => category.id === mainCategoryName);
            setCurrentMainCategory(activeCategory);
          }
        } catch (error) {
          console.error("Error fetching main categories:", error);
        }
      };
    
      fetchAndSetActiveMainCategory();
    }, [selectedRestaurantId, mainCategoryName, setMainCategoryName, setCurrentMainCategory, setMainCategories]);
    
    

  
    useEffect(() => {
      const selectedCategory = mainCategories.find(category => category.id === mainCategoryName);
      if (selectedCategory) {
          setCurrentMainCategory(selectedCategory);
      }
  }, [mainCategoryName, mainCategories]);


    const handleOpenMainCategoryModal = (mainCategory = null) => {
      document.body.classList.add('no-scroll');
      setEditMainCategory(mainCategory);
      setIsMainCategoryModalOpen(true);
    };
  
    const handleCloseMainCategoryModal = () => {
      document.body.classList.remove('no-scroll');
      setIsMainCategoryModalOpen(false);
      setMainCategoryHasChanges(false);
    };

    
    

	return {
        mainCategories,
        setMainCategories,
        fetchMainCategories,
        currentMainCategory,
        setCurrentMainCategory,
        mainCategoryName,
        setMainCategoryName,
        editMainCategory,
        setEditMainCategory,
        isMainCategoryModalOpen,
        setIsMainCategoryModalOpen,
		    handleDeleteMainCategory,
        handleSaveNewMainCategory,
        handleDuplicateMainCategory,
        handleOnDragEnd,
        mainCategoryHasChanges,
        setMainCategoryHasChanges,
        handleOpenMainCategoryModal,
        handleCloseMainCategoryModal,
	};
};

export default useMainCategories;
