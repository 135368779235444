import useOrdersKPI from "../../hooks/useOrdersKPI";
import { Flex, Text, UnorderedList, ListItem } from "@chakra-ui/react";
import { TrendingUp, TrendingDown } from "lucide-react";
import KPIsSkeleton from "./KPIsSkeleton";
import { Tooltip } from "react-tooltip";

const KPIs = () => {
  const {
    currentMonthRevenue,
    revenueChangePercentage,
    currentMonthTips,
    tipsChangePercentage,
    currentMonthServiceFee, 
    serviceFeeChangePercentage,
    isLoadingKPI,
    currentMonthAverageOrder,
    averageOrderChangePercentage,
    previousMonthServiceFee,
    previousMonthRevenue,
    previousMonthTips,
    previousMonthAverageOrder
  } = useOrdersKPI();

  const getArrowAndColor = (percentage) => {
    if (percentage > 0) {
      return {
        icon: <TrendingUp size={16} color="var(--success)" />,
        color: "var(--success)",
      };
    } else if (percentage < 0) {
      return {
        icon: <TrendingDown size={16} color="var(--error)" />,
        color: "var(--error)",
      };
    } else {
      return { icon: null, color: "inherit" };
    }
  };

  return (
    <>
      {isLoadingKPI ? (
        <KPIsSkeleton />
      ) : (
        <Flex
          pb={{ desktop: 12, mobile: 8 }}
          borderBottom={"1px solid var(--borders)"}
        >
          <UnorderedList className="summary" m={0}>
            <ListItem>
              <Text>This month's income</Text>
              <Flex className="kpi" gap={2}>
                {currentMonthRevenue.toFixed(2).replace(".", ",")}€{" "}
                <Flex gap={1} alignItems={"center"}>
                  {getArrowAndColor(revenueChangePercentage).icon}
                  <Text
                    data-tooltip-id="revenue-tooltip"
                    data-tooltip-content={`Previous month: ${previousMonthRevenue.toFixed(2).replace(".", ",")}€`}
                    fontSize={"12px"}
                    color={getArrowAndColor(revenueChangePercentage).color}
                  >
                    {revenueChangePercentage.toFixed(2).replace(".", ",")}%{" "}
                  </Text>
                </Flex>
              </Flex>
            </ListItem>
            
            <ListItem>
              <Text>This month's average order</Text>
              <Flex className="kpi" gap={2}>
                {currentMonthAverageOrder.toFixed(2).replace(".", ",")}€{" "}
                <Flex gap={1} alignItems={"center"}>
                  {getArrowAndColor(averageOrderChangePercentage).icon}
                  <Text
                    data-tooltip-id="average-tooltip"
                    data-tooltip-content={`Previous month: ${previousMonthAverageOrder.toFixed(2).replace(".", ",")}€`}
                    fontSize={"12px"}
                    color={getArrowAndColor(averageOrderChangePercentage).color}
                  >
                    {averageOrderChangePercentage.toFixed(2).replace(".", ",")}%{" "}
                  </Text>
                </Flex>
              </Flex>         
            </ListItem>
            
            <ListItem>
              <Text>This month's tips</Text>
              <Flex className="kpi" gap={2}>
                {currentMonthTips.toFixed(2).replace(".", ",")}€{" "}
                <Flex gap={1} alignItems={"center"}>
                  {getArrowAndColor(tipsChangePercentage).icon}
                  <Text
                    data-tooltip-id="tips-tooltip" 
                    data-tooltip-content={`Previous month: ${previousMonthTips.toFixed(2).replace(".", ",")}€`}
                    fontSize={"12px"}
                    color={getArrowAndColor(tipsChangePercentage).color}
                  >
                    {tipsChangePercentage.toFixed(2).replace(".", ",")}%{" "}
                  </Text>
                </Flex>
              </Flex>
            </ListItem>

            <ListItem>
              <Text>This month's service fees</Text>
              <Flex className="kpi" gap={2}>
                {currentMonthServiceFee.toFixed(2).replace(".", ",")}€{" "}
                <Flex gap={1} alignItems={"center"}>
                  {getArrowAndColor(serviceFeeChangePercentage).icon}
                  <Text
                    data-tooltip-id="servicefee-tooltip" // Identifiant du tooltip
                    data-tooltip-content={`Previous month: ${previousMonthServiceFee.toFixed(2).replace(".", ",")}€`}
                    fontSize={"12px"}
                    color={getArrowAndColor(serviceFeeChangePercentage).color}
                  >
                    {serviceFeeChangePercentage.toFixed(2).replace(".", ",")}%{" "}
                  </Text>
                </Flex>
              </Flex>
            </ListItem>
           
          </UnorderedList>

          <Tooltip id="revenue-tooltip" place="top" effect="solid" />
          <Tooltip id="servicefee-tooltip" place="top" effect="solid" />
          <Tooltip id="average-tooltip" place="top" effect="solid" />
          <Tooltip id="tips-tooltip" place="top" effect="solid" />

        </Flex>
      )}
    </>
  );
};

export default KPIs;
