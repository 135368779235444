import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import {
  collection,
  query,
  where,
  onSnapshot,
  orderBy,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";

const useReservations = ({ selectedDate = null, selectedYear = null } = {}) => {
  
  const { selectedRestaurantId, settings, userData } = useAppContext();
  const [reservations, setReservations] = useState([]);
  const [isReservationModalOpen, setIsReservationModalOpen] = useState(false);

  const [cancelReservationNote, setCancelReservationNote] = useState('');
  const [years, setYears] = useState([]);

  useEffect(() => {
    // Ensure settings and settings.modules exist before proceeding
    if (!selectedRestaurantId || !settings?.modules?.reservations) {
      setReservations([]); // Ensure reservations is an empty array
      return;
    }

    let q;
    const reservationsRef = collection(db, `restaurants/${selectedRestaurantId}/reservations`);

    if (selectedDate) {
      const todayStart = new Date(selectedDate);
      todayStart.setHours(0, 0, 0, 0);
      const todayEnd = new Date(selectedDate);
      todayEnd.setHours(23, 59, 59, 999);

      q = query(
        reservationsRef,
        where("scheduledTime", ">=", Timestamp.fromDate(todayStart)),
        where("scheduledTime", "<=", Timestamp.fromDate(todayEnd)),
        orderBy("scheduledTime", "asc")
      );
    } else {
      q = query(reservationsRef, orderBy("scheduledTime", "asc"));
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedReservations = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          scheduledTime: data.scheduledTime,
        };
      });
      setReservations(fetchedReservations);
    });

    return () => unsubscribe();
  }, [selectedRestaurantId, selectedDate, settings?.modules?.reservations]);

  
  const updateReservationStatus = async (
    reservationId,
    newStatus,
    newScheduledTime = null,
    cancellationNote = null
  ) => {
    try {
      const reservationRef = doc(db, `restaurants/${selectedRestaurantId}/reservations`, reservationId);
      const reservationSnap = await getDoc(reservationRef);
  
      if (!reservationSnap.exists()) {
        console.error("Reservation not found");
        return;
      }
  
      const reservationData = reservationSnap.data();
      console.log("Reservation Data:", reservationData);
  
      let updatePayload = { status: newStatus };
  
      if (newStatus === "cancelled" && cancellationNote) {
        updatePayload.cancellationNote = cancellationNote;
      }
  
      if (newScheduledTime && newScheduledTime instanceof Date) {
        updatePayload.scheduledTime = Timestamp.fromDate(newScheduledTime);
      }
  
      let logEntry = {
        action: `Status changed to ${newStatus}`,
        timestamp: new Date().toISOString(),
        user: userData ? `${userData.name} ${userData.lastName}` : "Unknown User",
      };
  
      const customerEmail = reservationData.customer.email;
      console.log("Customer Email:", customerEmail);
  
      const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
      const customerQuery = query(customersRef, where("email", "==", customerEmail));
      const customerSnapshot = await getDocs(customerQuery);
  
      let customerDocRef;
  
      if (!customerSnapshot.empty) {
        // If customer exists, get their document reference
        customerDocRef = customerSnapshot.docs[0].ref;
      } else {
        // If customer does not exist, create a new customer
        const newCustomer = {
          email: customerEmail,
          firstName: reservationData.customer.firstName,
          lastName: reservationData.customer.lastName,
          phoneNumbers: {
            [reservationData.customer.phone]: true,
          },
          numberOfReservations: { finished: 0, noshow: 0, cancelled: 0 },
        };
        
        customerDocRef = await addDoc(customersRef, newCustomer);
        console.log("New customer created with ID:", customerDocRef.id);
      }
  
      const customerData = (await getDoc(customerDocRef)).data();
      let { numberOfReservations = { finished: 0, noshow: 0, cancelled: 0 } } = customerData;
  
      // Update the reservation count based on the new status
      if (newStatus === "finished") {
        numberOfReservations.finished += 1;
      } else if (newStatus === "noshow") {
        numberOfReservations.noshow += 1;
      } else if (newStatus === "cancelled") {
        numberOfReservations.cancelled += 1;
      }
  
      // Decrease the count for the previous status if applicable
      if (reservationData.status === "finished" && newStatus !== "finished") {
        numberOfReservations.finished -= 1;
      } else if (reservationData.status === "noshow" && newStatus !== "noshow") {
        numberOfReservations.noshow -= 1;
      } else if (reservationData.status === "cancelled" && newStatus !== "cancelled") {
        numberOfReservations.cancelled -= 1;
      }
  
      // Ensure the counts do not drop below zero
      numberOfReservations.finished = Math.max(0, numberOfReservations.finished);
      numberOfReservations.noshow = Math.max(0, numberOfReservations.noshow);
      numberOfReservations.cancelled = Math.max(0, numberOfReservations.cancelled);
  
      // Update the customer's document with the new reservation counts
      await updateDoc(customerDocRef, {
        numberOfReservations,
      });
  
      // Update the reservation document with the new status and history log
      if (reservationData.historyLog) {
        updatePayload.historyLog = [...reservationData.historyLog, logEntry];
      } else {
        updatePayload.historyLog = [logEntry];
      }
  
      await updateDoc(reservationRef, updatePayload);
      toast.success("Reservation status updated");
    } catch (error) {
      console.error("Error updating reservation status:", error);
      toast.error("Error updating reservation status");
    }
  };
  
  

  return {
    reservations,
    setReservations,
    isReservationModalOpen,
    setIsReservationModalOpen,
    updateReservationStatus,
    cancelReservationNote,
    setCancelReservationNote,
    years
  };
};

export default useReservations;
