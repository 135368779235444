import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import useMenuData from "../../hooks/useMenuData";
import EditTagModal from "./EditTagModal";
import { Flex, Button, Text } from '@chakra-ui/react';
import { useMenuContext } from "../../contexts/MenuContext";
import { Plus } from "lucide-react";

const ManageTags = () => {
  const { settings, mainLanguage } = useAppContext();
  const {tags } = useMenuContext();
  const { confirmDeleteTag, onUpdateItems, saveTag } = useMenuData();
  const [isEditing, setIsEditing] = useState(null);
  const [tagName, setTagName] = useState('');
  const [selectedTag, setSelectedTag] = useState(null);

  const openTagModal = (tag = null) => {
    if (tag) {
      setSelectedTag(tag);
      setIsEditing(true);
    } else {
      setSelectedTag({ color: '' });
      setIsEditing(false);
    }
  };
  

  const handleSaveTag = async (updatedTag) => {
    await saveTag(updatedTag);
    setSelectedTag(null);
  };
   
  const deleteTag = (tagId) => {
    confirmDeleteTag(tagId, onUpdateItems);
    setSelectedTag(null);
  };


  return (
    <Flex 
      direction="column" 
      flex={0}
      gap={4}
      pl={{ desktop: 4, mobile: 0 }}
      borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none" }}
    >
            <Flex className="widgetTitle" justifyContent="space-between">
			<h5>Tags</h5>
			<Button
                            leftIcon={<Plus size={12} color="var(--grey700)"/>}
                            sx={{
                              '.chakra-button__icon': {
                                marginRight: 0,
                              },
                            }}
                            gap={1}
                            size="xs"
                            variant={"outline"}
                            onClick={() => openTagModal()}>
                            Add tag
        	</Button>
			</Flex>
      <div className="widgetBody">
        <Flex direction="row" className="tagItems" flexWrap="wrap">
      {tags.length > 0 ? (
        tags.map((tag) => (
          <Flex 
            direction="row" 
            key={tag.id} 
            className="tag" 
            onClick={() => openTagModal(tag)} 
            cursor="pointer" 
            bg={tag.color || "var(--grey700)"}
            color={tag.color === "var(--grey100)" ? "var(--grey600)" : "white"}
            fontSize="14px" 
            whiteSpace="nowrap" >
                {tag[`name_${mainLanguage}`]}
          </Flex>
        ))
      ) : (
        <Text>No tags yet.</Text>
      )}

            {selectedTag && (
              <EditTagModal 
                tag={selectedTag}
                secondaryLanguages={settings.secondaryLanguages}
                isOpen={!!selectedTag || !isEditing}
                tagName={tagName}
                setTagName={setTagName}
                onSave={handleSaveTag}
                onClose={() => setSelectedTag(null)}
                onDelete={() => deleteTag(selectedTag.id)}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                mainLanguage={mainLanguage}
                setSelectedTag={setSelectedTag}
              />
            )}


  
      </Flex>
    </div>
    </Flex>
  );
};

export default ManageTags;
