import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import {
  Flex,
  Button,
  Text,
  Select,
  Input,
  InputGroup,
  InputRightAddon,
  CheckboxGroup,
  Checkbox,
  Stack,
} from "@chakra-ui/react";

Modal.setAppElement("#root");

const SubCategoryModal = ({
  isOpen,
  onDelete,
  onClose,
  category,
  onSave,
  isEditing,
  subCategoryName,
  setSubCategoryName,
  subCategoryDescription,
  setSubCategoryDescription,
  setSubCategoryStyle,
  subCategoryStyle,
  subCategoryFormula,
  setSubCategoryFormula,
  subCategoryFormulaPrice,
  setSubCategoryFormulaPrice,
  displayDays,
  setDisplayDays,
  timeRange,
  setTimeRange
}) => {
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings } =
    useAppContext();
  const [subCategoryHasChanges, setSubCategoryHasChanges] = useState(false);
  const { customStyles } = useModal();

  const [selectedLanguage, setSelectedLanguage] = useState(
    settings.language[0].value
  );



  const handleNameChange = (lang, value) => {
    setSubCategoryName((prev) => ({ ...prev, [lang]: value }));
    setSubCategoryHasChanges(true);
  };

  const handleDescriptionChange = (lang, value) => {
    setSubCategoryDescription((prev) => ({ ...prev, [lang]: value }));
    setSubCategoryHasChanges(true);
  };

  const handleStyleChange = (e) => {
    setSubCategoryStyle(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleFormulaChange = (e) => {
    setSubCategoryFormula(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleFormulaPriceChange = (e) => {
    setSubCategoryFormulaPrice(e.target.value);
    setSubCategoryHasChanges(true);
  };

  const handleDelete = () => {
    if (
      category &&
      window.confirm("Are you sure you want to delete this category?")
    ) {
      onDelete(category);
      onClose();
    }
  };

  const handleDaysChange = (selectedDays) => {
    setDisplayDays(selectedDays);
    setSubCategoryHasChanges(true);
  };

  const handleTimeRangeChange = (e) => {
    const { name, value } = e.target;
    setTimeRange((prev) => ({ ...prev, [name]: value }));
    setSubCategoryHasChanges(true);
  };

  useEffect(() => {
    if (isOpen) {
      if (isEditing && category) {
        const initialNameState = {};
        const initialDescriptionState = {};
        const allLanguages = [
          settings.language[0],
          ...settings.secondaryLanguages,
        ];
        allLanguages.forEach((lang) => {
          const nameKey = `name_${lang.value}`;
          const descriptionKey = `description_${lang.value}`;
          initialNameState[lang.value] = category[nameKey] || "";
          initialDescriptionState[lang.value] = category[descriptionKey] || "";
        });

        setSubCategoryName(initialNameState);
        setSubCategoryDescription(initialDescriptionState);
        setSubCategoryStyle(category.style || "List");
        setSubCategoryFormula(category.formula ? "yes" : "no");
        setSubCategoryFormulaPrice(
          category.formulaPrice ? String(category.formulaPrice) : ""
        );
        setDisplayDays(category.displayDays || []); // Load existing display days if editing
        setTimeRange(category.timeRange || { start: "", end: "" }); // Load existing time range if editing
      } else {
        setSubCategoryName({});
        setSubCategoryDescription({});
        setSubCategoryStyle("List");
        setSubCategoryFormula("no");
        setSubCategoryFormulaPrice("");
        setDisplayDays([]);
        setTimeRange({ start: "", end: "" });
      }
      setSubCategoryHasChanges(false);
    }
  }, [
    isOpen,
    isEditing,
    category,
    settings,
    setSubCategoryName,
    setSubCategoryDescription,
    setSubCategoryStyle,
    setSubCategoryFormula,
    setSubCategoryFormulaPrice,
    setDisplayDays,
    setTimeRange
  ]);

  const renderNameFields = () => {
    const allLanguages = [settings.language[0], ...settings.secondaryLanguages];
    return (
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>
            Category name
          </Text>
          <Text maxW={"85%"} fontSize={14}>
            Enter the name of the category.
          </Text>
        </Flex>
        <Flex direction="column" gap={2} justifyContent={"flex-start"} flex={1}>
          <Input
            flex={"0 1 !important"}
            type="text"
            name={`name_${selectedLanguage}`}
            id={`name_${selectedLanguage}`}
            placeholder={`Category name (${selectedLanguage})`}
            required
            value={subCategoryName[selectedLanguage] || ""}
            onChange={(e) => handleNameChange(selectedLanguage, e.target.value)}
          />
          <Flex
            className="languageButtons"
            gap={1}
            position={"absolute"}
            top={2.5}
            right={4}
            zIndex={1}>
            {allLanguages.map((lang) => (
              <Button
                key={lang.value}
                onClick={() => setSelectedLanguage(lang.value)}
                p={0}
                height="unset"
                minWidth="unset"
                borderRadius={"16px"}
                overflow={"hidden"}
                className={selectedLanguage === lang.value ? "active" : ""}
                opacity=".4"
                title={lang.label}>
                <img
                  src={`/images/flags/${lang.value}.svg`}
                  alt={lang.label}
                  width="18px"
                  height="18px"
                />
              </Button>
            ))}
          </Flex>
        </Flex>
      </Flex>
    );
  };

  const renderDescriptionFields = () => {
    return (
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex direction={"column"} w={{ desktop: "50%", mobile: "100%" }}>
          <Text fontWeight={600} color={"var(--heading)"}>
            Tagline
          </Text>
          <Text maxW={"85%"} fontSize={14}>
            Enter the tagline of the category (optional).
          </Text>
        </Flex>
        <Flex direction="column" gap={2} justifyContent={"flex-start"} flex={1}>
          <textarea
            name={`description_${selectedLanguage}`}
            id={`description_${selectedLanguage}`}
            className="descriptionInput"
            placeholder={`Category tagline (${selectedLanguage})`}
            value={subCategoryDescription[selectedLanguage] || ""}
            onChange={(e) =>
              handleDescriptionChange(selectedLanguage, e.target.value)
            }
          />
        </Flex>
      </Flex>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
      <Flex className="modal-content" w="640px">
        <form onSubmit={onSave}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              {isEditing ? "Edit category" : "Add category"}
            </div>

            <div className="actionButtons">
              <Button
                type="submit"
                size="sm"
                className="btn primary"
                isDisabled={!subCategoryHasChanges}>
                {isEditing ? "Save changes" : "Add category"}
              </Button>

              {isEditing && (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("subCategoryActions")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z"
                      fill="black"
                    />
                  </svg>

                  {openSubMenu === "subCategoryActions" && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={() => category && handleDelete(category)}
                          style={{ color: "var(--error)" }}>
                          Delete category
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "subCategoryActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <Flex className="modalInner" gap={8} p={8}>
            <Flex direction="column" gap={8}>
              {renderNameFields()}
              {renderDescriptionFields()}
              <Flex
                direction={{ desktop: "row", mobile: "column" }}
                gap={4}
                borderBottom={"1px solid var(--borders)"}
                pb={8}>
                <Flex
                  direction={"column"}
                  w={{ desktop: "50%", mobile: "100%" }}>
                  <Text fontWeight={600} color={"var(--heading)"}>
                    Fixed-price menu
                  </Text>
                  <Text maxW={"85%"} fontSize={14}>
                    Choose whether you want to use a fixed-price menu.
                  </Text>
                </Flex>
                <Flex direction="column" gap={2}>
                  <Select
                    value={subCategoryFormula}
                    onChange={handleFormulaChange}>
                    <option value="no">No</option>
                    <option value="yes">Yes</option>
                  </Select>
                </Flex>
              </Flex>

              {subCategoryFormula === "yes" && (
                <Flex
                  direction={{ desktop: "row", mobile: "column" }}
                  gap={4}
                  borderBottom={"1px solid var(--borders)"}
                  pb={8}>
                  <Flex
                    direction={"column"}
                    w={{ desktop: "50%", mobile: "100%" }}>
                    <Text fontWeight={600} color={"var(--heading)"}>
                      Fixed-price menu price
                    </Text>
                    <Text maxW={"85%"} fontSize={14}>
                      Enter the fixed-price menu price.
                    </Text>
                  </Flex>
                  <Flex direction="column" gap={2}>
                    <InputGroup>
                      <Input
                        borderRadius={"8px 0 0 8px !important"}
                        type="number"
                        value={subCategoryFormulaPrice}
                        onChange={handleFormulaPriceChange}
                        placeholder="Enter price"
                      />
                      <InputRightAddon
                        bg="var(--grey50)"
                        border="none"
                        boxShadow="0 0 0 1.5px var(--borders)">
                        €
                      </InputRightAddon>
                    </InputGroup>
                  </Flex>
                </Flex>
              )}

              <Flex
                direction={{ desktop: "row", mobile: "column" }}
                gap={4}
                borderBottom={"1px solid var(--borders)"}
                pb={8}>
                <Flex
                  direction={"column"}
                  w={{ desktop: "50%", mobile: "100%" }}>
                  <Text fontWeight={600} color={"var(--heading)"}>
                    Style
                  </Text>
                  <Text maxW={"85%"} fontSize={14}>
                    Select the display style of the category.
                  </Text>
                </Flex>
                <Flex direction="column" gap={2}>
                  <Select value={subCategoryStyle} onChange={handleStyleChange}>
                    <option value="List">List</option>
                    <option value="Cards">Cards</option>
                  </Select>
                </Flex>
              </Flex>

              <Flex
                direction={{ desktop: "row", mobile: "column" }}
                gap={4}
                borderBottom={"1px solid var(--borders)"}
                pb={8}>
                <Flex
                  direction={"column"}
                  w={{ desktop: "50%", mobile: "100%" }}>
                  <Text fontWeight={600} color={"var(--heading)"}>
                    Display days
                  </Text>
                  <Text maxW={"85%"} fontSize={14}>
                    Select the days this category should be displayed. Leave
                    empty to display on all days.
                  </Text>
                </Flex>
                <Flex direction="column" gap={2} flex={1}>
                  <CheckboxGroup
                    value={displayDays}
                    onChange={handleDaysChange}>
                    <Stack direction="row" wrap="wrap" gap={2}>
                      {[
                        "Monday",
                        "Tuesday",
                        "Wednesday",
                        "Thursday",
                        "Friday",
                        "Saturday",
                        "Sunday",
                      ].map((day) => (
                        <Checkbox key={day} value={day}>
                          {day}
                        </Checkbox>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                </Flex>
              </Flex>

              <Flex
                direction={{ desktop: "row", mobile: "column" }}
                gap={4}
               >
                <Flex
                  direction={"column"}
                  w={{ desktop: "50%", mobile: "100%" }}>
                  <Text fontWeight={600} color={"var(--heading)"}>
                    Display time range
                  </Text>
                  <Text maxW={"85%"} fontSize={14}>
                    Select the time range this category should be displayed on
                    each display day. Leave blank to display all day.
                  </Text>
                </Flex>
                <Flex gap={2}>
                  <Input
                    type="time"
                    name="start"
                    value={timeRange.start}
                    onChange={handleTimeRangeChange}
                    placeholder="Start Time"
                  />
                  <Input
                    type="time"
                    name="end"
                    value={timeRange.end}
                    onChange={handleTimeRangeChange}
                    placeholder="End Time"
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
};

export default SubCategoryModal;
