import React, { useState, useEffect } from 'react';
import { Flex, Table, Tbody, Thead, Th, Tr, Td, Tooltip, IconButton, Checkbox, Button, Box } from "@chakra-ui/react";
import { Plus, Trash } from 'lucide-react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, where, doc, writeBatch } from 'firebase/firestore';
import CouponModal from '../components/shop/CouponModal';
import { useAppContext } from '../contexts/AppContext';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
const Coupons = () => {
  const { selectedRestaurantId, openSubMenu, showOverlay, toggleSubMenu, closeSubMenu } = useAppContext();
  const [coupons, setCoupons] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [customerEmail, setCustomerEmail] = useState("");  // Track email if passed through query params
  const location = useLocation();
  const [selectedCoupons, setSelectedCoupons] = useState([]); // État pour les coupons sélectionnés

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get('addCouponForCustomer');
    if (email) {
      setCustomerEmail(email);
      openCouponModal(null);  // Open modal for adding a new coupon
    }
  }, [location.search]);

  const fetchCouponUsageCount = async (couponId, application) => {
    const usageRef = collection(db, `restaurants/${selectedRestaurantId}/couponsUsageLogs`);
    const q = query(usageRef, where('couponId', '==', couponId));
    const usageSnapshot = await getDocs(q);
  
    // Map to track usage by customer (email or userId)
    const usageByCustomer = new Map();
  
    // Count usage per customer
    usageSnapshot.docs.forEach(doc => {
      const { userId } = doc.data();
      if (!usageByCustomer.has(userId)) {
        usageByCustomer.set(userId, 0);
      }
      usageByCustomer.set(userId, usageByCustomer.get(userId) + 1);
    });
  
    // Initialize variables to calculate usage and total customers
    let totalUsage = 0;
    let totalCustomersRedeemed = 0;
    let totalCustomers = 0; // Initialize totalCustomers
  
    if (Array.isArray(application)) {
      // Case for specific customers
      totalCustomers = application.length; // Set total customers to the length of the specific customers array
      application.forEach(customer => {
        const usageCount = usageByCustomer.get(customer) || 0;
        totalUsage += usageCount;
        
        // If this customer has redeemed the coupon (usage >= maxUses)
        if (usageCount >= 1) {
          totalCustomersRedeemed++;
        }
      });
    } else if (application === "All customers") {
      // Handle "All customers" case
      totalUsage = usageSnapshot.size;
      totalCustomers = "All"; // Since it's for all customers, you could use "All" or calculate it differently if needed
      totalCustomersRedeemed = Array.from(usageByCustomer.values()).filter(usage => usage >= 1).length;
    }
  
    return { totalUsage, totalCustomersRedeemed, totalCustomers };
  };
  


  const fetchCoupons = async () => {
    try {
      const couponsRef = collection(db, `restaurants/${selectedRestaurantId}/coupons`);
      const couponsSnapshot = await getDocs(couponsRef);
  
      const fetchedCoupons = await Promise.all(
        couponsSnapshot.docs.map(async (doc) => {
          const couponData = doc.data();
          const { totalUsage, totalCustomersRedeemed, totalCustomers } = await fetchCouponUsageCount(doc.id, couponData.application);
          const currentDate = new Date(); // Current date for comparison
  
          let status = couponData.status;
  
          // If coupon has been used by all customers the maximum allowed times
          if (couponData.maxUses > 0 && totalCustomersRedeemed >= totalCustomers) {
            status = "redeemed";
          } 
          // Check if coupon is expired but not fully redeemed
          else if (couponData.expirationDate && new Date(couponData.expirationDate) < currentDate) {
            status = "expired";
          }
  
          return { 
            id: doc.id, 
            ...couponData, 
            totalUsage, // Total coupon usage
            totalCustomersRedeemed, // Number of customers who have redeemed it
            totalCustomers, // Correctly calculated total customers
            status // Status could be "redeemed", "expired", or "active"
          };
        })
      );
  
      setCoupons(fetchedCoupons);
    } catch (error) {
      console.error("Error fetching coupons: ", error);
    }
  };
  


  useEffect(() => {
    fetchCoupons();
  }, [selectedRestaurantId]);

  const openCouponModal = (coupon = null) => {
    setSelectedCoupon(coupon);
    setIsModalOpen(true);
  };
  

  const closeCouponModal = () => {
    setIsModalOpen(false);
    setSelectedCoupon(null); // Réinitialise le coupon sélectionné après fermeture
  };

  const handleSelectCoupon = (couponId) => {
    setSelectedCoupons((prevSelected) => {
      if (prevSelected.includes(couponId)) {
        return prevSelected.filter((id) => id !== couponId);
      } else {
        return [...prevSelected, couponId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedCoupons.length === coupons.length) {
      setSelectedCoupons([]); // Si tout est déjà sélectionné, désélectionner tout
    } else {
      const allCouponIds = coupons.map(coupon => coupon.id);
      setSelectedCoupons(allCouponIds); // Sinon, sélectionner tous les coupons
    }
  };

  const handleDeleteSelectedCoupons = async () => {
    try {
      const batch = writeBatch(db);  // Use writeBatch instead of db.batch()
  
      selectedCoupons.forEach((couponId) => {
        const couponRef = doc(db, `restaurants/${selectedRestaurantId}/coupons`, couponId);
        batch.delete(couponRef);
      });
  
      await batch.commit();
      toast.success('Selected coupons deleted successfully');
      fetchCoupons(); // Refresh the list after deletion
      setSelectedCoupons([]); // Reset the selection
    } catch (error) {
      console.error("Error deleting coupons: ", error);
      toast.error("Error deleting selected coupons");
    }
  };
  
  

  return (
    <Flex w='100%' direction="column" gap={8}>
      <Flex className="sectionHeader row spaceBetween">
        <Flex gap={4} alignItems={"center"}>
          <h3>Coupons</h3>
          <Tooltip label="Add a new coupon">
            <IconButton size="xs" bg={"var(--brand)"} _hover={{ bg: 'var(--brand600)'}} onClick={() => openCouponModal()}>
              <Plus color="white" size={16} />
            </IconButton>
          </Tooltip>
        </Flex>
        
      </Flex>


      <Box w={"fit-content"}>
              <Button
                variant={"outline"}
                size={"md"}
                w={"fit-content"}
                onClick={toggleSubMenu("couponsActions")}>
                Actions
              </Button>

              {openSubMenu === "couponsActions" && (
                <Flex className="submenu" left={0}>
                  <ul>
                    <li
                      style={
                        selectedCoupons.length === 0
                          ? { pointerEvents: "none" }
                          : { pointerEvents: "auto" }
                      }
                      onClick={handleDeleteSelectedCoupons}>
                      <Flex
                        alignItems="center"
                        gap={2}
                        opacity={selectedCoupons.length === 0 ? 0.5 : 1}>
                        <Trash size={16} color="var(--grey700)" /> Delete
                      </Flex>
                    </li>
                  </ul>
                  <button onClick={closeSubMenu} className="closeSubmenu">
                    Cancel
                  </button>
                </Flex>
              )}
            </Box>

      {showOverlay && openSubMenu === "customersActions" && (
              <div className="overlay" onClick={closeSubMenu}></div>
      )}

      <Flex w='100%' direction="column" gap={8}>
        {coupons.length > 0 ? (
          <div className="tableWrapper">
            <Table w="100%">
              <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                <Tr className="reservationHeader">
                  <Th>
                  <Checkbox
                      isChecked={selectedCoupons.length === coupons.length}
                      onChange={handleSelectAll}  // Fonction pour tout sélectionner / désélectionner
                    />
                  </Th>
                    
                  <Th>Coupon name</Th>
                  <Th>Application for</Th>
                  <Th>Value</Th>
                  <Th>Expiration date</Th>
                  <Th>Usage</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="14px">
                {coupons.map((coupon) => (
                  <Tr key={coupon.id} _hover={{ bg: "var(--grey50)"}} cursor={"pointer"} >
                    <Td w={0} pr={0}>
                      <Checkbox
                        isChecked={selectedCoupons.includes(coupon.id)}  // Vérifiez si le coupon est sélectionné
                        onChange={() => handleSelectCoupon(coupon.id)}  // Fonction pour gérer la sélection d'un coupon
                      />
                    </Td>
                      <Td fontWeight={"500"} onClick={() => openCouponModal(coupon)}>{coupon.name}</Td>
                    <Td onClick={() => openCouponModal(coupon)}>
                      <Flex direction={"row"} flexWrap={"wrap"} gap={2}>
                      {Array.isArray(coupon.application) ? (
                        coupon.application.map((email, index) => (
                          <Flex key={index} alignItems="center" className='tag' textTransform={"initial"}>
                            {email}
                          </Flex>
                        ))
                      ) : (
                        <Flex>{coupon.application}</Flex> // Si ce n'est pas un tableau, afficher la valeur dans un Flex aussi
                      )}
                      </Flex>
                    </Td>


                    <Td onClick={() => openCouponModal(coupon)}>{coupon.couponValue}</Td>

                    <Td onClick={() => openCouponModal(coupon)}>
                    {new Date(coupon.expirationDate).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  })}

                    </Td>
                    <Td>
        {coupon.totalCustomersRedeemed}/
        {coupon.application === "All customers" 
          ? "All"  // Display "All" if the coupon applies to all customers
          : coupon.maxUses === 0 
            ? '∞'  // Display "∞" for infinite uses
            : coupon.maxUses}  {/* Display maxUses for specific customers */}
      </Td>
                    <Td><Flex className='tag' color={coupon.status === 'active' ? 'var(--success700)' : 'var(--error700)'} bg={coupon.status === 'active' ? 'var(--success50)' : 'var(--error50)'}>{coupon.status}</Flex></Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        ) : (
          <p>No coupons found.</p>
        )}
      </Flex>

      <CouponModal 
        isOpen={isModalOpen} 
        onClose={closeCouponModal} 
        selectedRestaurantId={selectedRestaurantId}
        onCouponAdded={fetchCoupons} 
        selectedCoupon={selectedCoupon} 
        preFilledCustomerEmail={customerEmail} 
      />

    </Flex>
  );
};

export default Coupons;
