import { useState, useEffect } from "react";
import { db } from '../firebaseConfig';
import { collection, doc, getDocs, deleteDoc, query, orderBy, limit } from 'firebase/firestore';
import { useAppContext } from "../contexts/AppContext";
import { toast } from 'react-toastify';

const useCustomers = () => {
    const { selectedRestaurantId, settings } = useAppContext();
    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [selectedCustomers, setSelectedCustomers] = useState([]); // State for selected customers
    const [topCustomers, setTopCustomers] = useState([]);
    const [isLoadingCustomersKPI, setIsLoadingCustomersKPI] = useState(true);


    useEffect(() => {
        if (!selectedRestaurantId) {
            setCustomers([]);
            return;
        }
        const fetchCustomers = async () => {
            try {
                const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
                const customersSnapshot = await getDocs(customersRef);
                const fetchedCustomers = customersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setCustomers(fetchedCustomers);
            } catch (error) {
                console.error("Error fetching customers:", error);
                setCustomers([]);
            }
        };

        fetchCustomers();
    }, [selectedRestaurantId, settings]);

    const deleteCustomer = async () => {
        if (!selectedCustomer?.id) return;

        const isConfirmed = window.confirm("Are you sure you want to delete this customer? This action cannot be undone.");

        if (isConfirmed) {
            try {
                const customerRef = doc(db, `restaurants/${selectedRestaurantId}/customers`, selectedCustomer.id);
                await deleteDoc(customerRef);
                toast.success("Customer deleted successfully.");
                setCustomers(customers.filter(customer => customer.id !== selectedCustomer.id)); // Met à jour la liste des clients
                setSelectedCustomer(null); // Réinitialise le client sélectionné
            } catch (error) {
                console.error("Error deleting customer:", error);
                toast.error("Error deleting customer.");
            }
        }
    };

    // Suppression multiple
const deleteSelectedCustomers = async () => {
    const isConfirmed = window.confirm(`Are you sure you want to delete ${selectedCustomers.length} customer(s)? This action cannot be undone.`);

    if (isConfirmed) {
        try {
            const deletePromises = selectedCustomers.map(async (customerId) => {
                const customerRef = doc(db, `restaurants/${selectedRestaurantId}/customers`, customerId);
                await deleteDoc(customerRef);
            });

            await Promise.all(deletePromises); // Attendre la suppression de tous les clients
            toast.success("Selected customers deleted successfully.");
            setCustomers(customers.filter(customer => !selectedCustomers.includes(customer.id))); // Met à jour la liste
            setSelectedCustomers([]); // Réinitialiser la sélection
        } catch (error) {
            console.error("Error deleting customers:", error);
            toast.error("Error deleting customers.");
        }
    }
};

    useEffect(() => {
        const fetchTopCustomers = async () => {
            setIsLoadingCustomersKPI(true);
            
            if (!selectedRestaurantId || !settings?.modules?.shop || !settings?.modules?.reservations) {
                setCustomers([]);
                setIsLoadingCustomersKPI(false);  // Ajoutez ceci pour garantir la fin du chargement
                return;
            }
    
            try {
                // Query Firestore pour obtenir les 5 clients avec le plus grand totalSpent
                const customersRef = collection(db, `restaurants/${selectedRestaurantId}/customers`);
                const q = query(customersRef, orderBy('totalSpent', 'desc'), limit(5));
                const querySnapshot = await getDocs(q);
    
                // Transformer les données pour les utiliser dans le composant
                const customersData = querySnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data()
                }));
    
                setTopCustomers(customersData);
            } catch (error) {
                console.error("Erreur lors de la récupération des meilleurs clients:", error);
            } finally {
                // On s'assure que isLoadingCustomersKPI passe à false, même en cas d'erreur
                setIsLoadingCustomersKPI(false);
            }
        };
    
        fetchTopCustomers();
    }, [selectedRestaurantId, settings]);


    // Helper function to convert an array of objects to CSV format
const convertToCSV = (data) => {
    const csvRows = [];
  
    // Get the headers
    const headers = Object.keys(data[0]);
    csvRows.push(headers.join(',')); // Join headers with a comma
  
    // Loop through the data and push each row as a CSV line
    for (const row of data) {
      const values = headers.map(header => {
        const escaped = ('' + row[header]).replace(/"/g, '\\"'); // Escape any double quotes
        return `"${escaped}"`; // Wrap each value in double quotes
      });
      csvRows.push(values.join(',')); // Join the row with a comma
    }
  
    return csvRows.join('\n'); // Combine all rows with a new line
  };
  
  // Function to trigger CSV download
  const downloadCSV = (csvData, fileName) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };
  
  // Function to export all customers as CSV
const exportAllCustomersToCSV = () => {
    if (customers.length === 0) {
      toast.error('No customers to export.');
      return;
    }
  
    // Map only relevant fields for CSV export
    const formattedData = customers.map(customer => ({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      numberOfOrders: customer.numberOfOrders || 0,
      totalSpent: customer.totalSpent || 0,
      averageSpent: customer.averageSpent || 0,
    }));
  
    // Convert the data to CSV format
    const csvData = convertToCSV(formattedData);
  
    // Trigger the CSV download
    downloadCSV(csvData, 'customers_export.csv');
  };
  
  
    

    

    return {
        customers,
        selectedCustomer,
        setSelectedCustomer,
        selectedCustomers,
        setSelectedCustomers,
        deleteCustomer,
        topCustomers,
        isLoadingCustomersKPI,
        deleteSelectedCustomers,
        exportAllCustomersToCSV
    };
};

export default useCustomers;