import { useState, useEffect } from "react";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../firebaseConfig";
import useOrders from "../hooks/useOrders";
import { Flex, Link, Button, Tooltip, IconButton, Tag } from "@chakra-ui/react";
import { Clock, MapPin, Milestone, Phone } from "lucide-react";
import { useAppContext } from "../contexts/AppContext";

const Deliveries = () => {
  const { userData } = useAppContext();
  const { updateOrderStatus, formatScheduledTime } = useOrders();
  const [deliveryOrders, setDeliveryOrders] = useState([]);
  const [orderStatuses, setOrderStatuses] = useState({});

  useEffect(() => {
    if (!userData?.restaurantIds || !userData?.userId) return;

    const unsubscribeList = userData.restaurantIds.map((restaurantId) => {
      const ordersRef = collection(db, `restaurants/${restaurantId}/orders`);

      return onSnapshot(ordersRef, (snapshot) => {
        const filteredOrders = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            restaurantId,
            ...doc.data(),
          }))
          .filter(
            (order) =>
              order.delivererId === userData.userId &&
              (order.status === "confirmed" || order.status === "sent")
          );


        setDeliveryOrders((prevOrders) => {
          const updatedOrders = prevOrders
            .filter((order) => order.restaurantId !== restaurantId)
            .concat(filteredOrders);

          return updatedOrders;
        });

        const newStatuses = filteredOrders.reduce((acc, order) => {
          acc[order.id] = order.status;
          return acc;
        }, {});

        setOrderStatuses((prevStatuses) => ({ ...prevStatuses, ...newStatuses }));
      });
    });

    return () => unsubscribeList.forEach((unsubscribe) => unsubscribe());
  }, [userData]);

  const handleMarkAsDelivered = (order) => {
    if (order.paymentStatus === "unpaid") {
      const userConfirmed = window.confirm(
        "This order is marked as unpaid. By marking it as delivered, you confirm that the client has paid. Do you want to proceed?"
      );
      if (!userConfirmed) return;
    }

    updateOrderStatus(order.id, "completed");
    setOrderStatuses((prevStatuses) => ({ ...prevStatuses, [order.id]: "completed" }));
    setDeliveryOrders((prevOrders) => prevOrders.filter((o) => o.id !== order.id));
  };

  const handleMarkAsPickedUp = (order) => {
    updateOrderStatus(order.id, "sent");
    setOrderStatuses((prevStatuses) => ({ ...prevStatuses, [order.id]: "sent" }));
  };

  const getWazeLink = (address) => {
    return `https://waze.com/ul?q=${encodeURIComponent(address)}`;
  };

  return (
    <Flex padding="3vw" maxW="1320px" direction="column" gap={8} flex="1">
      <Flex direction="column" gap={2} mx={{ desktop: 0, mobile: -4 }} w="100%">
        {deliveryOrders.map((order) => {
          const currentStatus = orderStatuses[order.id] || order.status;

          return (
            <Flex
              key={order.id}
              direction={"column"}
              w="100%"
              justifyContent="flex-start"
              borderBottom="1px solid var(--borders)"
              bg="white"
              borderRadius="8px"
              overflow="hidden"
              py={4}
              px={8}
              gap={2}
            >
              <Tooltip label={order.fullDate}>
                <h6>
                  {order.orderId ? `#${order.orderId}` : "No Order ID"}{" "}
                  {order.customerDetails?.firstName || "No First Name"}{" "}
                  {order.customerDetails?.lastName || "No Last Name"}
                </h6>
              </Tooltip>

              <Flex gap={2}>
                <Flex className="tag" alignItems="center" gap={1}>
                  <Clock size={16} color="var(--grey500)" />
                  {order.scheduledTime
                    ? formatScheduledTime(order.scheduledTime.toDate())
                    : "N/A"}
                </Flex>
                <Tag
                  size={"sm"}
                  colorScheme={order.paymentStatus === "unpaid" ? "red" : "green"}
                  textTransform={"capitalize"}
                >
                  {order.paymentStatus}
                </Tag>
              </Flex>

              <Flex
                className="tag"
                alignItems="center"
                gap={1}
                as={Link}
                href={getWazeLink(order.customerDetails.address)}
                isExternal
              >
                <MapPin size={16} color="var(--grey500)" />
                <Tag textDecoration={"none !important"}>{order.customerDetails.address}</Tag>
              </Flex>

              <Flex gap={2} alignItems={"center"}>
                <IconButton
                  as={Link}
                  variant={"outline"}
                  href={`tel:${order.customerDetails.phone}`}
                  icon={<Phone size={16} color="var(--grey500)" />}
                />

                <IconButton
                  as={Link}
                  variant={"outline"}
                  href={getWazeLink(order.customerDetails.address)}
                  isExternal
                  icon={<Milestone size={16} color="var(--grey500)" />}
                />

                {currentStatus === "confirmed" && (
                  <Button colorScheme="blue" onClick={() => handleMarkAsPickedUp(order)}>
                    Mark as picked up
                  </Button>
                )}

                {currentStatus === "sent" && (
                  <Button colorScheme="green" onClick={() => handleMarkAsDelivered(order)}>
                    Mark as delivered
                  </Button>
                )}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default Deliveries;
