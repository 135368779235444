import EditExtrasGroup from "./EditExtrasGroup";
import useExtras from "../../hooks/useExtras";
import { Flex, Button, Heading, Text } from "@chakra-ui/react";
import { Pencil, Plus } from "lucide-react";
import { useAppContext } from "../../contexts/AppContext";

const ManageExtras = ({ categories, items }) => {
  const { mainLanguage } = useAppContext();

  const {
    extrasGroups,
    currentExtrasGroup,
    groupDetails,
    setGroupDetails,
    extraModalIsOpen,
    closeExtrasGroupModal,
    applicableTo,
    setApplicableTo,
    selectedOptions,
    setSelectedOptions,
    saveExtrasGroup,
    deleteExtrasGroup,
    openExtrasModal,
    isEditing,
    setIsEditing,
  } = useExtras();

  return (
    <Flex
      direction="column"
      gap={4}
      pl={{ desktop: 4, mobile: 0 }}
      borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none" }}
      flex={0}
    >
      <Flex className="widgetTitle" justifyContent="space-between">
        <h5>Options & extras</h5>
        <Button
          leftIcon={<Plus size={12} color="var(--grey700)" />}
          sx={{
            ".chakra-button__icon": {
              marginRight: 0,
            },
          }}
          gap={1}
          size="xs"
          variant={"outline"}
          onClick={() => openExtrasModal()}
        >
          Add extras
        </Button>
      </Flex>

      <div className="widgetBody">
        {extrasGroups.length > 0 ? (
          <Flex direction="column" className="tagItems" gap={0}>
            {extrasGroups.map((group, index) => (
              <Flex
                direction="row"
                className="category-item"
                key={index}
                onClick={() => openExtrasModal(group)}
                cursor="pointer"
                p={3}
                _hover={{
                  backgroundColor: "var(--grey50)",
                  ".editIcon": { visibility: "visible" },
                }}
                borderRadius={"var(--radius-sm)"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Heading
                  as={"h6"}
                  fontSize={"1em"}
                  fontWeight={"500"}
                  maxWidth="180px"
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  whiteSpace={"nowrap"}
                  textTransform={"capitalize"}
                >
                  {group.name[`${mainLanguage}`]}
                </Heading>
                <Pencil
                  className="editIcon"
                  size={16}
                  style={{ minHeight: "16px", minWidth:"16px" }} 
                  visibility={"hidden"}
                  cursor={"pointer"}
                />
              </Flex>
            ))}
          </Flex>
        ) : (
          <Text>No options yet.</Text>
        )}
      </div>

      {extraModalIsOpen && (
        <EditExtrasGroup
          isOpen={extraModalIsOpen}
          extrasGroup={currentExtrasGroup}
          onClose={closeExtrasGroupModal}
          onSave={saveExtrasGroup}
          onDelete={deleteExtrasGroup}
          groupDetails={groupDetails}
          setGroupDetails={setGroupDetails}
          categories={categories}
          items={items}
          applicableTo={applicableTo}
          setApplicableTo={setApplicableTo}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
          currentExtrasGroup={currentExtrasGroup}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      )}
    </Flex>
  );
};

export default ManageExtras;
