import React from "react";
import {
  Flex,
  Text,
  Select,
  InputGroup,
  Input,
  InputRightAddon,
} from "@chakra-ui/react";
import ColorSelect from "./ColorSelect";

const FontStyleSettings = ({
  fontSettings,
  onFontFamilyChange,
  onFontWeightChange,
  onFontSizeChange,
  onColorChange,
  colorOptions,
  setHasChanges,
}) => {
  const fontWeightOptions = {
    "Barlow Condensed": ["300", "400", "500", "600", "700"],
    HostGrotesk: ["300", "400", "500", "600", "700"],
    Montserrat: ["300", "400", "500", "600", "700"],
    "Noto Serif": ["300", "400", "500", "600", "700"],
    Poppins: ["300", "400", "500", "600", "700"],
    Satisfy: ["400"],
  };

  const availableFontWeights = fontWeightOptions[fontSettings.fontFamily] || [];

  return (
    <Flex direction={"column"} gap={4}>
      <Flex direction="column" gap={4} justifyContent={"flex-end"} flex={1}>
        <Flex direction="row" gap={4}>
          <Flex flex={1} direction={"column"} gap={1}>
            <Text fontWeight={600} fontSize={"10px"} color={"var(--grey600)"}>
              Font family
            </Text>
            <Select
              value={fontSettings.fontFamily}
              onChange={onFontFamilyChange}
              size={"xs"}>
              <option value="Barlow Condensed">Barlow Condensed</option>
              <option value="HostGrotesk">HostGrotesk</option>
              <option value="Montserrat">Montserrat</option>
              <option value="Noto Serif">Noto Serif</option>
              <option value="Poppins">Poppins</option>
              <option value="Satisfy">Satisfy</option>
            </Select>
          </Flex>
        </Flex>
        <Flex direction="row" gap={4} alignItems={"center"}>
          <Flex direction={"column"} gap={1}>
            <Text fontWeight={600} fontSize={"10px"} color={"var(--grey600)"}>
              Font size
            </Text>
            <InputGroup>
              <Input
                maxW={"48px"}
                borderRadius={"8px 0 0 8px !important"}
                type="number"
                value={fontSettings.fontSize}
                onChange={onFontSizeChange}
                min={8}
                max={20}
              />
              <InputRightAddon
                children="pt"
                bg="var(--grey50)"
                border="none"
                boxShadow="0 0 0 1.5px var(--borders)"
              />
            </InputGroup>
          </Flex>
          <Flex flex={1} direction={"column"} gap={1}>
            <Text fontWeight={600} fontSize={"10px"} color={"var(--grey600)"}>
              Font weight
            </Text>
            <Select
              value={fontSettings.fontWeight || "400"}
              onChange={onFontWeightChange}>
              {availableFontWeights.map((weight) => (
                <option key={weight} value={weight}>
                  {weight === "300"
                    ? "Light"
                    : weight === "400"
                    ? "Regular"
                    : weight === "500"
                    ? "Medium"
                    : weight === "600"
                    ? "SemiBold"
                    : "Bold"}
                </option>
              ))}
            </Select>
          </Flex>
          <Flex flex={1} direction={"column"} gap={1}>
            <Text fontWeight={600} fontSize={"10px"} color={"var(--grey600)"}>
              Font color
            </Text>
            <ColorSelect
              value={fontSettings.color}
              onChange={onColorChange}
              options={colorOptions}
              setHasChanges={setHasChanges}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default FontStyleSettings;
