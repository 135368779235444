import { Skeleton } from "@chakra-ui/react";


function RestaurantSelectSkeleton() {
  

  return (
   

          <Skeleton height='48px' w="142px" maxW="142px" borderRadius="var(--radius-sm)" flex={1} />
        

  );
}
export default RestaurantSelectSkeleton;
