import { useState, useEffect } from "react";
import Modal from "react-modal";
import { Flex, Input, Textarea, Box } from '@chakra-ui/react';
import { useAppContext } from "../../../../contexts/AppContext";
import { doc, updateDoc } from "firebase/firestore"; // Importez updateDoc pour mettre à jour Firestore
import { db } from "../../../../firebaseConfig";
import ModalTopBar from "../../../modal/ModalTopBar";
import useModal from "../../../../hooks/useModal";
import isEqual from 'lodash/isEqual'; // Importez isEqual de Lodash

Modal.setAppElement("#root");

const CancellationMessageModal = ({
  isOpen,
  onClose,
  isEditMode,
  editMessage,
  editIndex,
  setIsEditMode,
}) => {
  const { selectedRestaurantId, settings, setSettings } = useAppContext();
  const { customStyles } = useModal();

  // États pour gérer les valeurs de name et message, initialisés avec le message à éditer
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true); // État pour désactiver le bouton Save

  useEffect(() => {
    if (isEditMode && editMessage) {
      setName(editMessage.name);
      setMessage(editMessage.message);
    } else {
      // Réinitialise les champs si ce n'est pas en mode édition
      setName('');
      setMessage('');
    }
  }, [isEditMode, editMessage]);

  // Effect pour vérifier si les valeurs actuelles sont égales aux valeurs d'origine
  useEffect(() => {
    if (isEditMode && editMessage) {
      // Compare l'état actuel avec l'état initial
      const currentMessage = { name, message };
      setIsSaveDisabled(isEqual(currentMessage, editMessage)); // Désactive le bouton si pas de changements
    } else {
      // Si ce n'est pas en mode édition (ajout d'un nouveau message), activer le bouton Save si les champs sont remplis
      setIsSaveDisabled(!name || !message);
    }
  }, [name, message, editMessage, isEditMode]);

  const handleSave = async () => {
    if (!name || !message) {
      console.log("Name or message is empty. Save is not triggered.");
      return;
    }

    const updatedMessage = { name, message };

    // Définir updatedMessages avant setSettings pour l'utiliser après
    const updatedMessages = [...(settings.shopSettings?.cancellationMessages || [])];

    if (isEditMode && editIndex !== null) {
      // Mise à jour du message existant en mode édition
      updatedMessages[editIndex] = updatedMessage;
    } else {
      // Ajout d'un nouveau message en mode ajout
      updatedMessages.push(updatedMessage);
    }

    // Met à jour l'état local
    setSettings((prevSettings) => ({
      ...prevSettings,
      shopSettings: {
        ...prevSettings.shopSettings,
        cancellationMessages: updatedMessages,
      },
    }));

    // Mise à jour dans Firestore avec la liste `updatedMessages`
    try {
      const settingsRef = doc(db, `restaurants/${selectedRestaurantId}/settings/config`);
      await updateDoc(settingsRef, {
        "shopSettings.cancellationMessages": updatedMessages, // Utilisation de updatedMessages correctement définie
      });
      console.log("Cancellation message saved to Firestore.");
    } catch (error) {
      console.error("Error updating Firestore: ", error);
    }

    // Réinitialiser les champs après la sauvegarde
    setName('');
    setMessage('');
    setIsEditMode(false); // Sort du mode édition
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}
    >
      <Flex className="modal-content">
        <ModalTopBar
          onClose={onClose}
          onSave={handleSave}
          title={isEditMode ? "Edit cancellation message" : "Add cancellation message"}
          disabled={isSaveDisabled} // Utilisation de isSaveDisabled pour désactiver le bouton
        />

        <Flex direction="column" gap={2} className="modalInner">
          <Box>
            <label>Message Name</label>
            <Input
              placeholder="Enter message name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Box>

          <Box>
            <label>Message</label>
            <Textarea
              h={"180px !important"}
              placeholder="Enter cancellation message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default CancellationMessageModal;
