import { Tooltip } from "react-tooltip";
import { Flex, Button, Text } from "@chakra-ui/react";
import { CircleHelp } from "lucide-react";
import MenuItem from "./MenuItem";
import { useMenuContext } from "../../contexts/MenuContext";
import useMenuData from "../../hooks/useMenuData";

const UncategorizedItems = ({
  uncategorizedItems,
  onDeleteUncategorized,
  openEditModal,
  currentMainCategory,
}) => {

    const { getDisplayStyles } = useMenuContext();
    const { duplicateItem, archiveItem, confirmAndDelete } = useMenuData();

  return (
    <Flex
      direction="column"
      flex={0}
      gap={4}
      pl={{ desktop: 4, mobile: 0 }}
      borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none" }}
    >
      <Flex
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex direction="row" gap={1} alignItems={"center"}>
          <h5>Uncategorized</h5>
          <CircleHelp
            color="var(--grey500)"
            size={16}
            data-tooltip-id="uncategorizedTooltip"
            data-tooltip-content="Items that don't have a category assigned won't appear on your menu."
            data-tooltip-place="top"
          />
          <Tooltip
            id="uncategorizedTooltip"
            place="top"
            className="toolTip"
          ></Tooltip>
        </Flex>
        {uncategorizedItems.length > 0 && (
          <Button
            variant={"outline"}
            size="xs"
            onClick={() => onDeleteUncategorized()}
          >
            Delete all
          </Button>
        )}
      </Flex>

      <div className="widgetBody">
        {uncategorizedItems.length > 0 ? (
          <div className="dishes-grid">
            {uncategorizedItems.map((item) => (
              <MenuItem
                key={item.id}
                item={item}
                currentMainCategory={currentMainCategory}
                openEditModal={openEditModal}
                enableReordering={false}
                getDisplayStyles={getDisplayStyles}
                onDuplicate={duplicateItem}
                onArchive={archiveItem}
                uncategorizedItem={true}
              />
            ))}
          </div>
        ) : (
          <Text className="no-items">No uncategorized items.</Text>
        )}
      </div>
    </Flex>
  );
};
export default UncategorizedItems;
