import { useAppContext } from "../../contexts/AppContext";
import {
  Flex,
  UnorderedList,
  ListItem,
  ButtonGroup,
  Button,
  IconButton,
} from "@chakra-ui/react";
import { Plus } from "lucide-react";
import OptionsManager from "./OptionsManager";

const ActionButtons = ({
  openSubCategoryModal,
  mainCategoryName,
  addItemWithCategory,
  categories,
  itemsByCategory,
  currentMainCategory,
}) => {
  const {
    selectedRestaurantId,
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    settings,
    mainLanguage,
  } = useAppContext();

  return (
    <Flex direction="row" alignItems={"center"} position="relative" gap={0}>
      <ButtonGroup gap={1}>
        <IconButton
          gap={1}
          className="btn primary"
          sx={{
            ".chakra-button__icon": {
              marginRight: 0,
            },
          }}
          onClick={toggleSubMenu("addActions")}
        >
          <Plus size={16} color="white" />
        </IconButton>

        {openSubMenu === "addActions" && (
          <div className="submenu">
            <UnorderedList>
              
              {mainCategoryName && (
                <>
                  <ListItem
                    onClick={() => {
                      openSubCategoryModal();
                      closeSubMenu();
                    }}
                  >
                    Add category
                  </ListItem>
                  
                  {categories.length > 0 && (
                    <ListItem
                    onClick={() => {
                      addItemWithCategory();
                      closeSubMenu();
                    }}
                  >
                    Add item
                  </ListItem>
                  )}
                  
                </>
              )}
            </UnorderedList>
            <Button onClick={closeSubMenu} className="closeSubmenu">
              Cancel
            </Button>
          </div>
        )}
        {showOverlay && openSubMenu === "addActions" && (
          <div className="overlay" onClick={closeSubMenu}></div>
        )}

        <OptionsManager
          categories={categories}
          itemsByCategory={itemsByCategory}
          settings={settings}
          mainLanguage={mainLanguage}
          selectedRestaurantId={selectedRestaurantId}
          currentMainCategory={currentMainCategory}
        />
      </ButtonGroup>
    </Flex>
  );
};

export default ActionButtons;
