import React, { useState, useEffect } from "react";
import useAllRestaurants from "../../../hooks/useAllRestaurants";
import RestaurantDropdown from "../RestaurantDropdown";
import { useAppContext } from "../../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../../hooks/useModal";
import { Spinner, Button, Flex, InputGroup, Input, Select } from "@chakra-ui/react"; // Ou utilisez un autre loader visuel
import { EllipsisVertical, Copy, Eye, EyeOff } from "lucide-react";
import { toast } from "react-toastify";

Modal.setAppElement("#root");

const UserModal = ({
  user,
  setUsers,
  editedUser,
  setEditedUser,
  isOpen,
  onClose,
  onSave,
  onDelete,
  addUser,
  userHasChanges,
  setUserHasChanges,
  isAddingUser,
  isDeletingUser,
  setIsUserModalOpen,
}) => {
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, userData } = useAppContext();

  
  const [selectedRestaurants, setSelectedRestaurants] = useState([]);
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // Contrôle pour afficher/masquer le mot de passe
  const { restaurants: allRestaurants, loading, error } = useAllRestaurants();
  const isEditMode = user != null;
  const { customStyles } = useModal();
  const [originalUser, setOriginalUser] = useState({});

  const roles = [
    { value: "restaurantOwner", label: "Restaurant Owner" },
    { value: "manager", label: "Manager" },
    { value: "deliverer", label: "Deliverer" },
  ];

  // Nouvelle validation pour le bouton Save
  const isFormValid = () => {
    const { name, lastName, email, password, role, } = editedUser;
    const areFieldsFilled = name && lastName && email && role && !emailError; // Ajout de la vérification du rôle
  
    // En mode édition, le mot de passe est optionnel, mais il doit être présent lors de l'ajout d'un utilisateur
    if (!isEditMode && !password) return false; // En mode ajout, le mot de passe est requis
    return areFieldsFilled && selectedRestaurants.length > 0;
  };
  

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (isEditMode && user) {
      // Mode édition : on utilise les restaurants de l'utilisateur édité
      const clonedUser = {
        ...user,
        password: "", // Réinitialiser le champ mot de passe
      };

      setEditedUser(clonedUser);
      setOriginalUser(clonedUser); // Synchroniser originalUser avec editedUser
      setSelectedRestaurants(user.restaurantIds || []);
    } else {
      // Mode ajout : on pré-sélectionne le premier restaurant de currentUser
      setEditedUser({});
      
      if (userData && userData.restaurantIds && userData.restaurantIds.length > 0) {
        // Si currentUser a des restaurants, on sélectionne le premier
        setSelectedRestaurants([userData.restaurantIds[0]]);
      } else {
        setSelectedRestaurants([]); // Réinitialiser si pas de restaurants
      }
      
      setOriginalUser({});
    }
    setUserHasChanges(false); // S'assurer que userHasChanges est bien à false lors de l'ouverture
  }, [user, isEditMode, isOpen, userData]);

  const hasUserChanged = () => {
    return (
      JSON.stringify(editedUser) !== JSON.stringify(originalUser) ||
      selectedRestaurants.toString() !==
        (originalUser.restaurantIds || []).toString()
    );
  };

  useEffect(() => {
    if (hasUserChanged()) {
      setUserHasChanges(true);
    } else {
      setUserHasChanges(false);
    }
  }, [editedUser, selectedRestaurants]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedUser = {
      ...editedUser,
      [name]: value,
      id: editedUser.id,
    };

    if (name === "email") {
      if (!validateEmail(value)) {
        setEmailError("Adresse e-mail non valide");
      } else {
        setEmailError("");
      }
    }

    if (name === "password" && !value && isEditMode) {
      updatedUser.password = "";
    }

    setEditedUser(updatedUser);
    setUserHasChanges(hasUserChanged());
  };

  const handleRoleChange = (e) => {
    setEditedUser({ ...editedUser, role: e.target.value });
    setUserHasChanges(true);
  };

  const handleAddRestaurant = (restaurantId) => {
    setSelectedRestaurants((prev) => [...prev, restaurantId]);
    setUserHasChanges(true); // Déclenche l'activation du bouton Save
  };

  const handleRemoveRestaurant = (restaurantId) => {
    setSelectedRestaurants((prev) => prev.filter((id) => id !== restaurantId));
    setUserHasChanges(true); // Déclenche l'activation du bouton Save
  };

  const generatePassword = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+[]{}<>?,./";
    let password = "";
    for (let i = 0; i < 12; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    const updatedUser = {
      ...editedUser,
      password: password,
    };
    setEditedUser(updatedUser);
    setUserHasChanges(true);
  };

  const copyToClipboard = () => {
    if (editedUser.password) {
      navigator.clipboard.writeText(editedUser.password);
      toast.info("Password copied to clipboard");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const submitUserData = async (e) => {
    e.preventDefault();

    if (!editedUser.name || !editedUser.email) {
      console.error("Required user fields are missing");
      return;
    }

    if (!isEditMode && !editedUser.password) {
      console.error("Password is required for new users");
      return;
    }

    const userData = {
      id: editedUser.id,
      name: editedUser.name,
      lastName: editedUser.lastName,
      email: editedUser.email,
      restaurantIds: selectedRestaurants || [],
      role: editedUser.role || "restaurantOwner",
    };

    if (!isEditMode || (isEditMode && editedUser.password)) {
      userData.password = editedUser.password;
    }

    if (isEditMode) {
      onSave(userData);
    } else {
      addUser(userData);
    }
  };

  const handleDeleteClick = () => {
    if (user && user.id) {
      onDelete(user.id);
      setUsers(prevUsers => prevUsers.filter(u => u.id !== user.id));  // Mise à jour locale de la liste des utilisateurs
      setUserHasChanges(false);  // Réinitialiser l'indicateur de changements
      setIsUserModalOpen(false);  // Fermer la modal
    }
  };
  

  // Filter restaurants based on the role and ensure we have valid restaurant data
  const availableRestaurants =
    userData.role === "restaurantOwner" && Array.isArray(userData.restaurantIds)
      ? allRestaurants.filter((restaurant) =>
          userData.restaurantIds.includes(restaurant.id)
        )
      : allRestaurants || [];

  // Ensure availableRestaurants is an array of valid objects
  const filteredRestaurants = availableRestaurants.filter(
    (restaurant) => restaurant && restaurant.name
  );

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;
  if (!filteredRestaurants.length) return <div>No restaurants available</div>;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      className={"modal"}>
      <div className="modal-content">
        <form onSubmit={submitUserData}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={onClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              {isEditMode ? "Edit user" : "Add user"}
            </div>
            <div className="actionButtons">
            
                <Button
                  type="submit"
                  size={"sm"}
                  className="btn primary"
                  onClick={submitUserData}
                  isDisabled={!userHasChanges || !isFormValid()}
                  isLoading={isAddingUser || isDeletingUser}>
                  {isEditMode ? "Save changes" : "Add user"}
                </Button>
              

              {isEditMode && (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("userActions")}>
                  <EllipsisVertical
                    size="20px"
                    color="var(--grey600)"
                    _hover={{ color: "var(--grey700)" }}
                  />

                  {openSubMenu === "userActions" && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={handleDeleteClick}
                          style={{ color: "var(--error)" }}>
                          Delete user
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "userActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <Flex className="modalInner">
            <div className="field">
              <label htmlFor="name">First name</label>
              <Input
                type="text"
                name="name"
                value={editedUser.name || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="lastName">Last name</label>
              <Input
                type="text"
                name="lastName"
                value={editedUser.lastName || ""}
                onChange={handleInputChange}
              />
            </div>
            <div className="field">
              <label htmlFor="email">Email address</label>
              <Input
                type="email"
                name="email"
                value={editedUser.email || ""}
                onChange={handleInputChange}
              />
              {emailError && <p style={{ color: "red" }}>{emailError}</p>}
            </div>

            <div className="field">
              <label htmlFor="password">
                {isEditMode
                  ? "New password (Enter a new password if you want to change it)"
                  : "Password (required)"}
              </label>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={editedUser.password || ""}
                  onChange={handleInputChange}
                  placeholder="Enter password"
                  style={{ width: "100%" }}
                />
                <Flex
                  position={"absolute"}
                  right={2}
                  top={"50%"}
                  transform={"translateY(-50%)"}
                  alignItems={"center"}
                  gap={2}>
                  {showPassword ? (
                    <EyeOff
                      size={16}
                      onClick={togglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                    />
                  ) : (
                    <Eye
                      size={16}
                      onClick={togglePasswordVisibility}
                      aria-label={
                        showPassword ? "Hide password" : "Show password"
                      }
                    />
                  )}
                  <Copy size={16} onClick={copyToClipboard} />
                  <Button
                    type="button"
                    size="sm"
                    onClick={generatePassword}
                    style={{ marginLeft: "8px" }}>
                    Generate
                  </Button>
                </Flex>
              </InputGroup>
            </div>

            <div className="field">
              <label htmlFor="role">Role</label>
              <Select
                name="role"
                value={editedUser.role || ""}
                onChange={handleRoleChange}>
                <option value="" disabled>
                  Select a role
                </option>
                {roles.map((role) => (
                  <option key={role.value} value={role.value}>
                    {role.label}
                  </option>
                ))}
              </Select>
            </div>

            <div className="field">
              <label htmlFor="restaurantSelect">Select Restaurants</label>
              <RestaurantDropdown
                options={filteredRestaurants} // Use the filtered restaurants list
                selectedValues={selectedRestaurants}
                onAdd={handleAddRestaurant}
                onRemove={handleRemoveRestaurant}
              />
            </div>
          </Flex>
        </form>

      </div>
    </Modal>
  );
};

export default UserModal;
