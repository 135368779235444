import { Box, Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";


const ColorSelect = ({ value, onChange, options, setHasChanges }) => {
    
    return (
      <Menu>
        <MenuButton
          h={"42px"}
          size={"md"}
          as={Button}
          bg={"transparent !important"}
          variant={"outline"}
          p={0}
          border={"none"}
          gap={0}
          leftIcon={
            <Box
              w={"42px"}
              h={"42px"}
              borderRadius={8}
              backgroundColor={value}
              p={2}
              border={"2px solid var(--borders)"}
            />
          }
        ></MenuButton>
        <MenuList>
          {options.map((option) => (
            <MenuItem
              key={option.value}
              onClick={() => {
                onChange(option.value);
                setHasChanges(true);
              }}
              display="flex"
              justifyContent={"flex-start"}
              alignItems="center"
              gap={1}
            >
              <Box
                width="12px"
                height="12px"
                borderRadius="50%"
                backgroundColor={option.value}
                marginRight="8px"
              ></Box>
              {option.label}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  };

  export default ColorSelect;