import React, { useState, useEffect, useMemo } from 'react';
import Select from 'react-select';
import { Flex, useDisclosure } from '@chakra-ui/react';
import CancelReservationModal from './CancelReservationModal';
import useReservations from '../../hooks/useReservations';

const ReservationStatusSelector = ({ reservationId, currentStatus, onUpdateStatus }) => {
    
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedValue, setSelectedValue] = useState(null);
    const { cancelReservationNote, setCancelReservationNote } = useReservations();
    
    const allOptions = useMemo(() => [
        { value: 'cancelled', label: 'Cancelled', color: 'var(--error)' },
        { value: 'pending', label: 'Pending', color: 'var(--warning)' },
        { value: 'confirmed', label: 'Confirmed', color: 'var(--teal500)' },
        { value: 'seated', label: 'Seated', color: 'var(--blue400)' },
        { value: 'noshow', label: 'No-show', color: 'black' },
        { value: 'finished', label: 'Finished', color: 'var(--success)' },

    ], []);


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
        singleValue: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const formatOptionLabel = ({ label, color }) => (
        <Flex className='statusLabel' direction="row" backgroundColor={color} color="white" pr={2} pl={2} pt={1} pb={1} borderRadius={8} fontSize={13} fontWeight="500" textTransform="initial" whiteSpace="nowrap">
            {label}
        </Flex>
    );
    
    const handleChange = selectedOption => {
        console.log("Option selected:", selectedOption);
        if (selectedOption.value === 'cancelled') {
            onOpen();
        } else {
            onUpdateStatus(reservationId, selectedOption.value);
        }
    };

    const handleConfirmCancellation = () => {
        console.log("Confirming cancellation");
        onUpdateStatus(reservationId, 'cancelled', cancelReservationNote);
        setCancelReservationNote(''); 
        onClose();
    };
    


    useEffect(() => {
        setSelectedValue(allOptions.find(option => option.value === currentStatus));
    }, [currentStatus, allOptions ]);
      


    return (
       <> <Select
            value={selectedValue}
            onChange={handleChange}
            options={allOptions}
            styles={customStyles}
            formatOptionLabel={formatOptionLabel}
            className="statusSelectorContainer"
            classNamePrefix="statusSelector"
            isSearchable={false}
        />
        <CancelReservationModal 
            isOpen={isOpen} 
            onClose={onClose}
            cancelReservationNote={cancelReservationNote}
            setCancelReservationNote={setCancelReservationNote}
            handleConfirmCancellation={handleConfirmCancellation}
        />
        
    
        </>
    );
};

export default ReservationStatusSelector;
