import React, { memo } from "react";
import {
  Flex,
  Select,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  TabPanel,
  Switch
} from "@chakra-ui/react";
import { useAppContext } from "../../../../../contexts/AppContext";

const Categories = memo(({ categoriesState, setCategoriesState, setHasChanges }) => {
  const { mainLanguage } = useAppContext();

  const handlePageBreakChange = (e, index) => {
    const updatedCategories = [...categoriesState];
    updatedCategories[index] = {
      ...updatedCategories[index],
      pageBreak: e.target.value,
    };
    setCategoriesState(updatedCategories);
    setHasChanges(true);
  };

  const handleColumnsChange = (e, index) => {
    const updatedCategories = [...categoriesState];
    updatedCategories[index] = {
      ...updatedCategories[index],
      columns: e.target.value,
    };
    setCategoriesState(updatedCategories);
    setHasChanges(true);
  };

  const handleHideToggle = (index) => {
    const updatedCategories = [...categoriesState];
    updatedCategories[index] = {
      ...updatedCategories[index],
      hide: !updatedCategories[index].hide,
    };
    setCategoriesState(updatedCategories);
    setHasChanges(true);
  };

  return (
    <TabPanel>
      <Accordion 
        defaultIndex={[0]}
        sx={{
          ".chakra-collapse": {
            overflow: "visible !important",
          },
          ".chakra-accordion__item": {
            borderTop: "1px solid var(--borders)",
            borderRadius: "0 !important",
            marginTop: "-1px",
          },
          ".chakra-accordion__button": {
            textAlign: "left",
            color: "var(--grey700)",
            fontWeight: "500",
            py: 3,
            fontSize: "var(--chakra-fontSizes-md)",
            justifyContent: "space-between",
            borderBottom: "1px solid var(--borders)",
            borderRadius: "0 !important",
            textTransform: "capitalize",
          },
        }}>
        {categoriesState.map((category, index) => (
          <AccordionItem key={category.id}>
            <AccordionButton>
                {category[`name_${mainLanguage}`] || "Unnamed Category"}
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel overflow={"visible"}>
              <Flex direction="column" gap={4} p={4} pt={2}>
                <Flex direction="row" gap={4} flex={1}>
                <Flex direction="column" gap={1}>
                    <Text fontWeight={600} fontSize="10px" color="var(--grey600)">Hide</Text>
                    <Switch
                      isChecked={category.hide === true}
                      onChange={() => handleHideToggle(index)}
                      size={"lg"}
                    />
                  </Flex>
                  
                  <Flex direction="column" gap={1}>
                  <Text fontWeight={600} fontSize="10px" color="var(--grey600)">Page break</Text>
                  <Select
                      value={category.pageBreak}
                      onChange={(e) => handlePageBreakChange(e, index)}
                      disabled={index === 0} // Disable for the first category
                    >
                      <option value="none">None</option>
                      <option value="before">Yes</option>
                    </Select>
                  </Flex>
                  <Flex direction="column" w="96px" gap={1}>
                  <Text fontWeight={600} fontSize="10px" color="var(--grey600)">Column(s)</Text>
                  <Select
                      value={category.columns}
                      onChange={(e) => handleColumnsChange(e, index)}
                    >
                      <option value="1">1</option>
                      <option value="2">2</option>
                    </Select>
                  </Flex>
                  
                </Flex>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </TabPanel>
  );
});

export default Categories;
