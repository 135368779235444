import React, { useState, useEffect } from "react";
import StatusSelector from "../components/shop/StatusSelector";
import OrderModal from "../components/shop/OrderModal";
import useOrders from "../hooks/useOrders";
import {
  Flex,
  Text,
  Table,
  Tbody,
  Thead,
  Th,
  Tr,
  Td,
  Box,
  Button,
  Tooltip,
  IconButton,
  InputGroup,
  Input,
  InputLeftElement,
  InputRightElement
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import {
  Car,
  ShoppingBag,
  ChevronLeft,
  ChevronRight,
  Search,
  X,
  Clock,
  Calendar,
  Plus,
  Settings
} from "lucide-react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useAppContext } from "../contexts/AppContext";
import useDates from "../hooks/useDates";
import CompletedDisclosure from "../components/shop/OrderCompletedDialog";

const Orders = () => {
  const location = useLocation();
  const { selectedRestaurantId } = useAppContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery); // Stocke la valeur pour la recherche avec délai
  const [selectedDate, setSelectedDate] = useState(new Date()); // Pour suivre la date sélectionnée
  const [isSearchActive, setIsSearchActive] = useState(false); // Pour savoir si la recherche est active ou non
  const [isToday, setIsToday] = useState(true); // État pour vérifier si la date sélectionnée est aujourd'hui
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isDateLoaded, setIsDateLoaded] = useState(false); // Nouvel état pour indiquer que la date est chargée


  const {
    orders,
    hasMoreOrders,
    loadOrders,
    searchOrders,
    filteredOrders,
    setFilteredOrders,
    isSearching,
    setIsSearching,
    deleteOrder,
    isOrderModalOpen,
    setIsOrderModalOpen,
    updateOrderStatus,
    processRefund,
    formatScheduledTime,
  } = useOrders();

  const { getDateLabel } = useDates();


  // Fonction pour filtrer les commandes par date
  const filterOrdersByDate = (orders, date) => {
    return orders.filter((order) => {
      if (!order.scheduledTime) return false;
      const scheduledDate = order.scheduledTime.toDate(); // Supposant que c'est un Timestamp Firestore
      return (
        scheduledDate.getFullYear() === date.getFullYear() &&
        scheduledDate.getMonth() === date.getMonth() &&
        scheduledDate.getDate() === date.getDate()
      );
    });
  };


  // Charger la date depuis localStorage au montage du composant
  useEffect(() => {
    const savedDate = localStorage.getItem("selectedDate");
    if (savedDate) {
      const parsedDate = new Date(savedDate);
      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
      }
    }
    setIsDateLoaded(true); // Date récupérée et chargée
  }, []);

  // Charger les commandes uniquement après que la date soit chargée
  useEffect(() => {
    if (isDateLoaded) {
      const ordersForSelectedDate = filterOrdersByDate(orders, selectedDate);
      setFilteredOrders(ordersForSelectedDate);
    }
  }, [isDateLoaded, orders, selectedDate]);

  // Sauvegarder la date dans localStorage à chaque changement de date
  useEffect(() => {
    if (isDateLoaded) {
      localStorage.setItem("selectedDate", selectedDate.toISOString());
    }
  }, [selectedDate, isDateLoaded]);
  

  useEffect(() => {
    // Vérifier si la date sélectionnée est aujourd'hui
    const today = new Date();
    setIsToday(
      selectedDate.getDate() === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear()
    );
  }, [selectedDate]);

  // Déclencher la recherche avec debounce
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery); // Met à jour la recherche après un délai
    }, 1000); // Définir un délai de 1000ms

    return () => {
      clearTimeout(handler); // Nettoyer le timeout si l'utilisateur continue de taper
    };
  }, [searchQuery]);

  // Charger les commandes et appliquer la recherche si besoin
  useEffect(() => {
    if (debouncedSearchQuery.trim()) {
      setIsSearchActive(true);
      setIsSearching(true);
      searchOrders(debouncedSearchQuery.toLowerCase())
        .then((results) => {
          setFilteredOrders(results); // Ignorer le filtre par date pendant la recherche
        })
        .finally(() => {
          setIsSearching(false);
        });
    } else {
      // Si pas de recherche, la recherche n'est plus active, et on filtre par la date sélectionnée
      setIsSearchActive(false);
      const ordersForSelectedDate = filterOrdersByDate(orders, selectedDate);
      setFilteredOrders(ordersForSelectedDate);
    }
  }, [debouncedSearchQuery, selectedDate, orders]);

  const changeDay = (direction) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + direction); // direction peut être -1 (jour précédent) ou +1 (jour suivant)
    setSelectedDate(newDate);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const openOrderModal = (order) => {
    setSelectedOrder(order);
    setIsOrderModalOpen(true);
  };



 

  useEffect(() => {
    if (location.state && location.state.orderId) {
      const orderToOpen = orders.find(
        (order) => order.id === location.state.orderId
      );
      if (orderToOpen) {
        setSelectedOrder(orderToOpen);
        setIsOrderModalOpen(true);
      }
    }
  }, [location, orders]);

  const handleBackToToday = () => {
    setSelectedDate(new Date());
  };

  return (
    <>
      <Flex w="100%" direction="column" gap={8}>
        <Flex direction="column" justifyContent="space-between" gap={4}>
          <Flex justifyContent="space-between" direction={{ desktop: "row", mobile: "column"}} gap={4}>
            <Flex gap={4} alignItems={"center"}>
              <h3>Orders</h3>
              <Tooltip label="Add a new order">
                <IconButton
                  size="xs"
                  bg={"var(--brand)"}
                  _hover={{ bg: "var(--brand600)" }}
                  onClick={() => openOrderModal(null)}>
                  <Plus color="white" size={16} />
                </IconButton>
              </Tooltip>
            </Flex>

            <Flex alignItems={"center"} gap={2} overflow={"visible"} justifyContent={"flex-start"}>
             
              <Flex
                alignItems="center"
                justifyContent="space-between"
                gap={0}
                border={"1px solid var(--borders)"}
                borderRadius={8}
                w="fit-content">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <Button
                      as="button"
                      gap={0}
                      justifyContent={"flex-start"}
                      leftIcon={<Calendar size={20} color="var(--grey500)" />}
                      color={
                        isSearchActive ? "var(--grey300)" : "var(--grey700)"
                      }
                      bg="white"
                      borderRadius={8}
                      _hover={{
                        color: "var(--brand)",
                      }}
                      _focus={{
                        color: "var(--brand)",
                      }}
                      _focusVisible={{
                        color: "var(--brand)",
                      }}
                      cursor={isSearchActive ? "none" : "pointer"}
                      pointerEvents={isSearchActive ? "none" : "normal"}
                      disabled={isSearchActive}
                      px={2}
                      minWidth="140px">
                      {getDateLabel(selectedDate)}
                    </Button>
                  }
                  disabled={isSearchActive} // Désactiver le DatePicker si la recherche est active
                />
                
                <Flex>
                  <Tooltip label="Previous Day">
                    <IconButton
                      bg="white"
                      _hover={{
                        bg: "white",
                        color: "var(--grey700)",
                      }}
                      size="sm"
                      icon={<ChevronLeft />}
                      onClick={() => changeDay(-1)} // Jour précédent
                      aria-label="Previous Day"
                      isDisabled={isSearchActive}
                      pointerEvents={isSearchActive ? "none" : "normal"} // Désactiver si la recherche est active
                    />
                  </Tooltip>

                  <Tooltip label="Next Day">
                    <IconButton
                      size="sm"
                      bg="white"
                      _hover={{
                        bg: "white",
                        color: "var(--grey700)",
                      }}
                      borderRadius="0 var(--chakra-radii-md) var(--chakra-radii-md) 0"
                      icon={<ChevronRight />}
                      onClick={() => changeDay(1)} // Jour suivant
                      aria-label="Next Day"
                      isDisabled={isSearchActive}
                      pointerEvents={isSearchActive ? "none" : "normal"} // Désactiver si la recherche est active
                    />
                  </Tooltip>
                </Flex>
              </Flex>
              <Button
                  onClick={handleBackToToday}
                  variant={"outline"}
                  bg={"white"}
                  isDisabled={isToday || isSearchActive}
                  
                  >
                  Today
              </Button>
              <Tooltip label="Shop settings">
                <IconButton as={Link} variant="outline" icon={<Settings />} to={`/${selectedRestaurantId}/settings?tab=shop`}></IconButton>
              </Tooltip>
            </Flex>
          </Flex>

          <Flex gap={2}>
            <Flex flex={1}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <Search color="var(--grey500)" width="16px" />
                </InputLeftElement>
                <Input
                  placeholder="Search by order ID"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  flex={1}
                />
                {searchQuery && (
                  <InputRightElement onClick={() => setSearchQuery("")}>
                    <Tooltip label="Clear search">
                      <Button size={"xs"} width="22.5px">
                        <X size={16} />
                      </Button>
                    </Tooltip>
                  </InputRightElement>
                )}
              </InputGroup>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          gap={8}
          mx={{ desktop: 0, mobile: 0 }}
          w={{ desktop: "100%", mobile: "100%" }}>
          {isSearching ? (
            <p>Searching...</p> // Affiche un message pendant la recherche
          ) : filteredOrders.length > 0 ? (
            <div className="tableWrapper">
              <Table w="100%">
                <Thead
                  display={{ desktop: "table-header-group", mobile: "none" }}>
                  <Tr className="orderHeader">
                    <Th>Order</Th>
                    <Th className="mobile-hidden">Method</Th>
                    <Th className="mobile-hidden">Scheduled time</Th>
                    <Th className="mobile-hidden">Payment status</Th>
                    <Th>Status</Th>
                  </Tr>
                </Thead>
                <Tbody fontSize="14px">
                  {filteredOrders.map((order) => {
                    const paymentStatusBg =
                      order.paymentStatus === "paid" ||
                      order.paymentStatus === "Paid"
                        ? "var(--success50)"
                        : "var(--error50)";
                    const paymentStatusColor =
                      order.paymentStatus === "paid" ||
                      order.paymentStatus === "Paid"
                        ? "var(--success700)"
                        : "var(--error700)";
                    return (
                      <Tr key={order.orderId} className="order" onClick={() => openOrderModal(order)} cursor={"pointer"}>
                        <Td>
                          <Tooltip label={order.fullDate}>
                            <Flex direction={{ desktop: "column", mobile: "row" }} gap={1} >
                              <h6>
                              {order.orderId
                                ? `#${order.orderId}`
                                : "No Order ID"}{" "}
                              {order.customerDetails?.firstName ||
                                "No First Name"}{" "}
                              {order.customerDetails?.lastName ||
                                "No Last Name"}
                            </h6>
                            <Text fontSize="1em" color="var(--grey500)">{order.customerDetails?.email}</Text>
                            </Flex>
                          </Tooltip>
                        </Td>
                        <Td textTransform="capitalize">
                          <Flex className="tag" alignItems="center" gap={1}>
                            {order.deliveryMode === "delivery" && (
                              <Car size={16} color="var(--grey500)" />
                            )}
                            {order.deliveryMode === "pickup" && (
                              <ShoppingBag size={16} color="var(--grey500)" />
                            )}
                            <span className="mobile-hidden">{order.deliveryMode}</span>
                          </Flex>
                        </Td>
                        <Td>
                          <Flex className="tag" alignItems="center" gap={1}>
                            <Clock size={16} color="var(--grey500)" />
                            {order.scheduledTime
                              ? formatScheduledTime(
                                  order.scheduledTime.toDate()
                                )
                              : "N/A"}
                          </Flex>
                        </Td>
                        <Td>
                          <Flex
                            alignItems="center"
                            gap={1}
                            pt={1}
                            pb={1}
                            pl={2}
                            pr={2}
                            className="tag"
                            bg={paymentStatusBg}
                            color={paymentStatusColor}>
                            <Box
                              h="6px"
                              w="6px"
                              borderRadius={"50%"}
                              bg={paymentStatusColor}></Box>
                            {order.paymentStatus}
                          </Flex>
                        </Td>
                        <Td>
                          <StatusSelector
                            paymentStatus={order.paymentStatus}
                            currentStatus={order.status}
                            onUpdateStatus={updateOrderStatus}
                            orderId={order.id}
                            deliveryMode={order.deliveryMode}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
          ) : (
            <p>No orders found.</p>
          )}

          {hasMoreOrders && !searchQuery && (
            <Button onClick={() => loadOrders()}>Load More</Button>
          )}
        </Flex>
      </Flex>

      <OrderModal
        isOpen={isOrderModalOpen}
        onClose={() => setIsOrderModalOpen(false)}
        order={selectedOrder}
        onDelete={deleteOrder}
        onUpdate={updateOrderStatus}
        processRefund={processRefund}
        formatScheduledTime={formatScheduledTime}
      />

    
    </>
  );
};

export default Orders;
