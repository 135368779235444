import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import logo from "../images/guddenApp2.svg";
import { Flex, Link, Button, Text, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { toast } from "react-toastify";

function Login({ onLoginSuccess }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // État pour gérer la visibilité du mot de passe
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Login attempt for:", email); // Ajout du log de l'email utilisé
    const auth = getAuth();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const userDocRef = doc(db, "users", user.uid);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();

        if (userData.role === "customer") {
          toast.error("This account does not exist");
          await auth.signOut();
          return;
        }
        onLoginSuccess();
        navigate(`/${localStorage.getItem("lastSelectedRestaurantId")}`);
      } else {
        toast.error("This account does not exist");
      }
    } catch (error) {
      console.error("Error during login:", error); // Log de l'erreur
      toast.error(error.message);
    }
  };

  // Fonction pour basculer la visibilité du mot de passe
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <Flex
      style={{ height: "calc(var(--vh, 1vh) * 100)" }}
      justifyContent="center"
      py={24}
      w="100%"
    >
      <Flex
        direction="column"
        className="login-container"
        gap={16}
        maxWidth="100%"
        w="320px"
      >
        <Link href="/" className="logo" title="Retour au menu" style={{ width: "82px" }}>
          <img src={logo} alt="logo" />
        </Link>
        <div className="login-inside">
          <div className="welcome-txt">
            <h3>Welcome!</h3>
            <p>Log in into your account</p>
          </div>

          <form onSubmit={handleSubmit} className="login-form">
            <div className="field">
              <label htmlFor="email">Email address</label>
              <Input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
              />
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"} // Change dynamiquement entre texte et mot de passe
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
                <InputRightElement width="4.5rem">
                  <Button size="xs" onClick={togglePasswordVisibility} variant={"outline"} isDisabled={!password}>
                    {showPassword ? "Hide" : "Show"}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </div>
            <Button size="lg" type="submit" className="btn primary" fontSize="16px">
              Log in
            </Button>
          </form>
        </div>
        <div className="bottom-text">
          <Text>© Good Robot Sarls. All rights reserved.</Text>
        </div>
      </Flex>
    </Flex>
  );
}

export default Login;
