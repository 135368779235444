import React from "react";
import { pdf } from "@react-pdf/renderer";
import useMonthlyOrders from "../hooks/useMonthlyOrders";
import CommissionInvoice from "../components/shop/CommissionInvoice";
import { useAppContext } from "../contexts/AppContext";
import { Flex, Table, Button } from '@chakra-ui/react';


const Invoicing = () => {
  const { monthlyOrders, updateInvoiceStatus } = useMonthlyOrders();
  const { settings, userData, generalSettings } = useAppContext();

  const openPdfInNewWindow = async (invoiceData) => {
    const blob = await pdf(
      <CommissionInvoice invoiceData={invoiceData} settings={settings} generalSettings={generalSettings} />
    ).toBlob();
    const url = URL.createObjectURL(blob);
    
    // Forcer le téléchargement
    const link = document.createElement('a');
    link.href = url;
    link.download = `Invoice-${invoiceData.monthYear}.pdf`; // Nom de fichier personnalisé
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const VAT_RATE = generalSettings.vat;
  const VAT_DECIMAL = VAT_RATE / 100;
  const formatAmount = (amount) => amount.toFixed(2).replace('.', ',');

  return (
    <Flex direction="column" p={{ desktop: "48px", mobile: "24px" }} pb={{ desktop: "124px", mobile: "172px" }} gap={8} maxW="1320px">
      <div className="sectionHeader row spaceBetween">
        <h2>Invoicing</h2>
      </div>

      <div className="widgetBody">
        <div className="tableWrapper">
          <Table w="100%">
            <thead>
              <tr className="orderHeader">
                <th>Month</th>
                <th>Total due</th>
                <th>Invoice</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {monthlyOrders.map((invoiceData, index) => (
                <tr key={index}>
                  <td>{invoiceData.monthYear}</td>
                  <td>{formatAmount((invoiceData.totalCommission + invoiceData.totalServiceFees) * (1 + VAT_DECIMAL ))}€</td>
                  <td>
                    <Button
                      size="xs"
                      onClick={() => openPdfInNewWindow(invoiceData)}
                    >
                      Download Invoice
                    </Button>
                  </td>
                  <td>
                    

                    {userData.role === "superAdmin" ? (
                      <select
                        value={invoiceData.status}
                        onChange={(e) =>
                          updateInvoiceStatus(
                            `${invoiceData.monthYearKey}`,
                            e.target.value
                          )
                        }
                      >
                        <option value="Unpaid">Unpaid</option>
                        <option value="Paid">Paid</option>
                        <option value="Pending">Pending</option>
                      </select>
                    ) : 
                    (
                      <span>{invoiceData.status}</span>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </Flex>
  );
};

export default Invoicing;
