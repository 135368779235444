import React, { useState, useEffect, useRef, useMemo } from "react";
import useAllergenes from "../../../hooks/useAllergenes";
import { useAppContext } from "../../../contexts/AppContext";
import { Button, Flex } from "@chakra-ui/react";
import { EllipsisVertical } from "lucide-react";
import { useMenuContext } from "../../../contexts/MenuContext";
import useMenuData from "../../../hooks/useMenuData";
import ImageDropzone from "./ImageDropzone";
import CommonFields from "./CommonFields";
import LanguageFields from "./LanguageFields";
import ItemActions from "../ItemActions";


const ItemModal = ({
  categories,
  handleSubmit,
  onDuplicate,
  handleImageUpload,
  handleDragOver,
  handleDrop,
  handleImageDelete,
  handleImageUploadClick,
  currentItem,
  setCurrentItem,
  closeEditModal,
  currentMainCategory,
  hasChanges,
  setHasChanges,
  toggleAvailability,
  onArchive,
}) => {
  
  const { toggleSubMenu, openSubMenu, closeSubMenu, showOverlay, settings, mainLanguage } = useAppContext();


  const { tags, selectedTags, setSelectedTags, saving } = useMenuContext();
  const { confirmAndDelete } = useMenuData();


  const [activeLanguageTab, setActiveLanguageTab] = useState(mainLanguage);
  useEffect(() => {
    setActiveLanguageTab(mainLanguage);
  }, [mainLanguage]);


  const [productType, setProductType] = useState(currentItem.type || "simple");

  const defaultVariations = [
    { name: "", price: "" },
    { name: "", price: "" },
  ];
  const [variations, setVariations] = useState(
    productType === "variable"
      ? defaultVariations
      : currentItem.variations || []
  );

  const allergenesWithUndefined = useAllergenes();

  const [selectedAllergenes, setSelectedAllergenes] = useState([]);

  const [allergenMode, setAllergenMode] = useState("Ask Waiter");

  const [selectedCategory, setSelectedCategory] = useState(currentItem.categorieId || "");
  const [isCategoryValid, setIsCategoryValid] = useState(true);
  const selectedCategoryObject = categories.find((cat) => cat.id === selectedCategory);

  const combinedLanguages = useMemo(() => {
    return [settings.language[0], ...settings.secondaryLanguages].filter(
      Boolean
    );
  }, [settings.language, settings.secondaryLanguages]);

  const handleAllergenModeChange = (newMode) => {
    setAllergenMode(newMode);
    if (newMode === "Ask Waiter") {
      setSelectedAllergenes(["ask waiter"]);
    } else if (newMode === "Define Allergens") {
      setSelectedAllergenes(
        selectedAllergenes.filter((allergene) => allergene !== "ask waiter")
      );
    }
    setHasChanges(true);
  };

  const toggleAllergene = (selectedAllergene) => {
    if (allergenMode !== "Define Allergens") return;

    setSelectedAllergenes((prevSelectedAllergenes) => {
      if (selectedAllergene === "ask waiter") {
        return prevSelectedAllergenes.includes("ask waiter")
          ? []
          : ["ask waiter"];
      }

      if (prevSelectedAllergenes.includes("ask waiter")) {
        const newSelectedAllergenes = ["ask waiter"].includes(selectedAllergene)
          ? []
          : [selectedAllergene];
        return newSelectedAllergenes;
      }

      const isAllergeneSelected =
        prevSelectedAllergenes.includes(selectedAllergene);
      return isAllergeneSelected
        ? prevSelectedAllergenes.filter(
            (allergene) => allergene !== selectedAllergene
          )
        : [...prevSelectedAllergenes, selectedAllergene];
    });
    setHasChanges(true);
  };

  useEffect(() => {
    setProductType(currentItem.type || "simple");
  }, [currentItem.type]);

  useEffect(() => {
    setSelectedAllergenes(currentItem.allergenes || []);

    if (
      currentItem.allergenes &&
      currentItem.allergenes.includes("ask waiter")
    ) {
      setAllergenMode("Ask Waiter");
      setSelectedAllergenes(["ask waiter"]);
    } else if (currentItem.allergenes && currentItem.allergenes.length > 0) {
      setAllergenMode("Define Allergens");
      setSelectedAllergenes(currentItem.allergenes);
    } else {
      setAllergenMode("Ask Waiter");
      setSelectedAllergenes(["ask waiter"]);
    }
    setVariations(currentItem.variations || []);
    setSelectedCategory(currentItem.categorieId || "");

    if (currentItem && currentItem.tags) {
      const updatedSelectedTags = currentItem.tags
        .map((tagInfo) => {
          const foundTag = tags.find((tag) => tag.id === tagInfo.value);
          return foundTag ? { ...tagInfo, color: foundTag.color } : null;
        })
        .filter((tag) => tag !== null);

      setSelectedTags(updatedSelectedTags);
    } else {
      setSelectedTags([]);
    }
  }, [currentItem, tags]);

  useEffect(() => {
    if (currentItem && currentItem.id) {
      const editItemModal = document.querySelector("#edit-item-modal");
      editItemModal.style.display = "flex";
      editItemModal.classList.add("active");
    }
  }, [currentItem]);

  useEffect(() => {
    if (productType === "variable") {
      if (currentItem.variations && currentItem.variations.length > 0) {
        setVariations(currentItem.variations);
      } else {
        setVariations(defaultVariations);
      }
    } else if (productType === "simple" && variations.length !== 0) {
      setVariations([]);
    }
  }, [productType, currentItem.variations]);

  useEffect(() => {
    if (currentItem.display) {
      const initialDisplayOptions = displayOptions.filter((option) =>
        currentItem.display.includes(option.value)
      );
      setSelectedDisplayOptions(initialDisplayOptions);
    } else {
      setSelectedDisplayOptions([]);
    }
  }, [currentItem.display]);

  const addVariation = () => {
    setVariations([...variations, { name: "", price: "" }]);
  };

  const handleVariationChange = (index, field, value) => {
    if (productType !== "variable") return;

    const updatedVariations = variations.map((variation, i) => {
      if (i === index) {
        return { ...variation, [field]: value };
      }
      return variation;
    });
    setVariations(updatedVariations);
    setHasChanges(true);
  };

  const handleDeleteVariation = (index) => {
    setVariations(variations.filter((_, i) => i !== index));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const newItemState = {
      ...currentItem,
      [name]: value,
      variations: variations,  // Variations actuelles
      allergenes: selectedAllergenes,  // Allergènes sélectionnés
      display: selectedDisplayOptions.map((option) => option.value), // Options d'affichage
      tags: selectedTags,  // Tags sélectionnés
    };
  
    // Gestion du prix et des variations
    if (name.startsWith("price") && productType === "variable") {
      const index = parseInt(name.replace("price", ""), 10);
      const newVariations = variations.map((variation, idx) =>
        idx === index ? { ...variation, price: value } : variation
      );
      setVariations(newVariations);
      setCurrentItem({ ...newItemState, variations: newVariations });
    } else {
      setCurrentItem(newItemState);
    }
  
    setHasChanges(true);
  };
  

  const handleCategoryChange = (newValue) => {
    setSelectedCategory(newValue);
    setIsCategoryValid(true); // Réinitialiser la validation si une catégorie est sélectionnée
    setCurrentItem({
      ...currentItem,
      categorieId: newValue,
    });
    handleInputChange({ target: { name: "categorieId", value: newValue } });
  };

  

  

  const handleVATChange = (selectedOption) => {
    setCurrentItem((prevCurrentItem) => ({
      ...prevCurrentItem,
      vat: parseFloat(selectedOption.value),
      variations: prevCurrentItem.variations, // Explicitement préserver les variations existantes
    }));
    setHasChanges(true); // Indiquer que des modifications ont été apportées
  };

  useEffect(() => {
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");

    const handleAnimationEnd = () => {
      modalContent.classList.remove("modal-content-animating");
    };

    if (modalContent) {
      modalContent.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (modalContent) {
        modalContent.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  const textareaRef = useRef(null);
  useEffect(() => {
    const adjustTextareaHeight = () => {
      const textarea = textareaRef.current;
      if (textarea) {
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    };
    adjustTextareaHeight();
  }, [currentItem[`description_${activeLanguageTab}`], activeLanguageTab]);

  const capitalizeFirstLetter = (string) => {
    if (!string) return "";
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleCloseMouseDown = (e) => {
    if (e.target.id === "edit-item-modal") {
      closeEditModal();
      setActiveLanguageTab(settings.language[0].value); // Réinitialiser l'onglet de langue actif
    }
  };

  const displayOptions = useMemo(() => {
    const options = [{ value: "menu", label: "Menu" }];
    if (settings?.modules?.shop) {
      options.push({ value: "shop", label: "Shop" });
    }
    return options;
  }, [settings.modules?.shop]);

  const [selectedDisplayOptions, setSelectedDisplayOptions] = useState([]);

  const handleDisplayChange = (selectedOptions) => {
    setCurrentItem((prevCurrentItem) => ({
      ...prevCurrentItem,
      display: selectedOptions.map((option) => option.value),
      variations: prevCurrentItem.variations, // Explicitement préserver les variations existantes
    }));
    setHasChanges(true);
  };

  useEffect(() => {
    if (currentItem.display) {
      const initialDisplayOptions = displayOptions.filter((option) =>
        currentItem.display.includes(option.value)
      );
      setSelectedDisplayOptions(initialDisplayOptions);
    }
  }, [currentItem.display]);

  const isShopSelected = selectedDisplayOptions.some(
    (option) => option.value === "shop"
  );

  const handleCloseModal = () => {
    closeEditModal();
    setTimeout(() => {
      setActiveLanguageTab(mainLanguage);
    }, 1000); // Délai de 1 seconde
  };

 

  const validateForm = () => {
    let valid = true;
    if (!selectedCategory) {
      setIsCategoryValid(false);
      valid = false;
    }
    return valid;
  };

  const handleSave = (e) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e, productType, variations, selectedAllergenes, selectedTags);
      setTimeout(() => {
        setActiveLanguageTab(mainLanguage);
      }, 2000);
    }
  };

  

  return (
    <Flex
      direction="column"
      id="edit-item-modal"
      className="modal"
      style={{ display: "none" }}
      onMouseDown={handleCloseMouseDown}>
      <Flex
        className="modal-content"
        style={{ height: "calc(var(--vh, 1vh) * 100)" }}>
        <form className="itemForm" onSubmit={handleSave}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={handleCloseModal}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>

            <div className="modalTitle">
              {currentItem.id
                ? `Edit ${currentItem[`name_${mainLanguage}`]}`
                : "Add an item"}
            </div>

            <div className="actionButtons">
            
                <Button 
                  size={"sm"} 
                  variant={"solid"} 
                  isDisabled={!hasChanges || saving} // Désactive le bouton si pas de changements ou en train de sauvegarder
                  isLoading={saving}
                  onClick={handleSave}>
                    {currentItem.id ? "Save changes" : "Add item"}
                </Button>
              

              {currentItem.id ? (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("itemActions")}>
                  <EllipsisVertical size={20} color="var(--grey500)" />
                  {openSubMenu === "itemActions" && (
                    <div className="submenu">
                      
                      <ItemActions
                                            item={currentItem}
                                            onDuplicate={onDuplicate}
                                            toggleAvailability={toggleAvailability}
                                            confirmAndDelete={confirmAndDelete}
                                            closeSubMenu={closeSubMenu}
                                            onArchive={onArchive}
                                          />
                                          
                                        
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "itemActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          

          {combinedLanguages.length > 1 && (
            <div className="languageTabs">
              {combinedLanguages.map((lang, index) => (
                <div
                  key={index}
                  className={`languageTab ${
                    activeLanguageTab === lang.value ? "active" : ""
                  }`}
                  onClick={() => setActiveLanguageTab(lang.value)}>
                  {lang.label}
                </div>
              ))}
            </div>
          )}

          {(currentMainCategory?.imageDisplay || selectedCategoryObject?.style === "Cards") && (
            <ImageDropzone
              currentItem={currentItem}
              handleDragOver={handleDragOver}
              handleDrop={handleDrop}
              handleImageUpload={handleImageUpload}
              handleImageUploadClick={handleImageUploadClick}
              handleImageDelete={handleImageDelete}
            />
          )}

          {activeLanguageTab === settings.language[0].value && (
            <>
              <LanguageFields
                languageValue={settings.language[0].value}
                currentItem={currentItem}
                handleInputChange={handleInputChange}
                textareaRef={textareaRef}
                settings={settings}
              />
              <CommonFields
                productType={productType}
                setProductType={setProductType}
                currentItem={currentItem}
                setCurrentItem={setCurrentItem}
                handleInputChange={handleInputChange}
                categories={categories}
                selectedCategory={selectedCategory}
                handleCategoryChange={handleCategoryChange}
                capitalizeFirstLetter={capitalizeFirstLetter}
                isCategoryValid={isCategoryValid}
                allergenesWithUndefined={allergenesWithUndefined}
                allergenMode={allergenMode}
                handleAllergenModeChange={handleAllergenModeChange}
                selectedAllergenes={selectedAllergenes}
                toggleAllergene={toggleAllergene}
                variations={variations}
                handleVariationChange={handleVariationChange}
                handleDeleteVariation={handleDeleteVariation}
                addVariation={addVariation}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                displayOptions={displayOptions}
                handleDisplayChange={handleDisplayChange}
                selectedDisplayOptions={selectedDisplayOptions}
                isShopSelected={isShopSelected}
                handleVATChange={handleVATChange}
                setHasChanges={setHasChanges}
              />
            </>
          )}

          {settings.secondaryLanguages
            .map((lang) => lang.value)
            .includes(activeLanguageTab) && (
            <LanguageFields
              languageValue={activeLanguageTab}
              currentItem={currentItem}
              handleInputChange={handleInputChange}
              textareaRef={textareaRef}
              settings={settings}
            />
          )}
        </form>
      </Flex>
    </Flex>
  );
};

export default ItemModal;
