import React, { useEffect, useState, useRef } from "react";
import { db } from "../firebaseConfig";
import { doc, onSnapshot } from "firebase/firestore";
import { Flex, Button, Text } from "@chakra-ui/react";

const CURRENT_APP_VERSION = "3"; // Version actuelle de l'application

const VersionCheck = () => {
  const [isUpdateAvailable, setIsUpdateAvailable] = useState(false);
  const [latestVersion, setLatestVersion] = useState(CURRENT_APP_VERSION);
  const initialCheckDone = useRef(false); // Utilisé pour ignorer le premier affichage du message de mise à jour

  useEffect(() => {
    const versionDocRef = doc(db, "general", "settings");

    const unsubscribe = onSnapshot(versionDocRef, (doc) => {
      if (doc.exists()) {
        const fetchedVersion = String(doc.data().appConfig.versionNumber);
        console.log("Latest version from Firestore:", fetchedVersion);

        // Si c'est le premier chargement, stocker la version dans localStorage et ignorer le message
        if (!initialCheckDone.current) {
          initialCheckDone.current = true;
          localStorage.setItem("appVersion", fetchedVersion);
        } else if (fetchedVersion > CURRENT_APP_VERSION) {
          // Si une nouvelle version est détectée, afficher le message
          setLatestVersion(fetchedVersion);
          setIsUpdateAvailable(true);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const handleRefresh = () => {
    localStorage.setItem("appVersion", latestVersion);
    window.location.reload();
  };

  return (
    isUpdateAvailable && (
      <Flex
        position="fixed"
        top="0"
        width="100%"
        zIndex={999}
        align="center"
        justify="center"
        bg="yellow.50"
        py={3}
        px={6}
        gap={4}>
        <Text fontWeight={500} color="var(--grey700)">
          A new version of the app is available.
        </Text>
        <Button size="sm" onClick={handleRefresh}>
          Refresh page
        </Button>
      </Flex>
    )
  );
};

export default VersionCheck;
