import React, { useState } from "react";
import { Flex, Box, Text, Button, IconButton } from "@chakra-ui/react";
import { Trash } from "lucide-react";
import CancellationMessageModal from "./CancellationMessageModal";
import { doc, updateDoc } from "firebase/firestore"; // Importez updateDoc pour mettre à jour Firestore
import { db } from "../../../../firebaseConfig";
import { useAppContext } from "../../../../contexts/AppContext";

const CancellationMessages = () => {
  const { selectedRestaurantId, settings, setSettings } = useAppContext();
  const [editMessageIndex, setEditMessageIndex] = useState(null); // État pour gérer l'index du message à éditer
  const [isEditMode, setIsEditMode] = useState(false); // État pour gérer si on est en mode édition

  // Fonction pour ouvrir le modal en mode édition
  const handleEditMessage = (index) => {
    setEditMessageIndex(index);
    setIsEditMode(true);
    openCancellationModal();
  };

  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const openCancellationModal = () => {
    setIsCancellationModalOpen(true);
  };

  const closeCancellationModal = () => {
    setIsCancellationModalOpen(false);
  };

  const handleDeleteMessage = async (index) => {
    const updatedMessages = settings.shopSettings.cancellationMessages.filter(
      (msg, idx) => idx !== index
    );

    setSettings((prevSettings) => ({
      ...prevSettings,
      shopSettings: {
        ...prevSettings.shopSettings,
        cancellationMessages: updatedMessages,
      },
    }));

    try {
      const settingsRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings/config`
      );
      await updateDoc(settingsRef, {
        "shopSettings.cancellationMessages": updatedMessages,
      });
      console.log("Cancellation message deleted from Firestore.");
    } catch (error) {
      console.error(
        "Error deleting cancellation message from Firestore: ",
        error
      );
    }
  };

  return (
    <Flex
      className="settingsField"
      direction={{ desktop: "row", mobile: "column" }}
      alignItems={"flex-start"}
      gap={4}
      borderBottom={"1px solid var(--borders)"}
      pb={8}>
      <Flex direction={"column"} w={{ desktop: "40%", mobile: "100%" }} gap={1}>
        <h6>Cancellation messages</h6>
        <Text maxW={"320px"}>
          {" "}
          Define preset messages that will be sent to the customer when
          cancelling their order.
        </Text>
      </Flex>
      <Flex direction="column" gap={4} flex={1}>
        <Flex direction="column" gap={2} flex={1}>
          {settings.shopSettings?.cancellationMessages?.length > 0 ? (
            settings.shopSettings.cancellationMessages.map((message, index) => (
              <Flex
                key={index}
                alignItems="flex-start"
                justifyContent="space-between"
                w={"100%"}
                p={4}
                border={"1.5px solid var(--borders)"}
                borderRadius={8}>
                <Flex direction={"column"} gap={1} maxW={"80%"} >
                  <Text fontSize={15} fontWeight={500} color="var(--heading)">{message.name}</Text>
                  <Text fontSize={"13px"} overflow={"hidden"} display={"-webkit-box"} wordBreak={"break-word"} style={{ WebkitLineClamp: "2", WebkitBoxOrient: "vertical" }}>{message.message}</Text>
                </Flex>
                <Flex gap={1}>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => handleEditMessage(index)} // Bouton pour ouvrir le modal en mode édition
                >
                  Edit
                </Button>
                <IconButton
                  size="sm"
                  variant="outline"
                  onClick={() => handleDeleteMessage(index)}>
                  <Trash size={16} />
                </IconButton>
                </Flex>
              </Flex>
            ))
          ) : (
            <p>No cancellation messages available.</p>
          )}
        </Flex>
        <Button
          variant="outline"
          onClick={openCancellationModal}
          w={"fit-content"}>
          Add
        </Button>
      </Flex>
      <CancellationMessageModal
        isOpen={isCancellationModalOpen}
        onClose={closeCancellationModal}
        isEditMode={isEditMode}
        editMessage={
          settings.shopSettings?.cancellationMessages?.[editMessageIndex] ||
          null
        } // Transmet le message à éditer
        editIndex={editMessageIndex}
        setIsEditMode={setIsEditMode}
      />
    </Flex>
  );
};

export default CancellationMessages;
