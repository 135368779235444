import { useEffect } from "react";
import { db } from "../firebaseConfig";
import { getDocs, collection, query, where } from 'firebase/firestore';
import UserModal from "../components/settings/users/UserModal";
import useUsers from '../hooks/useUsers';
import { Flex, Box, Button, Table, Thead, Th, Tr, Td, Text, Tbody } from "@chakra-ui/react";
import { useAppContext } from "../contexts/AppContext";


const Staff = () => {
    const { selectedRestaurantId, userRole } = useAppContext();

    const { 
        users,
        setUsers,
        currentUser,
        setCurrentUser,
        editedUser,
        setEditedUser,
        addUserFromCloud,
        handleSaveUser,
        deleteUserFromCloud,
        isUserModalOpen,
        setIsUserModalOpen,
        isDeletingUser,
        isAddingUser,
        closeUserModal,
        userHasChanges,
        setUserHasChanges
      } = useUsers(selectedRestaurantId);


 
      useEffect(() => {
        const fetchUsers = async () => {
            if (selectedRestaurantId) {
                const q = query(collection(db, 'users'), where("restaurantIds", "array-contains", selectedRestaurantId));
                const usersSnapshot = await getDocs(q);
                const usersList = usersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUsers(usersList);
            }
        };
        fetchUsers();
    }, [selectedRestaurantId]);
    


    const handleUserEdit = (user) => {
      setCurrentUser(user);
      setIsUserModalOpen(true);
  };

  const handleAddUser = () => {
    setCurrentUser(null);  // Réinitialiser l'utilisateur actuel
    setEditedUser({});     // Réinitialiser l'utilisateur modifié
    setIsUserModalOpen(true);
};

  
return (
    
<Flex
    direction="column"
    padding={{ desktop: 12, mobile: 8 }}
    pb={{ desktop: '124px', mobile: '172px' }}     
    gap={8}
      maxW="1320px"
      flex={1}>
          <Flex direction='column' gap={8} flex={1} h="fit-content">
            <Flex direction="row" gap={4}>
            <h2>Staff</h2>
              <div className='buttons'>
              {(userRole === 'superAdmin' || userRole === 'restaurantOwner') && 
              <Button size="sm" variant={'outline'} onClick={handleAddUser}>Add staff</Button>}
              </div>
            </Flex>
              
            <Box overflowX="auto">
              <Table>
                <Thead>
                  <Tr>
                  <Th>Name</Th>
                  <Th>Role</Th>
                  {userRole !== 'manager' && <Th>Last Login</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                {users.map(user => (
                  <Tr direction="row" p={2} className='user' key={user.id} onClick={() => handleUserEdit(user)} _hover={{ bg: 'var(--grey25)' }} cursor={'pointer'}>
                    <Td>
                        <Flex gap={1} fontWeight={500} color={'var(--grey700)'}>{user.name} {user.lastName}</Flex>
                          <Text fontSize={'14px'}>{user.email}</Text>
                      </Td>
                    
                    <Td><Box className="tag" textTransform={"capitalize"}>{user.role}</Box></Td>
                    {userRole !== 'manager' && (
                    <Td>
                      <Box className="tag" textTransform="initial">
                        {user.lastLogin ? 
                          new Date(user.lastLogin.seconds * 1000).toLocaleString("en-GB", {
                            year: 'numeric', month: 'short', day: 'numeric', 
                            hour: '2-digit', minute: '2-digit', hour12: false
                          }).replace(',', ' at') : 'N/A'}
                      </Box>
                    </Td>
                  )}

                  </Tr>
                ))}
                </Tbody>
                </Table>
        </Box>
          </Flex>
          <UserModal 
                user={currentUser}
                setUsers={setUsers}
                editedUser={editedUser}
                setEditedUser={setEditedUser}
                isOpen={isUserModalOpen} 
                onClose={closeUserModal} 
                onSave={currentUser ? handleSaveUser : addUserFromCloud}
                onDelete={deleteUserFromCloud}
                addUser={addUserFromCloud}
                userHasChanges={userHasChanges}
                setUserHasChanges={setUserHasChanges}
                isAddingUser={isAddingUser}
                isDeletingUser={isDeletingUser}
                setIsUserModalOpen={setIsUserModalOpen}
          />
        </Flex>
  );
};

export default Staff;

