// useMenuData.js

import { useState, useEffect, useCallback } from "react";
import { db, storage } from "../firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  writeBatch,
  deleteField,
  query,
  setDoc,
  getDoc
} from "firebase/firestore";
import {
  ref as storageRef,
  ref,
  getStorage,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { toast } from "react-toastify";
import { useAppContext } from "../contexts/AppContext";
import { useMenuContext } from "../contexts/MenuContext";

const useMenuData = () => {
  const {
    selectedRestaurantId,
    mainCategoryName,
    settings,
    mainLanguage,
  } = useAppContext();

  const {
    categories,
    setCategories,
    itemsByCategory,
    setItemsByCategory,
	  menuItems, setMenuItems,
    tags, setTags,
    saving, setSaving
  } = useMenuContext();

  
  const [currentItem, setCurrentItem] = useState({
    price: "",
    costPrice: "",
    ratio: "",
    note: "",
    allergenes: "",
    variations: "",
    type: "",
    id: "",
    photo: "",
    categorieId: "",
    tags: "",
    display: "",
    vat: "",
  });
  const [categoryOrderChanged, setCategoryOrderChanged] = useState(false);
  const [uncategorizedItems, setUncategorizedItems] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
        if (!selectedRestaurantId || !mainCategoryName) {
            setIsLoading(false);
            return;
        }

        const menuSnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`));
        const categorySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`));

        const fetchedMenuItems = menuSnapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => a.order - b.order);

        const fetchedCategories = categorySnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .sort((a, b) => a.order - b.order);

        setMenuItems(fetchedMenuItems);
        setCategories(fetchedCategories);
        updateUncategorizedItems(fetchedMenuItems, fetchedCategories);
    } catch (error) {
        console.error("Error fetching data: ", error);
    } finally {
        setIsLoading(false);
    }
}, [mainCategoryName, selectedRestaurantId, setCategories]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    const newItemsByCategory = categories.reduce((acc, category) => {
      acc[category.id] = menuItems.filter(
        (item) => item.categorieId === category.id
      );
      return acc;
    }, {});
    setItemsByCategory(newItemsByCategory);
  }, [menuItems, categories, setItemsByCategory]);


  useEffect(() => {
    setCurrentItem(currentItem);
  }, [currentItem, setCurrentItem]);

  const updateUncategorizedItems = (items, categories) => {
    const uncategorizedCategory = categories.find((cat) => cat.isUncategorized);
    const uncategorizedCategoryId = "uncategorized";
    const newUncategorizedItems = items.map((item) => {
      if (!item.categorieId || item.categorieId === uncategorizedCategory?.id) {
        return { ...item, categorieId: uncategorizedCategoryId };
      }
      return item;
    });
    setUncategorizedItems(
      newUncategorizedItems.filter(
        (item) => item.categorieId === uncategorizedCategoryId
      )
    );
  };

  const handleOrderChange = async (newOrder) => {
    const batch = writeBatch(db);
    newOrder.forEach((category, index) => {
      if (!category || !category.id) {
        console.error("Invalid category data:", category);
        return;
      }
      const categoryRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        category.id
      );
      batch.update(categoryRef, { order: index });
    });

    try {
      await batch.commit();
    } catch (error) {
      console.error("Error updating order of categories: ", error);
    }
  };

  const handleCategoryUpdate = (updatedCategory) => {
    setCategories(
      categories.map((category) => {
        if (category.id === updatedCategory.id) {
          return { ...category, ...updatedCategory };
        }
        return category;
      })
    );
    document.body.classList.remove("no-scroll");
  };

  const deleteSubCategory = async (category) => {
    if (!category || !category.id) {
      console.error("Invalid category object or missing ID.");
      return;
    }
    try {
      // Suppression de la sous-catégorie dans Firestore
      const subCategoryDocRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        category.id
      );
      await deleteDoc(subCategoryDocRef);
  
      // Mise à jour des éléments pour les passer en 'uncategorized'
      await updateMenuItemsToUncategorized(category.id);
  
      // Mise à jour locale de l'état des catégories
      setCategories((prevCategories) =>
        prevCategories.filter((cat) => cat.id !== category.id)
      );
  
      toast.success("Category deleted successfully");
    } catch (error) {
      console.error("Error deleting sub-category:", error);
      toast.error("Error deleting sub-category");
    }
  };
  

  const updateMenuItemsToUncategorized = async (categoryId) => {
    const batch = writeBatch(db);

    menuItems.forEach((item) => {
      if (item.categorieId === categoryId) {
        const itemRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          item.id
        );
        batch.update(itemRef, { categorieId: "uncategorized" });
      }
    });

    try {
      await batch.commit();
      console.log("Menu items updated to uncategorized successfully.");
    } catch (error) {
      console.error("Error updating menu items to uncategorized:", error);
    }
  };

  // Fonction pour formater le prix
const formatPrice = (price, productType) => {
  if (productType === "variable") return null;
  
  const numericPrice = parseFloat(price?.replace(",", "."));
  if (!isNaN(numericPrice)) {
    return numericPrice.toFixed(2).replace(".", ",");
  }
  return "";
};

// Fonction pour nettoyer les champs de langue
const cleanLanguageFields = (item, languages) => {
  const cleanedItem = { ...item };
  languages.forEach((lang) => {
    if (cleanedItem[`name_${lang}`]) {
      cleanedItem[`name_${lang}`] = cleanedItem[`name_${lang}`].trim();
    }
    if (cleanedItem[`description_${lang}`]) {
      cleanedItem[`description_${lang}`] = cleanedItem[`description_${lang}`].trim();
    }
  });
  return cleanedItem;
};

// Fonction pour formater les variations si le produit est de type "variable"
const formatVariations = (variations) => {
  return variations.map((variation) => ({
    ...variation,
    price: parseFloat(variation.price.replace(",", ".")).toFixed(2).replace(".", ","),
  }));
};

// Fonction pour mettre à jour ou créer un item dans Firestore
const saveItemToFirestore = async (updatedItem, currentItem, productType) => {
  try {
    if (currentItem.id) {
      // Mise à jour de l'item existant
      const itemRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
        currentItem.id
      );

      const updates = { ...updatedItem }; // Copie des mises à jour

      // Si le type est "variable", on supprime le champ "price"
      if (productType === "variable") {
        updates.price = deleteField(); // Supprime le champ "price" car variations gère le prix
      } else {
        updates.variations = deleteField(); // Supprime "variations" si ce n'est pas un produit variable
      }

      // Suppression de l'image si aucune photo n'est présente
      if (!updatedItem.photo) {
        updates.photo = deleteField(); // Supprime le champ photo s'il est vide
      }

      // Mise à jour dans Firestore
      await updateDoc(itemRef, updates);

    } else {
      // Ajout d'un nouvel item
      const newItemRef = await addDoc(
        collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`),
        updatedItem
      );
      updatedItem.id = newItemRef.id; // Ajoute l'ID généré à l'item
      await updateDoc(newItemRef, { id: newItemRef.id });
    }

    // Mise à jour locale sans refaire un fetch global
    if (currentItem.id) {
      setItemsByCategory((prevItemsByCategory) => {
        const updatedCategoryItems = prevItemsByCategory[currentItem.categorieId].map((item) =>
          item.id === currentItem.id ? updatedItem : item
        );
        return {
          ...prevItemsByCategory,
          [currentItem.categorieId]: updatedCategoryItems,
        };
      });
    } else {
      // Ajouter un nouvel élément dans la catégorie
      setItemsByCategory((prevItemsByCategory) => ({
        ...prevItemsByCategory,
        [updatedItem.categorieId]: [...prevItemsByCategory[updatedItem.categorieId], updatedItem],
      }));
    }
  } catch (error) {
    console.error("Erreur lors de la sauvegarde dans Firestore : ", error);
  }
};



const handleSubmit = async (
  e,
  productType,
  variations,
  selectedAllergenes,
  selectedTags
) => {
  e.preventDefault();

  setSaving(true); // Commence l'état de sauvegarde

  // 1. Formater le prix pour les items simples
  const formattedPrice = formatPrice(currentItem.price, productType);

  // 2. Préparer l'item mis à jour
  let updatedItem = {
    ...currentItem,
    allergenes: selectedAllergenes,
    tags: selectedTags.map((tag) => ({
      color: tag.color,
      label: tag.label,
      value: tag.value,
    })),
    type: productType,
    ...(productType !== "variable" && formattedPrice !== "" && { price: formattedPrice }),
  };

  if (!currentItem.id) {
    updatedItem.availability = true; // Par défaut, un nouvel item est disponible
  }

  // 3. Nettoyer les champs de langue (si nécessaire)
  const languages = [settings.language[0].value, ...settings.secondaryLanguages.map(lang => lang.value)];
  updatedItem = cleanLanguageFields(updatedItem, languages);

  // 4. Gérer les variations
  if (productType === "variable") {
    updatedItem.variations = formatVariations(variations);
    delete updatedItem.price; // Supprimer le champ prix pour les variations
  } else {
    delete updatedItem.variations; // Supprimer les variations si ce n'est pas un produit variable
  }

  try {
    // 5. Sauvegarder ou mettre à jour l'item dans Firestore
    await saveItemToFirestore(updatedItem, currentItem, productType);

    // *** Log pour diagnostiquer le problème ***
    console.log("Item ID après sauvegarde: ", currentItem.id);

    // *** Ne pas interagir avec /stock si l'item est nouveau et n'a pas d'ID ***
    if (!currentItem.id) {
      console.log("New item created, skipping stock update.");
      closeEditModal(true); // Fermer le modal après la création
      toast.success("Item saved successfully");
      return; // Sortir de la fonction car l'item est nouveau et n'a pas d'ID
    }

    // *** Mise à jour du stock pour les items existants ***
    if (currentItem.id && settings.modules.ratio) {
      const batch = writeBatch(db);

      // Synchronisation du prix et des descriptions dans /stock si c'est un item simple
      if (productType !== "variable" && formattedPrice !== "" && formattedPrice !== null) {
        const stockRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`,
          currentItem.id
        );

        const nameFields = {};
        languages.forEach(lang => {
          nameFields[`name_${lang}`] = currentItem[`name_${lang}`] || "";
          nameFields[`description_${lang}`] = currentItem[`description_${lang}`] || "";
        });

        batch.update(stockRef, { 
          price: formattedPrice,
          ...nameFields, // Mettre à jour les noms et descriptions dans /stock
        });

        await batch.commit();
      }
    }

    // Fermer le modal après la sauvegarde
    closeEditModal(true);
    toast.success("Item saved successfully");
  } catch (error) {
    console.error("Erreur lors de la sauvegarde de l'item : ", error);
    toast.error("Erreur lors de la sauvegarde de l'item");
  } finally {
    setSaving(false); // Fin de l'état de sauvegarde
  }
};










  

const duplicateItem = async (itemToDuplicate) => {
  try {
    // Vérifie si mainLanguage est défini et non vide
    if (typeof mainLanguage !== "string" || !mainLanguage.trim()) {
      throw new Error("mainLanguage is undefined or empty.");
    }

    let newItem = {
      ...itemToDuplicate,
      [`name_${mainLanguage}`]: `${itemToDuplicate[`name_${mainLanguage}`]} (Copy)`,
    };

    delete newItem.id; // Supprime l'ID pour que Firestore génère un nouvel ID

    // Ajouter le nouvel item dans Firestore
    const docRef = await addDoc(
      collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`),
      newItem
    );

    // Mise à jour de l'ID généré dans Firestore
    await updateDoc(docRef, { id: docRef.id });

    // Ajouter l'item dupliqué localement à l'état `menuItems`
    const duplicatedItem = { ...newItem, id: docRef.id };
    setMenuItems((prevMenuItems) => [...prevMenuItems, duplicatedItem]);

    // Fermer le modal après la duplication
    closeEditModal(true);
    toast.success("Item duplicated successfully");
  } catch (error) {
    console.error("Error duplicating item:", error);
    toast.error("Error duplicating item");
  }
};

const archiveItem = async (itemId) => {
  try {
    if (!selectedRestaurantId || !mainCategoryName) {
      throw new Error('selectedRestaurantId or mainCategoryName is undefined.');
    }

    // 1. Récupérer l'item depuis la collection /items
    const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, itemId);
    const itemSnapshot = await getDoc(itemRef);
    
    if (!itemSnapshot.exists()) {
      console.error(`Item with ID ${itemId} does not exist.`);
      toast.error("Item does not exist.");
      return;
    }

    const itemData = itemSnapshot.data();

    // 2. Copier l'item vers la collection /archives
    const archiveRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/archives`, itemId);
    await setDoc(archiveRef, {
      ...itemData,
      categorieId: '',
      archivedAt: new Date(),
    });

    // 3. Supprimer l'item de la collection /items (utiliser la fonction handleDelete)
    await handleDelete(itemId);

    toast.success("Item successfully archived and removed from items.");
  } catch (error) {
    console.error("Error archiving item:", error);
    toast.error("Error archiving item.");
  }
};


const restoreItemFromArchive = async (itemId, setArchivedItems) => {
  try {
    // Vérifie si itemId est bien défini
    if (!itemId) {
      throw new Error('itemId is undefined or empty.');
    }

    if (!selectedRestaurantId || !mainCategoryName) {
      throw new Error('selectedRestaurantId or mainCategoryName is undefined.');
    }

    // 1. Récupérer l'item depuis la collection /archives
    const archiveRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/archives`, itemId);
    const archiveSnapshot = await getDoc(archiveRef);
    
    if (!archiveSnapshot.exists()) {
      console.error(`Archived item with ID ${itemId} does not exist.`);
      toast.error("Archived item does not exist.");
      return;
    }

    const archivedItemData = archiveSnapshot.data();

    // 2. Copier l'item vers la collection /items
    const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, itemId);
    await setDoc(itemRef, {
      ...archivedItemData,
      restoredAt: new Date(),  // Ajoute la date de restauration
    });

    // 3. Supprimer l'item de la collection /archives
    await deleteDoc(archiveRef);

    // 4. Mettre à jour localement la liste des items archivés
    setArchivedItems((prevItems) => prevItems.filter(item => item.id !== itemId));

    toast.success("Item successfully restored and removed from archives.");
  } catch (error) {
    console.error("Error restoring item:", error);
    toast.error("Error restoring item.");
  }
};



  // MODAL

  const openEditModal = (itemToEdit) => {
    setCurrentItem(itemToEdit);
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");
    document.body.classList.add("no-scroll");
    editItemModal.style.display = "flex";
    editItemModal.style.backgroundColor = "";
    modalContent.classList.remove("modal-content-exiting");
    modalContent.classList.add("modal-content-animating");
    setTimeout(() => {
      modalContent.scrollTop = 0;
    }, 0);
  };

  const closeEditModal = (forceClose = false) => {
    if (!forceClose && hasChanges) {
      const confirmClose = window.confirm(
        "Changes were made. Are you sure that you want to close without saving ?"
      );
      if (!confirmClose) {
        return;
      }
    }
    const editItemModal = document.querySelector("#edit-item-modal");
    const modalContent = editItemModal.querySelector(".modal-content");
    setTimeout(() => {
      modalContent.classList.add("modal-content-exiting");
      modalContent.addEventListener(
        "animationend",
        () => {
          editItemModal.style.display = "none";
          modalContent.classList.remove("modal-content-exiting");
          document.body.classList.remove("no-scroll");

          setCurrentItem({
            price: "",
            note: "",
            allergenes: "Ask Waiter",
            variations: "",
            type: "",
            id: "",
            photo: "",
            categorieId: "",
            tags: "",
            display: "",
            vat: "",
          });
          setHasChanges(false);
          
        },
        { once: true }
      );
    }, 50);
  };

  const addItemWithCategory = (categorieId) => {
    setCurrentItem({
      price: "",
      costPrice: "",
      ratio: "",
      allergenes: "",
      variations: "",
      type: "simple",
      id: "",
      photo: "",
      categorieId: categorieId,
      display: "menu",
      vat: "0.03",
    });
    const editItemModal = document.querySelector("#edit-item-modal");
    editItemModal.style.display = "flex";
  };

  const confirmAndDelete = (id) => {
    if (window.confirm("Are you sure that you want to delete this item?")) {
      handleDelete(id);
      closeEditModal(true);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(
        doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          id
        )
      );  
      // Mise à jour locale : suppression de l'item dans menuItems sans rafraîchir tout
      setMenuItems((prevMenuItems) =>
        prevMenuItems.filter((item) => item.id !== id)
      );
  
      toast.success("Item deleted successfully");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'item :", error);
      toast.error("Failed to delete item.");
    }
  };


  
  
  
  
  
  

  useEffect(() => {
    if (categoryOrderChanged) {
      // Mise à jour locale des catégories après changement d'ordre
      setCategories((prevCategories) => {
        const updatedCategories = [...prevCategories];
        
        // Appliquer le nouvel ordre aux catégories
        updatedCategories.sort((a, b) => a.order - b.order);
  
        // Retourner les catégories mises à jour
        return updatedCategories;
      });
  
      // Réinitialiser l'état après avoir traité le changement d'ordre
      setCategoryOrderChanged(false);
    }
  }, [categoryOrderChanged, setCategories]);
  

  useEffect(() => {
    updateUncategorizedItems(menuItems, categories);
  }, [menuItems, categories]);

  
  const deleteUncategorizedItems = async () => {
    if (window.confirm("Are you sure that you want to delete all uncategorized items?")) {
      const batch = writeBatch(db);
      const itemsToDelete = menuItems.filter(
        (item) => !item.categorieId || item.categorieId === "uncategorized"
      );
  
      itemsToDelete.forEach((item) => {
        const itemRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          item.id
        );
        batch.delete(itemRef);
      });
  
      try {
        await batch.commit();
        toast.success("Uncategorized items deleted successfully");
  
        // Mettre à jour localement l'état `menuItems` pour enlever les items supprimés
        setMenuItems((prevMenuItems) =>
          prevMenuItems.filter(
            (item) => item.categorieId && item.categorieId !== "uncategorized"
          )
        );
      } catch (error) {
        console.error("Error deleting uncategorized items: ", error);
        toast.error("Error deleting uncategorized items");
      }
    }
  };
  

  // useItemImage

  const uploadImage = async (file) => {
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `${selectedRestaurantId}/${mainCategoryName}/images` + file.name
    );
    try {
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);
      setCurrentItem({ ...currentItem, photo: url });
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      uploadImage(file);
    }
    setHasChanges(true);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setHasChanges(true);
  };

  const handleDrop = async (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      await uploadImage(file);
    }
    setHasChanges(true);
  };

  const handleImageDelete = async () => {
    if (currentItem.photo) {
      const imageRef = storageRef(storage, currentItem.photo);
      try {
        await deleteObject(imageRef); // Supprimer l'image de Firebase Storage
      } catch (error) {
        console.error("Error deleting image from Storage:", error);
      }
    }
    setCurrentItem({ ...currentItem, photo: "" });
    setHasChanges(true);
  };

  const handleImageUploadClick = () => {
    document.getElementById("image-upload").click();
  };


  const toggleAvailability = async (item) => {
    const newAvailability = !item.availability; // Inverse l'état de disponibilité
    const itemRef = doc(db,`restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,item.id);
  
    // Mise à jour de l'état de disponibilité dans Firestore
    await updateDoc(itemRef, {
      availability: newAvailability,
    });
  
    // Mettez à jour l'état local après la mise à jour
    const updatedItemsByCategory = {
      ...itemsByCategory,
      [item.categorieId]: itemsByCategory[item.categorieId].map((currentItem) =>
        currentItem.id === item.id
          ? { ...currentItem, availability: newAvailability }
          : currentItem
      ),
    };
    setItemsByCategory(updatedItemsByCategory);
  };
  

  // TAGS

   
    const fetchTags = async () => {
      if (!selectedRestaurantId || !mainCategoryName) {
        return;
      }
      const querySnapshot = await getDocs(
        query(
          collection(
            db,
            `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`
          )
        )
      );
      const tagsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTags(tagsData);
    };
  useEffect(() => {
    fetchTags();
  }, [selectedRestaurantId, mainCategoryName, setTags]);

  const saveTag = async (tag) => {
    const dbPath = `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`;
    try {
      const tagData = {
        ...tag,
        color: tag.color || '#292D36',
      };
      
      if (tag.id) {
        // Update existing tag
        await setDoc(doc(db, dbPath, tag.id), tagData, { merge: true });
      } else {
        // Add a new tag if it doesn't have an ID
        const newTagId = doc(collection(db, dbPath)).id;
        tagData.id = newTagId;
        await setDoc(doc(db, dbPath, newTagId), tagData);
      }
  
      // Refresh tags list to reflect changes
      fetchTags();
  
      // Update the color in itemsByCategory immediately for real-time feedback
      setItemsByCategory((prevItemsByCategory) => {
        const updatedItemsByCategory = { ...prevItemsByCategory };
  
        // Iterate through each category in itemsByCategory
        for (const categoryId in updatedItemsByCategory) {
          updatedItemsByCategory[categoryId] = updatedItemsByCategory[categoryId].map((item) => {
            const updatedTags = item.tags.map((itemTag) =>
              itemTag.value === tagData.id
                ? { ...itemTag, color: tagData.color } // Update tag color
                : itemTag
            );
            return { ...item, tags: updatedTags };
          });
        }
        
        return updatedItemsByCategory; // Update state to trigger re-render
      });
    } catch (error) {
      console.error(`Error saving tag:`, error);
    }
  };


const confirmDeleteTag = async (tagId, onUpdateItems) => {
  if (window.confirm("Are you sure you want to delete this tag?")) {
    try {
      const tagRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/tags`,
        tagId
      );
      await deleteDoc(tagRef);

      // Mettre à jour localement l'état `tags` pour enlever le tag supprimé
      setTags((prevTags) => prevTags.filter((t) => t.id !== tagId));
      toast.success(`Tag deleted successfully`);

      // Appeler la fonction onUpdateItems pour mettre à jour les éléments associés au tag
      if (onUpdateItems) {
        await onUpdateItems(tagId); // Assurer que cette fonction est asynchrone si nécessaire
      }
    } catch (error) {
      console.error("Error deleting tag:", error);
      toast.error("Error deleting tag");
    }
  }
};


// Function to update items when a tag is deleted
const onUpdateItems = async (tagIdToRemove) => {
  const batch = writeBatch(db);
  const updatedItemsByCategory = { ...itemsByCategory };

  // Update Firestore and local state simultaneously
  for (const categoryId in updatedItemsByCategory) {
    updatedItemsByCategory[categoryId] = updatedItemsByCategory[categoryId].map((item) => {
      if (item.tags && item.tags.some((tag) => tag.value === tagIdToRemove)) {
        const newTags = item.tags.filter((tag) => tag.value !== tagIdToRemove);

        console.log("Updating item:", item.id, "; New tags:", newTags);

        // Prepare Firestore batch update for this item
        const itemRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
          item.id
        );
        batch.update(itemRef, { tags: newTags });

        // Update the item locally
        return { ...item, tags: newTags };
      }
      return item;
    });
  }

  try {
    await batch.commit();
    console.log("Items updated successfully after tag deletion.");
    
    // Update the state with modified itemsByCategory to reflect the change immediately
    setItemsByCategory(updatedItemsByCategory);
  } catch (error) {
    console.error("Error updating items:", error);
  }
};


  return {
    mainCategoryName,
    selectedRestaurantId,
    fetchData,
    menuItems,
    setMenuItems,
    currentItem,
    setCurrentItem,
    handleSubmit,
    duplicateItem,
    updateDoc,
    addDoc,
    deleteField,
    handleOrderChange,
    handleDelete,
    handleCategoryUpdate,
    deleteSubCategory,
    itemsByCategory,
    setItemsByCategory,
    confirmAndDelete,
    openEditModal,
    addItemWithCategory,
    closeEditModal,
    handleImageDelete,
    handleImageUpload,
    handleImageUploadClick,
    handleDrop,
    handleDragOver,
    hasChanges,
    setHasChanges,
    uncategorizedItems,
    deleteUncategorizedItems,
    updateUncategorizedItems,
    onUpdateItems,
    saveTag,
    confirmDeleteTag,
    isLoading,
    setIsLoading,
    toggleAvailability,
    saving,
    setSaving,
    archiveItem,
    restoreItemFromArchive
  };
};

export default useMenuData;
