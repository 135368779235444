import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { db } from "../../firebaseConfig";
import { doc, updateDoc, writeBatch } from "firebase/firestore";
import useStrictModeDroppable from "../../hooks/useStrictModeDroppable"; // Adjust the import path as necessary
import { useAppContext } from "../../contexts/AppContext";
import {
  Flex,
  Button,
  Text,
  InputGroup,
  Input,
  InputRightElement,
  InputLeftElement,
} from "@chakra-ui/react";
import { Search, X } from "lucide-react";
import ActionButtons from "./ActionButtons";
import { Tooltip } from "react-tooltip";
import MenuItem from "./MenuItem";
import { useMenuContext } from "../../contexts/MenuContext";
import SkeletonLayout from "./SkeletonLayout";
import useMenuData from "../../hooks/useMenuData";
import useImportExport from "../../hooks/useImportExport";
import EmptyList from "./EmptyList";

const StrictModeDroppableComponent = ({ droppableId, children }) => {
  const { enabled } = useStrictModeDroppable();

  if (!enabled) {
    return null;
  }

  return (
    <Droppable droppableId={droppableId} key={droppableId}>
      {(provided, snapshot) => children(provided, snapshot)}
    </Droppable>
  );
};

const MenuList = ({
  categories = [],
  mainCategoryName,
  itemsByCategory = {},
  setItemsByCategory,
  onCategoryDelete,
  addItemWithCategory,
  openEditModal,
  currentMainCategory,
  openSubCategoryModal,
  onDuplicate,
  toggleAvailability,
  scrollPosition,
  onArchive,
  handleOpenMainCategoryModal,
  mainCategoryHasChanges,
  handleDeleteMainCategory,
}) => {
  const {
    selectedRestaurantId,
    mainLanguage,
    userRole,
    settings,
  } = useAppContext();

  const { getDisplayStyles } = useMenuContext();
  const { isLoading } = useMenuData();
  const { openImportModal  } = useImportExport();



  const [showOverlay, setShowOverlay] = useState(false);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [maxWidth, setMaxWidth] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredItemsByCategory = Object.keys(itemsByCategory).reduce(
    (acc, categoryId) => {
      const filteredItems = itemsByCategory[categoryId].filter((item) => {
        const itemName = item[`name_${mainLanguage}`]; 
        return itemName && itemName.toLowerCase().includes(searchTerm.toLowerCase());
      });
      acc[categoryId] = filteredItems;
      return acc;
    },
    {}
  );
  

  const updateMinWidth = () => {
    const dishPriceElements = document.querySelectorAll(".dish-price");
    let maxFoundWidth = 0;

    dishPriceElements.forEach((element) => {
      const width = element.offsetWidth;
      if (width > maxFoundWidth) {
        maxFoundWidth = width;
      }
    });

    setMaxWidth(maxFoundWidth);
  };

  useEffect(() => {
    const observer = new MutationObserver(updateMinWidth);
    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleOnDragEnd = async (result) => {
    const { source, destination } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }
    const sourceItems = [...itemsByCategory[source.droppableId]];
    const destinationItems =
      source.droppableId === destination.droppableId
        ? sourceItems
        : [...itemsByCategory[destination.droppableId]];

    const [removed] = sourceItems.splice(source.index, 1);
    destinationItems.splice(destination.index, 0, removed);

    const updatedRemovedItem = {
      ...removed,
      categorieId: destination.droppableId,
    };

    const newItemsByCategory = {
      ...itemsByCategory,
      [source.droppableId]: sourceItems,
      [destination.droppableId]: destinationItems.map((item) =>
        item.id === updatedRemovedItem.id ? updatedRemovedItem : item
      ),
    };
    setItemsByCategory(newItemsByCategory);
    await updateItemCategoryAndOrderInFirestore(
      updatedRemovedItem,
      destination.droppableId,
      destinationItems
    );
  };

  const updateItemsOrderInFirestore = async (items, categoryId) => {
    const batch = writeBatch(db);
    items.forEach((item, index) => {
      const itemRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
        item.id
      );
      batch.update(itemRef, { order: index });
    });
    await batch.commit();
  };

  const updateItemCategoryAndOrderInFirestore = async (
    item,
    newCategoryId,
    newCategoryItems
  ) => {
    const itemRef = doc(
      db,
      `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`,
      item.id
    );
    await updateDoc(itemRef, {
      categorieId: newCategoryId,
      order: newCategoryItems.indexOf(item),
    });
    await updateItemsOrderInFirestore(newCategoryItems, newCategoryId);
  };

  const confirmCategoryDeletion = (categoryId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this category?"
    );
    if (isConfirmed) {
      onCategoryDelete(categoryId);
    }
  };

  

  const secondaryLanguages = settings.secondaryLanguages.map(
    (lang) => lang.value
  );
  const areTranslationsMissing = (item, settings) => {
    return secondaryLanguages.some((lang) => {
      return (
        (item[`name_${mainLanguage}`] && !item[`name_${lang}`]) ||
        (item[`description_${mainLanguage}`] && !item[`description_${lang}`]) ||
        (item[`note_${mainLanguage}`] && !item[`note_${lang}`])
      );
    });
  };

  
  const closeSubMenu = () => {
    setOpenSubMenu(null); // Ferme le sous-menu
    setShowOverlay(false); // Masque l'overlay
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openSubMenu && !event.target.closest(".submenu")) {
        closeSubMenu(); // Ferme le sous-menu
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [openSubMenu]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (openSubMenu && !event.target.closest(".subMenuToggle")) {
        setOpenSubMenu(null); // Ferme le sous-menu
        setShowOverlay(false); // Masque l'overlay
      }
    };

    const handleScroll = () => {
      if (openSubMenu && Math.abs(window.scrollY - scrollPosition) > 200) {
        setOpenSubMenu(null); // Ferme le sous-menu après avoir défilé de 100px
        setShowOverlay(false); // Masque l'overlay
      }
    };

    document.addEventListener("click", handleClickOutside);
    document.addEventListener("scroll", handleScroll);

    // Nettoyage des événements lors du démontage du composant
    return () => {
      document.removeEventListener("click", handleClickOutside);
      document.removeEventListener("scroll", handleScroll);
    };
  }, [openSubMenu]); // Relance l'effet uniquement lorsque `openSubMenu` change

  if (isLoading || (categories.length === 0 && !itemsByCategory)) {
    return <SkeletonLayout />;
  }

  if (!isLoading && (!categories || categories.length === 0)) {
    return (
      <EmptyList
        title="Your menu is empty."
        text="Start by adding a category."
        primaryButton={true}
        primaryButtonText="Add category"
        secondaryButton={true}
        secondaryButtonText="Import CSV"
        onPrimaryButtonClick={() => openSubCategoryModal()}
        onSecondaryButtonClick={() => openImportModal()}
      />
    );
  }
  

  return (
    <>
    
    
    <Flex direction="column" flexGrow="1" minW="0" gap={4}>
      <Flex gap={2} mb={4}>
        <Flex flex={1} gap={2}>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search color="var(--grey500)" width="16px" />
            </InputLeftElement>
            <Input
              type="text"
              pl="36px !important"
              placeholder="Search by name"
              value={searchTerm}
              disabled={categories.length === 0}
              onChange={(e) => setSearchTerm(e.target.value)}
              flex={1}
            />
            {searchTerm && (
              <InputRightElement onClick={(e) => setSearchTerm("")}>
                <Button
                  size={"xs"}
                  variant={"outline"}
                  width="22.5px"
                  data-tooltip-id="clearSearch"
                  data-tooltip-content="Clear search"
                  data-tooltip-place="bottom"
                >
                  <X size={16} />
                </Button>
                <Tooltip id="clearSearch" />
              </InputRightElement>
            )}
          </InputGroup>
          <ActionButtons
            openSubCategoryModal={openSubCategoryModal}
            handleOpenMainCategoryModal={handleOpenMainCategoryModal}
            handleDeleteMainCategory={handleDeleteMainCategory}
            mainCategoryName={mainCategoryName}
            mainCategoryHasChanges={mainCategoryHasChanges}
            categories={categories}
            itemsByCategory={itemsByCategory}
            settings={settings}
            mainLanguage={mainLanguage}
            selectedRestaurantId={selectedRestaurantId}
            currentMainCategory={currentMainCategory}
            addItemWithCategory={addItemWithCategory}
        />
        </Flex>
      </Flex>



   
      
      
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {showOverlay && <div className="overlay" onClick={closeSubMenu}></div>}
        <Flex direction="column" className="menuItems" gap={8}>
          {categories
            .filter((cat) => cat.id !== "uncategorized")
            .map((category) => {
              // Vérification si la catégorie a des items après filtrage
              const filteredItems = filteredItemsByCategory[category.id];
              // Si une recherche est en cours, masquer les catégories vides
              if (
                searchTerm &&
                (!filteredItems || filteredItems.length === 0)
              ) {
                return false;
              }

              return (
                <StrictModeDroppableComponent
                  key={category.id}
                  droppableId={category.id}
                >
                  {(provided) => (
                    <Flex
                      direction="column"
                      gap="var(--padding-xs)"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className="menu-category"
                      borderBottom="1px solid var(--borders)"
                      pb={8}
                      sx={{
                        "&:last-of-type": {
                          borderBottom: "none",
                          pb: 0,
                        },
                      }}
                      id={category.id}
                    >
                      <Flex direction={"column"} gap={1}>
                        <Flex
                          direction="row"
                          className="category-title"
                          justifyContent="space-between"
                        >
                          <h5>{category[`name_${mainLanguage}`]}</h5>
                          <Flex direction="row" gap={2}>
                            <Button
                              size="sm"
                              variant={"outline"}
                              onClick={() => openSubCategoryModal(category)}
                            >
                              Edit
                            </Button>
                          </Flex>
                        </Flex>

                        {category[`description_${mainLanguage}`] && (
                          <Text fontSize="17px">
                            {category[`description_${mainLanguage}`]}
                          </Text>
                        )}
                      </Flex>
                      <Flex className="dishes-grid" direction="column">
                        {filteredItemsByCategory[category.id] &&
                        filteredItemsByCategory[category.id].length > 0 ? (
                          filteredItemsByCategory[category.id].map(
                            (item, idx) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={idx}
                              >
                                {(provided) => {
                                  const missingTranslations =
                                    areTranslationsMissing(item);
                                  return (
                                    
                                    <MenuItem
                                      item={item}
                                      provided={provided}
                                      missingTranslations={missingTranslations}
                                      category={category}
                                      currentMainCategory={currentMainCategory}
                                      maxWidth={maxWidth}
                                      userRole={userRole}
                                      openEditModal={openEditModal}
                                      getDisplayStyles={getDisplayStyles}
                                      onDuplicate={onDuplicate}
                                      toggleAvailability={toggleAvailability}
                                      onArchive={onArchive}
                                    />

                                  );
                                }}
                              </Draggable>
                            )
                          )
                        ) : (
                          <Flex
                            direction="column"
                            justifyContent={"center"}
                            p={8}
                            textAlign={"center"}
                            alignItems={"center"}
                            gap={4}
                          >
                            <Text>This category is empty.</Text>
                            <Flex direction={"row"} gap={4} alignItems={"center"}>
                            <Button
                                
                                onClick={() => addItemWithCategory(category.id)}
                              >
                                Add an item
                              </Button>
                              or
                              <Button
                               variant={"outline"}
                               onClick={() =>
                                  confirmCategoryDeletion(category)
                                }
                              >
                                Delete category?
                              </Button>
                                
                             
                            </Flex>
                          </Flex>
                        )}
                      </Flex>
                      
                      
                      {provided.placeholder}
                      
                      {itemsByCategory[category.id] &&
                        itemsByCategory[category.id].length > 0 && (
                          <Button
                            size="sm"
                            variant={"outline"}
                            onClick={() => addItemWithCategory(category.id)}
                            width="fit-content">
                            Add item
                          </Button>
                      )}

                    </Flex>
                  )}
                </StrictModeDroppableComponent>
              );
            })}
        </Flex>
      </DragDropContext>
    </Flex>
      
   
   </>
  );
};

export default MenuList;
