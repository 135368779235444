
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button, Link } from "@chakra-ui/react";
import { useMenuContext } from "../../contexts/MenuContext";
import "react-horizontal-scrolling-menu/dist/styles.css";
import { NavLink } from "react-router-dom";
import { ExternalLink } from "lucide-react";
const MenuHeader = () => {
  
  const { selectedRestaurantId, mainCategoryName } = useAppContext();

  const {
    currentMainCategory 
  } = useMenuContext();




  return (
    <>
      


      {mainCategoryName && currentMainCategory && (
        <Flex
          justifyContent="space-between"
          direction="row"
          w={"100%"}
          alignItems="center"
    
          bg={{ desktop: "white", mobile: "var(--grey100)" }}
          px={{ desktop: 8, mobile: 4 }}
          maxWidth="100vw"
          borderBottom="1px solid var(--borders)">
          
          <Flex className="tabLinks">
            <NavLink className={"tabLink"} to={`/${selectedRestaurantId}/`} end>Menus</NavLink>
            <NavLink className={"tabLink"} to={`/${selectedRestaurantId}/menus/archives`}>Archives</NavLink>
          </Flex>          

          <Button variant="link" as={Link} href={`https://gudden.app/${selectedRestaurantId}/menu`} 
          target="_blank" gap={1} size={"sm"} _hover={{ color: "var(--grey700)" }} fontWeight={500}>
          <ExternalLink size={16} color="var(--grey500)"/>
          View menu
        </Button>

        </Flex>
      )}
      

     
      
    </>
  );
};

export default MenuHeader;
