import Select from 'react-select';

const RestaurantDropdown = ({ options, selectedValues, onAdd, onRemove }) => {
   
    const handleRestaurantChange = (selectedOptions) => {
        // Filter out options that were removed
        const removedOptions = selectedValues.filter(value => !selectedOptions.some(option => option.value === value));
        removedOptions.forEach(value => onRemove(value));

        // Filter out options that were added
        const addedOptions = selectedOptions.filter(option => !selectedValues.includes(option.value));
        addedOptions.forEach(option => onAdd(option.value));
    };

    return (
        <Select
            isMulti
            onChange={handleRestaurantChange}
            options={options.map(option => ({ value: option.id, label: option.name }))}
            value={selectedValues
                .map(value => {
                    const restaurant = options.find(option => option.id === value);
                    if (restaurant) {
                        return { value: restaurant.id, label: restaurant.name };
                    }
                    return null;  // Return null if not found
                })
                .filter(Boolean) // Remove null values
            }
        />
    );
};

export default RestaurantDropdown;
