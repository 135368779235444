import React, { memo } from "react";

import {
    Flex,
    Select,
    Switch,
    Text,
    TabPanel,
  } from "@chakra-ui/react";


const Display = memo (({
    selectedLanguage,
    setSelectedLanguage,
    allLanguages,
    setHasChanges,
    showDescriptions,
    setShowDescriptions,
    showAllergens,
    setShowAllergens,
    showItemSeparator,
    setShowItemSeparator,
    showTags,
    setShowTags
}) => {

    return (

    <TabPanel>
                  <Flex className="modalInner" direction={"column"} gap={4}>
                    <Flex
                      direction={{ desktop: "row", mobile: "column" }}
                      gap={4}
                      borderBottom={"1px solid var(--borders)"}
                      pb={4}>
                      <Flex
                        direction={"column"}
                        w={{ desktop: "50%", mobile: "100%" }}>
                        <Text fontWeight={600} color={"var(--heading)"}>
                          Language
                        </Text>
                        <Text maxW={"85%"} fontSize={14}>
                          Select the language of the menu.
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={2}
                        justifyContent={"flex-start"}>
                        <Select
                          value={selectedLanguage}
                          onChange={(e) => {
                            setSelectedLanguage(e.target.value);
                            setHasChanges(true);
                          }}>
                          {allLanguages.map((lang) => (
                            <option key={lang.value} value={lang.value}>
                              {lang.label}
                            </option>
                          ))}
                        </Select>
                      </Flex>
                    </Flex>

                    <Flex
                      direction={{ desktop: "row", mobile: "column" }}
                      gap={4}
                      borderBottom={"1px solid var(--borders)"}
                      pb={4}>
                      <Flex
                        direction={"column"}
                        w={{ desktop: "50%", mobile: "100%" }}>
                        <Text fontWeight={600} color={"var(--heading)"}>
                          Display descriptions
                        </Text>
                        <Text maxW={"85%"} fontSize={14}>
                          Activate if you want to display descriptions.
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={2}
                        flex={1}
                        justifyContent={"flex-start"}>
                        <Switch
                          id="descriptions-switch"
                          isChecked={showDescriptions}
                          onChange={(e) => {
                            setShowDescriptions(e.target.checked);
                            setHasChanges(true); // Indicate changes
                          }}
                        />
                      </Flex>
                    </Flex>

                    

                    <Flex
                      direction={{ desktop: "row", mobile: "column" }}
                      gap={4}
                      borderBottom={"1px solid var(--borders)"}
                      pb={4}>
                      <Flex
                        direction={"column"}
                        w={{ desktop: "50%", mobile: "100%" }}>
                        <Text fontWeight={600} color={"var(--heading)"}>
                          Display allergens
                        </Text>
                        <Text maxW={"85%"} fontSize={14}>
                          Activate if you want to display allergens.
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={2}
                        flex={1}
                        justifyContent={"flex-start"}>
                        <Switch
                          id="allergenes-switch"
                          isChecked={showAllergens}
                          onChange={(e) => {
                            setShowAllergens(e.target.checked);
                            setHasChanges(true);
                          }}
                        />
                      </Flex>
                    </Flex>


                    <Flex
                      direction={{ desktop: "row", mobile: "column" }}
                      gap={4}
                      borderBottom={"1px solid var(--borders)"}
                      pb={4}
                     >
                      <Flex
                        direction={"column"}
                        w={{ desktop: "50%", mobile: "100%" }}>
                        <Text fontWeight={600} color={"var(--heading)"}>
                          Display tags
                        </Text>
                        <Text maxW={"85%"} fontSize={14}>
                          Activate if you want to display tags.
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={2}
                        flex={1}
                        justifyContent={"flex-start"}>
                        <Switch
                          id="tags-switch"
                          isChecked={showTags}
                          onChange={(e) => {
                            setShowTags(e.target.checked);
                            setHasChanges(true);
                          }}
                        />
                      </Flex>
                    </Flex>
                    
                    <Flex
                      direction={{ desktop: "row", mobile: "column" }}
                      gap={4}
                     >
                      <Flex
                        direction={"column"}
                        w={{ desktop: "50%", mobile: "100%" }}>
                        <Text fontWeight={600} color={"var(--heading)"}>
                          Display item separator
                        </Text>
                        <Text maxW={"85%"} fontSize={14}>
                          Activate if you want to display a line between items.
                        </Text>
                      </Flex>
                      <Flex
                        direction="column"
                        gap={2}
                        flex={1}
                        justifyContent={"flex-start"}>
                        <Switch
                          id="separator-switch"
                          isChecked={showItemSeparator}
                          onChange={(e) => {
                            setShowItemSeparator(e.target.checked);
                            setHasChanges(true);
                          }}
                        />
                      </Flex>
                    </Flex>


                    


                  </Flex>
                </TabPanel>
                
    );
});

export default Display;
