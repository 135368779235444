import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Flex, useDisclosure, Spinner } from '@chakra-ui/react';
import CancellationModal from '../navigation/CancellationModal';
import useOrders from '../../hooks/useOrders';
import useUsers from '../../hooks/useUsers'; 
import { useAppContext } from '../../contexts/AppContext';
import { X, Clock, Check, CheckCheck, Car } from 'lucide-react';
import DelivererModal from './DelivererModal';
import OrderCompletedDialog from './OrderCompletedDialog';

const StatusSelector = ({ orderId, currentStatus, onUpdateStatus, deliveryMode, delivererId, paymentStatus }) => {
    
    const { selectedRestaurantId } = useAppContext();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
    const [selectedValue, setSelectedValue] = useState(null);
    const { cancellationNote, setCancellationNote, localOrder, setLocalOrder } = useOrders();
    const { getDeliverers, deliverers, setDeliverers, selectedDeliverer, setSelectedDeliverer } = useUsers(selectedRestaurantId);
    
    const [isUpdating, setIsUpdating] = useState(false); // Nouvel état pour gérer l'affichage du Spinner
    
    const [isDelivererModalOpen, setIsDelivererModalOpen] = useState(false);
   
    const [previousStatus, setPreviousStatus] = useState(null); // Pour garder en mémoire l'ancien statut
    
    const [preselectedDeliverer, setPreselectedDeliverer] = useState(null); // Nouvel état pour garder le deliverer pré-sélectionné
    const [pendingStatusChange, setPendingStatusChange] = useState(null);  // État pour stocker le statut en attente de confirmation

    const allOptions = [
        { value: 'cancelled', label: 'Cancelled', color: 'var(--error700)', bgColor: 'var(--error50)', icon: X },
        { value: 'pending', label: 'Pending', color: 'var(--warning)', bgColor: 'var(--warning50)', icon: Clock },
        { value: 'confirmed', label: 'Confirmed', color: 'var(--blue600)', bgColor: 'var(--blue50)', icon: Check },
        { value: 'sent', label: 'Sent', color: 'var(--purple600)', bgColor: 'var(--purple50)', icon: Car },
        { value: 'completed', label: `${deliveryMode === "pickup" ? "Collected": 'Delivered'}`, color: 'var(--success700)', bgColor: 'var(--success50)', icon: CheckCheck },
    ];

    const options = deliveryMode === 'pickup' 
        ? allOptions.filter(option => option.value !== 'sent') 
        : allOptions;

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
            cursor: state.isDisabled ? 'not-allowed' : 'pointer', // Désactiver le curseur si l'option est désactivée
        }),
        singleValue: (provided) => ({
            ...provided,
            display: 'flex',
            alignItems: 'center',
        }),
    };

    const formatOptionLabel = ({ label, color, bgColor, icon: Icon }) => (
        <Flex className='tag' direction="row" alignItems="center" bg={bgColor} color={color} fontSize={13} fontWeight="600" textTransform="initial" whiteSpace="nowrap" gap={1}>
            <Icon color={color} size={16} />
            {label}
        </Flex>
    );

    const handleChange = async (selectedOption) => {
        if (selectedOption.value === currentStatus || isUpdating) {
            return;
        }
    
        if (selectedOption.value === 'cancelled') {
            // Ouvre la modal d'annulation
            setPreviousStatus(currentStatus);
            onOpen(); // Ouvre la modale d'annulation
            return;
        }
    
        if (selectedOption.value === 'completed' && paymentStatus === 'unpaid') {
            // Ouvre la confirmation pour les commandes impayées
            setPendingStatusChange(selectedOption.value);
            onAlertOpen(); // Ouvre la modale d'alerte pour "completed"
            return;
        }
    
        // Autres statuts
        setIsUpdating(true);
        try {
            await onUpdateStatus(orderId, selectedOption.value); // Mets à jour le statut
            setSelectedValue(selectedOption); // Mets à jour l'option sélectionnée
            setLocalOrder((prevOrder) => ({
                ...prevOrder,
                status: selectedOption.value, // Mets à jour l'état local du statut de commande
            }));
        } catch (error) {
            console.error('Error updating status:', error);
        }
        setIsUpdating(false);
    };
    
    
    const handleConfirmAlert = async () => {
        setIsUpdating(true);
        await onUpdateStatus(orderId, pendingStatusChange);  // Mettre à jour avec le statut "completed"
        setSelectedValue(options.find(option => option.value === pendingStatusChange)); 
        setPendingStatusChange(null);  // Réinitialiser
        setIsUpdating(false);
        onAlertClose();  // Fermer l'alerte
    };

    const handleCancelAlert = () => {
        setPendingStatusChange(null);  // Réinitialiser le statut en attente
        onAlertClose();  // Fermer l'alerte
    };
    


     // Fonction pour éviter la propagation de l'événement
     const stopPropagation = (event) => {
        event.stopPropagation();
    };

    // Fonction pour confirmer l'annulation
    const handleConfirmCancellation = async () => {
        setIsUpdating(true); // Affiche le Spinner pendant la mise à jour
        try {
            await onUpdateStatus(orderId, 'cancelled', paymentStatus, cancellationNote); // Passer le paymentStatus
            setLocalOrder((prevOrder) => ({
                ...prevOrder,
                status: 'cancelled',  // Mettre à jour le statut à "cancelled"
                paymentStatus: paymentStatus || 'unpaid',  // Conserver le statut de paiement
                cancellationNote: cancellationNote, // Mettre à jour la note d'annulation immédiatement
            }));
            setCancellationNote(''); // Réinitialiser la note d'annulation
            onClose(); // Fermer la modale
        } catch (error) {
            console.error('Error cancelling order:', error);
        }
        setIsUpdating(false); // Cacher le Spinner après la mise à jour
    };
    
    
    

    // Fonction pour confirmer le livreur
    const handleConfirmDeliverer = async () => {
        if (selectedDeliverer) {
            setIsUpdating(true); // Affiche le Spinner pendant la mise à jour
            try {
                await onUpdateStatus(orderId, 'sent', null, null, selectedDeliverer.value, selectedDeliverer.label);
                setLocalOrder((prevOrder) => ({
                    ...prevOrder,
                    status: 'sent',  // Mets le statut à "sent"
                    delivererId: selectedDeliverer.value,  // Mets à jour le livreur sélectionné
                }));
                setIsDelivererModalOpen(false); // Ferme la modale après sélection
            } catch (error) {
                console.error('Error sending order with deliverer:', error);
            }
            setIsUpdating(false); // Cache le Spinner après la mise à jour
        }
    };
    

    // Annuler le changement de statut si le livreur n'est pas confirmé
    const handleCancelDelivererSelection = () => {
        setSelectedValue(options.find(option => option.value === previousStatus)); // Remettre l'ancien statut
        setIsDelivererModalOpen(false); // Fermer le modal sans confirmer
    };

    // Préremplir le livreur dans le select si delivererId existe dans Firebase
    useEffect(() => {
        const fetchDeliverersAndPreselect = async () => {
            if (selectedRestaurantId) {
                try {
                    const deliverers = await getDeliverers(selectedRestaurantId);
                    setDeliverers(deliverers);

                    // Pré-sélectionner le livreur correspondant à delivererId s'il existe
                    if (delivererId && deliverers.length > 0) {
                        const matchedDeliverer = deliverers.find(deliverer => deliverer.id === delivererId);
                        if (matchedDeliverer) {
                            const preselected = {
                                value: matchedDeliverer.id,
                                label: `${matchedDeliverer.name} ${matchedDeliverer.lastName}`,
                            };
                            setPreselectedDeliverer(preselected); // Stocker le deliverer pré-sélectionné
                            setSelectedDeliverer(preselected); // Aussi le définir comme deliverer sélectionné
                        }
                    }
                } catch (error) {
                    console.error("Error fetching deliverers:", error);
                }
            }
        };

        if (isDelivererModalOpen) {
            fetchDeliverersAndPreselect(); // Charger les deliverers et pré-sélectionner si le modal s'ouvre
        }
    }, [delivererId, selectedRestaurantId, getDeliverers, isDelivererModalOpen]);

    useEffect(() => {
        const selectedOption = options.find(option => option.value === currentStatus);
        if (selectedOption && selectedOption.value !== selectedValue?.value) {
            setSelectedValue(selectedOption);
        }
    }, [currentStatus, options, selectedValue]);

    return (
        <>
            {isUpdating ? ( // Afficher un spinner si la mise à jour est en cours
                <Spinner size="sm" />
            ) : (
                <div onClick={stopPropagation}> 
                  <Select
                      value={selectedValue}
                      onChange={handleChange}
                      options={options}
                      styles={customStyles}
                      formatOptionLabel={formatOptionLabel}
                      className="statusSelectorContainer"
                      classNamePrefix="statusSelector"
                      isSearchable={false}
                      isOptionDisabled={(option) => option.value === currentStatus} // Désactiver l'option si elle est égale au statut actuel
                  />
                </div>
            )}
            
            <CancellationModal 
                isOpen={isOpen} 
                onClose={onClose}
                cancellationNote={cancellationNote}
                setCancellationNote={setCancellationNote}
                handleConfirmCancellation={handleConfirmCancellation}
            />

            <OrderCompletedDialog
                isOpen={isAlertOpen}
                onClose={handleCancelAlert}
                onConfirm={handleConfirmAlert}
            />

            

            
            <DelivererModal 
                onClose={handleCancelDelivererSelection}
                isOpen={isDelivererModalOpen}
                deliverers={deliverers}
                selectedDeliverer={selectedDeliverer}
                setSelectedDeliverer={setSelectedDeliverer}
                preselectedDeliverer={preselectedDeliverer}
                handleCancelDelivererSelection={handleCancelDelivererSelection}
                handleConfirmDeliverer={handleConfirmDeliverer}
            />

            
        </>
    );
};

export default StatusSelector;
