import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 40,
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 20,
    marginBottom: 20,
  },
  headerColumn: {
    width: '50%',
  },
  headerColumnRight: {
    width: '50%',
  },
  h4: {
    fontSize: 10,
    textTransform: 'capitalize',
  },
  h5: {
    fontSize: 10,
    color: "#667085",
    marginBottom: 5,
  },
  text: {
    marginTop: 2,
    marginBottom: 2,
    fontSize: 10,
  },
  smallText: {
    textTransform: 'uppercase',
    fontSize: 8,
    marginRight: 1,
},
  table: {
    display: "table",
    width: "auto",
    borderWidth: 0,
    marginTop: 20,
  },
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  },
  thead: {
    borderBottomWidth: 1,
    paddingBottom: 4,
  },
  tfoot: {
    borderTopWidth: 1,
    paddingTop: 2,
    marginTop: 4,
  },
  tableFirstColumn: { 
    width: "40%", 
    borderWidth: 0,
    textAlign: "left",
  },
  tableColumn: { 
    width: "15%", 
    borderWidth: 0,
    textAlign: "left",
  },
  tableCell: { 
    textAlign: "left",
    marginTop: 5, 
    fontSize: 10 
  },
  tableCellRight: { 
    textAlign: "right", // Alignement à droite pour le texte dans la cellule
    marginTop: 5, 
    fontSize: 10 
  },
  strong : {
    fontWeight: "bold",
  },
  footer: {
    position: 'absolute',
    bottom: 25,
    left: 0,
    right: 0,
    textAlign: 'center',
    fontSize: 12,
},
  footerText: {
    color: '#667085',
    fontSize: 8,
    marginBottom: 3,
}
});



const OrderInvoice = ({ order }) => {


    const VAT_RATE = order.vatRate * 100 || 17 ;
    const cartItemsDetails = order.cartItems.map(item => {
    const vatRate = parseFloat(item.vat);
    const price = parseFloat(item.price.replace(",", "."));
    const quantity = item.quantity;
    const priceWithoutVAT = (price / (1 + vatRate)) * quantity;
    const priceTTC = price * quantity;
    const vatAmount = (priceTTC - priceWithoutVAT);

    return {
      ...item,
      priceWithoutVAT: priceWithoutVAT.toFixed(2),
      priceTTC: priceTTC.toFixed(2),
      vatAmount: vatAmount.toFixed(2), // Ajout du montant de la TVA arrondi à 2 décimales
    };
  });

    const totalTTC = cartItemsDetails.reduce((acc, item) => acc + parseFloat(item.priceTTC), 0);

    const grandTotalTTC = totalTTC + order.serviceFeeTTC - order.discount + order.tip + order.deliveryFee;

    const Footer = () => (
      <View style={styles.footer}>
          <Text style={styles.footerText}>Facture établie au nom et pour le compte de {order.restaurantDetails.company} par:</Text>
          <Text style={styles.footerText}>Good Robot Sarls / 37, Rue de la Libération L-5969 Itzig, Luxembourg </Text>
          <Text style={styles.footerText}>TVA: LU33341117 / RCS : B258963</Text>
      </View>
    );
    
    const formatDateTime = (timestamp) => {
      
      // Si l'objet est déjà un Date, formatez-le directement
      if (timestamp instanceof Date) {
        return timestamp.toLocaleDateString('fr-FR', {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timeZone: 'Europe/Paris',
        });
      }
    
    
      // Si le timestamp n'est pas reconnu, renvoyez une chaîne par défaut
      return 'Invalid date';
    };
    
    

    const formatAmount = (amount) => amount.toFixed(2).replace('.', ',');




    return (

        <Document>
    <Page size="A4" style={styles.body}>
    <View style={styles.headerRow}>
    <View style={styles.headerColumn}>
      <Text style={styles.h5}>Facturé à:</Text>
      <Text style={styles.text}>{order.customerDetails.firstName} {order.customerDetails.lastName}</Text>
      <Text style={styles.text}>{order.customerDetails.email}</Text>
      <Text style={styles.text}>{order.customerDetails.phone}</Text>
    </View>
    <View style={[styles.headerColumn, styles.headerColumnRight]}>
    <Text style={styles.h5}>Facture émise par Good Robot Sarls au nom de :</Text>
    <Text style={styles.h4}>{order.restaurantId}</Text>
    <Text style={styles.text}>{order.restaurantDetails.company}</Text>
    <Text style={styles.text}>{order.restaurantDetails.address}</Text>
    <Text style={styles.text}><Text style={styles.smallText}>TVA</Text> {order.restaurantDetails.vat}</Text>
    </View>
    </View>
    <View>
        <Text style={styles.text}>Numéro de facture : INV-{order.id}</Text>
        <Text style={styles.text}>Date de facturation : {formatDateTime(order.createdAt)}</Text>
        <Text style={styles.text}>Date de la transaction : {formatDateTime(order.createdAt)}</Text>
    </View>
      <View style={styles.table}>
        <View style={[styles.tableRow, styles.thead]}>
          <View style={styles.tableFirstColumn}><Text style={[styles.tableCell, styles.strong]}>Article</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Qté</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Taux TVA</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Tarif HTVA</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Prix TTC</Text></View>
        </View>

        {cartItemsDetails.map((item, index) => (
                           <View style={styles.tableRow} key={index}>
                            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>{item.name_en}</Text></View>
                            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{item.quantity}</Text></View>
                            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{(parseFloat(item.vat) * 100).toFixed(0)}%</Text></View>
                            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{item.priceWithoutVAT.replace(".", ",")}€</Text></View>
                            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{item.priceTTC.replace(".", ",")}€</Text></View>
                            </View>
        ))}
       
       {order.deliveryFee > 0 && (
            <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Frais de livraison</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{order.deliveryFee.toFixed(2).replace("." , ",")}€</Text></View>
        </View>
        )}
        
        {order.tip > 0 && (
            <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Pourboire</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{order.tip.toFixed(2).replace("." , ",")}€</Text></View>
        </View>
        )}

        {order.serviceFee > 0 && (
        
        <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Frais de service</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>1</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{VAT_RATE}%</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{order.serviceFee.toFixed(2).replace("." , ",")}€</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{order.serviceFeeTTC.toFixed(2).replace("." , ",")}€</Text></View>
        </View>
        
        )}


        {order.discount > 0 && (
            <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Réduction</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>-{order.discount.toFixed(2).replace("." , ",")}€</Text></View>
        </View>
        )}
        
    
        <View style={[styles.tableRow, styles.tfoot]}>
            <View style={styles.tableFirstColumn}></View>
            <View style={styles.tableColumn}></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}></Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{formatAmount(grandTotalTTC)}€</Text></View>
        </View>
      </View>
      <Footer/>
            </Page>
  </Document>
);
};

export default OrderInvoice;