import { useState } from 'react';
import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Textarea, Select } from '@chakra-ui/react';
import { useAppContext } from '../../contexts/AppContext';

const CancellationModal = ({ isOpen, onClose, cancellationNote, setCancellationNote, handleConfirmCancellation }) => {
  
    const { settings } = useAppContext(); // Utilisez le contexte pour récupérer les messages d'annulation
    const [selectedMessage, setSelectedMessage] = useState(''); // État pour gérer le message sélectionné

  const handleSelectMessage = (e) => {
    const selectedMessageIndex = e.target.value;
    const selectedMessage = settings.shopSettings?.cancellationMessages?.[selectedMessageIndex]?.message || '';
    
    // Mettez à jour le Textarea avec le message sélectionné
    setSelectedMessage(selectedMessage);
    setCancellationNote(selectedMessage);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className='cancellationModal' zIndex={2000}>
      <ModalOverlay zIndex={2000} />
      <ModalContent containerProps={{ zIndex: '2000' }}>
        <ModalHeader>Confirm cancellation</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Sélectionnez un message préconfiguré */}
          <Select
            placeholder="Select a cancellation message"
            onChange={handleSelectMessage}
            mb={4} // Marges au-dessous du Select
          >
            {settings.shopSettings?.cancellationMessages?.map((msg, index) => (
              <option key={index} value={index}>
                {msg.name}
              </option>
            ))}
          </Select>

          {/* Affichez et modifiez le message sélectionné */}
          <Textarea
            placeholder="Please enter a cancellation motive..."
            value={cancellationNote}
            onChange={(e) => setCancellationNote(e.target.value)}
            required
          />
        </ModalBody>
        <ModalFooter>
          <Flex gap={2}>
            <Button className='btn secondary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              className='btn primary'
              onClick={handleConfirmCancellation}
              disabled={!cancellationNote}
            >
              Confirm
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CancellationModal;
