import { Flex, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, Textarea } from '@chakra-ui/react';

const CancelReservationModal = ({ isOpen, onClose, cancelReservationNote, setCancelReservationNote, handleConfirmCancellation }) => {

return (
<Modal isOpen={isOpen} onClose={onClose}>
<ModalOverlay />
<ModalContent>
    <ModalHeader>Confirm cancellation</ModalHeader>
    <ModalCloseButton />
    <ModalBody>
        <Textarea
            placeholder="Please enter a cancellation motive..."
            value={cancelReservationNote}
            onChange={(e) => setCancelReservationNote(e.target.value)}
            required
        />
    </ModalBody>
    <ModalFooter>
        <Flex gap={2}>
        <Button className='btn secondary' onClick={onClose}>Cancel</Button>
        <Button 
            className='btn primary' 
            onClick={handleConfirmCancellation}
            disabled={ !cancelReservationNote }
            >
            Confirm
        </Button>
        </Flex>
    </ModalFooter>
</ModalContent>
</Modal>
);
};
export default CancelReservationModal;
