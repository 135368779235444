import React, { useState, useEffect } from "react";
import useAllRestaurants from "../../hooks/useAllRestaurants";
import { useAppContext } from "../../contexts/AppContext";
import CreateRestaurantModal from "./CreateRestaurantModal";
import { useSelectedRestaurant } from "../../contexts/SelectedRestaurantContext";
import { Flex, Heading, Image } from "@chakra-ui/react";
import { ChevronDown } from "lucide-react";
import RestaurantSelectSkeleton from "./RestaurantSelectSkeleton";

const RestaurantSelect = () => {
  
  const { error } = useAllRestaurants();
  const {
    selectedRestaurantId,
    setSelectedRestaurantId,
    setMenuAdminBarZIndex,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    userRole,
  } = useAppContext();
  
  const { selectedRestaurant, handleChangeRestaurant, filteredRestaurants, isSelectedRestaurantLoading } = useSelectedRestaurant();
  const [isCreateRestaurantModalOpen, setIsCreateRestaurantModalOpen] = useState(false);
  const [isImageLoaded, setIsImageLoaded] = useState(false); // Suivi du chargement de l'image

  const handleRestaurantAdded = (newRestaurantId) => {
    setSelectedRestaurantId(newRestaurantId);
  };

  const handleOpenSubMenu = () => {
    setMenuAdminBarZIndex(1);
    toggleSubMenu("restaurantSelection")();
  };

  // Remettre à zéro le chargement de l'image seulement lorsque le logo change
  useEffect(() => {
    setIsImageLoaded(false);
  }, [settings.logo]);

  // Timeout de sécurité pour forcer le passage de isImageLoaded à true après 5 secondes
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isImageLoaded) {
        setIsImageLoaded(true);
      }
    }, 500);

    return () => clearTimeout(timeout); // Nettoyer le timeout si l'image se charge avant
  }, [isImageLoaded]);

  // Combinaison des états de chargement
  const isLoading = isSelectedRestaurantLoading || !isImageLoaded;


  return (
    <>
      {error && <div>Erreur lors du chargement des restaurants : {error.message}</div>}
      
      {isLoading ? (
        <RestaurantSelectSkeleton />
      ) : (
        selectedRestaurant && (
          <Flex
            gap={3}
            justifyContent="center"
            alignItems={"center"}
            textTransform={"capitalize"}
            fontWeight="500"
            w="fit-content"
            transition={"all 0.24s ease"}
            cursor={"pointer"}
            h="48px"
            fontSize={"1em"}
            onClick={handleOpenSubMenu}
          >
            <Flex
              justifyContent="center"
              alignItems="center"
              w={{ desktop: "42px", mobile: "36px" }}
              h={{ desktop: "42px", mobile: "36px" }}
              borderRadius={"8px"}
              border={"1px solid var(--borders)"}
              p="4px"
              bg={settings.backgroundColor}
            >
              {settings.logo && (
                <Image
                  src={settings.logo.menu}
                  alt="logo"
                  onLoad={() => {
                    setIsImageLoaded(true);
                  }} 
                  onError={() => {
                    setIsImageLoaded(true); // Même en cas d'échec du chargement, on considère que l'image est chargée
                  }}
                />
              )}
            </Flex>
            <Heading as="h2" fontSize={"16px"} fontWeight={"600"}>
              {selectedRestaurant?.name}
            </Heading>
            {openSubMenu === "restaurantSelection" && (
              <Flex className="submenu" left={0}>
                <ul className="restaurantOptions">
                  {filteredRestaurants
                    .filter((restaurant) => restaurant.id !== selectedRestaurantId)
                    .map((restaurant) => (
                      <li key={restaurant.id} onClick={() => handleChangeRestaurant(restaurant.id)}>
                        {restaurant.name}
                      </li>
                    ))}
                </ul>
                {userRole === "superAdmin" && (
                  <button onClick={() => setIsCreateRestaurantModalOpen(true)} className="primary addRestaurant">
                    Add a restaurant
                  </button>
                )}
                <button onClick={closeSubMenu} className="closeSubmenu">
                  Cancel
                </button>
              </Flex>
            )}
            {showOverlay && openSubMenu === "restaurantSelection" && (
              <div className="overlay" onClick={closeSubMenu}></div>
            )}
            {filteredRestaurants.length > 1 && <ChevronDown size={20} color="var(--grey700)" />}
          </Flex>
        )
      )}
      
      <CreateRestaurantModal
        isOpen={isCreateRestaurantModalOpen}
        onRequestClose={() => setIsCreateRestaurantModalOpen(false)}
        onRestaurantAdded={handleRestaurantAdded}
      />
    </>
  );
};

export default RestaurantSelect;
