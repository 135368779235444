import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, setDoc, updateDoc } from "firebase/firestore";
import Sidebar from "../components/menu/Sidebar";
import MenuList from "../components/menu/MenuList";
import ItemModal from "../components/menu/itemModal/ItemModal";
import useMenuData from "../hooks/useMenuData";
import { useAppContext } from "../contexts/AppContext";
import useMainCategories from "../hooks/useMainCategories";
import MainCategoryModal from "../components/menu/MainCategoryModal";
import SubCategoryModal from "../components/menu/SubCategoryModal";
import { Flex, Button } from "@chakra-ui/react";
import { Eye, EyeOff } from "lucide-react";
import MenuSwitcher from "../components/menu/MenuSwitcher";
import { toast } from "react-toastify";
import { useMenuContext } from "../contexts/MenuContext";
import "react-horizontal-scrolling-menu/dist/styles.css";
import useImportExport from "../hooks/useImportExport";
import ImportModal from "../components/menu/importModal";

const Menu = ({ productType, category }) => {
  const { selectedRestaurantId, settings, mainLanguage } =
    useAppContext();

  const {
    currentMainCategory,
    categories,
    setCategories,
    itemsByCategory,
    setItemsByCategory,
    importModalIsOpen, setImportModalIsOpen
  } = useMenuContext();

  const {
    mainCategories,
    mainCategoryName,
    editMainCategory,
    isMainCategoryModalOpen,
    handleDeleteMainCategory,
    handleSaveNewMainCategory,
    handleOpenMainCategoryModal,
    handleCloseMainCategoryModal,
    mainCategoryHasChanges,
    setMainCategoryHasChanges,
  } = useMainCategories(selectedRestaurantId);

  const {
    currentItem,
    setCurrentItem,
    handleSubmit,
    duplicateItem,
    archiveItem,
    handleOrderChange,
    uncategorizedItems,
    onAddCategory,
    deleteSubCategory,
    openEditModal,
    addItemWithCategory,
    closeEditModal,
    fetchData,
    handleImageDelete,
    handleImageUploadClick,
    handleImageUpload,
    handleDrop,
    handleDragOver,
    hasChanges,
    setHasChanges,
    deleteUncategorizedItems,
    handleCategoryUpdate,
    toggleAvailability,
  } = useMenuData(mainCategoryName, selectedRestaurantId);

  const { closeImportModal } = useImportExport();

  const [currentSubCategory, setCurrentSubCategory] = useState(null);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [subCategoryDescription, setSubCategoryDescription] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [subCategoryFormula, setSubCategoryFormula] = useState(false);
  const [subCategoryFormulaPrice, setSubCategoryFormulaPrice] = useState("");
  const [displayDays, setDisplayDays] = useState([]);
  const [timeRange, setTimeRange] = useState({ start: "", end: "" });

  const [subCategoryStyle, setSubCategoryStyle] = useState("List");
  const [isSubCategoryModalOpen, setIsSubCategoryModalOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    if (category) {
      setSubCategoryName(category[`name${mainLanguage}`]);
      setSubCategoryStyle(category.style);

    } else {
      setSubCategoryName("");
      setSubCategoryStyle("List");

    }
  }, [category, mainLanguage]);

  const handleSaveSubCategory = async (e) => {
    e.preventDefault();
    try {
      const isUpdate = currentSubCategory && currentSubCategory.id;
      const mainLanguage = settings.language[0].value;
      let categoryId;
  
      if (isUpdate) {
        categoryId = currentSubCategory.id;
      } else {
        categoryId = subCategoryName[mainLanguage]
          ? subCategoryName[mainLanguage].trim().toLowerCase().replace(/ /g, "-")
          : "";
      }
  
      const categoryRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
        categoryId
      );
  
      let categoryData = {
        style: subCategoryStyle,
        formula: subCategoryFormula === "yes",
        displayDays,          // Add displayDays to Firestore data
        timeRange: {          // Add timeRange (start and end) to Firestore data
          start: timeRange.start,
          end: timeRange.end,
        },
      };
  
      if (subCategoryFormula === "yes") {
        categoryData.formulaPrice = parseFloat(subCategoryFormulaPrice) || 0;
      }
  
      // Add name fields for each language
      Object.keys(subCategoryName).forEach((lang) => {
        if (typeof subCategoryName[lang] === "string") {
          categoryData[`name_${lang}`] = subCategoryName[lang].trim();
        }
      });
  
      // Add description fields for each language
      Object.keys(subCategoryDescription).forEach((lang) => {
        if (typeof subCategoryDescription[lang] === "string") {
          categoryData[`description_${lang}`] = subCategoryDescription[lang].trim();
        }
      });
  
      // Save data to Firestore
      if (isUpdate) {
        await updateDoc(categoryRef, categoryData);
        handleCategoryUpdate({ ...currentSubCategory, ...categoryData });
      } else {
        const newCategory = {
          id: categoryId,
          order: categories.length,
          ...categoryData,
        };
        await setDoc(categoryRef, newCategory);
        handleCategoryUpdate(newCategory);
      }
  
      fetchData();
      setIsSubCategoryModalOpen(false);
      toast.success("Category saved successfully");
      document.body.classList.remove("no-scroll");
    } catch (error) {
      console.error("Error saving category:", error);
      toast.error("Failed to save category");
    }
  };
  

  const openSubCategoryModal = (category) => {
    if (category) {
      setCurrentSubCategory(category);
      setIsEditing(true);

      const nameObject = {};
      const allLanguages = [
        settings.language[0],
        ...settings.secondaryLanguages,
      ];
      allLanguages.forEach((lang) => {
        const langKey = `name_${lang.value}`;
        nameObject[lang.value] = category[langKey] || "";
      });
      setSubCategoryName(nameObject);
      setSubCategoryStyle(category.style);
    } else {
      setCurrentSubCategory(null);
      setIsEditing(false);
      setSubCategoryName({});
      setSubCategoryStyle("List");
    }
    setIsSubCategoryModalOpen(true);
    document.body.classList.add("no-scroll");
  };

  const closeSubCategoryModal = () => {
    document.body.classList.remove("no-scroll");
    setIsSubCategoryModalOpen(false);
  };

  const renderContent = () => {
    

    if (!mainCategoryName) {
      return (
        <Flex
          direction={"column"}
          flex={1}
          gap={6}
          justifyContent="center"
          padding={{ desktop: "0", mobile: "3vw" }}
          w="100%"
          alignItems="center"
        >
          <Flex
            direction={"column"}
            gap={2}
            w="fit-content"
            alignItems="center"
            justifyContent="center"
          >
            <h2>Welcome!</h2>
            <p>Start by adding a menu.</p>
          </Flex>
          <Button
            size="lg"
            className="btn primary"
            onClick={() => {
              handleOpenMainCategoryModal();
            }}
            width="fit-content"
          >
            Add menu
          </Button>
        </Flex>
      );
    }

    return (
      <Flex
        direction={{ desktop: "row", mobile: "column" }}
        gap={{ desktop: 16, mobile: 8 }}
        flex={1}
        w={"100%"}
      >
        <Flex direction="column" gap={4} flex={1}>
          <MenuSwitcher
            handleOpenMainCategoryModal={handleOpenMainCategoryModal}
            currentMainCategory={currentMainCategory}
          />

            <MenuList
              categories={categories.filter((cat) => !cat.isUncategorized)}
              setCategories={setCategories}
              itemsByCategory={itemsByCategory}
              setItemsByCategory={setItemsByCategory}
              onOrderChange={handleOrderChange}
              openEditModal={openEditModal}
              onCategoryDelete={deleteSubCategory}
              onAddDish={openEditModal}
              toggleAvailability={toggleAvailability}
              onDuplicate={duplicateItem}
              onArchive={archiveItem}
              mainCategoryName={mainCategoryName}
              currentItem={currentItem}
              productType={productType}
              setCurrentItem={setCurrentItem}
              selectedRestaurantId={selectedRestaurantId}
              addItemWithCategory={addItemWithCategory}
              handleSubmit={handleSubmit}
              mainCategories={mainCategories}
              currentMainCategory={currentMainCategory}
              handleOpenModal={handleOpenMainCategoryModal}
              openSubCategoryModal={openSubCategoryModal}
              scrollPosition={scrollPosition}
              setScrollPosition={setScrollPosition}
              handleOpenMainCategoryModal={handleOpenMainCategoryModal}
              mainCategoryHasChanges={mainCategoryHasChanges}
              handleDeleteMainCategory={handleDeleteMainCategory}
            />
            
        </Flex>

        <Sidebar
          categories={categories}
          setCategories={setCategories}
          itemsByCategory={itemsByCategory}
          handleOrderChange={handleOrderChange}
          handleCategoryDeletion={deleteSubCategory}
          onAddCategory={onAddCategory}
          uncategorizedItems={uncategorizedItems}
          openEditModal={openEditModal}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          mainCategoryName={mainCategoryName}
          currentMainCategory={currentMainCategory}
          selectedRestaurantId={selectedRestaurantId}
          handleSubmit={handleSubmit}
          productType={productType}
          subCategoryName={subCategoryName}
          currentSubCategory={currentSubCategory}
          onDeleteUncategorized={deleteUncategorizedItems}
          openSubCategoryModal={openSubCategoryModal}
        />
      </Flex>
    );
  };

  return (
    <>
      <MainCategoryModal
        isOpen={isMainCategoryModalOpen}
        onClose={handleCloseMainCategoryModal}
        onSave={handleSaveNewMainCategory}
        onDelete={handleDeleteMainCategory}
        mainCategoryName={mainCategoryName}
        mainCategoryHasChanges={mainCategoryHasChanges}
        setMainCategoryHasChanges={setMainCategoryHasChanges}
        editCategory={editMainCategory}
      />

      {renderContent()}

      {isSubCategoryModalOpen && (
        <SubCategoryModal
          isOpen={isSubCategoryModalOpen}
          onClose={closeSubCategoryModal}
          category={currentSubCategory}
          mainCategoryName={mainCategoryName}
          onCategoryUpdate={handleCategoryUpdate}
          currentSubCategory={currentSubCategory}
          onSave={handleSaveSubCategory}
          isEditing={isEditing}
          subCategoryName={subCategoryName}
          setSubCategoryName={setSubCategoryName}
          subCategoryDescription={subCategoryDescription}
          setSubCategoryDescription={setSubCategoryDescription}
          subCategoryStyle={subCategoryStyle}
          setSubCategoryStyle={setSubCategoryStyle}
          subCategoryFormula={subCategoryFormula}
          setSubCategoryFormula={setSubCategoryFormula}
          subCategoryFormulaPrice={subCategoryFormulaPrice}
          setSubCategoryFormulaPrice={setSubCategoryFormulaPrice}
          displayDays={displayDays}
          setDisplayDays={setDisplayDays}
          timeRange={timeRange}
          setTimeRange={setTimeRange}
          onDelete={deleteSubCategory}
        />
      )}

      <ItemModal
        categories={categories}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        onDuplicate={duplicateItem}
        onArchive={archiveItem}
        toggleAvailability={toggleAvailability}
        handleSubmit={handleSubmit}
        handleImageDelete={handleImageDelete}
        handleImageUploadClick={handleImageUploadClick}
        handleImageUpload={handleImageUpload}
        handleDrop={handleDrop}
        handleDragOver={handleDragOver}
        closeEditModal={closeEditModal}
        mainCategoryName={mainCategoryName}
        currentMainCategory={currentMainCategory}
        hasChanges={hasChanges}
        setHasChanges={setHasChanges}
        scrollPosition={scrollPosition}
        setScrollPosition={setScrollPosition}
      />

      <ImportModal
        isOpen={importModalIsOpen}
        setImportModalIsOpen={setImportModalIsOpen}
        onClose={closeImportModal}  
      />

      {mainCategoryName && (
        <Button
          size={"md"}
          borderRadius={"full"}
          bg={"var(--grey700)"}
          border={"1px solid rgba(0, 0, 0, 0.08)"}
          position={"fixed"}
          left={"50%"}
          bottom={{ desktop: "48px", mobile: "100px" }}
          transform={"translateX(-50%)"}
          transition={"-ms-transform 0.25s cubic-bezier(0.2,0,0,1),-webkit-transform 0.25s cubic-bezier(0.2,0,0,1),transform 0.25s cubic-bezier(0.2,0,0,1) !important;"}
          _hover={{
            transform: "scale(1.04) translateX(-50%)"
          }}
          onClick={() => {
            handleOpenMainCategoryModal(currentMainCategory);
          }}
        >
          {currentMainCategory
            ? `Edit ${currentMainCategory[`name_${mainLanguage}`]}`
            : "Loading..."}

          {currentMainCategory && (
            <>
              {currentMainCategory.published ? (
                <Eye size={16} />
              ) : (
                <EyeOff size={16} />
              )}
            </>
          )}
        </Button>
      )}
    </>
  );
};

export default Menu;
