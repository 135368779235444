import { useAppContext } from '../../../contexts/AppContext';
import { useMenuContext } from '../../../contexts/MenuContext';
import useMenuData from '../../../hooks/useMenuData';
import CreatableSelect from 'react-select/creatable';

const TagSelect = ({ setHasChanges, setCurrentItem }) => {
    
    const { mainLanguage} = useAppContext();
    const { tags, selectedTags, setSelectedTags } = useMenuContext();
    const { saveTag } = useMenuData();

    const tagOptions = tags.map(tag => {
      const nameKey = `name_${mainLanguage}`;
      return {
          value: tag.id, 
          label: tag[nameKey] || 'Label inconnu',
          color: tag.color
      };
  });
 
      
  const handleTagChange = (selectedOptions) => {
    // Map the selected options to match the structure of tags in currentItem
    const updatedSelectedTags = selectedOptions.map(option => {
      const originalTag = tags.find(tag => tag.id === option.value);
      return {
        ...option,
        color: originalTag ? originalTag.color : option.color
      };
    });
  
    // Update the selectedTags state
    setSelectedTags(updatedSelectedTags || []);
  
    // Update the currentItem to ensure the tags are saved
    setCurrentItem(prevItem => ({
      ...prevItem,
      tags: updatedSelectedTags,  // Update the tags in currentItem
    }));
  
    setHasChanges(true);  // Mark changes
  };
  
    
    
    const handleCreateTag = async (inputValue) => {
      const newTagData = {
          [`name_${mainLanguage}`]: inputValue,
          color: '#defaultColor'
      };
      
      const savedTag = await saveTag(newTagData);
  
      if (savedTag && savedTag.id) {
          const newTagOption = {
              value: savedTag.id,
              label: savedTag[`name_${mainLanguage}`],
              color: savedTag.color
          };
          setSelectedTags(prevSelectedTags => [...prevSelectedTags, newTagOption]);
      }
      setHasChanges(true);
  };
  
    

    const formatOptionLabel = ({ label, color }) => (
        <>
          <span className="tagPoint" style={{ backgroundColor: color }}></span>
          {label}
        </>
      );

    
    
    
    
        
        return (

        <CreatableSelect
          isMulti
          onChange={handleTagChange}
          options={tagOptions}
          value={selectedTags}
          onCreateOption={handleCreateTag}
          className="customSelect-container"
          classNamePrefix="customSelect"
          formatOptionLabel={formatOptionLabel}
          mainLanguage={mainLanguage}
          

        />    

        );
};
        
        export default TagSelect;
        
        