import { Button, Flex, Input } from "@chakra-ui/react";
import { X  } from "lucide-react";


const VariationFields = ({ variation, index, handleVariationChange, handleDeleteVariation }) => {
    return (
      <Flex className="variation" flex={1} direction="row" alignItems={"center"} gap={4}>
        <Flex className="field">
          <label htmlFor={`variation${index}Name`}>Variation {index + 1}</label>
          <Input
            type="text"
            value={variation.name ?? ""}
            onChange={(e) => handleVariationChange(index, "name", e.target.value)}
            placeholder="Variation name"
            id={`variation${index}Name`}
            required
          />
        </Flex>
        <Flex className="field price">
          <label htmlFor={`variation${index}Price`}>Price</label>
          <Input
            type="text"
            value={variation.price}
            onChange={(e) => handleVariationChange(index, "price", e.target.value)}
            placeholder="Price"
            id={`variation${index}Price`}
          />
        </Flex>
        <Button size="xs" variant="outline" onClick={() => handleDeleteVariation(index)}>
          <X size={16} />
        </Button>
      </Flex>
    );
  };

  export default VariationFields;
  