import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import useStrictModeDroppable from "../../hooks/useStrictModeDroppable";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, Button, Text, Tooltip } from "@chakra-ui/react";
import { GripVertical, Pencil, Plus } from "lucide-react";
import { useState } from "react";

const StrictModeDroppableComponent = ({ droppableId, children }) => {
  const { enabled } = useStrictModeDroppable();
  if (!enabled) {
    return null;
  }
  return (
    <Droppable droppableId={droppableId} key={droppableId}>
      {(provided, snapshot) => children(provided, snapshot)}
    </Droppable>
  );
};

const ManageCategories = ({
  categories,
  setCategories,
  onOrderChange,
  itemsByCategory,
  openSubCategoryModal,
}) => {
  const { mainLanguage } = useAppContext();
  const [clickTimeout, setClickTimeout] = useState(null);

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const itemsArray = Array.from(categories);
    const [reorderedItem] = itemsArray.splice(result.source.index, 1);
    itemsArray.splice(result.destination.index, 0, reorderedItem);
    setCategories(itemsArray);
    onOrderChange(itemsArray);
  };

  const handleLinkClick = (anchorId) => {
    const anchorElement = document.getElementById(anchorId);
    if (anchorElement) {
      const offsetTop = anchorElement.offsetTop + 78; // 78px est l'offset désiré
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (category) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout); // Annuler le timeout du simple clic si un double clic est en cours
      setClickTimeout(null); // Assurez-vous de réinitialiser l'état
      return; // Ne pas exécuter le simple clic si le double clic est détecté
    }

    const timeout = setTimeout(() => {
      handleLinkClick(category.id); // Exécution du clic simple
      setClickTimeout(null); // Réinitialisation
    }, 300); // Délai pour attendre le double clic (300ms)

    setClickTimeout(timeout);
  };

  const handleDoubleClick = (category) => {
    if (clickTimeout) {
      clearTimeout(clickTimeout); // Annuler le clic simple en cas de double clic
    }
    setClickTimeout(null);
    openSubCategoryModal(category); // Exécution du double clic
  };


  return (
    <>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Flex
          direction="column"
          gap={4}
          bg={"white"}
          pl={{ desktop: 4, mobile: 0 }}
          borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none" }}
          position={{ desktop: "sticky", mobile: "relative" }}
          top={{ desktop: "24px", mobile: "0" }}
          zIndex={{ desktop: 4, mobile: "unset" }}
          flex={0}
          mt={{ desktop: 4, mobile: 8 }}
        >
          <Flex className="widgetTitle" justifyContent="space-between">
            <h5>Categories</h5>
            <Button
              leftIcon={<Plus size={12} color="var(--grey700)" />}
              sx={{
                ".chakra-button__icon": {
                  marginRight: 0,
                },
              }}
              gap={1}
              size="xs"
              variant={"outline"}
              onClick={() => openSubCategoryModal()}
            >
              Add category
            </Button>
          </Flex>
          <Flex className="categoriesScroll">
            <StrictModeDroppableComponent
              key="categories"
              droppableId="categories"
              flex={1}
            >
              {(provided) => (
                <Flex
                  direction="column"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="order-categories"
                  flex={1}
                >
                  {categories
                    .filter((cat) => cat.id !== "uncategorized")
                    .map((category, index) => {
                      const itemCount =
                        itemsByCategory[category.id]?.length || 0;

                      return (
                        <Draggable
                          key={category.id}
                          draggableId={category.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <Flex
                              direction="row"
                              flex={1}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="category-item"
                              p={2}
                              _hover={{
                                backgroundColor: "var(--grey50)",
                                ".editIcon": { visibility: "visible" },
                              }}
                              onClick={() => handleClick(category)}
                              onDoubleClick={(event) => {
                                event.stopPropagation();
                                handleDoubleClick(category);
                              }}

                            >
                              <Flex gap={2} alignItems="center">
                                <GripVertical
                                  size={16}
                                  color="var(--grey500)"
                                  style={{ minHeight: "16px", minWidth:"16px" }} 
                                />
                                <Text
                                  whiteSpace="nowrap"
                                  color="var(--grey700)"
                                  maxWidth="160px"
                                  overflow="hidden"
                                  textOverflow={"ellipsis"}
                                >
                                  {category[`name_${mainLanguage}`]}
                                </Text>
                                <span>({itemCount})</span>
                              </Flex>
                              <Tooltip
                                label={`Edit ${
                                  category[`name_${mainLanguage}`]
                                }`}
                              >
                                <Pencil
                                  className="editIcon"
                                  size={16}
                                  style={{ minHeight: "16px", minWidth:"16px" }} 
                                  visibility={"hidden"}
                                  cursor={"pointer"}
                              onClick={(event) => {
                                event.stopPropagation();
                                openSubCategoryModal(category);
                              }}
                                />
                              </Tooltip>
                            </Flex>
                          )}
                        </Draggable>
                      );
                    })}
                  {provided.placeholder}
                </Flex>
              )}
            </StrictModeDroppableComponent>
          </Flex>
        </Flex>
      </DragDropContext>
    </>
  );
};

export default ManageCategories;
