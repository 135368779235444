import { Flex, Skeleton } from "@chakra-ui/react";


function KPIsSkeleton() {
  

  return (
   

        <Flex flex={1} direction="row" gap={8} w='100%' h='50px'>
          <Skeleton height='50px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='50px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='50px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='50px' borderRadius="var(--radius-sm)" flex={1} />
        </Flex>
        

  );
}
export default KPIsSkeleton;
