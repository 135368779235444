import {
    Flex,
    Button,
    Text,
    Image,
  } from "@chakra-ui/react";
  import { Import, Plus } from "lucide-react";
  import emptyStatePattern from "../../images/empty-state-decorative-pattern.svg";
  
  const EmptyList = ({
    title,
    text,
    primaryButton,
    primaryButtonText,
    secondaryButton,
    secondaryButtonText,
    onPrimaryButtonClick,
    onSecondaryButtonClick
  }) => {
    return (
      <Flex direction={"column"} flex="1" gap={4} h={"-webkit-fill-available"}>
        <Flex
          direction={"column"}
          flex={1}
          gap={8}
          justifyContent="center"
          padding={{ desktop: "0", mobile: "3vw" }}
          w="100%"
          h={"-webkit-fill-available"}
          alignItems="center"
          overflow={"hidden"}
        >
          <Image
            src={emptyStatePattern}
            style={{ width: "520px", height: "520px", left: "50%", position: "absolute" }}
            minW={"520px"}
            minH={"520px"}
            top={{ desktop: "200px", mobile: "140px" }}
            transform={"translateX(-50%) translateY(-50%)"}
            opacity={0.7}
          />
  
          <Flex direction={"column"} gap={2} w="fit-content" alignItems="center" justifyContent="center">
            <Text fontSize={18} fontWeight={600} color={"var(--grey700)"}>
              {title}
            </Text>
            <Text>{text}</Text>
          </Flex>
  
          {primaryButton && secondaryButton && (
            <Flex alignItems={"center"} gap={4}>
              <Button
                leftIcon={<Plus color="white" size={16} />}
                sx={{
                  ".chakra-button__icon": {
                    marginRight: 0,
                  },
                }}
                onClick={onPrimaryButtonClick} // Utilisation de la fonction passée en prop
                width="fit-content"
              >
                {primaryButtonText}
              </Button>
  
              <Button
                variant={"outline"}
                bg={"white"}
                leftIcon={<Import color="var(--grey500)" size={16} />}
                sx={{
                  ".chakra-button__icon": {
                    marginRight: 0,
                  },
                }}
                onClick={onSecondaryButtonClick} // Utilisation de la fonction passée en prop
              >
                {secondaryButtonText}
              </Button>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };
  
  export default EmptyList;
  