import { useState, useEffect } from "react";
import { doc, setDoc, getDoc, updateDoc, collection, query, where, getDocs, orderBy } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useAppContext } from "../contexts/AppContext";

const useMonthlyOrders = () => {
  const { selectedRestaurantId, settings } = useAppContext();
  const [monthlyOrders, setMonthlyOrders] = useState([]);
  
  useEffect(() => {
    const fetchOrders = async () => {
      if (!selectedRestaurantId) return;

      const startOfYear = new Date(new Date().getFullYear(), 0, 1);
      const endOfYear = new Date(new Date().getFullYear(), 11, 31, 23, 59, 59);
      const ordersRef = collection(
        db,
        `restaurants/${selectedRestaurantId}/orders`
      );
      const q = query(
        ordersRef,
        where("createdAt", ">=", startOfYear),
        where("createdAt", "<=", endOfYear),
        orderBy("createdAt", "desc")
      );

      const calculateHT = (order) => {
        return order.cartItems.reduce((acc, item) => {
          const price = parseFloat(item.price.replace(",", "."));
          const vatRate = parseFloat(item.vat);
          const priceHT = price / (1 + vatRate);
          return acc + priceHT * item.quantity;
        }, 0);
      };

      const querySnapshot = await getDocs(q);
      const orders = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const order = {
          id: doc.id,
          ...data,
          createdAt: data.createdAt.toDate(), // Convertit createdAt en Date si nécessaire
        };

        // Calculez le total HTVA ici et ajoutez-le à l'objet de commande
        order.totalHTVA = calculateHT(order); // Utilisez votre fonction existante pour calculer le total HTVA

        return order;
      });

      const aggregatedData = orders.reduce((acc, order) => {
        const month = order.createdAt.getMonth();
        const year = order.createdAt.getFullYear();
        const monthYearKey = `${month + 1}-${year}`;

        if (!acc[monthYearKey]) {
          acc[monthYearKey] = {
            monthYear: `${month + 1}/${year}`,
            orders: [],
            totalTTC: 0,
            totalVAT: 0,
            totalTips: 0,
            totalCommission: 0,
          };
        }

        // Incluez l'objet de commande entier, qui a maintenant un champ totalHTVA
        acc[monthYearKey].orders.push(order);

        return acc;
      }, {});

      

      Object.keys(aggregatedData).forEach((key) => {
        const data = aggregatedData[key];
        data.orders.forEach((order) => {
          if (order.paymentStatus === "Paid" && order.status === "completed") {
            const orderHT = calculateHT(order);
            data.totalTTC += parseFloat(order.subTotal);
            data.totalTips += parseFloat(order.tip || 0);
            data.totalVAT += calculateVAT(order);
            data.totalHTVA = (data.totalHTVA || 0) + orderHT;
            data.totalServiceFees = calculateTotalServiceFees(data.orders);
          }
        });
        data.totalCommission = calculateTotalCommission(
          data,
          settings.shopSettings?.fees?.commissionFee
        );
      });

      const monthlyOrdersWithStatus = await Promise.all(Object.keys(aggregatedData).map(async (monthYearKey) => {
        const status = await fetchInvoiceStatus(monthYearKey);
        return { ...aggregatedData[monthYearKey], monthYearKey, status };
      }));
      console.log("monthly orders with status", monthlyOrdersWithStatus)
      setMonthlyOrders(monthlyOrdersWithStatus); 
    }; 

    fetchOrders();
  }, [selectedRestaurantId, settings]);

  const calculateVAT = (order) => {
    let totalVAT = 0;
    order.cartItems.forEach((item) => {
      const price = parseFloat(item.price.replace(",", "."));
      const vatRate = item.vat;
      const vatAmount = (price - price / (1 + vatRate)) * item.quantity;
      totalVAT += Math.round(vatAmount * 100) / 100;
    });
    return parseFloat(totalVAT.toFixed(2));
  };

  const calculateTotalServiceFees = (orders) => {
    return orders
      .filter(order => order.paymentStatus === "Paid" && order.status === "completed")
      .reduce((acc, order) => {
        return acc + (parseFloat(order.serviceFee) || 0);
      }, 0);
  };
  
  

  const calculateTotalCommission = (data, commissionRate) => {
    const commissionRateDecimal =
      parseFloat(commissionRate.replace(",", ".")) / 100;
    const commission = (data.totalTTC - data.totalVAT) * commissionRateDecimal;
    return commission;
  };

  const fetchInvoiceStatus = async (monthYearKey) => {
    console.log("Fetching invoice status for key:", monthYearKey);
      const statusRef = doc(db, 'invoicing', monthYearKey);
    const statusSnap = await getDoc(statusRef);
    if (statusSnap.exists()) {
      return statusSnap.data().status;
    } else {
      await setDoc(statusRef, { status: 'Unpaid', restaurantId: selectedRestaurantId }); // Définir un statut par défaut pour la nouvelle facture
      return 'Unpaid';
    }
  };
  
  const updateInvoiceStatus = async (monthYearKey, newStatus) => {
    const statusRef = doc(db, 'invoicing', monthYearKey);
    await updateDoc(statusRef, { status: newStatus });
    
    // Mise à jour de l'état local pour refléter le changement
    setMonthlyOrders(prevOrders => prevOrders.map(order => {
      if (order.monthYearKey === monthYearKey) {
        return { ...order, status: newStatus };
      }
      return order;
    }));
  };
  
  
  
  return {
    monthlyOrders,
    updateInvoiceStatus
  };
};

export default useMonthlyOrders;
