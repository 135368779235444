import { useState, useEffect } from "react";
import Modal from "react-modal";
import useModal from "../../../../hooks/useModal";
import useOpeningHours from '../../../../hooks/useOpeningHours';
import { Button, Flex, Heading, IconButton, Box, Select } from '@chakra-ui/react';
import { X } from 'lucide-react';
import ModalTopBar from "../../../modal/ModalTopBar";
import isEqual from 'lodash.isequal';

Modal.setAppElement("#root");

const OpeningHoursModal = ({ isOpen, onClose }) => {
  const { openingHours, handleSaveOpeningHours, handleSlotChange, handleRemoveSlot, handleAddSlot } = useOpeningHours();
  const { customStyles } = useModal();
  const [initialOpeningHours, setInitialOpeningHours] = useState([]);
  const [hasChanges, setHasChanges] = useState(false); // État pour savoir s'il y a des changements

  // Sauvegarder les heures d'ouverture initiales lorsque le modal est ouvert
  useEffect(() => {
    if (isOpen) {
      // Création d'une copie profonde des heures d'ouverture initiales
      setInitialOpeningHours(JSON.parse(JSON.stringify(openingHours)));  // Sauvegarder l'état initial lors de l'ouverture
      setHasChanges(false);  // Réinitialiser l'état des changements
    }
  }, [isOpen]);

  // Fonction pour vérifier s'il y a eu des changements
  const checkForChanges = () => {
    // Vérifier si openingHours est différent de initialOpeningHours
    if (!isEqual(openingHours, initialOpeningHours)) {
      setHasChanges(true);  // Si différent, il y a des changements
    } else {
      setHasChanges(false);  // Sinon, pas de changements
    }
  };

  // Appeler checkForChanges à chaque modification d'un créneau
  useEffect(() => {
    checkForChanges(); // Vérifier les changements chaque fois que openingHours change
  }, [openingHours]);

  const handleSave = () => {
    if (hasChanges) {
      handleSaveOpeningHours();  // Appeler la fonction de sauvegarde seulement si des changements sont détectés
      onClose();
    }
  };

  const createOptions = (values, currentValue, padZero = false) => {
    return values.map((value) => (
      <option key={value} value={padZero ? String(value).padStart(2, '0') : value}>
        {padZero ? String(value).padStart(2, '0') : value}
      </option>
    ));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={"modal"}
      style={customStyles}>
        <Flex className="modal-content" w={"640px"}>
          <ModalTopBar 
            onClose={onClose} 
            onSave={handleSave} 
            title="Set opening hours" 
            disabled={!hasChanges}  // Désactiver le bouton "Save" si pas de changements
          />
          <Flex direction="column" gap={2} className="modalInner">
            {openingHours.map((day, dayIndex) => (
              <Flex 
                direction="row" 
                key={dayIndex} 
                justifyContent="space-between" 
                pb={4} 
                sx={{
                  "&:not(:first-of-type)": {
                    pt: 4, // Ajouter pt=4 sauf pour le premier enfant
                  },
                  "&:last-of-type": {
                    borderBottom: "none",
                    pb: 0,
                  },
                }} 
                borderBottom={"1px solid var(--borders)"}>
                <Heading fontSize={"15px"} fontWeight={600} w={"25%"}>{day.day || 'Day'}</Heading>
                <Flex direction="row" gap={2} w={"65%"} minW={"75%"} justifyContent={"space-between"}>
                  <Button size="sm" variant="outline" w="fit-content" minWidth={"fit-content"} onClick={() => handleAddSlot(dayIndex)}>Add Slot</Button>
                  <Flex direction={"column"} gap={2}>
                    {day.slots.length > 0 ? (
                      day.slots.map((slot, slotIndex) => (
                        <Flex direction="row" gap={2} key={slotIndex} alignItems="center" className="timeSelect" borderBottom={"1px solid var(--borders)"} pb={2} sx={{ "&:last-of-type": { borderBottom: "none", pb: 0 } }}>
                          <Flex direction="row" gap={1} flex={1} alignItems={"center"}>
                            <Select 
                              value={slot.start ? slot.start.split(':')[0] : '00'}
                              onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'start', `${e.target.value}:${slot.start ? slot.start.split(':')[1] : '00'}`)}>
                              {createOptions(Array.from({length: 24}, (_, i) => i), slot.start ? slot.start.split(':')[0] : '00', true)}
                            </Select>:
                            <Select 
                              value={slot.start ? slot.start.split(':')[1] : '00'}
                              onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'start', `${slot.start ? slot.start.split(':')[0] : '00'}:${e.target.value}`)}>
                              {createOptions([0, 15, 30, 45], slot.start ? slot.start.split(':')[1] : '00', true)}
                            </Select>
                          </Flex> 
                          -
                          <Flex direction="row" gap={1} alignItems={"center"}>
                            <Select 
                              value={slot.end ? slot.end.split(':')[0] : '00'}
                              onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'end', `${e.target.value}:${slot.end ? slot.end.split(':')[1] : '00'}`)}>
                              {createOptions(Array.from({length: 24}, (_, i) => i), slot.end ? slot.end.split(':')[0] : '00', true)}
                            </Select>:
                            <Select 
                              value={slot.end ? slot.end.split(':')[1] : '00'}
                              onChange={(e) => handleSlotChange(dayIndex, slotIndex, 'end', `${slot.end ? slot.end.split(':')[0] : '00'}:${e.target.value}`)}>
                              {createOptions([0, 15, 30, 45], slot.end ? slot.end.split(':')[1] : '00', true)}
                            </Select>
                          </Flex>
                          <IconButton variant='outline' size="xs" onClick={() => handleRemoveSlot(dayIndex, slotIndex)} icon={<X size={12} />}/>
                        </Flex>
                      ))
                    ) : (
                      <Box className="tag error">Closed</Box>
                    )}
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex >
        </Flex>
    </Modal>
  );
};

export default OpeningHoursModal;
