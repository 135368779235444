import React from "react";
import { Navigate } from "react-router-dom";

// Route protégée avec vérification des rôles
const ProtectedRoute = ({ isAllowed, rolesAllowed = [], userRole, redirectPath = "/login", children }) => {

  // Vérifier si les rôles sont définis et si l'utilisateur a un rôle approprié
  if (!isAllowed || (rolesAllowed.length > 0 && !rolesAllowed.includes(userRole))) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default ProtectedRoute;
