
import { Flex } from "@chakra-ui/react";
import MenuHeader from "../components/menu/MenuHeader";
import { MenuProvider } from "../contexts/MenuContext";


function MenuLayout({children}) {
  

  return (
    <MenuProvider>

      <MenuHeader />

      <Flex
        padding={{ desktop: 12, mobile: 6 }}
        pb={{ desktop: '124px', mobile: '172px' }}
        maxW="1320px"
        flex="1">
          
      

        {children}


      </Flex>

    </MenuProvider>
  );
}
export default MenuLayout;
