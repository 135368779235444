import { useState, useEffect, useCallback } from "react";
import { debounce } from "lodash";
import Modal from "react-modal";
import MenuToPDF from "./MenuToPDF";
import { pdf } from "@react-pdf/renderer";
import {
  Flex,
  Button,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabIndicator,
  Spinner,
  useMediaQuery,
} from "@chakra-ui/react";
import useAllergenes from "../../../hooks/useAllergenes";
import { useMenuContext } from "../../../contexts/MenuContext";
import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";
import { useAppContext } from "../../../contexts/AppContext";
import Style from "./settings/tabs/Style";
import Categories from "./settings/tabs/Categories";
import Display from "./settings/tabs/Display";

Modal.setAppElement("#root");

const PdfSettingsModal = ({
  isOpen,
  onClose,
  selectedLanguage,
  setSelectedLanguage,
}) => {

  const { selectedRestaurantId, mainLanguage, currentMainCategory, settings } = useAppContext();
  const { menuItems, categories, itemsByCategory } = useMenuContext();
  
  const isIPad = /iPad|Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document;
  const [isMobile] = useMediaQuery("(max-width: 960px)");


  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(2px)",
      display: "flex",
      padding: "2dvh",
      paddingBottom: isMobile ? "0dvh" : "2dvh",
      justifyContent: isMobile ? "flex-end" : "center",
      alignItems: isMobile ? "flex-end" : "center",
      zIndex: "99",
    },
    content: {
      padding: 0,
      flex: 1,
    },
  };

  const { mainCategoryName } = useAppContext();
  const allLanguages = settings.language.concat(settings.secondaryLanguages);
  const [showDescriptions, setShowDescriptions] = useState(true);
  const [showAllergens, setShowAllergens] = useState(false);
  const [showTags, setShowTags] = useState(true);
  const [showItemSeparator, setShowItemSeparator] = useState(true);

  const allergenes = useAllergenes();

  const [isGenerating, setIsGenerating] = useState(false);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [triggerPreviewUpdate, setTriggerPreviewUpdate] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const [isPrintSettingsLoaded, setIsPrintSettingsLoaded] = useState(false); // New state to track print settings loading
  
  const [categoriesState, setCategoriesState] = useState(categories);
  
  
  

  const defaultFontStyles = {
    categoryTitle: {
      fontFamily: "Poppins",
      fontWeight: "600",
      fontSize: 14,
      color: `${settings.brandColor}`,
    },
    categoryPrice: {
      fontFamily: "Poppins",
      fontWeight: "600",
      fontSize: 14,
      color: "black",
    },
    categoryTagline: {
      fontFamily: "Satisfy",
      fontWeight: "400",
      fontSize: 11,
      color: "black",
    },
    itemName: {
      fontFamily: "Barlow Condensed",
      fontWeight: "500",
      fontSize: 11,
      color: "black",
    },
    itemDescription: {
      fontFamily: "Poppins",
      fontSize: 9,
      fontWeight: "400",
      color: "#667085",
      maxWidth: 85,
    },
    itemPrice: {
      fontFamily: "Barlow Condensed",
      fontSize: 11,
      fontWeight: "500",
      color: `${settings.brandColor}`,
    },
    itemVariations: {
      fontFamily: "Barlow Condensed",
      fontSize: 9,
      fontWeight: "500",
      color: "black",
    },
    itemVariationsPrice: {
      fontFamily: "Barlow Condensed",
      fontSize: 9,
      fontWeight: "500",
      color: `${settings.brandColor}`,
    },
  };
  
  const [printSettings, setPrintSettings] = useState({
    pageSize: "A4",
    margins: {
      top: 20,
      bottom: 20,
      left: 20,
      right: 20,
    },
    fontStyles: defaultFontStyles, // Initialise avec les valeurs par défaut
  });
  
  const fetchPrintSettingsFromFirestore = async () => {
    try {
      const docRef = doc(db, `restaurants/${selectedRestaurantId}/settings/config`);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.printSettings) {
          // Fusionne les styles récupérés avec les valeurs par défaut
          setPrintSettings((prev) => ({
            ...prev,
            ...data.printSettings,
            fontStyles: {
              ...defaultFontStyles, // Valeurs par défaut
              ...data.printSettings.fontStyles, // Remplace par les valeurs récupérées
            },
          }));
          setIsPrintSettingsLoaded(true);
        }
      } else {
        console.log("No document found in Firestore.");
      }
    } catch (error) {
      console.error("Error fetching print settings from Firestore:", error);
    }
  };
  

  

  const generatePdfBlob = async () => {
    try {
      return await pdf(
        <MenuToPDF
          selectedRestaurantId={selectedRestaurantId}
          categories={categoriesState}
          itemsByCategory={itemsByCategory}
          menuItems={menuItems}
          settings={settings}
          mainLanguage={mainLanguage}
          selectedLanguage={selectedLanguage}
          currentMainCategory={currentMainCategory}
          showAllergens={showAllergens}
          showDescriptions={showDescriptions}
          showItemSeparator={showItemSeparator}
          showTags={showTags}
          printSettings={printSettings}
          getAllergenNames={getAllergenNames}
          getTagsNames={getTagsNames}
        />
      ).toBlob();
    } catch (error) {
      console.error("Error generating PDF blob:", error);
      throw error;
    }
  };

  const generatePdfPreview = useCallback(
    debounce(async () => {
      if (!triggerPreviewUpdate) return;
      setIsGenerating(true);
      try {
        const blob = await generatePdfBlob();
        const url = URL.createObjectURL(blob);
        setPreviewUrl(url);
        setTriggerPreviewUpdate(false);
        setHasChanges(false);
      } catch (error) {
        console.error("Error generating PDF preview:", error);
      } finally {
        setIsGenerating(false);
      }
    }, 1000),
    [
      selectedRestaurantId,
      categoriesState,
      itemsByCategory,
      menuItems,
      settings,
      mainLanguage,
      selectedLanguage,
      currentMainCategory,
      showAllergens,
      showDescriptions,
      showItemSeparator,
      showTags,
      printSettings,
      triggerPreviewUpdate,
    ]
  );

  useEffect(() => {
    if (isOpen) {
      fetchPrintSettingsFromFirestore(); 
      setCategoriesState(categories);
      setTriggerPreviewUpdate(true);
    }
  }, [isOpen, categories]);
  
  useEffect(() => {
    if (triggerPreviewUpdate) {
      generatePdfPreview();
    }
  }, [triggerPreviewUpdate, generatePdfPreview]);
  



  const openPdfInNewTab = async () => {
    try {
      const blob = await generatePdfBlob();
      const url = URL.createObjectURL(blob);
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening PDF in new tab:", error);
    }
  };

  const savePrintSettingsToFirestore = async () => {
    try {
      const docRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/settings/config`
      );

      await updateDoc(docRef, {
        "printSettings.fontStyles": printSettings.fontStyles,
        "printSettings.margins": printSettings.margins, // Save margins in Firestore
      });

    } catch (error) {
      console.error("Error saving print settings to Firestore:", error);
    }
  };

  const handleClose = () => {
    onClose();
    setPreviewUrl(null);
    setShowAllergens(false);
    setShowDescriptions(true);
    setPrintSettings({
      pageSize: "A4",
      margins: {
        top: 20,
        bottom: 20,
        left: 20,
        right: 20,
      },
      fontStyles: {
        categoryTitle: {
          fontFamily: "Poppins",
          fontWeight: "600",
          fontSize: 14,
          color: `${settings.brandColor}`,
        },
        categoryPrice: {
          fontFamily: "Poppins",
          fontWeight: "600",
          fontSize: 14,
          color: "black",
        },
        categoryTagline: {
          fontFamily: "Satisfy",
          fontWeight: "400",
          fontSize: 11,
          color: "black",
        },
        itemName: {
          fontFamily: "Barlow Condensed",
          fontWeight: "500",
          fontSize: 11,
          color: "black",
        },
        itemDescription: {
          fontFamily: "Poppins",
          fontSize: 9,
          fontWeight: "400",
          color: "#667085",
          maxWidth: 85,
        },
        itemPrice: {
          fontFamily: "Barlow Condensed",
          fontSize: 11,
          fontWeight: "500",
          color: `${settings.brandColor}`,
        },
        itemVariations: {
          fontFamily: "Barlow Condensed",
          fontSize: 9,
          fontWeight: "500",
          color: "black",
        },
        itemVariationsPrice: {
          fontFamily: "Barlow Condensed",
          fontSize: 9,
          fontWeight: "500",
          color: `${settings.brandColor}`,
        },
      },
    });
  };
  

  const getAllergenNames = (itemAllergens) => {
    if (
      !Array.isArray(itemAllergens) ||
      itemAllergens.length === 0 ||
      allergenes.length === 0
    ) {
      return null;
    }

    const allergenNames = itemAllergens
      .map((allergenId) => {
        const cleanedAllergenId = allergenId.trim();
        const allergene = allergenes.find((a) => a.id === cleanedAllergenId);

        if (allergene) {
          const allergenName =
            allergene[`name_${selectedLanguage}`] ||
            allergene[`name_${mainLanguage}`];
          if (!allergenName) {
            return null;
          }

          return {
            name: allergenName, // Le nom de l'allergène
            order: allergene.order, // Le champ `order` de la base de données
          };
        }

        return null; // Retourner null si l'allergène n'est pas trouvé
      })
      .filter(Boolean) // Filtrer les valeurs nulles
      .sort((a, b) => a.order - b.order) // Trier par le champ `order`
      .map((allergene) => allergene.name) // Ne conserver que les noms après le tri
      .join(" | "); // Concaténer les noms avec un séparateur

    return allergenNames || null; // Retourner null si aucun allergène valide n'est trouvé
  };

  const tags = useMenuContext().tags;

  const getTagsNames = (itemTags) => {
    if (!itemTags || tags.length === 0) {
      return "";
    }

    const tagsNames = itemTags
      .map((itemTag) => {
        const cleanedTagValue = itemTag.value.trim();
        const tag = tags.find((a) => a.id === cleanedTagValue);

        if (tag) {
          const tagName =
            tag[`name_${selectedLanguage}`] || tag[`name_${mainLanguage}`];
          return tagName || cleanedTagValue;
        }
        return cleanedTagValue;
      })
      .join(" ");
    return tagsNames;
  };

  const handleSaveCategories = async () => {
    try {
      for (const category of categoriesState) {
        const categoryId = category.id;
        const categoryRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}-categories/categories`,
          categoryId
        );

        // Construct data object to update Firestore
        let categoryData = {
          pageBreak: category.pageBreak,
          columns: category.columns,
        };

        // Loop through available languages to save name and description
        allLanguages.forEach((lang) => {
          const langCode = lang.value;
          if (category[`name_${langCode}`]) {
            categoryData[`name_${langCode}`] = category[`name_${langCode}`];
          }
          if (category[`description_${langCode}`]) {
            categoryData[`description_${langCode}`] =
              category[`description_${langCode}`];
          }
        });

        // Update Firestore with category data
        await updateDoc(categoryRef, categoryData);
      }

    } catch (error) {
      console.error("Error saving categories:", error);
    }
  };

  

  const handlePreviewUpdate = async () => {
    await savePrintSettingsToFirestore(); // Save both font styles and margins
    await handleSaveCategories(); // Sauvegarder les catégories
    setTriggerPreviewUpdate(true);
  };

  

  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={"modal"}
      style={customStyles}>
      <Flex
        className="modal-content"
        flex={1}
        overflow={"hidden"}
        direction={"row"}
        borderRadius={{ desktop: "12px", mobile: "12px 12px 0 0" }}>
        <Flex
          maxW={{ desktop: "520px", mobile: "100%" }}
          h={"calc(100dvh - 4dvh)"}
          flex={1}>
          <form onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
            <div className="modalTopBar">
              <div className="closeModal">
                <span className="close" onClick={handleClose}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                      fill="black"
                    />
                  </svg>
                </span>
              </div>
              <div className="modalTitle">Generate menu in .pdf</div>
              <div className="actionButtons"></div>
            </div>

            <Tabs
              variant="unstyled"
              style={{ flexGrow: 1, backgroundColor: "white" }}
              maxH={"calc(100% - 75px - 65px)"}>
              <TabList bg="white" borderBottom={"1px solid var(--borders)"}>
                <Tab>Display</Tab>
                <Tab>Style</Tab>
                <Tab>Categories</Tab>
              </TabList>

              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="var(--brand)"
                borderRadius="1px"
              />

              <TabPanels h={"100%"} overflowY={"auto"} overflowX={"hidden"}>
                

                <Display
                  selectedLanguage={selectedLanguage}
                  setSelectedLanguage={setSelectedLanguage}
                  allLanguages={allLanguages}
                  setHasChanges={setHasChanges}
                  showDescriptions={showDescriptions}
                  setShowDescriptions={setShowDescriptions}
                  showAllergens={showAllergens}
                  setShowAllergens={setShowAllergens}
                  showItemSeparator={showItemSeparator}
                  setShowItemSeparator={setShowItemSeparator}
                  showTags={showTags}
                  setShowTags={setShowTags}
                />  

                <Style 
                  settings={settings}
                  printSettings={printSettings}
                  setPrintSettings={setPrintSettings}
                  setHasChanges={setHasChanges}
                />


                <Categories
                  categoriesState={categoriesState}
                  setCategoriesState={setCategoriesState}
                  setHasChanges={setHasChanges}
                />

                
              </TabPanels>
            </Tabs>

            {!isIPad &&
            <Box p={4} bg={"white"} borderTop={"1px solid var(--borders)"}>
              {isMobile ? (
                <Button
                  size="lg"
                  onClick={openPdfInNewTab}
                  w={"100%"}
                  isLoading={isGenerating}>
                  Generate PDF
                </Button>
              ) : (
                <Button
                  size="lg"
                  onClick={handlePreviewUpdate}
                  w={"100%"}
                  isDisabled={!hasChanges}
                  isLoading={isGenerating}>
                  Update PDF
                </Button>
              )}
            </Box>
            }

            {isIPad &&
            <Flex p={4} bg={"white"} borderTop={"1px solid var(--borders)"} gap={2}>
                <Button
                  size="lg"
                  onClick={handlePreviewUpdate}
                  w={"100%"}
                  isDisabled={!hasChanges}
                  isLoading={isGenerating}>
                  Update Preview
                </Button>
                <Button
                  size="lg"
                  onClick={openPdfInNewTab}
                  w={"100%"}
                  isLoading={isGenerating}>
                  Generate PDF
                </Button>
            </Flex>
          }
          </form>
        </Flex>

        {(!isMobile || !isIPad) && (
          <Flex flex={1}>
            {isGenerating ? (
              <Flex
                w={"100%"}
                h={"100%"}
                justifyContent={"center"}
                alignItems={"center"}>
                <Spinner size="lg" />
              </Flex>
            ) : (
              previewUrl && (
                <iframe
                  src={previewUrl}
                  title="PDF Preview"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "1px solid var(--borders)",
                  }}
                />
              )
            )}
          </Flex>
        )}
      </Flex>
    </Modal>
  );
};

export default PdfSettingsModal;
