import React, { useRef } from "react";
import { db, storage } from "../../../firebaseConfig";
import { doc, updateDoc, FieldValue } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { Button, Flex, Text, Image } from "@chakra-ui/react";
import { useAppContext } from "../../../contexts/AppContext";

const MenusSettings = ({ onChange, onFocus, onBlur }) => {
  const { selectedRestaurantId, settings, setSettings, userRole } =
    useAppContext();

    const fileInputRef = useRef(null);

    const handleFileChange = async (e, logoType = "shop") => {
      const file = e.target.files[0];
      if (file) {
        try {
          const fileName = `${selectedRestaurantId}/${logoType}/${file.name}`;
          const storageRef = ref(storage, `logos/${fileName}`);
          const uploadResult = await uploadBytes(storageRef, file);
          const logoUrl = await getDownloadURL(uploadResult.ref);
  
          setSettings((prevSettings) => {
            const newSettings = {
              ...prevSettings,
              logo: {
                ...(prevSettings.logo || {}),
                [logoType]: logoUrl,
              },
            };
  
            const settingsDocRef = doc(
              db,
              `restaurants/${selectedRestaurantId}/settings`,
              "config"
            );
            updateDoc(settingsDocRef, { [`logo.${logoType}`]: logoUrl }).catch(
              console.error
            );
  
            return newSettings;
          });
        } catch (error) {
          console.error("Error uploading file:", error);
        }
      }
    };

    const handleBackgroundImageChange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          const storageRef = ref(
            storage,
            `${selectedRestaurantId}/backgroundImages/${file.name}`
          );
          const uploadResult = await uploadBytes(storageRef, file);
          const imageUrl = await getDownloadURL(uploadResult.ref);
  
          const updatedSettings = { ...settings, backgroundImage: imageUrl };
          setSettings(updatedSettings);
  
          // Sauvegarder les nouveaux paramètres dans Firestore
          const settingsDocRef = doc(
            db,
            `restaurants/${selectedRestaurantId}/settings`,
            "config"
          );
          await updateDoc(settingsDocRef, updatedSettings);
        } catch (error) {
          console.error("Error uploading background image:", error);
        }
      }
    };
  
    const handleDeleteLogo = async (logoType) => {
      const logoUrl = settings.logo?.[logoType];
      if (!logoUrl) return;
  
      try {
        const logoRef = ref(storage, logoUrl);
        await deleteObject(logoRef);
  
        setSettings((prevSettings) => ({
          ...prevSettings,
          logo: {
            ...prevSettings.logo,
            [logoType]: "", // Mettez simplement la clé correspondante à une chaîne vide
          },
        }));
  
        const settingsDocRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/settings`,
          "config"
        );
        await updateDoc(settingsDocRef, {
          [`logo.${logoType}`]: FieldValue.delete(), // Utilisez FieldValue.delete() pour supprimer la clé spécifique
        });
      } catch (error) {
        console.error(`Error deleting ${logoType} logo:`, error);
      }
    };
  
    const handleDeleteBackgroundImage = async () => {
      if (!settings.backgroundImage) return;
  
      try {
        const backgroundImageRef = ref(storage, settings.backgroundImage);
        await deleteObject(backgroundImageRef);
        console.log("Background image deleted successfully from Storage");
  
        const updatedSettings = { ...settings, backgroundImage: "" };
        setSettings(updatedSettings);
        const settingsDocRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/settings`,
          "config"
        );
        await updateDoc(settingsDocRef, updatedSettings);
      } catch (error) {
        console.error("Error deleting background image:", error);
      }
    };
  
    // Fonction pour gérer le clic sur le bouton 'Replace File'
    const handleReplaceFileClick = () => {
      fileInputRef.current.click();
    };

  return (
    <Flex
      direction="column"
      flexGrow="1"
      minW="0"
      gap={{ desktop: 8, mobile: 8 }}
      pt={8}
      sx={{
        ".settingsField:last-of-type": {
          borderBottom: "none",
        },
      }}>
 
      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Restaurant logo</h6>
          <Text maxW={"320px"}>Upload a logo for your online menu.</Text>
        </Flex>
        <Flex flex={1} gap={8} alignItems={"center"}>
          {settings.logo.menu && (
            <Flex
              width={"92px"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"92px"}
              borderRadius={8}
              p={4}
              style={{
                backgroundColor: settings.backgroundColor,
              }}>
              <Image
                src={settings.logo.menu}
                alt="Menu Logo preview"
                objectFit={"contain"}
              />
            </Flex>
          )}

          <Flex gap={2}>
            <Button onClick={handleReplaceFileClick} variant={"outline"}>
              {settings.logo.menu ? "Replace logo" : "Upload logo"}
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => handleFileChange(e, "menu")}
            />
            {settings.logo.menu && (
              <Button
                variant={"outline"}
                color={"var(--error)"}
                onClick={() => handleDeleteLogo("menu")}>
                {" "}
                Delete logo
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Brand color</h6>
          <Text maxW={"320px"}>Enter the hex code of your brand color.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <div className="color-input">
            <div
              className="color-preview"
              style={{
                backgroundColor: settings.brandColor,
              }}></div>
            <input
              type="text"
              name="brandColor"
              value={settings.brandColor}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              placeholder="#FFFFFF"
            />
          </div>
        </Flex>
      </Flex>

      <Flex
        className="settingsField"
            direction={{ desktop: "row", mobile: "column" }}
            gap={4}
            borderBottom={"1px solid var(--borders)"}
            pb={8}>
            <Flex
              direction={"column"}
              w={{ desktop: "40%", mobile: "100%" }}
              gap={1}>
              <h6>Background image</h6>
              <Text maxW={"320px"}>
                Upload a background image for your online menu. Leave it blank
                if you want to use a plain color.
              </Text>
            </Flex>
            <Flex flex={1} gap={8} alignItems={"center"}>
              {settings.backgroundImage && (
                <Flex
                  aspectRatio={"16/9"}
                  height={"92px"}
                  borderRadius={8}
                  overflow={"hidden"}
                  style={{
                    backgroundColor: settings.backgroundColor,
                  }}>
                  <Image
                    src={settings.backgroundImage}
                    alt="Background preview"
                    w={"100%"}
                    height={"100%"}
                    objectPosition={"center"}
                    objectFit={"cover"}
                  />
                </Flex>
              )}

              <Flex gap={2}>
                <Button onClick={handleReplaceFileClick} variant={"outline"}>
                  {settings.backgroundImage ? "Replace image" : "Upload image"}
                </Button>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  onChange={handleBackgroundImageChange}
                />
                {settings.backgroundImage && (
                  <Button
                    variant={"outline"}
                    color={"var(--error)"}
                    onClick={handleDeleteBackgroundImage}>
                    Delete image
                  </Button>
                )}
              </Flex>
            </Flex>
          </Flex>

          
      {userRole === "superAdmin" && (
        <>
          <Flex
            className="settingsField"
            direction={{ desktop: "row", mobile: "column" }}
            gap={4}
            borderBottom={"1px solid var(--borders)"}
            pb={8}>
            <Flex
              direction={"column"}
              w={{ desktop: "40%", mobile: "100%" }}
              gap={1}>
              <h6>Background color</h6>
              <Text maxW={"320px"}>
                Enter the hex code of your online menu background.
              </Text>
            </Flex>
            <Flex direction="column" gap={2} flex={1}>
              <div className="color-input">
                <div
                  className="color-preview"
                  style={{
                    backgroundColor: settings.backgroundColor,
                  }}></div>
                <input
                  type="text"
                  name="backgroundColor"
                  value={settings.backgroundColor}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder="#FFFFFF"
                />
              </div>
            </Flex>
          </Flex>

          

          <Flex
            className="settingsField"
            direction={{ desktop: "row", mobile: "column" }}
            gap={4}
            borderBottom={"1px solid var(--borders)"}
            pb={8}>
            <Flex
              direction={"column"}
              w={{ desktop: "40%", mobile: "100%" }}
              gap={1}>
              <h6>Text color</h6>
              <Text maxW={"320px"}>
                Enter the hex code of your online menu text.
              </Text>
            </Flex>
            <Flex direction="column" gap={2} flex={1}>
              <div className="color-input">
                <div
                  className="color-preview"
                  style={{
                    backgroundColor: settings.textColor,
                  }}></div>
                <input
                  type="text"
                  name="textColor"
                  value={settings.textcolor}
                  onChange={onChange}
                  onFocus={onFocus}
                  onBlur={onBlur}
                  placeholder="#FFFFFF"
                />
              </div>
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default MenusSettings;
