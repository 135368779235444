import { Flex, Skeleton } from "@chakra-ui/react";


function SkeletonLayout() {
  

  return (
    

        <Flex flex={1} direction="column" gap={4}>
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
          <Skeleton h={"32px"} borderRadius={8} />
        </Flex>
       

  );
}
export default SkeletonLayout;
