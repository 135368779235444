import { Flex, Text, Table, Thead, Tr, Th, Td, Tbody } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useAppContext } from "../../contexts/AppContext";

const CouponUsageLog = ({ usageLogs }) => {
        
const { selectedRestaurantId } = useAppContext();

  return (
    <Flex
                    direction="column"
                    p={8}
                    gap={0}
                    flex={1}
                    w={"100%"}
                    overflowY={"auto"}
                    style={{ scrollbarWidth: "none" }}>
        
        {usageLogs.length > 0 ? (
                  <Flex className="tableWrapper" flex={1}>
                  <Table w="100%">
                    <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                      <Tr className="reservationHeader">
                        <Th>User</Th>
                        <Th>Order</Th>
                        <Th>Date</Th>                      
                      </Tr>
                    </Thead>
                    <Tbody fontSize="14px">
                        
                    {usageLogs.map((log, index) => (
                      <Tr key={index}>
                        <Td fontWeight={"500"}>{log.userEmail}</Td>
                        <Td><Link to={`${selectedRestaurantId}/shop/${log.orderId}`}>Order #{log.orderId}</Link></Td>
                        <Td>{new Date(log.usedAt.seconds * 1000).toLocaleString('en-GB', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
})}</Td>

                    </Tr>   
                    ))}
                    </Tbody>
                </Table>
                </Flex>
                ) : (
                  <Text>No usage logs found for this coupon.</Text>
                )}
        
    </Flex>
  );
};

export default CouponUsageLog;
