import React, { useRef } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Button } from '@chakra-ui/react';

const OrderCompletedDialog = ({ isOpen, onClose, onConfirm }) => {
  const cancelRef = useRef();

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay  zIndex={2000}>
        <AlertDialogContent containerProps={{ zIndex: '2001' }} px={8} py={8}>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" p={0}>
            Confirm payment received?
          </AlertDialogHeader>

          <AlertDialogBody py={4} px={0}>
            This order is marked as unpaid. By marking it as completed, you confirm that the client has paid. Do you want to proceed?
          </AlertDialogBody>

          <AlertDialogFooter p={0}>
            <Button ref={cancelRef} onClick={onClose} variant={"outline"}>
              Cancel
            </Button>
            <Button colorScheme="brand" onClick={onConfirm} ml={3}>
              Confirm
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default OrderCompletedDialog;
