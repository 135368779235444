import { useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth } from "firebase/auth";
import { useAppContext } from '../contexts/AppContext';
import { toast } from "react-toastify";

const useStripe = () => {
  
  const { selectedRestaurantId, stripeKeys, setSettings } = useAppContext();

  const [stripeModalIsOpen, setStripeModalIsOpen] = useState(null);
  const openStripeModal = () => {
    setStripeModalIsOpen(true);
  };
  const closeStripeModal = () => {
    setStripeModalIsOpen(false);
  };
  

  const handleSaveStripeKeys = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
  
    if (!user) {
      console.error("User is not authenticated.");
      toast.error("You must be logged in to perform this action.");
      return;
    }
  
    // Vérifiez que selectedRestaurantId est bien défini
    if (!selectedRestaurantId) {
      console.error("Selected Restaurant ID is missing.");
      toast.error("Restaurant ID is missing.");
      return;
    }
  
    try {
      const functions = getFunctions();
      const saveStripeKeys = httpsCallable(functions, "saveStripeKeys");
  
      const { PublicLive, SecretLive, PublicTest, SecretTest } = stripeKeys;
  
      await saveStripeKeys({
        selectedRestaurantId,
        PublicLive,
        SecretLive,
        PublicTest,
        SecretTest,
      });
  
      console.log("Stripe keys saved successfully.");
      toast.success("Stripe keys saved successfully");
      setSettings((prevSettings) => ({
        ...prevSettings,
        shopSettings: {
          ...prevSettings.shopSettings,
          paymentMethods: {
            ...prevSettings.shopSettings.paymentMethods,
            online: {
              ...prevSettings.shopSettings.paymentMethods.online,
              activation: true, // Active Stripe après la sauvegarde réussie
            },
          },
        },
      }));
      closeStripeModal();
    } catch (error) {
      console.error("Error saving Stripe keys:", error);
      toast.error("Error saving Stripe keys");
    }
  };
  
  
  
  
  
  
  return {  
    openStripeModal, 
    closeStripeModal, 
    handleSaveStripeKeys,
    stripeModalIsOpen,
    setStripeModalIsOpen
  };
};

export default useStripe;