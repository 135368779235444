
import { useEffect } from 'react';
import { Flex, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Input } from '@chakra-ui/react';
import Select from 'react-select';


const EditTable = ({ id, name, isOpen, onClose, updatedName, setUpdatedName, updatedCapacity, setUpdatedCapacity, updateTable, updateTableCombinations, updatedShape, setUpdatedShape, selectedCombinations, setSelectedCombinations, tables  }) => {
    
    useEffect(() => {
        // Mettre à jour les selectedCombinations à partir des tables chargées
        const currentTable = tables.find(t => t.id === id);
        const updatedSelectedCombinations = currentTable ? currentTable.combination.map(cid => {
            const table = tables.find(t => t.id === cid);
            return table ? { value: table.id, label: `Table ${table.id}` } : undefined;
        }).filter(opt => opt !== undefined) : [];
        setSelectedCombinations(updatedSelectedCombinations);
    }, [tables, id, isOpen])

    
    const handleUpdate = () => {
        updateTable( id, updatedName, updatedCapacity, updatedShape);
        updateTableCombinations(id, selectedCombinations.map(opt => opt.value));
        onClose();
    };

    const options = tables.filter(table => table.id !== id).map(table => ({
        value: table.id,
        label: `Table ${table.name}`
    }));

    const selectedCombinationOptions = selectedCombinations.map(combination => {
        const table = tables.find(t => t.id === combination.value);
        return table ? { value: table.id, label: `Table ${table.name}` } : undefined;
    }).filter(option => option !== undefined);  // Filtrer les valeurs undefined pour éviter les erreurs
    


    const handleCombinationChange = (selectedOptions) => {
        setSelectedCombinations(selectedOptions || []);
    };
    


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Edit Table #{id}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Flex direction="column" gap={4}>
                            <Flex className='field'>
                            <label>Table ID</label>
                            <Input type="text" value={updatedName} onChange={(e) => setUpdatedName(e.target.value)} />
                            </Flex>
                            <Flex className='field'>
                            <label>Capacity</label>
                            <Input type="number" value={updatedCapacity} onChange={(e) => setUpdatedCapacity(e.target.value)} />
                            </Flex>
                            <Flex className='field'>
                            <label>Shape</label>
                        <select value={updatedShape} onChange={(e) => setUpdatedShape(e.target.value)}>
                            <option value="rectangle">Rectangle</option>
                            <option value="round">Round</option>
                        </select>
                        </Flex>
                        <Flex className='field'>
                        <label>Combinations</label>
                        <Select
                            isMulti
                            value={selectedCombinationOptions}
                            onChange={handleCombinationChange}
                            options={options}
                            classNamePrefix="select"
                        />
                        </Flex>
                        </Flex>
                    </ModalBody>
                    <ModalFooter display="flex" gap={2} flex={1}>
                        <Button className="btn secondary" flex={1} onClick={onClose}>Cancel</Button>
                        <Button className='btn primary'flex={1} onClick={handleUpdate}>
                            Save
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
  );
};

export default EditTable;
