// StockContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs, query, writeBatch, doc, updateDoc, getDoc, onSnapshot } from 'firebase/firestore';
import { useAppContext } from './AppContext';

const StockContext = createContext();

export const useStockContext = () => useContext(StockContext);

export const StockProvider = ({ children }) => {
  
  const {selectedRestaurantId, mainCategoryName, mainLanguage, settings } = useAppContext();
  const [searchStockItem, setSearchStockItem] = useState('');
  
  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState({ id: '', name: '' });
  const [stockItems, setStockItems] = useState([]);

  const modules = settings?.modules;
  
  const fetchAndSyncStockItems = useCallback(() => {
    if (!selectedRestaurantId || !mainCategoryName || !modules.ratio) return;
  
    // Écouter les changements dans la collection /items en temps réel
    const unsubscribeItems = onSnapshot(
      collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`),
      (itemsSnapshot) => {
        const menuItems = itemsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
        // Synchroniser avec /stock
        syncItemsWithStock(menuItems).then(() => {
          // Écouter les changements dans la collection /stock
          const unsubscribeStock = onSnapshot(
            collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`),
            (stockSnapshot) => {
              const stockItemsData = stockSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
              setStockItems(stockItemsData);
            }
          );
  
          // Cleanup listener de /stock lors du démontage
          return () => unsubscribeStock();
        });
      }
    );
  
    // Cleanup listener de /items lors du démontage
    return () => unsubscribeItems();
  }, [selectedRestaurantId, mainCategoryName]);

  
  // Fonction pour synchroniser /items avec /stock
  // Fonction pour synchroniser /items avec /stock
const syncItemsWithStock = useCallback(async (menuItems) => {
  if (!selectedRestaurantId || !mainCategoryName) return;

  const stockSnapshot = await getDocs(
    collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`)
  );
  const stockItems = stockSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const batch = writeBatch(db);

  // Créer un ensemble d'IDs valides pour les items et variations basés sur les items du menu
  const validStockIds = new Set();

  // Synchroniser les items du menu avec le stock
  menuItems.forEach((menuItem) => {
    // Ne synchroniser que les items avec un ID
    if (!menuItem.id) {
      console.log("Skipping item without ID:", menuItem);
      return; // Passer cet item
    }

    if (menuItem.variations && menuItem.variations.length > 0) {
      // Pour les items variables, créer une entrée dans le stock pour chaque variation
      menuItem.variations.forEach((variation) => {
        const variationId = `${menuItem.id}-${variation.name}`;
        validStockIds.add(variationId); // Marquer cette variation comme valide

        const existsInStock = stockItems.find((stockItem) => stockItem.id === variationId);

        const stockData = {
          id: variationId, // Use the variation's ID
            name_en: `${menuItem.name_en} ${variation.name}` || '',
            name_fr: `${menuItem.name_fr} ${variation.name}` || '',
            name_lb: `${menuItem.name_lb} ${variation.name}` || '',
            name_de: `${menuItem.name_de} ${variation.name}` || '',
            name_it: `${menuItem.name_it} ${variation.name}` || '',
            name_pt: `${menuItem.name_pt} ${variation.name}` || '',
            description_en: menuItem.description_en || '',
            description_fr: menuItem.description_fr || '',
            description_it: menuItem.description_it || '',
            description_lb: menuItem.description_lb || '',
            description_de: menuItem.description_de || '',
            description_pt: menuItem.description_pt || '',
            price: variation.price || '',
            vat: menuItem.vat || '',
            content: '',
            cost: '',
            AM: '',
        };

        if (!existsInStock) {
          const stockRef = doc(
            db,
            `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`,
            variationId
          );
          batch.set(stockRef, {
            ...stockData,
            createdAt: new Date(),
          });
        }
      });
    } else {
      // Pour les items simples, créer une entrée avec l'ID de l'item
      validStockIds.add(menuItem.id); // Marquer cet item comme valide

      const existsInStock = stockItems.find((stockItem) => stockItem.id === menuItem.id);

      const stockData = {
          id: menuItem.id, // Use the menu item's ID
          name_en: menuItem.name_en || '',
          name_fr: menuItem.name_fr || '',
          name_it: menuItem.name_it || '',
          name_lb: menuItem.name_lb || '',
          name_de: menuItem.name_de || '',
          name_pt: menuItem.name_pt || '',
          description_en: menuItem.description_en || '',
          description_fr: menuItem.description_fr || '',
          description_it: menuItem.description_it || '',
          description_lb: menuItem.description_lb || '',
          description_de: menuItem.description_de || '',
          description_pt: menuItem.description_pt || '',
          price: menuItem.price || '',
          vat: menuItem.vat || '',
          content: '',
          cost: '',
          AM: '',
      };

      if (!existsInStock) {
        const stockRef = doc(
          db,
          `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`,
          menuItem.id
        );
        batch.set(stockRef, {
          ...stockData,
          createdAt: new Date(),
        });
      }
    }
  });

  // Supprimer toute entrée de stock qui n'est plus valide
  stockItems.forEach((stockItem) => {
    if (!validStockIds.has(stockItem.id)) {
      const stockRef = doc(
        db,
        `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/stock`,
        stockItem.id
      );
      batch.delete(stockRef); // Marquer cette entrée de stock pour suppression
      console.log(`Deleting orphaned stock item with ID: ${stockItem.id}`);
    }
  });

  await batch.commit(); // Appliquer tous les changements en une seule opération
}, [selectedRestaurantId, mainCategoryName]);

  

  useEffect(() => {
    fetchAndSyncStockItems();
  }, [fetchAndSyncStockItems]);

  const filteredStockItems = stockItems
  .filter((item) => {
    const itemName = item[`name_${mainLanguage}`];  // Récupérer le nom de l'item pour la langue principale
    return itemName ? itemName.toLowerCase().includes(searchStockItem.toLowerCase()) : false;
  })
  .sort((a, b) => {
    const nameA = a[`name_${mainLanguage}`] || '';
    const nameB = b[`name_${mainLanguage}`] || '';
    // Comparaison alphabétique des noms
    return nameA.localeCompare(nameB);
  });

  
  
  


  
 

  

  const updateItemPriceInItemsCollection = async (itemId, formattedPrice) => {
    try {
      if (itemId.includes('-')) {
        const [baseItemId, variationName] = itemId.split('-');
    
        const baseItemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, baseItemId);
        const baseItemDoc = await getDoc(baseItemRef);
    
        if (baseItemDoc.exists()) {
          const baseItemData = baseItemDoc.data();
    
          const updatedVariations = baseItemData.variations.map((variation) => {
            if (variation.name === variationName) {
              // Mettre à jour le prix de la variation spécifique
              return { ...variation, price: formattedPrice };  // Stocker la chaîne avec virgule
            }
            return variation;
          });
    
          await updateDoc(baseItemRef, {
            variations: updatedVariations,
          });
        }
      } else {
        // Mettre à jour l'élément sans variation
        const itemRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/items`, itemId);
    
        await updateDoc(itemRef, {
          price: formattedPrice,  // Stocker la chaîne avec virgule
        });
      }
  
      console.log(`Price updated successfully for item: ${itemId}`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du prix dans items collection:", error);
    }
  };
  
  
  
  


  const value = {
    filteredStockItems,
    searchStockItem, setSearchStockItem,
    supplier, setSupplier,
    suppliers, setSuppliers,
    updateItemPriceInItemsCollection
   
  };

  return (
    <StockContext.Provider value={value}>
      {children}
    </StockContext.Provider>
  );
};
