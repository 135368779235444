import React, { useState, useEffect } from "react";
import { chakra, Flex, Select, Text, Box } from "@chakra-ui/react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from "recharts";
import useOrders from "../../hooks/useOrders";
import { useAppContext } from "../../contexts/AppContext";


const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Flex
        direction="column"
        bg="var(--grey700)"
        color="white"
        p={2}
        borderRadius={4}>
        {payload.map((entry, index) => (
          <Box key={index} mb={index < payload.length - 1 ? 1 : 0}>
            {entry.dataKey === "Income"
              ? `${entry.value.toFixed(2).replace(".", ",")}€` // Garder les deux décimales
              : `${entry.value} orders`}
          </Box>
        ))}
      </Flex>
    );
  }

  return null;
};

const OrdersAnalytics = () => {
  const { settings, selectedRestaurantId } = useAppContext(); // Récupérer selectedRestaurantId
  const { orders, loadOrders, isLoading } = useOrders();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthlyData, setMonthlyData] = useState([]);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalIncome, setTotalIncome] = useState("0,00");

  // Charger les commandes lorsque selectedRestaurantId et settings sont disponibles
  useEffect(() => {
    if (!selectedRestaurantId || !settings?.modules?.shop) {
      return;
    }
    loadOrders(true);
  }, [selectedRestaurantId, settings]);

  // Calculer les données lorsque les commandes changent ou que l'année sélectionnée change
  useEffect(() => {
    if (isLoading || !orders || orders.length === 0) {
      return;
    }
  
    const calculateMonthlyData = () => {
      const income = Array(12).fill(0);
      const orderCounts = Array(12).fill(0);
      let yearlyIncome = 0;
      let yearlyOrders = 0;
  
      orders.forEach((order) => {
        try {
          if (order.status === "completed") {
            let orderDate;
  
            if (order.createdAt instanceof Date) {
              orderDate = order.createdAt; // Si c'est déjà un objet Date
            } else if (order.createdAt && typeof order.createdAt.toDate === 'function') {
              orderDate = order.createdAt.toDate(); // Si c'est un Timestamp Firebase
            } else {
              console.warn("Invalid createdAt:", order);
              return; // Sauter cette commande si createdAt est invalide
            }
  
            const orderYear = orderDate.getFullYear();
  
            if (orderYear === selectedYear) {
              const month = orderDate.getMonth();
              const orderIncome = parseFloat(order.subTotal.replace(",", "."));
  
              income[month] += orderIncome;
              orderCounts[month] += 1;
  
              yearlyIncome += orderIncome;
              yearlyOrders += 1;
            }
          }
        } catch (error) {
          console.error("Error processing order:", error, order);
        }
      });
  
      const formattedData = income.map((value, index) => ({
        month: new Date(0, index).toLocaleString("en-US", { month: "short" }),
        Income: parseFloat(value.toFixed(2)), // Utiliser deux décimales sans arrondir prématurément
        Orders: orderCounts[index],
      }));
  
      setMonthlyData(formattedData);
      setTotalIncome(yearlyIncome.toFixed(2).replace(".", ","));
      setTotalOrders(yearlyOrders);
    };
  
    calculateMonthlyData();
  }, [orders, selectedYear, isLoading]);
  

  // Si selectedRestaurantId ou le module de boutique ne sont pas disponibles, afficher un message d'attente
  if (!selectedRestaurantId || !settings?.modules?.shop) {
    return <Text>Loading restaurant data...</Text>;
  }

  // Si les commandes sont en cours de chargement, afficher un indicateur de chargement
  if (isLoading) {
    return <Text>Loading orders...</Text>;
  }

  // Années disponibles à partir des commandes
  const years = Array.from(
    new Set(
      orders.map((order) => new Date(order.createdAt).getFullYear())
    )
  ).sort((a, b) => a - b);


  return (
    <Flex direction="column" flex={1} gap={4}>
      {settings.modules?.shop ? (
        <>
          <Flex justifyContent="space-between" alignItems="flex-start">
            <h5>Orders</h5>
            <Select
              className="yearSelector"
              size={"sm"}
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              width="auto"
              border={"1px solid var(--borders)"}
              borderRadius={4}
              boxShadow={"none"}
              color={"var(--grey700)"}
              fontWeight={"600"}>
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </Select>
          </Flex>

          {monthlyData.length > 0 ? (
            <>
              <Flex direction={"row"} gap={4}>
                <Flex gap={1}>
                  <Text>Completed orders</Text>
                  <Text fontWeight={"600"} color={"var(--grey700)"}>
                    {totalOrders}
                  </Text>
                </Flex>
                <Flex gap={1}>
                  <Text>Yearly income</Text>
                  <Text fontWeight={"600"} color={"var(--grey700)"}>
                    {totalIncome}€ <chakra.span fontWeight={"400"} fontSize={12}>(excl. VAT)</chakra.span>
                  </Text>
                </Flex>
              </Flex>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={monthlyData}>
                  <CartesianGrid vertical={false} stroke="var(--borders)" />
                  <XAxis dataKey="month" />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Bar
                    yAxisId="left"
                    dataKey="Orders"
                    barSize={20}
                    fill="var(--brand100)"
                  />
                  <Line
                    yAxisId="right"
                    type="monotone"
                    dataKey="Income"
                    stroke="var(--brand)"
                    strokeWidth={2}
                  />
                </ComposedChart>
              </ResponsiveContainer>
            </>
          ) : (
            <Text>No data available for the selected year.</Text>
          )}
        </>
      ) : (
        <Text>The module is not activated for your restaurant.</Text>
      )}
    </Flex>
  );
};

export default OrdersAnalytics;
