import { useNavigate } from 'react-router-dom';
import { Flex, Text, Image, Box } from "@chakra-ui/react";
import { useAppContext } from '../../contexts/AppContext';
import useOrders from '../../hooks/useOrders';

const BestSellers = () => {
  
  const navigate = useNavigate();
  const { selectedRestaurantId,mainLanguage } = useAppContext();
  const { orders } = useOrders();

 
  
  const findTopFiveBestSellers = (orders, mainLanguage) => {
    const productCounts = {};
  
    // Filtrer les commandes pour ne conserver que celles qui sont "completed"
    const completedOrders = orders.filter(order => order.status === "completed");
  
    completedOrders.forEach((order) => {
      order.cartItems.forEach((item) => {
        const itemId = item.itemId;
        if (productCounts[itemId]) {
          productCounts[itemId].count += item.quantity;
        } else {
          productCounts[itemId] = {
            name: item[`name_${mainLanguage}`],
            count: item.quantity,
            img: item.photo,
          };
        }
      });
    });
  
    const sortedProducts = Object.entries(productCounts)
      .map(([itemId, { name, count, img }]) => ({ itemId, name, count, img }))
      .sort((a, b) => b.count - a.count) // Trier par ordre décroissant de count
      .slice(0, 5); // Prendre les 5 premiers
  
    return sortedProducts;
  };
    
  const topFiveBestSellers = findTopFiveBestSellers(orders, mainLanguage);

  

  return (  
    <Flex maxW="320px" w={"100%"} flex={"1 1 auto"} direction="column" gap={8} borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none"}} pl={{ desktop: 8, mobile: 0 }}>
      <h6>Best sellers</h6>
      {topFiveBestSellers.length > 0 ? (
        <Flex gap={4} direction={"column"}>
          {topFiveBestSellers.map((product) => (
            <Flex 
              key={product.itemId}
              alignItems={"center"}
              gap={4}
              >
              <Box h={"42px"} w={"42px"} borderRadius={4} bg={"var(--grey100)"} overflow={"hidden"}>
                {product.img && (
                  <Image
                    src={product.img} 
                    alt={product.name}
                    w="100%"
                    height={"100%"}
                    objectFit={"cover"} 
                  />
                )}
              </Box>
            
              <Flex direction={"column"}>
                <Text fontWeight={"600"} color={"var(--grey700)"}>{product.name}</Text>
                <Text fontSize={"13px"}>{product.count} sold</Text>
              </Flex>
            </Flex>
          ))}
        </Flex>
      ) : (
        <Text>No sales data available.</Text>
      )}
    </Flex>
  );
};

export default BestSellers;
