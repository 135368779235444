import { useState, useEffect } from "react";
import { db } from '../firebaseConfig';
import { collection, doc, addDoc, updateDoc, getDocs, deleteDoc } from 'firebase/firestore';
import { useAppContext } from "../contexts/AppContext";
import { toast } from 'react-toastify';


const useExtras = () => {

    const { selectedRestaurantId, mainCategoryName } = useAppContext();
    const [extrasGroups, setExtrasGroups] = useState([]);
    const [currentExtrasGroup, setCurrentExtrasGroup] = useState(null);
    const [groupDetails, setGroupDetails] = useState({ name: '', items: [] });
    const [newExtrasGroup, setNewExtrasGroup] = useState({ name: '', items: [], applicableTo: '', selectedOptions: [] });
    const [extraModalIsOpen, setExtraModalIsOpen] = useState(false);
    const [applicableTo, setApplicableTo] = useState('categories');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [ isEditing, setIsEditing ] = useState(null);

    
    useEffect(() => {
        const fetchExtrasGroups = async () => {
            const querySnapshot = await getDocs(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/extras`));
            const loadedExtrasGroups = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            setExtrasGroups(loadedExtrasGroups);
        };

        fetchExtrasGroups();
    });

    
    const openExtrasModal = (extrasGroup) => {
        if (extrasGroup) {
            setCurrentExtrasGroup(extrasGroup);
            setGroupDetails({
                ...extrasGroup,
                selectionType: extrasGroup.selectionType || 'multiple',
            });
            setIsEditing(true);
        } else {
            setCurrentExtrasGroup(null);
            setGroupDetails({
                name: '',
                items: [],
                applicableTo: 'categories',
                applicableToOptions: [],
                selectionType: 'multiple',
            });
            setApplicableTo('categories');
            setSelectedOptions([]);
            setIsEditing(false);
        }
        setExtraModalIsOpen(true);
    };
    
        const closeExtrasGroupModal = (extrasGroup) => {
        setCurrentExtrasGroup(''); 
        setExtraModalIsOpen(false); 
        };


        const saveExtrasGroup = async (groupToSave, groupId = null) => {
            let groupRef;
        
            const cleanedGroupToSave = {
                ...groupToSave,
                items: groupToSave.items?.filter(item => item && Object.keys(item).every(key => item[key] !== undefined)) || [],
                selectionType: groupToSave.selectionType || 'multiple',
            };
        
            try {
                if (groupId) {
                    groupRef = doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/extras`, groupId);
                    await updateDoc(groupRef, cleanedGroupToSave);
                } else {
                    groupRef = await addDoc(collection(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/extras`), cleanedGroupToSave);
                    // Mettre à jour l'ID dans l'objet après la création
                    cleanedGroupToSave.id = groupRef.id;
                }
        
                // Mise à jour de l'état après l'enregistrement
                if (groupId) {
                    setExtrasGroups(prevGroups => prevGroups.map(group => group.id === groupId ? { ...group, ...cleanedGroupToSave } : group));
                } else {
                    setExtrasGroups(prevGroups => [...prevGroups, cleanedGroupToSave]);
                }
        
                closeExtrasGroupModal();
                toast.success('Extras group saved');
            } catch (error) {
                console.error("Error saving extras group:", error);
                toast.error('Error saving extras group');
            }
        };
        

        const deleteExtrasGroup = async (groupId) => {
            try {
                await deleteDoc(doc(db, `restaurants/${selectedRestaurantId}/menu/${mainCategoryName}/extras`, groupId));
                setExtrasGroups(prevGroups => prevGroups.filter(group => group.id !== groupId));
                closeExtrasGroupModal();
                toast.success('Extras group deleted successfully');
            } catch (error) {
                console.error("Error deleting extras group:", error);
                toast.error('Error deleting extras group');
            }
        };
        
        
        
        
        
        
        
   


    return {      
        extrasGroups,
        setExtrasGroups,
        currentExtrasGroup,
        setCurrentExtrasGroup,
        groupDetails,
        setGroupDetails,
        newExtrasGroup,
        setNewExtrasGroup,
        extraModalIsOpen,
        setExtraModalIsOpen,
        closeExtrasGroupModal,
        applicableTo,
        setApplicableTo,
        selectedOptions,
        setSelectedOptions, 
        saveExtrasGroup,
        deleteExtrasGroup,
        openExtrasModal,
        isEditing,
        setIsEditing
    };
};

export default useExtras;