import { useNavigate } from 'react-router-dom';
import { Flex, Text } from "@chakra-ui/react";
import useCustomers from '../../hooks/useCustomers';
import { useAppContext } from '../../contexts/AppContext';
import TopCustomersSkeleton from './TopCustomersSkeleton';
import { useState, useEffect } from 'react';

const TopCustomers = () => {
  const { selectedRestaurantId } = useAppContext();
  const navigate = useNavigate();
  const { topCustomers, isLoadingCustomersKPI } = useCustomers();

  // Utilisation de cet état pour éviter de rendre des données avant que tout ne soit prêt
  const [dataReady, setDataReady] = useState(false);

  // Mettre à jour cet état une fois que le chargement est terminé
  useEffect(() => {
    if (!isLoadingCustomersKPI) {
      setDataReady(true);  // Indique que les données sont prêtes à être affichées
    }
  }, [isLoadingCustomersKPI]);

  const handleCustomerClick = (orderId) => {
    navigate(`/${selectedRestaurantId}/customers`, { state: { orderId } });
  };

  return (
    <Flex maxW="320px" w={"100%"} flex={"1 1 auto"} direction="column" gap={8} borderLeft={{ desktop: "1px solid var(--borders)", mobile: "none"}} pl={{ desktop: 8, mobile: 0 }}>
      <h6>Top customers</h6>

      {/* Affichage du skeleton pendant le chargement */}
      {!dataReady ? (
        <TopCustomersSkeleton />  // Affiche uniquement le Skeleton tant que les données ne sont pas prêtes
      ) : (
        <>
          {/* Si des clients existent, les afficher */}
          {topCustomers.length > 0 ? (
            <Flex gap={4} direction={"column"}>
              {topCustomers
                // Trier les clients par totalSpent en ordre décroissant
                .sort((a, b) => parseFloat(b.totalSpent) - parseFloat(a.totalSpent))
                .map((customer) => (
                  <Flex 
                    key={customer.id}
                    alignItems={"center"}
                    gap={4}
                  >
                    <Flex direction={"column"}>
                      <Text fontWeight={"600"} color={"var(--grey700)"}>
                        {customer.firstName} {customer.lastName}
                      </Text>
                      <Flex direction={"column"}>
                        <Text fontSize={"13px"}>{customer.totalSpent}€ spent in {customer.numberOfOrders} order(s)</Text>

                        <Text fontSize={"13px"}>{customer?.numberOfReservations?.finished} reservation(s)</Text>
                        </Flex>
                    </Flex>
                  </Flex>
              ))}
            </Flex>
          ) : (
            /* Afficher seulement après que tout est prêt et si aucune donnée client n'est présente */
            <Text>No customer data available.</Text>
          )}
        </>
      )}
    </Flex>
  );
};

export default TopCustomers;
