import React, { useState, useEffect } from 'react';
import { Flex, Select, Text } from "@chakra-ui/react";
import { BarChart, Bar, XAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import useReservations from '../../hooks/useReservations';
import { useAppContext } from '../../contexts/AppContext';

const ReservationsAnalytics = () => {
  const { settings } = useAppContext();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const [monthlyReservations, setMonthlyReservations] = useState([]);
  const [totalReservations, setTotalReservations] = useState(0);
  const { reservations, setReservations } = useReservations();

  useEffect(() => {
    if (!settings?.modules?.reservations) {
      if (reservations.length !== 0) { // Évite les mises à jour infinies si déjà vide
        setReservations([]); 
      }
      return;
    }

    const availableYears = Array.from(
      new Set(reservations.map(reservation => new Date(reservation.scheduledTime.toDate()).getFullYear()))
    );
    
    if (availableYears.length !== years.length) {
      setYears(availableYears); // Mettez à jour seulement si la liste des années change
    }

    const filteredReservations = reservations.filter(reservation => 
      new Date(reservation.scheduledTime.toDate()).getFullYear() === selectedYear
    );

    const reservationCounts = Array.from({ length: 12 }, () => ({
      finished: 0,
      noshow: 0,
      cancelled: 0,
    }));

    let total = 0;

    filteredReservations.forEach((reservation) => {
      const scheduledDate = new Date(reservation.scheduledTime.toDate());
      const month = scheduledDate.getMonth();
      const status = reservation.status.toLowerCase();

      if (reservationCounts[month][status] !== undefined) {
        reservationCounts[month][status] += 1;
        total += 1; 
      }
    });

    const formattedReservations = reservationCounts.map((counts, index) => ({
      month: new Date(0, index).toLocaleString('en-US', { month: 'short' }),
      Finished: counts.finished,
      NoShow: counts.noshow,
      Cancelled: counts.cancelled,
    }));

    setMonthlyReservations(formattedReservations);
    setTotalReservations(total); 

  }, [reservations, selectedYear, settings?.modules?.reservations, years]); // Ajout de "years" dans les dépendances

  return (  
    <Flex direction="column" flex={1}>
      {settings?.modules?.reservations ? (  // Safely check if the module exists
        <>
          <Flex justifyContent="space-between" alignItems="center" gap={4}>
            <h5>Reservations</h5>
            <Select
              className="yearSelector"
              size={"sm"}
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
              width="auto"
              border={"1px solid var(--borders)"}
              borderRadius={4}
              boxShadow={"none"}
              color={"var(--grey700)"}
              fontWeight={"600"}>
              {years.sort((a, b) => a - b).map((year) => ( // Sort the years
                <option key={year} value={year}>{year}</option>
              ))}
            </Select>
          </Flex>
          <Flex direction={"row"} gap={4} justifyContent={"center"}>
            <Flex gap={1}>
              <Text>Total reservations</Text>
              <Text fontWeight={"600"} color={"var(--grey700)"}>
                {totalReservations}
              </Text>
            </Flex>
          </Flex>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={monthlyReservations}>
              <CartesianGrid vertical={false} stroke='var(--borders)' />
              <XAxis dataKey="month" />
              <Tooltip/>
              <Legend />
              <Bar dataKey="Finished" stackId="a" fill="var(--success)" />
              <Bar dataKey="NoShow" stackId="a" fill="var(--grey700)" />
              <Bar dataKey="Cancelled" stackId="a" fill="var(--error)" />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <Flex justifyContent="space-between" alignItems="flex-start" gap={4} direction={"column"} flex={0}>
          <h5>Reservations</h5>
          <Text>The module is not activated for your restaurant.</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default ReservationsAnalytics;
