import { border, extendTheme } from "@chakra-ui/react";

const useCustomChakra = () => {
  
  const breakpoints = {
    "2xl": "1920px",
    desktop: "960px",
    mobile: "0px",
  };
  
  const colors = {
    brand: {
      50: '#FFF9F9',
      100: '#FDDDDC',
      200: '#FBC3C1',
      300: '#F9AAA6',
      400: '#f25c54',
      500: '#E65A53',
      600: '#D95851',
    },
  };

  // Extend Chakra components
  const components = {
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            bg: 'brand.400',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: '1px',
          borderRadius: '3px',
          _checked: {
            bg: 'brand.400',
            borderColor: 'brand.400',
            color: 'white', // Checkmark color
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
    },
    Textarea: {
      baseStyle: {
        height: "180px !important",
        fontSize: "14px",
        border: 'none',
        boxShadow: '0 0 0 1px var(--borders)',
        padding: 4,
        _focus: {
          borderColor: 'brand.400', // Focus border color
          boxShadow: '0 0 0 2px #f25c54',
        },
        _hover: {
          borderColor: 'brand.300', // Optional hover state
        },
      },
      defaultProps: {
        focusBorderColor: 'brand.400', // Ensure this is applied globally
        colorScheme: 'brand',
      },
    },
    Button: {
      variants: {
        solid: {
          bg: 'brand.400',
          color: 'white',
          _hover: {
            bg: 'brand.600',
          },
          _active: {
            bg: 'brand.600',
          },
        },
        ghost: {
          bg: 'transparent',
          border: '1px dashed var(--borders)',
          _hover: {
            bg: 'var(--grey100)',
          },
          _active: {
            bg: 'brand.50',
          },
        },
        outline: {
          borderColor: 'var(--borders)',
          color: 'var(--grey700)',
          _hover: {
            bg: 'var(--grey50)',
          },
        },
        link: {
          color: 'var(--grey700)',
          _hover: {
            color: 'brand.400',
          },
        },
      },
      defaultProps: {
        colorScheme: 'brand',
      },
    },
  };

  const theme = extendTheme({ breakpoints, colors, components });

  return {
    breakpoints,
    theme,
    colors,
  };
};

export default useCustomChakra;
