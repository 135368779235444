import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { db, storage } from "../firebaseConfig";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAppContext } from "../contexts/AppContext";
import { toast } from "react-toastify";
import ShopSettings from "../components/settings/tabs/ShopSettings";
import { Flex, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import ReservationsSettings from "../components/settings/tabs/ReservationsSettings";
import MenusSettings from "../components/settings/tabs/MenusSettings";
import GeneralSettings from "../components/settings/tabs/GeneralSettings";
import get from 'lodash/get';


const Settings = () => {
  const location = useLocation();

  // Extraire le paramètre `tab` de l'URL
  const queryParams = new URLSearchParams(location.search);
  const activeTab = queryParams.get("tab");

  // Map des onglets pour correspondre à leurs indices
  const tabIndexMap = {
    general: 0,
    menus: 1,
    shop: 2,
    reservations: 3,
  };

  // Déterminer l'index du tab actif basé sur l'URL
  const [tabIndex, setTabIndex] = React.useState(tabIndexMap[activeTab] || 0);

  useEffect(() => {
    if (activeTab && tabIndexMap[activeTab] !== undefined) {
      setTabIndex(tabIndexMap[activeTab]);
    }
  }, [activeTab]);

  const { selectedRestaurantId, menuAdminBarZIndex, settings, setSettings } = useAppContext();
  const [initialFieldValues, setInitialFieldValues] = useState({});


  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setSettings((prevSettings) => {
      const updatedSettings = { ...prevSettings };
      let temp = updatedSettings;
      const keys = name.split(".");

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          temp[key] = value;
        } else {
          temp[key] = { ...temp[key] };
          temp = temp[key];
        }
      });

      return updatedSettings;
    });
  };

  const saveFieldToFirestore = async (updatedSettings, fieldName) => {
    try {
      const fieldValue = get(updatedSettings, fieldName);

      if (fieldValue === undefined || fieldValue === null) {
        console.warn(`${fieldName} contient undefined ou null. Impossible de sauvegarder.`);
        return;
      }

      const settingsRef = doc(db, `restaurants/${selectedRestaurantId}/settings`, "config");
      await updateDoc(settingsRef, { [fieldName]: fieldValue });

      toast.success(`Settings saved successfully!`);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du champ:", error);
      toast.error(`Error saving settings`);
    }
  };

  const handleInputFocus = (event) => {
    const { name } = event.target;
    const currentValue = get(settings, name);
    setInitialFieldValues((prevValues) => ({
      ...prevValues,
      [name]: currentValue,
    }));
  };

  // Fonction pour comparer la valeur actuelle avec la valeur initiale
  const handleInputBlur = (event) => {
    const { name, value } = event.target;
    const initialValue = initialFieldValues[name];

    // Sauvegarder uniquement si la valeur a changé
    if (initialValue !== value) {
      setSettings((prevSettings) => {
        const updatedSettings = { ...prevSettings };
        let temp = updatedSettings;
        const keys = name.split(".");
        keys.forEach((key, index) => {
          if (index === keys.length - 1) {
            temp[key] = value;
          } else {
            temp[key] = { ...temp[key] };
            temp = temp[key];
          }
        });
        saveFieldToFirestore(updatedSettings, keys.join("."));
        return updatedSettings;
      });
    }
  };
  
  
  

  const handleFileChange = async (e, logoType = "shop") => {
    const file = e.target.files[0];
    if (file) {
      try {
        const fileName = `${selectedRestaurantId}/${logoType}/${file.name}`;
        const storageRef = ref(storage, `logos/${fileName}`);
        const uploadResult = await uploadBytes(storageRef, file);
        const logoUrl = await getDownloadURL(uploadResult.ref);

        setSettings((prevSettings) => {
          const updatedSettings = {
            ...prevSettings,
            logo: {
              ...(prevSettings.logo || {}),
              [logoType]: logoUrl,
            },
          };

          saveFieldToFirestore(updatedSettings, `logo.${logoType}`);
          return updatedSettings;
        });
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("Failed to upload file");
      }
    }
  };

  const handleDeleteLogo = async (logoType) => {
    try {
      setSettings((prevSettings) => ({
        ...prevSettings,
        logo: {
          ...prevSettings.logo,
          [logoType]: "", // Mettre à jour avec une chaîne vide
        },
      }));

      const settingsDocRef = doc(db, `restaurants/${selectedRestaurantId}/settings`, "config");
      await updateDoc(settingsDocRef, {
        [`logo.${logoType}`]: "", // Effacer le logo dans Firestore
      });

      toast.success("Logo supprimé avec succès !");
    } catch (error) {
      console.error(`Error deleting ${logoType} logo:`, error);
      toast.error(`Failed to delete ${logoType} logo`);
    }
  };

  

  
  
  

  return (
    <>
      <Flex
        padding={{ desktop: 12, mobile: 8 }}
        pb={{ desktop: 8, mobile: "89px" }}
        maxW="1320px"
        direction={"column"}
        gap={8}
        flex="1">
        <h3>Settings</h3>

        <Tabs index={tabIndex} onChange={(index) => setTabIndex(index)}>
          <Flex
            justifyContent="space-between"
            direction="row"
            w="100%"
            alignItems="center"
            position="sticky"
            top="0"
            bg={{ desktop: "white", mobile: "var(--grey100)" }}
            px={0}
            maxWidth="100vw"
            borderBottom="1px solid var(--borders)"
            style={{ zIndex: menuAdminBarZIndex }}>
            <TabList className="tabLinks" w="100%">
              <Tab className="tabLink">General</Tab>
              <Tab className="tabLink">Menus</Tab>
              {settings.modules?.shop && <Tab className="tabLink">Shop</Tab>}
              {settings.modules?.reservations && (
                <Tab className="tabLink">Reservations</Tab>
              )}
            </TabList>
          </Flex>

          <TabPanels>
            <TabPanel>
              <GeneralSettings 
                settings={settings}
                setSettings={setSettings}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                saveFieldToFirestore={saveFieldToFirestore}
              />
            </TabPanel>
            <TabPanel>
              <MenusSettings 
                settings={settings}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />
            </TabPanel>
            {settings.modules?.shop && (
              <TabPanel>
                <ShopSettings
                  settings={settings}
                  handleFileChange={handleFileChange}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                  onDelete={handleDeleteLogo}
                  saveFieldToFirestore={saveFieldToFirestore}
                />
              </TabPanel>
            )}
            {settings.modules?.reservations && (
              <TabPanel>
                <ReservationsSettings 
                  settings={settings}
                  onChange={handleInputChange}
                  onFocus={handleInputFocus}
                  onBlur={handleInputBlur}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Flex>
      
    </>
  );
};

export default Settings;
