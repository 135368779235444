// RefundModal.js
import React, { useState } from "react";
import Modal from "react-modal";
import { Flex, Box, Button, Stack } from "@chakra-ui/react";

const RefundModal = ({ isOpen, onRequestClose, order, processRefund }) => {
  const [refundAmount, setRefundAmount] = useState("");
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
      backdropFilter: "blur(2px)",
      display: "flex",
      paddingTop: "2%",
      paddingBottom: window.innerWidth <= 960 ? "0" : "2%",
      justifyContent: "center",
      alignItems: window.innerWidth <= 960 ? "flex-end" : "flex-start",
      zIndex: "1700",
    },
    content: {
      padding: "0",
    },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await processRefund(order.id, parseFloat(refundAmount));
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={"modal"}
      style={customStyles}
      zIndex={4000}>
      <div className="modal-content ">
      <Flex className="modalTopBar" justifyContent={"flex-start"} gap={4}>
          <div className="closeModal">
            <span className="close" onClick={onRequestClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                  fill="black"
                />
              </svg>
            </span>
          </div>
          <Box className="modalTitle">Refund Order #{order.id} </Box>
        </Flex>

        <Flex className="modalInner">
          
            <form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <Stack
                direction="column"
                gap={2}
              >
                <label>Refund Amount</label>
                <input
                  type="number"
                  value={refundAmount}
                  onChange={(e) => setRefundAmount(e.target.value)}
                  min="0.01"
                  step="0.01"
                  max={order.total}
                  required
                />
              </Stack>
       
            
            <Button
            size="lg"
            className="btn primary" 
            type="submit">
                Process Refund
            </Button>
            </Stack>
          </form>
         
        </Flex>
      </div>
    </Modal>
  );
};

export default RefundModal;
