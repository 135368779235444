import { Box, Button, Flex, Image } from "@chakra-ui/react";


const ImageDropzone = ({ currentItem, handleImageUpload, handleImageDelete, handleDragOver, handleDrop, handleImageUploadClick }) => {
  
  
  return (
    <Flex className="edit-image-dropzone" onDragOver={handleDragOver} onDrop={handleDrop}>
      <Box className="dish-img">
        {currentItem?.photo ? (
          <Image className="absolute-img" src={currentItem.photo} alt={currentItem.name} />
        ) : (
          <p>Drag an image here</p>
        )}
      </Box>
      <Flex className="upload-wrap" direction="row" justifyContent={"center"}>
        <Button className="btn upload primary" type="button" onClick={handleImageUploadClick}>
          Browse files
        </Button>
        <input
          type="file"
          id="image-upload"
          style={{ display: "none" }}
          accept=".jpg, .jpeg, .png, .webp, .heic"
          onChange={handleImageUpload}
        />
        {currentItem.photo && (
          <Button variant={"outline"} type="button" onClick={handleImageDelete} bg="white">
            Delete picture
          </Button>
        )}
      </Flex>
    </Flex>
    );
  };
  export default ImageDropzone;
  