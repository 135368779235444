import { Button, Flex, Text } from "@chakra-ui/react";
import VariationFields from "./VariationFields";
import Select from "react-select";
import TagSelect from "./TagSelect";
import { useAppContext } from "../../../contexts/AppContext";


const CommonFields = ({
    productType,
    setProductType,
    currentItem,
    setCurrentItem,
    handleInputChange,
    categories,
    selectedCategory,
    handleCategoryChange,
    capitalizeFirstLetter,
    isCategoryValid,
    allergenesWithUndefined,
    allergenMode,
    handleAllergenModeChange,
    selectedAllergenes,
    toggleAllergene,
    variations,
    handleVariationChange,
    handleDeleteVariation,
    addVariation,
    setHasChanges,
    displayOptions,
    handleDisplayChange,
    selectedDisplayOptions,
    isShopSelected,
    handleVATChange,
  }) => {
    
    const { mainLanguage, settings } = useAppContext();

    return (
      <>
        <div className="fieldset">
          <div className="row price-row">
            <div className="field">
              <label htmlFor="productType">Pricing type</label>
              <select
                value={productType}
                onChange={(e) => setProductType(e.target.value)}
                name="productType"
                id="productType">
                <option value="simple">Simple</option>
                <option value="variable">Variable</option>
              </select>
            </div>
  
            {productType === "simple" && (
              <div className="field price">
                <label htmlFor="price">Price</label>
                <input
                  type="text"
                  name="price"
                  id="price"
                  placeholder="Price"
                  value={currentItem.price}
                  onChange={handleInputChange}
                />
              </div>
            )}
          </div>
  
          {productType === "variable" && variations.map((variation, index) => (
            <VariationFields
              key={index}
              variation={variation}
              index={index}
              handleVariationChange={handleVariationChange}
              handleDeleteVariation={handleDeleteVariation}
            />
          ))}
  
          {productType === "variable" && (
            <Button size="md" type="button" onClick={addVariation} className="btn secondary">
              Add variation
            </Button>
          )}
        </div>
  
        <div className="fieldset">
          <div className="field">
            <label htmlFor="categorie" name="categorie">
              Category
            </label>
            <select
              value={selectedCategory}
              onChange={(e) => handleCategoryChange(e.target.value)}
              name="categorieId"
              id="categorie"
              style={{
                boxShadow: isCategoryValid ? "0 0 0 1.5px var(--borders)" : "0 0 0 1.5px var(--error)",
              }}>
              <option value="">Select a category</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {capitalizeFirstLetter(category[`name_${mainLanguage}`])}
                </option>
              ))}
            </select>
            {!isCategoryValid && (
              <Text color="var(--error)" fontSize={"12px"}>
                Category is required
              </Text>
            )}
          </div>
  
          <div className="field">
            <label htmlFor="allergenMode">Allergens</label>
            <select
              value={allergenMode}
              onChange={(e) => handleAllergenModeChange(e.target.value)}
              name="allergenMode"
              id="allergenMode">
              <option value="Ask Waiter">Ask Waiter</option>
              <option value="Define Allergens">Define Allergens</option>
            </select>
          </div>
  
          {allergenMode === "Define Allergens" && (
            <div className="allergenes">
              {allergenesWithUndefined
                .filter((allergene) => allergene.name !== "ask waiter")
                .map((allergene) => (
                  <div key={allergene.id} className="allergene">
                    <input
                      type="checkbox"
                      id={allergene.id}
                      name={allergene.id}
                      checked={selectedAllergenes.includes(allergene.id)}
                      onChange={() => toggleAllergene(allergene.id)}
                    />
                    <label className="tag" htmlFor={allergene.id}>
                      <img src={allergene.colorIcon} alt={allergene.id} className="icon" />
                      {allergene[`name_${mainLanguage}`]}
                    </label>
                  </div>
                ))}
            </div>
          )}
  
          <Flex className="field tagsField">
            <label htmlFor="tags">Tags</label>
            <TagSelect key={currentItem.id || "new-item"} setHasChanges={setHasChanges} setCurrentItem={setCurrentItem} />
          </Flex>
  

          {settings.modules?.shop && (

    <>
          <Flex className="field displayField">
            <label htmlFor="display">Display</label>
            <Select
              id="display"
              isMulti
              options={displayOptions}
              value={selectedDisplayOptions}
              onChange={handleDisplayChange}
              className="customSelect-container"
              classNamePrefix="customSelect"
              isSearchable={false}
            />
          </Flex>
  
          {isShopSelected && (
            <div className="field vatField">
              <label htmlFor="vat">VAT</label>
              <Select
                menuPlacement="auto"
                id="vat"
                options={[
                  { value: "0.03", label: "3%" },
                  { value: "0.14", label: "14%" },
                  { value: "0.17", label: "17%" },
                ]}
                value={
                  currentItem.vat
                    ? { label: `${(currentItem.vat * 100).toFixed(2)}%`, value: currentItem.vat }
                    : null
                }
                onChange={handleVATChange}
                className="customSelect-container"
                classNamePrefix="customSelect"
                isSearchable={false}
                required={isShopSelected}
              />
            </div>
          )}
</>
          )}
        </div>
      </>
    );
  };

  export default CommonFields;