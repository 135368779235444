import React, { useState, useEffect } from "react";
import { useAppContext } from "../../contexts/AppContext";
import Modal from "react-modal";
import useModal from "../../hooks/useModal";
import Select from "react-select";
import {
  Flex,
  Button,
  Input,
  Text,
  IconButton,
  Tooltip,
} from "@chakra-ui/react";
import { X } from "lucide-react";
import isEqual from "lodash.isequal";

Modal.setAppElement("#root");

const EditExtrasGroup = ({
  extrasGroup,
  onSave,
  onClose,
  onDelete,
  isOpen,
  isEditing,
  setIsEditing,
  groupDetails,
  setGroupDetails,
  categories,
  items,
  applicableTo,
  setApplicableTo,
  selectedOptions,
  setSelectedOptions,
  currentExtrasGroup,
}) => {
  const {
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    showOverlay,
    mainLanguage,
    settings,
  } = useAppContext();
  const { customStyles } = useModal();
  const [extrasList, setExtrasList] = useState(
    extrasGroup && extrasGroup.items ? extrasGroup.items : []
  );
  const [selectedNameLanguage, setSelectedNameLanguage] =
    useState(mainLanguage);
  const [initialState, setInitialState] = useState({});

  const hasChanges =
    !isEqual(groupDetails, initialState.groupDetails) ||
    !isEqual(extrasList, initialState.extrasList) ||
    applicableTo !== initialState.applicableTo ||
    !isEqual(selectedOptions, initialState.selectedOptions);

  const isFormValid =
    groupDetails.name[selectedNameLanguage]?.trim() !== "" &&
    extrasList.every(
      (extra) => extra.name[selectedNameLanguage]?.trim() !== ""
    ) &&
    selectedOptions.length > 0;

  const languageOptions = [
    { value: mainLanguage, label: mainLanguage.toUpperCase() },
    ...settings.secondaryLanguages.map((lang) => ({
      value: lang.value,
      label: lang.label || lang.value.toUpperCase(),
    })),
  ];
  const options = {
    categories: categories.map((cat) => ({
      value: cat.id,
      label: cat["name_" + mainLanguage],
    })),
  };

  const getItemOptions = () => {
    const itemOptions = [];
    for (const category in items) {
      items[category].forEach((item) => {
        itemOptions.push({
          value: item.id,
          label: item["name_" + mainLanguage],
        });
      });
    }
    return itemOptions;
  };

  useEffect(() => {
    if (!mainLanguage) {
      console.log("Langue principale non définie");
      return null;
    }

    if (!extrasGroup) {
      const initialDetails = {
        name: { [mainLanguage]: "" },
        items: [],
        applicableTo: "categories",
        selectedOptions: [],
      };
      setExtrasList([]);
      setApplicableTo("categories");
      setSelectedOptions([]);
      setGroupDetails(initialDetails);

      setInitialState({
        groupDetails: initialDetails,
        extrasList: [],
        applicableTo: "categories",
        selectedOptions: [],
      });
    } else {
      const nameTranslations = {
        [mainLanguage]: extrasGroup.name[`${mainLanguage}`] || "",
      };

      settings.secondaryLanguages.forEach((lang) => {
        nameTranslations[lang.value] = extrasGroup.name[`${lang.value}`] || "";
      });

      const groupDetailsToSet = {
        ...extrasGroup,
        name: nameTranslations,
      };

      setExtrasList(
        Array.isArray(extrasGroup.items)
          ? extrasGroup.items.map((item) => ({
              ...item,
              name: {
                ...item.name,
                [mainLanguage]: item.name[mainLanguage] || "",
                ...settings.secondaryLanguages.reduce((acc, lang) => {
                  acc[lang.value] = item.name[lang.value] || "";
                  return acc;
                }, {}),
              },
            }))
          : []
      );
      setApplicableTo(extrasGroup.applicableTo || "categories");
      setSelectedOptions(
        extrasGroup.applicableToOptions?.map((optionId) => {
          let optionLabel = categories.find((cat) => cat.id === optionId)?.[
            `name_${mainLanguage}`
          ];

          if (!optionLabel) {
            for (const category in items) {
              const foundItem = items[category].find(
                (item) => item.id === optionId
              );
              if (foundItem) {
                optionLabel = foundItem[`name_${mainLanguage}`];
                break;
              }
            }
          }
          return { value: optionId, label: optionLabel || "Label not found" };
        }) || []
      );

      setGroupDetails(groupDetailsToSet);

      setInitialState({
        groupDetails: groupDetailsToSet,
        extrasList: Array.isArray(extrasGroup.items) ? extrasGroup.items : [],
        applicableTo: extrasGroup.applicableTo || "categories",
        selectedOptions:
          extrasGroup.applicableToOptions?.map((optionId) => {
            let optionLabel = categories.find((cat) => cat.id === optionId)?.[
              `name_${mainLanguage}`
            ];
            return { value: optionId, label: optionLabel || "Label not found" };
          }) || [],
      });
    }
  }, [
    extrasGroup,
    mainLanguage,
    categories,
    items,
    settings.secondaryLanguages,
  ]);

  const handleApplicableToChange = (selectedOption) => {
    setApplicableTo(selectedOption.value);
    setSelectedOptions([]);
  };

  const handleSelectedOptionsChange = (selectedOptions) => {
    setSelectedOptions(selectedOptions);
  };

  const handleClose = () => {
    if (hasChanges) {
      const confirmed = window.confirm(
        "You have unsaved changes. Are you sure you want to leave?"
      );
      if (!confirmed) return;
    }
    onClose();
  };

  // Add the beforeunload event listener
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (hasChanges) {
        event.preventDefault();
        event.returnValue = ""; // Standard message in modern browsers
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [hasChanges]);

  const handleNameChange = (e) => {
    setGroupDetails((prevDetails) => ({
      ...prevDetails,
      name: {
        ...prevDetails.name,
        [selectedNameLanguage]: e.target.value,
      },
    }));
  };

  const handleOptionNameChange = (index, value) => {
    const updatedExtras = extrasList.map((extra, i) =>
      i === index
        ? { ...extra, name: { ...extra.name, [selectedNameLanguage]: value } }
        : extra
    );
    setExtrasList(updatedExtras);
    setGroupDetails({ ...groupDetails, items: updatedExtras });
  };

  const handleAddExtra = () => {
    const newExtra = { name: { [mainLanguage]: "" }, price: "0" };
    const updatedExtras = [...extrasList, newExtra];
    setExtrasList(updatedExtras);
    setGroupDetails({ ...groupDetails, items: updatedExtras });
  };

  const handleExtraChange = (index, field, value) => {
    const updatedExtras = extrasList.map((extra, i) =>
      i === index ? { ...extra, [field]: value } : extra
    );
    setExtrasList(updatedExtras);
    setGroupDetails({ ...groupDetails, items: updatedExtras });
  };

  const handleRemoveExtra = (index) => {
    const updatedExtras = extrasList.filter((_, i) => i !== index);
    setExtrasList(updatedExtras);
    setGroupDetails({ ...groupDetails, items: updatedExtras });
  };

  const handleMinSelectionsChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0; // Convert the value to an integer
    setGroupDetails((prevDetails) => ({
      ...prevDetails,
      minSelections: value,
    }));
  };

  const handleMaxSelectionsChange = (e) => {
    const value = parseInt(e.target.value, 10) || 0; // Convert the value to an integer
    setGroupDetails((prevDetails) => ({
      ...prevDetails,
      maxSelections: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  
    // Helper function to filter valid keys (language codes)
    const filterValidLanguageKeys = (nameObj) => {
      const validKeys = [...settings.secondaryLanguages.map(lang => lang.value), mainLanguage]; // E.g., ['en', 'fr', 'es']
      
      return Object.keys(nameObj).reduce((acc, key) => {
        if (validKeys.includes(key)) {
          acc[key] = nameObj[key];
        }
        return acc;
      }, {});
    };
  
    // Apply the filter to the group details and extras list
    const cleanedGroupDetails = {
      ...groupDetails,
      name: filterValidLanguageKeys(groupDetails.name),
      items: extrasList.map(extra => ({
        ...extra,
        name: filterValidLanguageKeys(extra.name),
      })),
    };
  
    const groupToSave = {
      ...cleanedGroupDetails,
      applicableTo,
      applicableToOptions: selectedOptions.map((option) => option.value),
    };
  
    if (currentExtrasGroup?.id) {
      onSave(groupToSave, currentExtrasGroup.id);
    } else {
      onSave(groupToSave);
    }
  };
  

  if (!isOpen || !mainLanguage) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      className={"modal"}
      style={customStyles}>
      <Flex className="modal-content" w={"640px"}>
        <form onSubmit={handleSubmit}>
          <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              {isEditing ? "Edit extras group" : "Add new extras group"}
            </div>
            <div className="actionButtons">
              <Button
                type="submit"
                size={"sm"}
                className="btn primary"
                onClick={(e) => handleSubmit(e)}
                isDisabled={!isFormValid || !hasChanges}>
                {isEditing ? "Save changes" : "Save"}
              </Button>

              {isEditing && (
                <div
                  className="btn simple itemActions subMenuToggle"
                  onClick={toggleSubMenu("extrasGroupActions")}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M10 5C10 3.89543 10.8954 3 12 3C13.1046 3 14 3.89543 14 5C14 6.10457 13.1046 7 12 7C10.8954 7 10 6.10457 10 5ZM10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12ZM10 19C10 17.8954 10.8954 17 12 17C13.1046 17 14 17.8954 14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19Z"
                      fill="black"
                    />
                  </svg>

                  {openSubMenu === "extrasGroupActions" && (
                    <div className="submenu">
                      <ul>
                        <li
                          onClick={() => onDelete(currentExtrasGroup.id)}
                          style={{ color: "var(--error)" }}>
                          Delete group
                        </li>
                      </ul>
                      <button onClick={closeSubMenu} className="closeSubmenu">
                        Cancel
                      </button>
                    </div>
                  )}
                  {showOverlay && openSubMenu === "extrasGroupActions" && (
                    <div className="overlay" onClick={closeSubMenu}></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <Flex className="modalInner">
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Group name
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                  Enter the name that will be displayed for this option group.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Flex
                  className="languageButtons"
                  gap={1}
                  position={"absolute"}
                  top={3}
                  right={4}
                  zIndex={2}>
                  {languageOptions.map((lang) => (
                    <Button
                      key={lang.value}
                      onClick={() => setSelectedNameLanguage(lang.value)}
                      p={0}
                      height="unset"
                      minWidth="unset"
                      borderRadius={"16px"}
                      overflow={"hidden"}
                      className={
                        selectedNameLanguage === lang.value ? "active" : ""
                      }
                      opacity=".4"
                      title={lang.label}>
                      <img
                        src={`/images/flags/${lang.value}.svg`}
                        alt={lang.label}
                        width="18px"
                        height="18px"
                      />
                    </Button>
                  ))}
                </Flex>
                <Input
                  flex={"0 1 !important"}
                  type="text"
                  value={groupDetails.name[selectedNameLanguage] || ""}
                  onChange={handleNameChange}
                  placeholder={`Group Name (${selectedNameLanguage})`}
                  required
                />
              </Flex>
            </Flex>

            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Type of selection
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                  Choose whether the customer can select one or multiple
                  options.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <select
                  value={groupDetails.selectionType || "unique"}
                  onChange={(e) =>
                    setGroupDetails({
                      ...groupDetails,
                      selectionType: e.target.value,
                    })
                  }
                  className="custom-select">
                  <option value="unique">Unique</option>
                  <option value="multiple">Multiple</option>
                </select>
              </Flex>
            </Flex>

        
        {settings.modules?.shop && groupDetails.selectionType === "multiple" && (
           <>
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                Minimum number of selections
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                Set the minimum number of selections that can be made.

                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Input type="number" 
                  value={groupDetails.minSelections}
                  onChange={handleMinSelectionsChange}
                  placeholder="Minimum number of selections" 
                />
              </Flex>
            </Flex>

            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              justifyContent={"flex-start"}
              alignItems={"flex-start"}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Maximum number of selections
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                    Set the maximum number of selections that can be made. Leave to 0 to allow unlimited selections.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Input type="number" 
                  value={groupDetails.maxSelections || 0} 
                  onChange={handleMaxSelectionsChange}
                  placeholder="Maximum number of selections" />
              </Flex>
            </Flex>
            </>
            )}

            {/* Options Section */}
            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Options
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                  List the choices customers can select within this group. Add
                  the price for each option.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                {extrasList &&
                  extrasList.map((extra, index) => (
                    <Flex
                      direction="row"
                      key={index}
                      gap={2}
                      alignItems="center">
                      <Input
                        type="text"
                        value={extra.name[selectedNameLanguage] || ""}
                        onChange={(e) =>
                          handleOptionNameChange(index, e.target.value)
                        }
                        placeholder={`Extra name (${selectedNameLanguage})`}
                        required
                        flex={1}
                      />
                      <Input
                        type="number"
                        value={extra.price}
                        onChange={(e) =>
                          handleExtraChange(
                            index,
                            "price",
                            parseFloat(e.target.value)
                          )
                        }
                        placeholder="Price"
                        min="0"
                        flex="0 0 72px !important"
                      />
                      <Tooltip label="Remove option">
                        <IconButton
                          size="xs"
                          variant={"outline"}
                          onClick={() => handleRemoveExtra(index)}>
                          <X size={12} />
                        </IconButton>
                      </Tooltip>
                    </Flex>
                  ))}
                <Button
                  type="button"
                  variant={"outline"}
                  size={"sm"}
                  onClick={handleAddExtra}>
                  Add option
                </Button>
              </Flex>
            </Flex>

            <Flex
              direction={{ desktop: "row", mobile: "column" }}
              gap={4}
              borderBottom={"1px solid var(--borders)"}
              pb={8}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Apply to
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                  Select whether this group applies to specific categories or
                  individual items.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Select
                  value={{ value: applicableTo, label: applicableTo }}
                  onChange={handleApplicableToChange}
                  options={[
                    { value: "categories", label: "Categories" },
                    { value: "items", label: "Items" },
                  ]}
                  className="customSelect-container"
                  classNamePrefix="customSelect"
                />
              </Flex>
            </Flex>

            <Flex direction={{ desktop: "row", mobile: "column" }} gap={4}>
              <Flex
                direction={"column"}
                w={{ desktop: "50%", mobile: "100%" }}
                gap={0}>
                <Text fontWeight={600} color={"var(--heading)"}>
                  Select categories or items
                </Text>
                <Text maxW={"85%"} fontSize={14}>
                  Choose the categories or items this group of options will be
                  linked to.
                </Text>
              </Flex>
              <Flex direction="column" gap={2} flex={1}>
                <Select
                  menuPlacement="auto"
                  value={selectedOptions}
                  onChange={handleSelectedOptionsChange}
                  options={
                    applicableTo === "categories"
                      ? options.categories
                      : getItemOptions()
                  }
                  isMulti="true"
                  className="customSelect-container"
                  classNamePrefix="customSelect"
                
                />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Modal>
  );
};

export default EditExtrasGroup;
