import { Flex, Text, Switch, Image, Input, InputLeftAddon, InputGroup } from "@chakra-ui/react";
import { useAppContext } from "../../../contexts/AppContext";
import Select from "react-select";
import useLanguages from "../../../hooks/useLanguages";

const GeneralSettings = ({ onChange, onFocus, onBlur, settings, setSettings, saveFieldToFirestore }) => {
  const { userRole } = useAppContext();
  

  const { languages } = useLanguages("");


  // Liste des options pour la langue principale, filtrant celles déjà dans secondaryLanguages
  const primaryLanguageOptions = languages.map((lang) => ({
    ...lang,
    isDisabled: settings.language && settings.language[0].value === lang.value, // Désactiver si c'est la langue active
  }));

  // Liste des options pour les langues secondaires, excluant la langue principale
  const secondaryLanguageOptions = languages.filter(
    (lang) => !settings.language || settings.language[0].value !== lang.value
  );

  const handleLanguageChange = (selectedOption) => {
    const languageData = languages.find((lang) => lang.value === selectedOption.value);
  
    const updatedLanguage = [
      {
        ...selectedOption,
        flag: languageData ? languageData.flag : "",
      },
    ];
  
    // Mettre à jour les settings et sauvegarder immédiatement
    setSettings((prevSettings) => ({
      ...prevSettings,
      language: updatedLanguage,
    }));
  
    // Sauvegarder dans Firestore immédiatement après le changement de langue
    saveFieldToFirestore({ ...settings, language: updatedLanguage }, 'language')
  };
  

// Fonction de gestion des changements de langues secondaires
const handleSecondaryLanguagesChange = (selectedOptions) => {
  // Vérifier s'il y a des options sélectionnées, sinon définir une liste vide
  const updatedSecondaryLanguages = selectedOptions
    ? selectedOptions.map((option) => {
        const languageWithFlag = languages.find((lang) => lang.value === option.value);
        return { value: option.value, label: option.label, flag: languageWithFlag?.flag || "" };
      })
    : [];

  // Mettre à jour les settings avec les nouvelles langues
  setSettings((prevSettings) => ({
    ...prevSettings,
    secondaryLanguages: updatedSecondaryLanguages,
  }));

  // Sauvegarder dans Firestore
  saveFieldToFirestore({ ...settings, secondaryLanguages: updatedSecondaryLanguages }, 'secondaryLanguages');
};




 

  // Format personnalisé pour afficher les drapeaux devant les langues
  const formatOptionLabel = ({ value, label, flag }) => (
    <Flex alignItems={"center"} direction={"row"}>
      {flag && (
        <Image
          src={flag}
          alt=""
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            marginRight: 10,
          }}
        />
      )}
      <Text
        textTransform={"capitalize"}
        color={"var(--grey700)"}
        fontWeight={"500"}>
        {label}
      </Text>
    </Flex>
  );


  // Gestion des Switch
const handleSwitchChange = (moduleName, isChecked) => {
  // Mettre à jour immédiatement les settings
  setSettings((prevSettings) => ({
    ...prevSettings,
    modules: {
      ...prevSettings.modules,
      [moduleName]: isChecked,
    },
  }));

  // Sauvegarder directement dans Firestore après modification du switch
  saveFieldToFirestore({ ...settings, modules: { ...settings.modules, [moduleName]: isChecked } }, `modules.${moduleName}`);
};

  return (
    <Flex
      direction="column"
      flexGrow="1"
      minW="0"
      gap={{ desktop: 8, mobile: 8 }}
      pt={8}
      sx={{
        ".settingsField:last-of-type": {
          borderBottom: "none",
        },
      }}
      >
      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Main language</h6>
          <Text maxW={"320px"}>Default language of your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1} maxW={"240px"}>
        <Select
            style={{ maxWidth: "220px" }}
            formatOptionLabel={formatOptionLabel}
            name="language"
            value={settings.language && settings.language.length > 0 ? settings.language[0] : ""}
            onChange={handleLanguageChange} // Sauvegarder directement lors du changement
            options={primaryLanguageOptions}
            className="customSelect-container"
            classNamePrefix="customSelect"
          />

        </Flex>
      </Flex>

      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Secondary languages</h6>
          <Text maxW={"320px"}>Other languages for your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
        <Select
            isMulti
            formatOptionLabel={formatOptionLabel}
            name="secondaryLanguages"
            value={settings.secondaryLanguages} 
            onChange={handleSecondaryLanguagesChange} 
            options={secondaryLanguageOptions} // Filtrer pour exclure la langue principale
            className="customSelect-container"
            classNamePrefix="customSelect"
          />
        </Flex>
      </Flex>

      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Company information</h6>
          <Text maxW={"320px"}>Update your company information</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Company</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.companyName"
              value={settings.shopSettings?.contact?.companyName || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Address</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.address"
              value={settings.shopSettings?.contact?.address || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          
        
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">Phone</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.phoneNumber"
              value={settings.shopSettings?.contact?.phoneNumber || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
         
          </Flex>

          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">VAT</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.vat"
              value={settings.shopSettings?.contact?.vat || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          
        
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">RCS</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              type="text"
              name="shopSettings.contact.rcs"
              value={settings.shopSettings?.contact?.rcs || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          
          </Flex>
        </Flex>
      </Flex>

      <Flex
        className="settingsField"
        direction={{ desktop: "row", mobile: "column" }}
        gap={4}
        borderBottom={"1px solid var(--borders)"}
        pb={8}>
        <Flex
          direction={"column"}
          w={{ desktop: "40%", mobile: "100%" }}
          gap={1}>
          <h6>Social profiles</h6>
          <Text maxW={"320px"}>Set your social profiles to activate links on your menu{settings.modules?.shop && " and shop"}.</Text>
        </Flex>
        <Flex direction="column" gap={2} flex={1}>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)" border="none" boxShadow="0 0 0 1.5px var(--borders)">facebook.com/</InputLeftAddon>
            <Input
              borderRadius={"0 8px 8px 0 !important"} 
              name="shopSettings.contact.social.facebook" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.facebook || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">instagram.com/</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              name="shopSettings.contact.social.instagram" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.instagram || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          </Flex>
          <Flex className="field">
          <InputGroup>
            <InputLeftAddon bg="var(--grey50)"  border="none" boxShadow="0 0 0 1.5px var(--borders)">x.com/</InputLeftAddon>
            <Input 
              borderRadius={"0 8px 8px 0 !important"}
              name="shopSettings.contact.social.x" 
              type='text' 
              placeholder=''
              value={settings.shopSettings?.contact?.social?.x || ""}
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          </InputGroup>
          </Flex>

          
        </Flex>
      </Flex>

      

      {userRole === "superAdmin" && (
        <Flex
          className="settingsField"
          direction={{ desktop: "row", mobile: "column" }}
          gap={4}
          pb={8}>
          <Flex
            direction={"column"}
            w={{ desktop: "40%", mobile: "100%" }}
            gap={1}>
            <h6>Modules</h6>
          </Flex>
          <Flex direction="column" gap={2} flex={1}>
            
            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Shop</h6>
              <Switch
                id="shop-switch"
                isChecked={settings.modules?.shop || false} 
                onChange={(e) => handleSwitchChange('shop', e.target.checked)} // Sauvegarder directement après modification
              />
            </Flex>

            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Ratio</h6>
              <Switch
                id="ratio-switch"
                isChecked={settings.modules?.ratio || false} // Assurez-vous que `settings.modules` est défini
                onChange={(e) => handleSwitchChange('ratio', e.target.checked)} // Sauvegarder directement après modification

              />
            </Flex>

            <Flex
              directionm="row"
              alignItems="center"
              justifyContent="space-between">
              <h6>Reservations</h6>
              <Switch
                id="reservations-switch"
                isChecked={settings.modules?.reservations || false} // Assurez-vous que `settings.modules` est défini
                onChange={(e) => handleSwitchChange('reservations', e.target.checked)} // Sauvegarder directement après modification

              />
            </Flex>

          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default GeneralSettings;
