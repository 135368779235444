// MenuContext.js
import React, { createContext, useState, useContext } from 'react';


const MenuContext = createContext();

export const useMenuContext = () => useContext(MenuContext);
export const MenuProvider = ({ children }) => {
  
  const [mainCategories, setMainCategories] = useState([]);
  const [currentMainCategory, setCurrentMainCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [menuItems, setMenuItems] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [saving, setSaving] = useState(false);
  const [ importModalIsOpen, setImportModalIsOpen ] = useState(false);

  
  const getDisplayStyles = (display) => {
    switch (display) {
      case "menu":
        return {
          color: "var(--blue400)",
          backgroundColor: "var(--blue50)",
        };
      case "shop":
        return {
          color: "var(--success700)",
          backgroundColor: "var(--success50)",
        };
      default:
        return {
          color: "white",
          backgroundColor: "var(--brand)",
        };
    }
  };
  
    
  const value = {
    mainCategories, setMainCategories,
    currentMainCategory, setCurrentMainCategory,
    categories, setCategories, 
    itemsByCategory, setItemsByCategory,
    menuItems, setMenuItems,
    tags, setTags,
    selectedTags, setSelectedTags,
    getDisplayStyles,
    saving, setSaving,
    importModalIsOpen, setImportModalIsOpen
  };

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  );
};
