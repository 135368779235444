import useStrictModeDroppable from "../../hooks/useStrictModeDroppable";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useAppContext } from "../../contexts/AppContext";
import { Flex, IconButton } from "@chakra-ui/react";
import useMainCategories from "../../hooks/useMainCategories";
import { Plus } from "lucide-react";

const StrictModeDroppableComponent = ({ droppableId, children }) => {
  const { enabled } = useStrictModeDroppable();

  if (!enabled) {
    return null;
  }

  return (
    <Droppable
      key={droppableId}
      droppableId={droppableId}
      direction="horizontal"
    >
      {(provided, snapshot) => children(provided, snapshot)}
    </Droppable>
  );
};

const MenuSwitcher = ({ handleOpenMainCategoryModal, currentMainCategory }) => {
  const { selectedRestaurantId, mainLanguage, menuAdminBarZIndex } = useAppContext();
  
  const {
    mainCategories,
    mainCategoryName,
    setMainCategoryName,
    handleOnDragEnd,
  } = useMainCategories(selectedRestaurantId);


  const handleCategoryClick = (categoryId) => {
    setMainCategoryName(categoryId);
    window.scrollTo(0, 0);
  };

  return (
    <Flex
        position={"sticky"}
        top={0}
        w={{ desktop: "100%", mobile: "100vw" }}
        gap={4}
        bg={"white"}
        mt={-4}
        py={4}
        zIndex={menuAdminBarZIndex}
        overflowX={"auto"}
        style={{ WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}
        borderBottom="1px solid var(--borders)"
        mx={{ desktop: "0", mobile: -6 }}
        px={{ desktop: 0, mobile: 6 }}
        
      >
        
        <Flex
      gap={1}
      alignItems={"center"}
      flex={1}
    >
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <StrictModeDroppableComponent
          droppableId="mainCategories"
          key="mainCategories"
        >
          {(provided) => (
            <Flex
              direction="row"
              gap={1}
              alignItems="center"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mainCategories.map((category, index) => (
                <Draggable
                  key={category.id}
                  draggableId={category.id}
                  index={index}
                >
                  {(provided) => (
                    <Flex
                      direction="row"
                      px={4}
                      py={2}
                      gap={2}
                      fontWeight={500}
                      alignItems="center"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className={`${
                        mainCategoryName === category.id ? "active" : ""
                      }`}
                      onClick={() => handleCategoryClick(category.id)}
                      onDoubleClick={() => handleOpenMainCategoryModal(currentMainCategory)}
                      border={
                        mainCategoryName === category.id
                          ? "1px solid transparent"
                          : "1px solid var(--borders)"
                      }
                      bg={
                        mainCategoryName === category.id
                          ? "var(--grey150)"
                          : "white"
                      }
                      color={
                        mainCategoryName === category.id
                          ? "var(--grey700)"
                          : "var(--grey500)"
                      }
                      cursor={"pointer"}
                      _hover={{
                        color: "var(--grey700)",
                      }}
                      borderRadius={8}
                    >
                      <span className="mainCat-icon">{category.menuIcon}</span>
                      <span className="mainCat-name">
                        {category[`name_${mainLanguage}`]}
                      </span>
                    </Flex>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Flex>
          )}
        </StrictModeDroppableComponent>
      </DragDropContext>

      <IconButton
        gap={1}
        size={"sm"}
        variant={"ghost"}
        icon={<Plus size={14} />}
        aria-label="Add a menu"
        onClick={() => {
          handleOpenMainCategoryModal(null);
        }}
      ></IconButton>
    </Flex>
    </Flex>
  );
};

export default MenuSwitcher;
