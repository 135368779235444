import React from "react";
import ManageCategories from "./ManageCategories";
import { useAppContext } from "../../contexts/AppContext";
import ManageTags from "./ManageTags";
import ManageExtras from "./ManageExtras";
import { Flex, Skeleton } from "@chakra-ui/react";
import UncategorizedItems from "./UncategorizedItems";
import useMenuData from "../../hooks/useMenuData";

const Sidebar = ({
  subCategoryName,
  currentSubCategory,
  categories,
  setCategories,
  itemsByCategory,
  handleOrderChange,
  handleCategoryDeletion,
  onAddCategory,
  openAddDishModal,
  uncategorizedItems,
  openEditModal,
  currentMainCategory,
  onDeleteUncategorized,
  openSubCategoryModal,
}) => {
  const { selectedRestaurantId } = useAppContext();
  const { isLoading } = useMenuData();

  return (
    <>
    
    {categories.length > 0 && (
    <Flex
      as="aside"
      flex={{ desktop: "0 0 320px", mobile: "1" }}
      w={{ desktop: "320px", mobile: "100%" }}
      direction="column"
      gap={16}>
      
    
    
      {!isLoading ? (
        <>
          <ManageCategories
            categories={
              categories ? categories.filter((cat) => !cat.isUncategorized) : []
            }
            setCategories={setCategories}
            itemsByCategory={itemsByCategory}
            onOrderChange={handleOrderChange}
            onCategoryDelete={handleCategoryDeletion}
            onAddCategory={onAddCategory}
            onAddDish={openAddDishModal}
            subCategoryName={subCategoryName}
            selectedRestaurantId={selectedRestaurantId}
            currentMainCategory={currentMainCategory}
            currentSubCategory={currentSubCategory}
            openSubCategoryModal={openSubCategoryModal}
          />

          <ManageTags />

          {currentMainCategory?.activateExtras && (
            <ManageExtras
              categories={
                categories
                  ? categories.filter((cat) => !cat.isUncategorized)
                  : []
              }
              items={itemsByCategory}
            />
          )}

          <UncategorizedItems
            uncategorizedItems={uncategorizedItems}
            currentMainCategory={currentMainCategory}
            onDeleteUncategorized={onDeleteUncategorized}
            openEditModal={openEditModal}
          />
        </>
      ) : (
        <Flex direction={"column"} gap={8}>
          <Skeleton w={"100%"} h={"150px"} borderRadius={8} />
          <Skeleton w={"100%"} h={"150px"} borderRadius={8} />
          <Skeleton w={"100%"} h={"150px"} borderRadius={8} />
        </Flex>
      )}
    
    
    </Flex>)}</>
  );
};
export default Sidebar;
