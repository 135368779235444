import Navigation from "../components/navigation/Navigation";
import MobileNavigation from "../components/navigation/MobileNavigation";
import useWindowWidth from "../hooks/useWindowWidth";
import { Flex } from "@chakra-ui/react";
import TopHeader from "../components/navigation/TopHeader";
import { useAppContext } from "../contexts/AppContext";

function BackendLayout({ children, setIsLoggedIn, setUserRole, setLoading }) {
  const { userRole } = useAppContext();
  const NavigationComponent = useWindowWidth() < 960 ? MobileNavigation : Navigation;

  return (
    <Flex direction="row" w="100vw" id="backend" flex={1} >
      {userRole !== "deliverer" && (
        <NavigationComponent setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading} />
      )}
      <Flex direction="column" flex={1}>
        <TopHeader setIsLoggedIn={setIsLoggedIn} setUserRole={setUserRole} setLoading={setLoading} />
        <Flex direction="column" flex={1} maxWidth="100vw">
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default BackendLayout;
