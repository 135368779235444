import { Flex, Link, Text } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";
import { useAppContext } from "../contexts/AppContext";
import { CalendarSearch, LayoutDashboard } from "lucide-react";

function ReservationsLayout({ children }) {
  const { selectedRestaurantId, menuAdminBarZIndex, settings } = useAppContext();

  return (
    <>
      {settings.modules?.reservations && (
        <Flex
          justifyContent="space-between"
          direction="row"
          w="100%"
          alignItems="center"
          position="sticky"
          top="0"
          bg={{ desktop: "white", mobile: "var(--grey100)" }}
          px={{ desktop: 8, mobile: 4 }}
          maxWidth="100vw"
          borderBottom="1px solid var(--borders)"
          style={{ zIndex: menuAdminBarZIndex }}
        >
          <Flex className="tabLinks">
          <NavLink
            to={`/${selectedRestaurantId}/reservations`}
            end
            className="tabLink"
            style={({ isActive }) => ({
              color: isActive ? "var(--brand)" : "var(--grey700)",
            })}>
            {({ isActive }) => (
              <Flex alignItems={"center"} gap={1}>
                <CalendarSearch
                  size={16}
                  color={isActive ? "var(--brand)" : "var(--grey500)"}
                />
                <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                  Reservations
                </Text>
              </Flex>
            )}
          </NavLink>
          <NavLink
            to={`/${selectedRestaurantId}/reservations/tableplan`}
            className="tabLink"
            style={({ isActive }) => ({
              color: isActive ? "var(--brand)" : "var(--grey700)",
            })}>
            {({ isActive }) => (
              <Flex alignItems={"center"} gap={1}>
                <LayoutDashboard
                  size={16}
                  color={isActive ? "var(--brand)" : "var(--grey500)"}
                />
                <Text color={isActive ? "var(--brand)" : "var(--grey500)"} _hover={{ color: "var(--grey700)"}}>
                  Table plan
                </Text>
              </Flex>
            )}
          </NavLink>
          
          
          
          </Flex>
        </Flex>
      )}

      <Flex
      direction="column"
      padding={{ desktop: 12, mobile: 8 }}
      pb={{ desktop: 8, mobile: '172px' }} 
      gap={8} maxW="1320px" flex={1}>
        {settings.modules?.reservations ? (
          children // Affiche les enfants si le module de réservation est activé
        ) : (
          <div>Reservations module is not enabled. Please <Link href="mailto:info@gudden.app?subject=Reservations module" fontWeight={"500"} color={"var(--brand)"}>contact us</Link> if you are interested.</div> // Sinon, affiche un message ou autre contenu
        )}
      </Flex>
    </>
  );
}

export default ReservationsLayout;
