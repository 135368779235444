import { useAppContext } from "../../contexts/AppContext";
import { Flex, Tooltip, Box, Image, IconButton, Text } from "@chakra-ui/react";
import { GripVertical, CircleAlert, EllipsisVertical } from "lucide-react";
import ItemActions from "./ItemActions";
import useMenuData from "../../hooks/useMenuData";

const MenuItem = ({
  item,
  provided,
  category,
  currentMainCategory,
  maxWidth,
  userRole,
  openEditModal,
  missingTranslations,
  getDisplayStyles,
  onDuplicate,
  toggleAvailability,
  onArchive,
  enableReordering = true,
  uncategorizedItem = false,
}) => {
  const {
    mainCategoryName,
    selectedRestaurantId,
    toggleSubMenu,
    openSubMenu,
    closeSubMenu,
    mainLanguage,
    settings,
  } = useAppContext();
  const { confirmAndDelete } = useMenuData(
    mainCategoryName,
    selectedRestaurantId
  );

  return (
    <Flex
      direction="row"
      justifyContent="space-between"
      ref={enableReordering ? provided.innerRef : null} // Condition pour innerRef
      {...(enableReordering ? provided.draggableProps : {})} // Condition pour draggableProps
      {...(enableReordering ? provided.dragHandleProps : {})} // Condition pour dragHandleProps
      className="dish"
      onClick={() => openEditModal(item)}>
      <Flex className="dish-name" alignItems="center">
        {enableReordering && <GripVertical size={16} color="var(--grey500)" />}
        {(currentMainCategory?.imageDisplay || category?.style === "Cards") && (
          <Box className="dish-img" bg={"var(--grey100)"}>
            {item.photo && <Image src={item.photo} alt={item.name} />}
          </Box>
        )}
        <Box
          whiteSpace={"nowrap"}
          overflow={"hidden"}
          textOverflow={"ellipsis"}>
          {item[`name_${mainLanguage}`]}
        </Box>

        {item.tags && item.tags.length > 0 && (
          <Flex className="dish-tags mobile-hidden" h={"fit-content"} gap={1}>
            {item.tags.map((tag, index) => (
              <Box
                className="tag sm"
                fontSize={11}
                px={2}
                py={1}
                key={index}
                bg={tag.color}
                color={"white"}>
                {tag.label}
              </Box>
            ))}
          </Flex>
        )}

        {missingTranslations && (
          <Tooltip label="Missing translations">
            <CircleAlert size={16} color="var(--error)" />
          </Tooltip>
        )}
      </Flex>

      {userRole === "superAdmin" && (
        <Text
          fontSize="10px"
          maxWidth="20px"
          whiteSpace="nowrap"
          overflow="hidden">
          {item.id}
        </Text>
      )}

      <Flex
        direction="row"
        justifyContent="flex-end"
        className="dish-info"
        alignItems={"center"}
        gap={4}>
        <Flex className="dishAvailability">
          {!item.availability && (
            <span
              className="tag sm"
              style={{
                color: "var(--error)",
                backgroundColor: "var(--error50)",
              }}>
              Unavailable
            </span>
          )}
        </Flex>

        {settings.modules?.shop && !uncategorizedItem && (
          <Flex className="dish-display mobile-hidden">
            {item.display &&
            Array.isArray(item.display) &&
            item.display.length > 0 ? (
              item.display
                .sort((a, b) => {
                  if (a === "menu") return -1;
                  if (b === "menu") return 1;
                  if (a === "shop") return -1;
                  if (b === "shop") return 1;
                  return 0;
                })
                .map((display, index) => (
                  <Tooltip
                    key={index}
                    label={`Item published in your ${display}`}>
                    <span
                      className="tag sm"
                      key={index}
                      style={getDisplayStyles(display)}>
                      {display}
                    </span>
                  </Tooltip>
                ))
            ) : (
              <span
                className="tag sm"
                style={{
                  color: "var(--error)",
                  backgroundColor: "var(--error50)",
                }}>
                Unpublished
              </span>
            )}
          </Flex>
        )}

        <Flex className="dish-price" minWidth={maxWidth + "px" }>
          {item.type === "variable" ? (
            Array.isArray(item.variations) ? (
              <div className="tag sm">{item.variations.length} variations</div>
            ) : null
          ) : (
            <>{item.price}€</>
          )}
        </Flex>
        <IconButton
          size="sm"
          bg={"transparent !important"}
          color="var(--grey500)"
          cursor="pointer"
          onClick={(e) => {
            e.stopPropagation();
            toggleSubMenu(item.id)(e);
          }}>
          <EllipsisVertical size={20} />
        </IconButton>

        {openSubMenu === item.id && (
          <Flex className="submenu" m={0} p={"0 !important"}>
            <ItemActions
              item={item}
              onDuplicate={onDuplicate}
              toggleAvailability={toggleAvailability}
              confirmAndDelete={confirmAndDelete}
              closeSubMenu={closeSubMenu}
              onArchive={onArchive}
              uncategorizedItem={uncategorizedItem}
            />

            <button
              onClick={(e) => {
                e.stopPropagation();
                closeSubMenu();
              }}
              className="closeSubmenu">
              Cancel
            </button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default MenuItem;
