import { useState, useEffect } from "react";
import { db } from '../firebaseConfig';
import { collection, query, getDocs } from 'firebase/firestore';

const useLanguages = () => {
    const [languages, setLanguages] = useState([]);
    const [primaryLanguage, setPrimaryLanguage] = useState(null);
    const [secondaryLanguages, setSecondaryLanguages] = useState([]);


    useEffect(() => {
        const fetchLanguages = async () => {
            const querySnapshot = await getDocs(query(collection(db, "languages")));
            const languagesData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLanguages(languagesData);
        };

        fetchLanguages();
    }, []);


    const languagesFormatted = languages.map(lang => ({
      value: lang.iso,
      label: lang.name,
      flag: lang.flag
    }));



    return {      
      
    languages: languagesFormatted,
    setLanguages,
    primaryLanguage,
    setPrimaryLanguage,
    secondaryLanguages,
    setSecondaryLanguages
    };
};

export default useLanguages;
