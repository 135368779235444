const LanguageFields = ({ languageValue, currentItem, handleInputChange, textareaRef, settings }) => {
    return (
      <div className="fieldset">
        <div className="field">
          <input
            type="text"
            name={`name_${languageValue}`}
            id={`name_${languageValue}`}
            className="nameInput"
            placeholder="Item name"
            value={currentItem[`name_${languageValue}`] || ""}
            onChange={handleInputChange}
            required
          />
        </div>
        <textarea
          ref={textareaRef}
          name={`description_${languageValue}`}
          placeholder="Description"
          id={`description_${languageValue}`}
          className="descInput"
          value={currentItem[`description_${languageValue}`] || ""}
          onChange={handleInputChange}
        />
        {languageValue === settings.language[0].value || currentItem[`note_${settings.language[0].value}`] ? (
          <div className="field">
            <label htmlFor={`note_${languageValue}`}>Note ({languageValue})</label>
            <input
              type="text"
              name={`note_${languageValue}`}
              id={`note_${languageValue}`}
              placeholder="Note"
              value={currentItem[`note_${languageValue}`] || ""}
              onChange={handleInputChange}
            />
          </div>
        ) : null}
      </div>
    );
  };

  export default LanguageFields;
  