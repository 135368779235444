import { useAppContext } from "../../contexts/AppContext";
import { Flex } from '@chakra-ui/react';
import useMainCategories from "../../hooks/useMainCategories";




const MenuSwitcherWithoutOrdering = () => {
  
    const { selectedRestaurantId, mainLanguage } = useAppContext();
    
    const {
        mainCategories,
        mainCategoryName,
        setMainCategoryName,
    } = useMainCategories(selectedRestaurantId);

    const handleCategoryClick = (categoryId) => {
        setMainCategoryName(categoryId);
        window.scrollTo(0, 0);
    };
    

  return (
    
    
              <Flex
                direction="row"
                pr="8px"
                gap={1}
                alignItems="center">            
                {mainCategories.map((category, index) => (
               
                      <Flex
                        key={index}
                     direction="row"
                     px={4}
                     py={2}
                     gap={2}
                     fontWeight={500}
                     alignItems="center"
                     className={`${mainCategoryName === category.id ? "active" : ""}`}
                     onClick={() => handleCategoryClick(category.id)}
                     border={mainCategoryName === category.id ? "1px solid transparent" : "1px solid var(--borders)"}
                     bg={mainCategoryName === category.id ? "var(--grey150)" : "transparent"}
                     color={mainCategoryName === category.id ? "va(--grey700)" : "var(--grey500)"}
                     pointerEvents={mainCategoryName === category.id ? "none" : "auto"}
                     cursor={"pointer"}
                     _hover={{ 
                        color: "var(--grey700)"  
                      }}
                     borderRadius={8}
          
                       >
            
                        <span className="mainCat-icon">
                          {category.menuIcon}
                        </span>
                        <span className="mainCat-name">
                          {category[`name_${mainLanguage}`]}
                        </span>
                      </Flex>
          
                ))}
              </Flex>
          


  );
};

export default MenuSwitcherWithoutOrdering;
