import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAppContext } from './AppContext';
import { db } from '../firebaseConfig';
import { collection, query, where, onSnapshot, orderBy } from "firebase/firestore";

// Créer le contexte
const NotificationContext = createContext();

// Fournisseur du contexte (Provider)
export const NotificationProvider = ({ children }) => {
  
  const { selectedRestaurantId, settings } = useAppContext();
  const [pendingOrders, setPendingOrders] = useState([]);
  const [pendingReservations, setPendingReservations] = useState([]);
  const [pendingOrdersCount, setPendingOrdersCount] = useState(0);
  const [pendingReservationsCount, setPendingReservationsCount] = useState(0);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

 // Load Pending or Confirmed Orders
useEffect(() => {
  if (!selectedRestaurantId || !settings?.modules?.shop) {
    setPendingOrders([]);
    return;
  }

  // Vérifier si l'autoconfirm est activé
  const orderStatus = settings?.shopSettings?.autoConfirm ? "confirmed" : "pending";

  const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
  const q = query(ordersRef, where("status", "==", orderStatus), orderBy("createdAt", "desc"));

  // Abonnement pour surveiller les commandes selon le statut
  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const fetchedOrders = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Mettre à jour l'état avec les commandes récupérées
    setPendingOrders(fetchedOrders);
    setPendingOrdersCount(querySnapshot.docs.length);
  });

  return () => unsubscribe();
}, [selectedRestaurantId, settings]);



 // Load Pending or Confirmed Reservations
useEffect(() => {
  if (!selectedRestaurantId || !settings?.modules?.reservations) {
    setPendingReservations([]);
    return;
  }

  // Vérifier si l'autoconfirm est activé pour les réservations
  const reservationStatus = settings?.shopSettings?.autoConfirm ? "confirmed" : "pending";

  const reservationsRef = collection(db, `restaurants/${selectedRestaurantId}/reservations`);
  const q = query(reservationsRef, where("status", "==", reservationStatus), orderBy("createdAt", "desc"));

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const fetchedReservations = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    setPendingReservations(fetchedReservations);
    setPendingReservationsCount(querySnapshot.docs.length);
  });

  return () => unsubscribe();
}, [selectedRestaurantId, settings?.modules?.reservations]);


  

  return (
    <NotificationContext.Provider value={{ pendingOrders, pendingReservations, pendingOrdersCount, pendingReservationsCount, isDrawerOpen, setIsDrawerOpen }}>
      {children}
    </NotificationContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte
export const useNotifications = () => {
  return useContext(NotificationContext);
};
