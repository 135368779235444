import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { collection, query, where, onSnapshot, Timestamp } from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { startOfMonth, endOfMonth, subMonths } from "date-fns";

const useOrdersKPI = () => {
  const { selectedRestaurantId } = useAppContext();
  
  // States for orders
  const [currentMonthOrders, setCurrentMonthOrders] = useState(0);
  const [previousMonthOrders, setPreviousMonthOrders] = useState(0);

  // States for revenue, tips, and service fees
  const [currentMonthRevenue, setCurrentMonthRevenue] = useState(0);
  const [previousMonthRevenue, setPreviousMonthRevenue] = useState(0);
  const [currentMonthTips, setCurrentMonthTips] = useState(0);
  const [previousMonthTips, setPreviousMonthTips] = useState(0);
  const [currentMonthServiceFee, setCurrentMonthServiceFee] = useState(0); // New state for current month service fee
  const [previousMonthServiceFee, setPreviousMonthServiceFee] = useState(0); // New state for previous month service fee

  // States for average order
  const [currentMonthAverageOrder, setCurrentMonthAverageOrder] = useState(0);
  const [previousMonthAverageOrder, setPreviousMonthAverageOrder] = useState(0);

  const [isLoadingKPI, setIsLoadingKPI] = useState(true);

  useEffect(() => {
    if (!selectedRestaurantId) return;

    const ordersRef = collection(db, `restaurants/${selectedRestaurantId}/orders`);
    const currentMonthStart = startOfMonth(new Date());
    const currentMonthEnd = endOfMonth(new Date());
    const previousMonthStart = startOfMonth(subMonths(new Date(), 1));
    const previousMonthEnd = endOfMonth(subMonths(new Date(), 1));

    const currentMonthQuery = query(
      ordersRef,
      where("status", "==", "completed"),
      where("createdAt", ">=", Timestamp.fromDate(currentMonthStart)),
      where("createdAt", "<=", Timestamp.fromDate(currentMonthEnd))
    );

    const previousMonthQuery = query(
      ordersRef,
      where("status", "==", "completed"),
      where("createdAt", ">=", Timestamp.fromDate(previousMonthStart)),
      where("createdAt", "<=", Timestamp.fromDate(previousMonthEnd))
    );

    // Current month listener
    const unsubscribeCurrent = onSnapshot(currentMonthQuery, (snapshot) => {
      let currentRevenue = 0;
      let currentTips = 0;
      let currentOrdersCount = 0;
      let currentServiceFee = 0;

      snapshot.forEach((doc) => {
        const data = doc.data();
        currentRevenue += parseFloat(data.subTotal.replace(",", "."));
        currentTips += data.tip ? parseFloat(data.tip) : 0;
        currentServiceFee += data.serviceFeeTTC ? parseFloat(data.serviceFeeTTC) : 0;
        currentOrdersCount += 1;
      });

      setCurrentMonthRevenue(currentRevenue);
      setCurrentMonthTips(currentTips);
      setCurrentMonthServiceFee(currentServiceFee); // Set current service fee
      setCurrentMonthOrders(currentOrdersCount);

      const averageOrder = currentOrdersCount > 0 ? currentRevenue / currentOrdersCount : 0;
      setCurrentMonthAverageOrder(averageOrder);
    });

    // Previous month listener
    const unsubscribePrevious = onSnapshot(previousMonthQuery, (snapshot) => {
      let previousRevenue = 0;
      let previousTips = 0;
      let previousOrdersCount = 0;
      let previousServiceFee = 0; // Variable to store previous month's service fees

      snapshot.forEach((doc) => {
        const data = doc.data();
        previousRevenue += parseFloat(data.subTotal.replace(",", "."));
        previousTips += data.tip ? parseFloat(data.tip) : 0;
        previousServiceFee += data.serviceFeeTTC ? parseFloat(data.serviceFeeTTC) : 0; // Add service fee calculation
        previousOrdersCount += 1;
      });

      setPreviousMonthRevenue(previousRevenue);
      setPreviousMonthTips(previousTips);
      setPreviousMonthServiceFee(previousServiceFee); // Set previous service fee
      setPreviousMonthOrders(previousOrdersCount);

      const previousAverageOrder = previousOrdersCount > 0 ? previousRevenue / previousOrdersCount : 0;
      setPreviousMonthAverageOrder(previousAverageOrder);

      setIsLoadingKPI(false);
    });

    return () => {
      unsubscribeCurrent();
      unsubscribePrevious();
    };
  }, [selectedRestaurantId]);

  const revenueChangePercentage = previousMonthRevenue
    ? ((currentMonthRevenue - previousMonthRevenue) / previousMonthRevenue) * 100 : 0;

  const tipsChangePercentage = previousMonthTips
    ? ((currentMonthTips - previousMonthTips) / previousMonthTips) * 100 : 0;

  const orderChangePercentage = previousMonthOrders
    ? ((currentMonthOrders - previousMonthOrders) / previousMonthOrders) * 100 : 0;

  const averageOrderChangePercentage = previousMonthAverageOrder
    ? ((currentMonthAverageOrder - previousMonthAverageOrder) / previousMonthAverageOrder) * 100 : 0;

  const serviceFeeChangePercentage = previousMonthServiceFee
    ? ((currentMonthServiceFee - previousMonthServiceFee) / previousMonthServiceFee) * 100 : 0;

  return {
    currentMonthRevenue,
    currentMonthTips,
    currentMonthServiceFee, // Return current service fee
    revenueChangePercentage,
    tipsChangePercentage,
    serviceFeeChangePercentage, // Return service fee change percentage
    currentMonthOrders,
    previousMonthOrders,
    orderChangePercentage,
    currentMonthAverageOrder,
    averageOrderChangePercentage,
    isLoadingKPI,
    previousMonthServiceFee, 
    previousMonthRevenue, 
    previousMonthTips,
    previousMonthAverageOrder
  };
};

export default useOrdersKPI;
