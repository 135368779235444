import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    body: {
      paddingTop: 20,
      paddingBottom: 65,
      paddingHorizontal: 40,
    },
    
    headerRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
      marginBottom: 20,
    },
    headerColumn: {
      width: '50%',
    },
    headerColumnRight: {
      width: '50%',
      textAlign: 'right',
      justifyContent: 'flex-end',
      alignItems:'flex-end',
    },
    h4: {
      fontSize: 10,
      textTransform: 'capitalize',
    },
    h5: {
      fontSize: 10,
      color: "#667085",
      marginBottom: 5,
    },
    text: {
      marginTop: 2,
      marginBottom: 2,
      fontSize: 10,
    },
    smallText: {
        textTransform: 'uppercase',
        fontSize: 8,
        marginRight: 1,
    },
    table: {
      display: "table",
      width: "auto",
      borderWidth: 0,
      marginTop: 20,
    },
    detailsTable: {
       marginTop: 40,
    },
    tableRow: { 
      margin: "auto", 
      flexDirection: "row" 
    },
    thead: {
      borderBottomWidth: 1,
      paddingBottom: 4,
    },
    tfoot: {
      borderTopWidth: 1,
      marginTop: 4,
    },
    tableFirstColumn: { 
      width: "60%", 
      borderWidth: 0,
      textAlign: "left",
    },
    detailsFirstColumn: { 
      width: "30%", 
      borderWidth: 0,
      textAlign: "left",
    },
    tableColumn: { 
      width: "20%", 
      borderWidth: 0,
      textAlign: "left",
    },
    tableDetailColumn: { 
        width: "17.5%", 
        borderWidth: 0,
        textAlign: "left",
      },
    totalFirstColumn: { 
        width: "80%", 
        borderWidth: 0,
        textAlign: "left",
    },
    totalColumn: { 
        width: "20%", 
        borderWidth: 0,
        textAlign: "left",
    },
    tableCell: { 
      textAlign: "left",
      marginTop: 5, 
      fontSize: 10 
    },
    tableCellRight: { 
      textAlign: "right",
      marginTop: 5, 
      fontSize: 10 
    },
    strong : {
      fontWeight: "bold",
    },
    footer: {
        position: 'absolute',
        bottom: 25,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 12,
    },
    footerText: {
        color: '#667085',
        fontSize: 8,
        marginBottom: 3,
    }
  });
  
  const Footer = () => (
    <View style={styles.footer}>
        <Text style={styles.footerText}>Good Robot Sarls / 37, Rue de la Libération L-5969 Itzig, Luxembourg / TVA: LU33341117</Text>
        <Text style={styles.footerText}>IBAN : LU24 0019 6155 2437 1000 / BIC : BCEELULL</Text>
    </View>
  );

  
const CommissionInvoice = ({ invoiceData, settings, generalSettings }) => {
    
    const VAT_RATE = generalSettings.vat;
    const commissionHTVA = invoiceData.totalCommission; 
    const VAT_DECIMAL = VAT_RATE / 100;


    

const formatAmount = (amount) => amount.toFixed(2).replace('.', ',');

  
  const formatDateTime = (date) => {
    return date.toLocaleDateString('fr-FR', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit'
    });
  };


return (
  
  <Document>
    <Page size="A4" style={styles.body}>
        <View style={styles.headerRow}>
            <View style={styles.headerColumn}>
                <Text style={styles.h5}>Facturé à:</Text>
                <Text style={styles.text}>{settings.shopSettings.contact.companyName}</Text>
                <Text style={styles.text}>{settings.shopSettings.contact.address}</Text>
                <Text style={styles.text}><Text style={styles.smallText}>TVA</Text> {settings.shopSettings.contact.vat}</Text>
            </View>
            <View style={styles.headerColumnRight}>
                <Image src="/images/Good-Robot-logo.png" style={{ width: 80, height: 80 }} />
            </View>
    </View>
    <View style={styles.table}>
        <View style={[styles.tableRow, styles.thead]}>
          <View style={styles.tableFirstColumn}><Text style={[styles.tableCell, styles.strong]}>Description</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Taux TVA</Text></View>
          <View style={styles.tableColumn}><Text style={[styles.tableCellRight, styles.strong]}>Montant HTVA</Text></View>
        </View>
        <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Commission pour le mois de {invoiceData.monthYear}</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{VAT_RATE}%</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{formatAmount(commissionHTVA)}€</Text></View>       
        </View>
        <View style={styles.tableRow}>
            <View style={styles.tableFirstColumn}><Text style={styles.tableCell}>Frais de service pour le mois de {invoiceData.monthYear}</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{VAT_RATE}%</Text></View>
            <View style={styles.tableColumn}><Text style={styles.tableCellRight}>{formatAmount(invoiceData.totalServiceFees)}€</Text></View>       
        </View>  
        
        <View style={[styles.headerRow, styles.tfoot]}>
            <View style={styles.headerColumn}></View> 
              <View style={styles.headerColumn}>
              <View style={styles.tableRow}>
                <View style={styles.totalFirstColumn}>
                  <Text style={styles.tableCellRight}>Sous-total</Text>
                </View>
                <View style={styles.totalColumn}>
                  <Text style={styles.tableCellRight}>{formatAmount((commissionHTVA + invoiceData.totalServiceFees))}€</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.totalFirstColumn}>
                  <Text style={styles.tableCellRight}>Montant TVA</Text>
                </View>
                <View style={styles.totalColumn}>
                  <Text style={styles.tableCellRight}>{formatAmount((commissionHTVA + invoiceData.totalServiceFees) * VAT_DECIMAL )}€</Text>
                </View>
              </View>
              
              <View style={styles.tableRow}>
                <View style={styles.totalFirstColumn}>
                  <Text style={styles.tableCellRight}>Total TTC</Text>
                </View>
                <View style={styles.totalColumn}>
                  <Text style={styles.tableCellRight}>{formatAmount((commissionHTVA + invoiceData.totalServiceFees) * (1 + VAT_DECIMAL ))}€</Text>
                </View>
              </View>
            </View>
        </View>   
    </View>
    
    <View style={[styles.table, styles.detailsTable]}>
        <Text style={styles.text}>Détails des commandes</Text>
        <View style={[styles.tableRow, styles.thead]}>
          <View style={styles.detailsFirstColumn}><Text style={[styles.tableCell, styles.strong]}>Commande #</Text></View>
          <View style={styles.tableDetailColumn}><Text style={[styles.tableCellRight, styles.strong]}>Montant HTVA</Text></View>
          <View style={styles.tableDetailColumn}><Text style={[styles.tableCellRight, styles.strong]}>Taux </Text></View>
          <View style={styles.tableDetailColumn}><Text style={[styles.tableCellRight, styles.strong]}>Commission</Text></View>
          <View style={styles.tableDetailColumn}><Text style={[styles.tableCellRight, styles.strong]}>Frais de service</Text></View>

        </View>
        {invoiceData.orders
            .filter(order => order.paymentStatus === 'Paid' && order.status !== 'Cancelled')
            .map((order, index) => (
            <View key={index} style={styles.tableRow}>
                <View style={styles.detailsFirstColumn}><Text style={styles.tableCell}>#{order.id} {formatDateTime(order.createdAt)}</Text></View>
                <View style={styles.tableDetailColumn}><Text style={styles.tableCellRight}>{order.totalHTVA.toFixed(2).replace('.', ',')}€</Text></View>
                <View style={styles.tableDetailColumn}><Text style={styles.tableCellRight}>{settings.shopSettings.fees.commissionFee}%</Text></View>
                <View style={styles.tableDetailColumn}><Text style={styles.tableCellRight}> {(order.totalHTVA * settings.shopSettings.fees.commissionFee / 100).toFixed(2).replace('.', ',')}€</Text></View>       
                <View style={styles.tableDetailColumn}><Text style={styles.tableCellRight}> {order.serviceFee.toFixed(2).replace('.', ',')}€</Text></View>       
            </View>
        ))}
        
    </View>
    <Footer/>
    </Page>
  </Document>

)};

export default CommissionInvoice;
