import { Flex, Skeleton } from "@chakra-ui/react";


function TopCustomersSkeleton() {
  

  return (
   

        <Flex flex={1} direction="column" gap={4} w='100%' h='50px'>
          <Skeleton height='42px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='42px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='42px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='42px' borderRadius="var(--radius-sm)" flex={1} />
          <Skeleton height='42px' borderRadius="var(--radius-sm)" flex={1} />

        </Flex>
        

  );
}
export default TopCustomersSkeleton;
