import { useState, useEffect } from 'react';
import { db } from '../firebaseConfig';
import { collection, getDocs } from 'firebase/firestore';

const useAllRestaurants = () => {
    
  const [restaurants, setRestaurants] = useState([]);
    const [error, setError] = useState(null);

    const fetchRestaurants = async () => {
        try {
            const querySnapshot = await getDocs(collection(db, 'restaurants'));
            const fetchedRestaurants = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setRestaurants(fetchedRestaurants);
            setError(null);
        } catch (err) {
            console.error('Error fetching restaurants: ', err);
            setError(err);
        }
    };

    useEffect(() => {
        fetchRestaurants();
    }, []);

    const refreshRestaurants = async () => {
        await fetchRestaurants();
    };

    return { 
        restaurants, 
        refreshRestaurants, 
        error
    };
};

export default useAllRestaurants;
