import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const useLogout = (setIsLoggedIn, setUserRole, setLoading) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const auth = getAuth();
    setLoading(true); // Démarre le chargement pendant la déconnexion
    signOut(auth)
      .then(() => {
        // Réinitialiser les états après la déconnexion
        setIsLoggedIn(false);
        setUserRole(null);
        setLoading(false); // Arrêter le chargement après la déconnexion

        // Rediriger vers la page de login sans recharger la page
        navigate('/login');
      })
      .catch((error) => {
        console.error("Erreur lors de la déconnexion :", error);
        setLoading(false); // Arrêter le chargement même en cas d'erreur
      });
  };

  return handleLogout;
};

export default useLogout;
