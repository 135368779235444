import React, { useState } from "react";
import { useAppContext } from "../../contexts/AppContext";
import { IconButton, Text, ListItem, UnorderedList } from "@chakra-ui/react";
import { EllipsisVertical, FileUp, Import, Printer, QrCode } from "lucide-react";
import PdfSettingsModal from "./pdf/PdfSettingsModal";
import { useMenuContext } from "../../contexts/MenuContext";
import useImportExport from "../../hooks/useImportExport";
import { pdf } from "@react-pdf/renderer";
import QrPDF from "./pdf/QrPDF";
import { generateQRDataURL } from "./pdf/QrPDF";

const OptionsManager = () => {

  const {
    selectedRestaurantId,
    toggleSubMenu,
    showOverlay,
    closeSubMenu,
    openSubMenu,
    settings,
    mainLanguage,
  } = useAppContext();
  
  const { categories, itemsByCategory, menuItems, currentMainCategory } = useMenuContext();
  const { openImportModal  } = useImportExport();
  const [pdfModalIsOpen, setPdfModalIsOpen] = useState(false);

    
  const { handleExport } = useImportExport();

  const openPdfModal = () => {
    closeSubMenu();
    setPdfModalIsOpen(true);
  };
  const [pdfLanguage, setPdfLanguage] = useState(
    settings.language && settings.language.length > 0
      ? settings.language[0].value
      : ""
  );
  const closePdfModal = () => {
    setPdfModalIsOpen(false);
    setPdfLanguage("");
  };

  const openQrPdf = async () => {
    const qrCodeURL = await generateQRDataURL(`https://gudden.app/${selectedRestaurantId}/menu`);
    
    if (!qrCodeURL) {
      console.error("Failed to generate QR code URL.");
      return;
    }
  
    const blob = await pdf(<QrPDF qrCodeURL={qrCodeURL} />).toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
    closeSubMenu();
  };
  
  

  return (
    <>
   
          <IconButton 
            variant={"outline"}
            marginInlineStart={"0 !important"} 
            onClick={toggleSubMenu("CsvActions")}>
              <EllipsisVertical size={16} color="var(--grey700)" />
            </IconButton>

        {openSubMenu === "CsvActions" && (
          <div className="submenu">
            <UnorderedList>
              
              <ListItem
                onClick={() => {
                  openImportModal();
                  closeSubMenu();
                }}>
                  <Import size={16} color="var(--grey700)" /><Text color={"var(--grey700)"}>Import CSV</Text>
                  </ListItem>
              <ListItem
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={() => {
                  handleExport();
                  closeSubMenu();
                }}>
                  <FileUp size={16} color="var(--grey700)" /><Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>Export to CSV</Text>
              </ListItem>
              <ListItem 
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={openPdfModal}>
                <Printer size={16} color="var(--grey700)" /><Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>Print</Text>
              </ListItem>
              
              <ListItem 
                pointerEvents={menuItems.length === 0 ? "none" : "auto"}
                cursor={menuItems.length === 0 ? "not-allowed" : "pointer"}
                onClick={openQrPdf}>                  
                <QrCode size={16} color="var(--grey700)" /> <Text color={"var(--grey700)"} opacity={menuItems.length === 0 ? "0.5" : "1"}>QR Code</Text>
              </ListItem>
            
            </UnorderedList>
            <button onClick={closeSubMenu} className="closeSubmenu">
              Cancel
            </button>
          </div>
        )}
        {showOverlay && openSubMenu === "CsvActions" && (
          <div className="overlay" onClick={closeSubMenu}></div>
        )}

   
      <PdfSettingsModal
        onClose={closePdfModal}
        isOpen={pdfModalIsOpen}
        settings={settings}
        selectedLanguage={pdfLanguage}
        setSelectedLanguage={setPdfLanguage}
      />
    </>
  );
};

export default OptionsManager;