// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXvR5Wpn7tFXbxoRaeY1DR9ReRHGjQ-8s",
  authDomain: "menu-digital---demo.firebaseapp.com",
  projectId: "menu-digital---demo",
  storageBucket: "menu-digital---demo.appspot.com",
  messagingSenderId: "994039087969",
  appId: "1:994039087969:web:38a9eab7b39d09d9503b20"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialisation de Firestore
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);


export { db, auth, storage };