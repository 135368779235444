
const ArchiveItemActions = ({ item, closeSubMenu, onDelete, onRestore }) => {
  
  return (
    <ul>
      <li
        onClick={(e) => {
          e.stopPropagation();
          onRestore();
          closeSubMenu();
        }}>
        Send to menus
      </li>
      <li
        onClick={(e) => {
          e.stopPropagation(); 
          onDelete(item.id); 
          closeSubMenu();
        }}
        style={{ color: "var(--error)" }}>
        Delete item
      </li>
    </ul>
  );
};

export default ArchiveItemActions;
