import { useState, useEffect } from 'react';

const useStrictModeDroppable = () => {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    const handle = requestAnimationFrame(() => {
      setEnabled(true);
    });
    return () => cancelAnimationFrame(handle);
  }, []);

  return { enabled };
};

export default useStrictModeDroppable;
