import React, { memo, useCallback } from "react";
import {
  Flex,
  InputGroup,
  InputRightAddon,
  Input,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  TabPanel,
  Select,
  Text,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from "@chakra-ui/react";
import FontStyleSettings from "../FontStyleSettings";

const Style = memo(
  ({ settings, printSettings, setPrintSettings, setHasChanges }) => {
    const colorOptions = [
      { label: "Brand", value: settings.brandColor },
      { label: "Black", value: "#000000" },
      { label: "Dark Grey", value: "var(--grey700)" },
      { label: "Light Grey", value: "#667085" },
    ];

    const handleFontFamilyChange = useCallback((e, styleKey) => {
      const fontFamily = e.target.value;
      setPrintSettings((prev) => ({
        ...prev,
        fontStyles: {
          ...prev.fontStyles,
          [styleKey]: {
            ...prev.fontStyles[styleKey],
            fontFamily,
          },
        },
      }));
      setHasChanges(true);
    }, []);

    const handleFontWeightChange = (e, styleKey) => {
      const fontWeight = e.target.value;
      setPrintSettings((prev) => ({
        ...prev,
        fontStyles: {
          ...prev.fontStyles,
          [styleKey]: {
            ...prev.fontStyles[styleKey],
            fontWeight,
          },
        },
      }));
      setHasChanges(true);
    };

    const handleFontSizeChange = (e, styleKey) => {
      const fontSize = parseInt(e.target.value, 10);
      setPrintSettings((prev) => ({
        ...prev,
        fontStyles: {
          ...prev.fontStyles,
          [styleKey]: {
            ...prev.fontStyles[styleKey],
            fontSize,
          },
        },
      }));
      setHasChanges(true);
    };

    const handleColorChange = (color, styleKey) => {
      setPrintSettings((prev) => ({
        ...prev,
        fontStyles: {
          ...prev.fontStyles,
          [styleKey]: {
            ...prev.fontStyles[styleKey],
            color,
          },
        },
      }));
      setHasChanges(true);
    };

    const handleWidthChange = (value) => {
      setPrintSettings((prev) => ({
        ...prev,
        fontStyles: {
          ...prev.fontStyles,
          itemDescription: {
            ...prev.fontStyles.itemDescription,
            width: value, // Update the width property with the slider value
          },
        },
      }));
      setHasChanges(true);
    };

    return (
      <TabPanel>
        <Accordion
          defaultIndex={[0]}
          sx={{
            ".chakra-collapse": {
              overflow: "visible !important",
            },
            ".chakra-accordion__item": {
              borderTop: "1px solid var(--borders)",
              borderRadius: "0 !important",
              marginTop: "-1px",
            },
            ".chakra-accordion__panel": {
              p: 4,
            },
            ".chakra-accordion__button": {
              textAlign: "left",
              color: "var(--grey700)",
              fontWeight: "500",
              py: 3,
              fontSize: "var(--chakra-fontSizes-md)",
              justifyContent: "space-between",
              borderBottom: "1px solid var(--borders)",
              borderRadius: "0 !important",
            },
          }}>
          {/* Page size */}
          <AccordionItem key="page-settings">
            <AccordionButton>
              Page settings
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Flex
                direction="row"
                flexWrap="wrap"
                gap={2}
                flex={1}
                p={4}
                pt={2}>
                <Flex direction={"column"} gap={1}>
                  <Text
                    fontWeight={600}
                    fontSize={"10px"}
                    color={"var(--grey600)"}>
                    Page format
                  </Text>
                  <Select
                    name="pageSize"
                    id="pageSize"
                    value={printSettings.pageSize}
                    onChange={(e) => {
                      setPrintSettings((prev) => ({
                        ...prev,
                        pageSize: e.target.value,
                      }));
                      setHasChanges(true);
                    }}>
                    <option value="A3">A3</option>
                    <option value="A4">A4</option>
                    <option value="A5">A5</option>
                  </Select>
                </Flex>{" "}
              </Flex>
              <Flex
                direction="row"
                flexWrap="wrap"
                gap={2}
                flex={1}
                p={4}
                pt={2}>
                {["Top", "Bottom", "Left", "Right"].map((side) => (
                  <Flex direction={"column"} gap={1}>
                    <Text
                      fontWeight={600}
                      fontSize={"10px"}
                      color={"var(--grey600)"}>
                      Margin {side}
                    </Text>
                    <InputGroup
                      key={side}
                      justifyContent="flex-end"
                      w="fit-content">
                      <Input
                        maxW="48px"
                        borderRadius="8px 0 0 8px !important"
                        type="number"
                        name={`margin${side}`}
                        id={`margin${side}`}
                        value={printSettings.margins[side.toLowerCase()]}
                        onChange={(e) => {
                          setPrintSettings((prev) => ({
                            ...prev,
                            margins: {
                              ...prev.margins,
                              [side.toLowerCase()]: parseInt(
                                e.target.value,
                                10
                              ),
                            },
                          }));
                          setHasChanges(true);
                        }}
                      />
                      <InputRightAddon
                        children="mm"
                        bg="var(--grey50)"
                        border="none"
                        boxShadow="0 0 0 1.5px var(--borders)"
                      />
                    </InputGroup>
                  </Flex>
                ))}
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          {/* Category Title Section */}
          <AccordionItem key="category-title">
            <AccordionButton>
              Category Title
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                label="Category title"
                fontSettings={printSettings.fontStyles.categoryTitle}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "categoryTitle")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "categoryTitle")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "categoryTitle")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "categoryTitle")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Category Price Section */}
          <AccordionItem key="category-price">
            <AccordionButton>
              Category Price
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.categoryPrice}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "categoryPrice")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "categoryPrice")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "categoryPrice")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "categoryPrice")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Category Tagline Section */}
          <AccordionItem key="category-tagline">
            <AccordionButton>
              Category Tagline
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.categoryTagline}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "categoryTagline")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "categoryTagline")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "categoryTagline")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "categoryTagline")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Item Name Section */}
          <AccordionItem key="item-name">
            <AccordionButton>
              Item Name
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.itemName}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "itemName")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "itemName")
                }
                onFontSizeChange={(e) => handleFontSizeChange(e, "itemName")}
                onColorChange={(color) => handleColorChange(color, "itemName")}
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Item Description Section */}
          <AccordionItem key="item-description">
            <AccordionButton>
              Item Description
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel p={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.itemDescription}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "itemDescription")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "itemDescription")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "itemDescription")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "itemDescription")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
              <Flex direction="column" mt={4}>
                <Text fontWeight={600} fontSize="10px" color="var(--grey600)">
                  Width
                </Text>
                <Slider
                  defaultValue={
                    printSettings.fontStyles.itemDescription.width || 85
                  }
                  min={20}
                  max={100}
                  step={5}
                  colorScheme="brand"
                  size={"md"}
                  onChange={(val) => handleWidthChange(val)}>
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb boxSize={5} />
                </Slider>
                <Text fontSize="12px" color="var(--grey600)">
                  {printSettings.fontStyles.itemDescription.width || 85}%
                </Text>
              </Flex>
            </AccordionPanel>
          </AccordionItem>

          {/* Item Price Section */}
          <AccordionItem key="item-price">
            <AccordionButton>
              Item Price
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.itemPrice}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "itemPrice")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "itemPrice")
                }
                onFontSizeChange={(e) => handleFontSizeChange(e, "itemPrice")}
                onColorChange={(color) => handleColorChange(color, "itemPrice")}
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Item Variations Section */}
          <AccordionItem key="item-variations">
            <AccordionButton>
              Item Variations
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.itemVariations}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "itemVariations")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "itemVariations")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "itemVariations")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "itemVariations")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>

          {/* Item Variations Price Section */}
          <AccordionItem key="item-variations-price">
            <AccordionButton>
              Item Variations Price
              <AccordionIcon color={"var(--grey700)"} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <FontStyleSettings
                fontSettings={printSettings.fontStyles.itemVariationsPrice}
                onFontFamilyChange={(e) =>
                  handleFontFamilyChange(e, "itemVariationsPrice")
                }
                onFontWeightChange={(e) =>
                  handleFontWeightChange(e, "itemVariationsPrice")
                }
                onFontSizeChange={(e) =>
                  handleFontSizeChange(e, "itemVariationsPrice")
                }
                onColorChange={(color) =>
                  handleColorChange(color, "itemVariationsPrice")
                }
                colorOptions={colorOptions}
                setHasChanges={setHasChanges}
              />
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </TabPanel>
    );
  }
);

export default Style;
