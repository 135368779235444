// AppContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc, getDoc, onSnapshot} from 'firebase/firestore';
import { useSelectedRestaurant } from './SelectedRestaurantContext';

const AppContext = createContext();

export const useAppContext = () => useContext(AppContext);
export const AppProvider = ({ children, userRole, setUserRole }) => {
  
  const [selectedRestaurantId, setSelectedRestaurantId] = useState('');
  const [isSelectedRestaurantLoading, setIsSelectedRestaurantLoading] = useState(true);

  const [userData, setUserData] = useState({});
  
  
  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(db, "users", user.uid);
      const unsubscribe = onSnapshot(userDocRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          setUserData({ ...userData, userId: user.uid });
          setUserRole(userData.role);
          setUserRestaurantIds(userData.restaurantIds || []);
          if (
            userData.role === "restaurantOwner" &&
            userData.restaurantIds &&
            userData.restaurantIds.length > 0
          ) {
            setSelectedRestaurantId(userData.restaurantIds[0]);
          }
        }
      });
  
      return () => unsubscribe(); // Cleanup the subscription
    }
  }, [auth.currentUser]);  


  const userId = userData.userId; 

  const [userRestaurantIds, setUserRestaurantIds] = useState([]);

  const [mainCategoryName, setMainCategoryName] = useState(null);
  
  const [menuAdminBarZIndex, setMenuAdminBarZIndex] = useState(3);
  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
	



  
  const [generalSettings, setGeneralSettings] = useState([]);

    const [settings, setSettings] = useState({ 
    language: [{ value: '', label: '', flag: '' }],
    secondaryLanguages: [],
    brandColor: '', 
    backgroundColor: '', 
    textColor: '', 
    logo: {
      menu: '',
      shop: '',
    },
    backgroundImage: '', 
    menuUrl: '',
    reservationsSettings: {
      serviceDuration: 60,
    },
    shopSettings: {
      takeAway: {
        activation: 'false',
        minimumOrderAmount: 0,
      },
      delivery: {
        activation: 'false',
        minimumOrderAmount: 0,
        zone: [],
      },
      contact: {
        shopManagerEmail: '',
        phoneNumber:'',
        address: '',
        companyName: '',
        vat:'',
        rcs: '',
      },
      paymentMethods: {
        cash: {
          activation: false,
        },  
        stripe: {
          activation: false,
        },
      },
      fees: {
        commissionFee: '',
      },
      closingDays: [],
    },
  });
  const [isSettingsLoading, setIsSettingsLoading] = useState(false);

  const [stripeKeys, setStripeKeys] = useState({
    PublicLive: '',
    SecretLive: '',
    PublicTest: '',
    SecretTest: '',
  });
  const mainLanguage = settings.language[0]?.value;

  const [isCollapsed, setIsCollapsed] = useState(() => {
    const initialCollapsed = window.innerWidth < 1281;
    return JSON.parse(localStorage.getItem("isCollapsed")) ?? initialCollapsed;
  });
  
    

  const fetchSettings = async () => {
    if (!isSelectedRestaurantLoading && selectedRestaurantId) {
      setIsSettingsLoading(true);
      const settingsRef = doc(db, `restaurants/${selectedRestaurantId}/settings`, 'config');
      try {
        const docSnap = await getDoc(settingsRef);
        if (docSnap.exists()) {
          setSettings(docSnap.data());
        }
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setIsSettingsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!isSelectedRestaurantLoading && selectedRestaurantId) {
      fetchSettings();
    }
  }, [selectedRestaurantId, isSelectedRestaurantLoading]);


useEffect(() => {
  const fetchGeneralSettings = async () => {
    const settingsRef = doc(db, "general/settings");
    const docSnap = await getDoc(settingsRef);
    if (docSnap.exists()) {
      const data = docSnap.data();
      setGeneralSettings(data);
    } else {
      console.log("No such document!");
    }
  };
  fetchGeneralSettings();
}, []);
 


  const toggleSubMenu = (submenuId) => () => {
    if (openSubMenu === submenuId) {
      setOpenSubMenu(null);
      setMenuAdminBarZIndex(3);
      setShowOverlay(false);
    } else {
      setOpenSubMenu(submenuId);
      setShowOverlay(true);
    }
  };
    
  
  const closeSubMenu = () => {
    setOpenSubMenu(null);
    setShowOverlay(false);
    setMenuAdminBarZIndex(3);
  };


  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (openSubMenu && !event.target.closest('.submenu') && !event.target.closest('.subMenuToggle')) {
        closeSubMenu();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [openSubMenu]);

  
  const loadStripeKeys = async () => {
    if (selectedRestaurantId) {
      const docRef = doc(db, `stripeConfigs/${selectedRestaurantId}`);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setStripeKeys(docSnap.data());
      } else {
        console.log("Aucune configuration Stripe trouvée.");
      }
    }
  };





  

  const value = {
    selectedRestaurantId,
    setSelectedRestaurantId,
    menuAdminBarZIndex,
    setMenuAdminBarZIndex,
    openSubMenu,
    toggleSubMenu,
    closeSubMenu,
    showOverlay,
    setShowOverlay,
    mainCategoryName,
    setMainCategoryName,
    generalSettings,
    settings,
    setSettings,
    fetchSettings,
    mainLanguage,
    stripeKeys,
    setStripeKeys,
    loadStripeKeys,
    userRole,
    setUserRole,
    userId,
    userData,
    setUserData,
    userRestaurantIds,
    setUserRestaurantIds,
    isCollapsed,
    setIsCollapsed,
    isSelectedRestaurantLoading, setIsSelectedRestaurantLoading,
    isSettingsLoading, setIsSettingsLoading

  };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  );
};
