import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReservationStatusSelector from "../components/reservations/ReservationStatusSelector";
import useReservations from "../hooks/useReservations";
import { useAppContext } from "../contexts/AppContext";
import { Flex, Box, Text, Table, Tbody, Thead, Th, Tr, Td, Button, Tooltip, IconButton } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, Calendar, Clock, Plus, Settings } from 'lucide-react';
import DatePicker from 'react-datepicker';
import enGB from 'date-fns/locale/en-GB';
import 'react-datepicker/dist/react-datepicker.css';
import AddReservation from "../components/reservations/AddReservation";
import useOrders from "../hooks/useOrders";
import useDates from "../hooks/useDates";

const Reservations = () => {
  const { selectedRestaurantId } = useAppContext();
  const [selectedDate, setSelectedDate] = useState(new Date());

  const { reservations, updateReservationStatus, isReservationModalOpen, setIsReservationModalOpen } = useReservations({ selectedDate });
  const [selectedReservation, setSelectedReservation] = useState(null);
  const { formatScheduledTime } = useOrders();
  const [isToday, setIsToday] = useState(true);
  const [isDateLoaded, setIsDateLoaded] = useState(false);
  const { getDateLabel } = useDates();

  const handleBackToToday = () => {
    setSelectedDate(new Date());
  };


  useEffect(() => {
    const savedDate = localStorage.getItem("selectedReservationDate");
    if (savedDate) {
      const parsedDate = new Date(savedDate);
      if (!isNaN(parsedDate)) {
        setSelectedDate(parsedDate);
      }
    }
    setIsDateLoaded(true); // Date récupérée et chargée
  }, []);

  // Sauvegarder la date sélectionnée dans localStorage
  useEffect(() => {
    if (isDateLoaded) {
      localStorage.setItem("selectedReservationDate", selectedDate.toISOString());
    }
  }, [selectedDate, isDateLoaded]);
  

  useEffect(() => {
    // Vérifier si la date sélectionnée est aujourd'hui
    const today = new Date();
    setIsToday(
      selectedDate.getDate() === today.getDate() &&
        selectedDate.getMonth() === today.getMonth() &&
        selectedDate.getFullYear() === today.getFullYear()
    );
  }, [selectedDate]);
  




  const openReservationModal = (reservation = null) => {
    setSelectedReservation(reservation);
    setIsReservationModalOpen(true);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const goPreviousDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() - 1);
      return newDate;
    });
  };

  const goNextDay = () => {
    setSelectedDate(prevDate => {
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 1);
      return newDate;
    });
  };

  
  if (!selectedRestaurantId) {
    return <div>Error: Restaurant not selected. Please select a restaurant.</div>;
  }
 

  return (
    <Flex w='100%' direction="column" gap={8}>
          <Flex justifyContent="space-between" direction={{ desktop: "row", mobile: "column"}} gap={4}>
          <Flex gap={4} alignItems={"center"}>
            <h3>Reservations</h3>
            <Tooltip label="Add a new reservation">
              <IconButton size="xs" bg={"var(--brand)"} _hover={{ bg: 'var(--brand600)'}} onClick={() => openReservationModal()}>
              <Plus color="white" size={16}  />
            </IconButton>
            </Tooltip>
          </Flex>
      
        <Flex alignItems="center" gap="2">
          <Flex alignItems="center" justifyContent="space-between" gap={0} border={"1px solid var(--borders)"} borderRadius={8} w="fit-content">
            <DatePicker
              selected={selectedDate}
              onChange={handleDateChange}
              locale={enGB}
              dateFormat="dd/MM/yyyy"
              customInput={
                <Button
                  as="button"
                  gap={0}
                  justifyContent={"flex-start"}
                  leftIcon={<Calendar size={20} color="var(--grey500)" />}
                  color={"var(--grey700)"}
                  bg='white'
                  borderRadius={8}
                  _hover={{ 
                    color: 'var(--brand)',
                  }}
                  _focus={{ 
                    color: 'var(--brand)',
                  }}
                  _focusVisible={{ 
                    color: 'var(--brand)',
                  }}
                  cursor={"pointer"}
                  px={2}
                  minWidth="140px"
                >
                  {getDateLabel(selectedDate)}
                </Button>
              }
            />
            <Flex>
              <Tooltip label="Previous Day">
                <IconButton
                  bg='white'
                  _hover={{ 
                    bg: 'white',
                    color: 'var(--grey700)',
                  }}
                  size='sm'
                  icon={<ChevronLeft />}
                  onClick={goPreviousDay} // Jour précédent
                  aria-label="Previous Day"
                />
              </Tooltip>

              <Tooltip label="Next Day">
                <IconButton
                  size='sm'
                  bg='white'
                  _hover={{ 
                    bg: 'white',
                    color: 'var(--grey700)',
                  }}
                  borderRadius='0 var(--chakra-radii-md) var(--chakra-radii-md) 0'
                  icon={<ChevronRight />}
                  onClick={goNextDay}
                  aria-label="Next Day"
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Button
                  onClick={handleBackToToday}
                  variant={"outline"}
                  isDisabled={isToday}
                  >
                  Today
              </Button>
          <Tooltip label="Reservations settings">
          <IconButton as={Link} variant="outline" icon={<Settings />} to={`/${selectedRestaurantId}/settings?tab=reservations`}></IconButton>

              </Tooltip>
        </Flex>
      </Flex>

      <Flex w='100%' direction="column" gap={8}>
        {reservations.length > 0 ? (
          <div className="tableWrapper">
            <Table w="100%">
              <Thead display={{ desktop: "table-header-group", mobile: "none" }}>
                <Tr className="reservationHeader">
                  <Th>Scheduled time</Th>
                  <Th>Customer</Th>
                  <Th isNumeric>Guests</Th>
                  <Th>Preference</Th>
                  <Th>Assigned table</Th>
                  <Th>Status</Th>
                </Tr>
              </Thead>
              <Tbody fontSize="14px">
                {reservations.map((reservation) => (
                  <Tr key={reservation.id} className="reservation" _hover={{ bg: 'var(--grey25)' }}>
                    <Td>
                      <Flex className="tag" alignItems="center" gap={1}>
                        <Clock size={16} color="var(--grey500)" />
                        {reservation.scheduledTime
                          ? formatScheduledTime(reservation.scheduledTime.toDate())
                          : "N/A"}
                      </Flex>
                    </Td>
                    <Td>
                      <b>{reservation.customer.firstName}{" "}{reservation.customer.lastName}</b>
                    </Td>
                    <Td isNumeric>
                      <span className="tag">{reservation.guests}</span>                    
                    </Td>
                    <Td>
                      <Text>{reservation.preference}</Text>
                    </Td>
                    <Td>
                      <Flex gap={2} direction="row">
                        {reservation.tables.map((table, index) => (
                          <Box key={index} className="tag">{table}</Box>
                        ))}
                      </Flex>
                    </Td>
                    <Td>
                      <ReservationStatusSelector 
                        currentStatus={reservation.status}
                        onUpdateStatus={updateReservationStatus}
                        reservationId={reservation.id}
                      />
                    </Td>
                    
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </div>
        ) : (
          <p>No reservations found.</p>
        )}
      </Flex>

      <AddReservation
        isOpen={isReservationModalOpen}
        onClose={() => setIsReservationModalOpen(false)}
        reservation={selectedReservation}
      />
    </Flex>
  );
};

export default Reservations;
