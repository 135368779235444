import { Page, Image, View, Document, StyleSheet, Font, Text } from "@react-pdf/renderer";
import QRCode from 'qrcode';

Font.register({
  family: "Poppins",
  fonts: [

    {
      src: "/fonts/Poppins/Poppins-Medium.ttf",
      fontWeight: 500,
    },
    {
      src: "/fonts/Poppins/Poppins-SemiBold.ttf",
      fontWeight: 600,
    },
   
  ],
});



export async function generateQRDataURL(value) {
  try {
    const url = await QRCode.toDataURL(value, { errorCorrectionLevel: 'H', type: 'image/jpeg' }); // try with JPEG format
    return url;
  } catch (err) {
    console.error("Error generating QR code:", err);
    return null;
  }
}

const styles = StyleSheet.create({
  page: {
    fontFamily: "Poppins",
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  printQR: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "2mm",
    padding: "8mm",
  },
  image: {
    height: 80,
    width: 80,
    border: "2px solid #000",
    padding: "1px"
  },
  title: {
    fontSize: "13pt",
    fontWeight: "600",
    fontFamily: "Poppins",
    textAlign: "center"
  },
  text: {
    fontSize: "8pt",
    fontWeight: "500",
    fontFamily: "Poppins",
    textAlign: "center"
  }
});

const QrPDF = ({ qrCodeURL }) => {
  return (
    <Document>
      <Page size={[148, 210]} style={styles.page} wrap>
        <View style={styles.printQR}>
          {qrCodeURL ? (
            <Image src={qrCodeURL} style={styles.image} />
          ) : (
            <Text style={styles.text}>Generating QR Code...</Text>
          )}
          <Text style={styles.title}>Scan to view our menu</Text>
        </View>
      </Page>
    </Document>
  );
};
export default QrPDF;
