import { useState, useEffect } from "react";
import { db } from "../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useAppContext } from "../contexts/AppContext";
import { toast } from 'react-toastify';


const useOpeningHours = () => {
  const { selectedRestaurantId } = useAppContext();
  const [openingHours, setOpeningHours] = useState([]);
  
  
  const fetchOpeningHours = async () => {
    if (!selectedRestaurantId) {
      console.log("No restaurant ID provided!");
      return; // Quitte la fonction si aucun ID de restaurant n'est fourni
    }
    
    const restaurantRef = doc(db, "restaurants", selectedRestaurantId);
    const docSnap = await getDoc(restaurantRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      setOpeningHours(data.openingHours || []);
    } else {
      console.log("Restaurant document not found!");
    }
  };

  useEffect(() => {
    if (selectedRestaurantId) {
      fetchOpeningHours();
    }
  }, [selectedRestaurantId]);

  
  const handleSaveOpeningHours = async () => {
    const restaurantRef = doc(db, "restaurants", selectedRestaurantId);
    try {
      await updateDoc(restaurantRef, { openingHours });
      console.log("Opening hours updated successfully");
      toast.success("Opening hours saved successfully");
      fetchOpeningHours();
    } catch (error) {
      console.error("Error updating opening hours:", error);
      toast.error("Error saving opening hours");
    }
  };

  const handleSlotChange = (dayIndex, slotIndex, field, value) => {
    const newOpeningHours = [...openingHours];
    const daySlots = newOpeningHours[dayIndex].slots || [];
    let newValue = value;
      if ((field === 'start' || field === 'end') && !value.includes(':')) {
      newValue += ':00';
    }
  
    daySlots[slotIndex] = { ...daySlots[slotIndex], [field]: newValue };
    newOpeningHours[dayIndex].slots = daySlots;
    setOpeningHours(newOpeningHours);
  };
  
  
  const handleAddSlot = (dayIndex) => {
    const newOpeningHours = [...openingHours];
    const daySlots = newOpeningHours[dayIndex].slots || [];
    daySlots.push({ start: '12:00', end: '14:00' });
    newOpeningHours[dayIndex].slots = daySlots;
    setOpeningHours(newOpeningHours);
  };
  
  
  const handleRemoveSlot = (dayIndex, slotIndex) => {
    const newOpeningHours = [...openingHours];
    newOpeningHours[dayIndex].slots = newOpeningHours[dayIndex].slots.filter((_, index) => index !== slotIndex);
    setOpeningHours(newOpeningHours);
  };
  

  return { 
    openingHours,
    setOpeningHours, 
    handleSaveOpeningHours,
    handleAddSlot,
    handleSlotChange,
    handleRemoveSlot
  };
};

export default useOpeningHours;
