import Modal from 'react-modal';
import useModal from '../../hooks/useModal';
import { Button, Divider, Box, AbsoluteCenter, Text } from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import useImportExport from '../../hooks/useImportExport';


Modal.setAppElement('#root');

const ImportModal = ({ isOpen, onClose  }) => {
  
  const { handleStartImport, selectedFile, setSelectedFile, message, setMessage, importIsLoading, setImportIsLoading  } = useImportExport();
    
  const {customStyles} = useModal();
 

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setSelectedFile(acceptedFiles[0]);
    },
    accept: ".csv",
  });
  
  const resetState = () => {
    setSelectedFile(null);
    setMessage("");
    setImportIsLoading(0);
  };
  
  const handleClose = () => {
    resetState(); // Réinitialiser les états
    onClose(); // Fermer le modal
  };
  
  

  if (!isOpen) return null;

  return (
      <Modal
        isOpen={isOpen}
        onRequestClose={handleClose}
        className="modal"
        style={customStyles}>
            <div className="modal-content">

            <div className="modalTopBar">
            <div className="closeModal">
              <span className="close" onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
                    fill="black"
                  />
                </svg>
              </span>
            </div>
            <div className="modalTitle">
              Import .csv
            </div>
            <div className="actionButtons"></div>
          </div>


        <div className="modalInner">
        <div {...getRootProps()} className="dropzone">
          <input {...getInputProps()} />
          <h6>Drag and drop a .csv file</h6>
          <Box position='relative' padding='10'>
  <Divider />
  <AbsoluteCenter bg='white' px='2' fontSize="12px">
    or
  </AbsoluteCenter>
</Box>
<Text textDecoration="underline" fontSize="14px">Click to select a file</Text>
        </div>
        {selectedFile && <p>File ready to import: {selectedFile.name}</p>}
        {message && <p>{message}</p>}
        <div className="progress-bar">
          <div className="progress" style={{ width: `${importIsLoading}%` }}></div>
        </div>
        {selectedFile && importIsLoading === 0 && (
          <Button  size="lg" onClick={handleStartImport} className="primary">
            Start Import
          </Button>
        )}
        {importIsLoading > 0 && importIsLoading < 100 && (
          <button disabled className="primary">
            Importing...
          </button>
        )}
        </div>
        </div>
      </Modal>
  );
};

export default ImportModal;
